<template>
  <el-dialog
    :title="!dataForm.id ? '审核' : '审核'"
    :close-on-click-modal="false"
    :visible.sync="visible">
    <el-form :model="dataForm" :rules="dataRule"  size="small" ref="dataForm" @keyup.enter.native="dataFormSubmit()" label-width="80px"
     v-loading="dataLoading" style="width:100%;"  element-loading-text=""  element-loading-spinner="el-icon-loading"
    >
      <el-form-item prop="state">
        <!--<el-input v-model="dataForm.state" placeholder="售后状态"  size="small"></el-input>-->
        <template>
        <el-radio v-model="dataForm.state" label="4" @change="consent()">同意</el-radio>
        <el-radio v-model="dataForm.state" label="1" @change="callOff()">取消</el-radio>
        </template>
      </el-form-item>
      <el-form-item label="备注" prop="description" v-if="showBzu">
        <el-input v-model="dataForm.description" type="textarea" size="small" placeholder="请输入取消备注" maxlength="50" show-word-limit></el-input>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="visible = false" size="small">返回</el-button>
      <el-button type="primary" @click="dataFormSubmit()" size="small"  :disabled="dataLoading">确定</el-button>
    </span>
  </el-dialog>
</template>

<script>
  import {checkOrder,getCheckState} from '@/api/modules/sampleaftersale'
  export default {
    data () {
      return {
          showBzu:false,
        visible: false,
        dataLoading: false,
        dataForm: {
          id: 0,
          description: '',
          state: ''
        },
          dataRule: {
              description: [
                  { required: true, message: '备注不能为空', trigger: 'blur' }
              ],
              state: [
           { required: true, message: '审核状态', trigger: 'blur' }
              ],
          }
      }
    },
    methods: {
        //取消显示备注信息填写
        callOff(){
            this.showBzu = true;
        },
        consent(){
            this.showBzu = false;
            this.dataForm.description = ""
        },
      init (id) {
        this.dataForm.id = id || 0
           //= ""
          console.log(this.dataForm.state)

        this.visible = true
        this.$nextTick(() => {
          this.$refs['dataForm'].resetFields()
          if (this.dataForm.id) {
              getCheckState({id:this.dataForm.id}).then(({data}) => {
              if (data && data.code === 0) {
                /*this.dataForm.description = data.sampleaftersale.description*/
                  if (data.dataState.description!=null){
                      this.dataForm.state = data.dataState.state
                      this.dataForm.description = data.dataState.description
                  }else {
                      this.dataForm.state = data.dataState
                  }
                  this.showBzu = this.dataForm.state=='1'?true:false
              }
            })
          }
        })
      },
      // 表单提交
      dataFormSubmit () {
        this.$refs['dataForm'].validate((valid) => {
          if (valid) {
            this.dataLoading = true;
            var params = {
              'aftersaleId': this.dataForm.id || undefined,
              /*'sampleOrderId': this.dataForm.sampleOrderId,
              'sampleOrderNo': this.dataForm.sampleOrderNo,
              'returnMoney': this.dataForm.returnMoney,
              'reason': this.dataForm.reason,
              'returnPic': this.dataForm.returnPic,*/
              'description': this.dataForm.description,
              'state': this.dataForm.state,
              /*'customerReply': this.dataForm.customerReply,
              'delFlag': this.dataForm.delFlag,
              'createBy': this.dataForm.createBy,
              'createDate': this.dataForm.createDate,
              'updateBy': this.dataForm.updateBy,
              'updateDate': this.dataForm.updateDate,
              'remarks': this.dataForm.remarks*/
            }
            var tick = !this.dataForm.id ? checkOrder(params) : checkOrder(params)
            tick.then(({data}) => {
              if (data && data.code === 0) {
                this.$message({
                  message: '操作成功',
                  type: 'success',
                  duration: 1500,
                  onClose: () => {
                    this.visible = false;
                  //    this.showBzu = false;
                    this.dataLoading = false;
                    this.$emit('refreshDataList')
                  }
                })
              } else {
                this.$message.error(data.msg)
                this.dataLoading = false;
              }
            })
          }
        })
      }
    }
  }
</script>
