<template>
  <div class="app-container">
    <el-form :inline="true" :model="dataForm" @keyup.enter.native="getDataList()">
      <el-form-item  prop="orderNumber">
        <el-input v-model="dataForm.orderNumber" placeholder="订单编号" clearable></el-input>
      </el-form-item>
      <el-form-item  prop="title">
        <el-input v-model="dataForm.title" placeholder="商品标题" clearable></el-input>
      </el-form-item>
      <el-form-item  prop="mallGoodsCatgeoryId">
        <el-select v-model="dataForm.mallGoodsCatgeoryId" placeholder="商品分类">
          <el-option
            v-for="item in categoryList"
            :key="item.categoryName"
            :label="item.categoryName"
            :value="item.mallGoodsCategoryId"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item  prop="orderStatus">
        <el-select v-model="dataForm.orderStatus" placeholder="商品状态">
          <el-option
            v-for="item in statusList"
            :key="item.name"
            :label="item.name"
            :value="item.id"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item >
        <div class="block">
          <el-date-picker
            v-model="dataForm.orderTime"
            value-format="yyyy-MM-dd"
            type="daterange"
            editable="false"
            range-separator="至"
            start-placeholder="下单时间开始"
            end-placeholder="下单时间结束"
          ></el-date-picker>
        </div>
      </el-form-item>
      <el-form-item>
        <el-button @click="restPage()">查询</el-button>
        <el-button @click="reset('dataForm')">清空</el-button>
      </el-form-item>
    </el-form>
    <el-table
      :data="dataList"
      border
      v-loading="dataListLoading"
      @selection-change="selectionChangeHandle"
      style="width: 100%;"
    >
      <el-table-column type="selection" header-align="center" align="center" width="50"></el-table-column>
      <el-table-column prop="orderNumber" header-align="center" align="center" label="订单编号"></el-table-column>
      <el-table-column prop="title" header-align="center" align="center" label="商品标题"></el-table-column>
      <el-table-column prop="categoryName" header-align="center" align="center" label="分类"></el-table-column>
      <el-table-column prop="totalSkuPayPrice" header-align="center" align="center" label="购买价格"></el-table-column>
      <el-table-column prop="buyNum" header-align="center" align="center" label="购买数量"></el-table-column>
      <el-table-column prop="personalName" header-align="center" align="center" label="收货人"></el-table-column>
      <el-table-column prop="personalMobile" header-align="center" align="center" label="收货电话"></el-table-column>
      <el-table-column prop="deliveryAddress" header-align="center" align="center" label="收货地址"></el-table-column>
      <el-table-column prop="orderTime" header-align="center" align="center" label="下单时间"></el-table-column>
      <el-table-column prop="orderStatus" header-align="center" align="center" label="状态">
        <template slot-scope="scope">
          <i v-if="scope.row.orderStatus=='unpaid'">未支付</i>
          <i v-else-if="scope.row.orderStatus=='paid'">已支付</i>
          <i v-else-if="scope.row.orderStatus=='refund'">已取消</i>
          <i v-else-if="scope.row.orderStatus=='delivered'">已发货</i>
          <i v-else-if="scope.row.orderStatus=='trade_finished'">交易完成</i>
          <i v-else></i>
        </template>
      </el-table-column>
      <el-table-column fixed="right" header-align="center" align="center" width="150" label="操作">
        <template slot-scope="scope">
          <el-button
            v-if="isAuth('mall:ordermallbuy:info')"
            type="text"
            size="small"
            @click="addOrUpdateHandle(scope.row.orderMallBuyId)"
          >详情</el-button>
          <el-button
            v-if="isAuth('mall:ordermallbuy:update')&&scope.row.orderStatus=='paid'"
            type="text"
            size="small"
            @click="deliveryOrderHandle(scope.row.orderMallBuyId)"
          >发货</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      @size-change="sizeChangeHandle"
      @current-change="currentChangeHandle"
      :current-page="pageIndex"
      :page-sizes="[10, 20, 50, 100]"
      :page-size="pageSize"
      :total="totalPage"
      layout="total, sizes, prev, pager, next, jumper"
    ></el-pagination>
    <!-- 弹窗, 新增 / 修改 -->
    <add-or-update v-if="addOrUpdateVisible" ref="addOrUpdate" @refreshDataList="getDataList"></add-or-update>

    <!-- 发货-->
    <el-dialog title="发货" :visible.sync="deliveryOrderVisible">
      <el-form :model="deliveryForm" label-width="150px">
        <el-form-item label="物流公司：" prop="logisticsId">
          <el-select v-model="deliveryForm.logisticsId" placeholder="请选择物流公司">
            <el-option
              v-for="item in companyList"
              :key="item.logisticsName"
              :label="item.logisticsName"
              :value="item.logisticsId"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="物流单号：" prop="logisticsNumber">
          <el-input v-model="deliveryForm.logisticsNumber" placeholder="请输入物流单号"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="deliveryOrderVisible = false">取 消</el-button>
        <el-button type="primary" @click="deliveryOrder(deliveryForm.orderMallBuyId)">发 货</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import AddOrUpdate from "./components/order-add-or-update";
import { orderMallBuyList, deliveryOrder, findLogisticList } from "@/api/sys";
export default {
  data() {
    return {
      dataForm: {
        orderNumber: "",
        title: "",
        categoryName: "",
        totalSkuPayPrice: "",
        mallGoodsCatgeoryId: "",
        buyNum: "",
        deliveryAddress: "",
        personalName: "",
        personalMobile: "",
        orderTime: [],
        orderStatus: ""
      },
      deliveryForm: {
        orderMallBuyId: "",
        logisticsId: "",
        logisticsNumber: ""
      },
      // 分类id
      categoryList: [],
      statusList: [
        {
          id: "unpaid",
          name: "未支付"
        },
        {
          id: "paid",
          name: "已支付"
        },
        {
          id: "refund",
          name: "已取消"
        },
        {
          id: "delivered",
          name: "已发货"
        },
        {
          id: "trade_finished",
          name: "交易完成"
        }
      ],
      companyList: [],
      dataList: [],
      pageIndex: 1,
      pageSize: 10,
      totalPage: 0,
      dataListLoading: false,
      dataListSelections: [],
      addOrUpdateVisible: false,
      deliveryOrderVisible: false
    };
  },
  components: {
    AddOrUpdate
  },
  methods: {
    // 查询清空 修改  by葛海军
    reset(dataForm) {
      this.dataForm.orderNumber = "";
      this.dataForm.title = "";
      this.dataForm.mallGoodsCatgeoryId = "";
      this.dataForm.orderStatus = "";
      this.dataForm.orderTime = [];
      this.restPage();
    },
    // 跳到第一页进行查询
    restPage() {
      this.pageIndex = 1;
      this.getDataList();
    },
    // 获取数据列表
    async getDataList() {
      this.dataListLoading = true;
      var arrOrderTime = this.dataForm.orderTime;
      var strOrderTime = "";
      var startDate = null;
      var endDate = null;
      if (arrOrderTime.length > 0) {
        startDate = arrOrderTime[0];
        endDate = arrOrderTime[1];
      }
      let params = {
        page: this.pageIndex,
        limit: this.pageSize,
        orderNumber: this.dataForm.orderNumber,
        title: this.dataForm.title,
        mallGoodsCatgeoryId: this.dataForm.mallGoodsCatgeoryId,
        orderStatus: this.dataForm.orderStatus,
        startDate: startDate,
        endDate: endDate
      };
      const { data } = await orderMallBuyList(params);
      if (data && data.code === 0) {
        this.dataList = data.page.list;
        this.totalPage = data.page.totalCount;
        this.categoryList = data.categoryList;
      } else {
        this.dataList = [];
        this.totalPage = 0;
        this.categoryList = [];
      }
      this.dataListLoading = false;
    },
    // 每页数
    sizeChangeHandle(val) {
      this.pageSize = val;
      this.pageIndex = 1;
      this.getDataList();
    },
    // 当前页
    currentChangeHandle(val) {
      this.pageIndex = val;
      this.getDataList();
    },
    // 多选
    selectionChangeHandle(val) {
      this.dataListSelections = val;
    },
    // 新增 / 修改
    addOrUpdateHandle(id) {
      this.addOrUpdateVisible = true;
      this.$nextTick(() => {
        this.$refs.addOrUpdate.init(id);
      });
    },
    // 发货
    async deliveryOrderHandle(id) {
      this.deliveryOrderVisible = true;
      this.deliveryForm.orderMallBuyId = id;
      const { data } = await findLogisticList(`/mall/logistics/list`);
      if (data && data.code === 0) {
        this.companyList = data.logisticList;
      }
    },
    // 确定发货
    async deliveryOrder(id) {
      const params = {
        orderMallBuyId: id,
        logisticsId: this.deliveryForm.logisticsId,
        logisticsNumber: this.deliveryForm.logisticsNumber
      };
      const { data } = await deliveryOrder(
        `/mall/ordermallbuy/delivery`,
        params
      );
      if (data && data.code === 0) {
        this.deliveryOrderVisible = false;
        this.$message({
          message: "发货成功",
          type: "success",
          duration: 1500,
          onClose: () => {
            this.sideClose();
            this.$emit("refreshDataList");
          }
        });
        this.getDataList();
      } else {
        this.$message.error(data.msg);
      }
    }
  },
  mounted() {
    this.getDataList();
  }
};
</script>
