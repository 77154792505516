<template>
  <div class="app-container">
    <el-form :inline="true" size="small" :model="dataForm" ref="dataForm" @keyup.enter.native="restPage()">
      <el-form-item prop="state">
        <el-select v-model="dataForm.state" clearable placeholder="请选择工厂状态">
          <el-option v-for="item in states" :key="item.label" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item prop="brandId">
        <el-select v-model="dataForm.brandId" size="small" clearable placeholder="请选择品牌">
          <el-option v-for="item in brandList" :key="item.brandId" :label="item.brandName" :value="item.brandId"
            size="small"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item prop="factoryName">
        <el-input v-model="dataForm.factoryName" placeholder="工厂名称" size="small" clearable maxlength="30"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button @click="restPage()" size="small">筛选</el-button>
        <el-button @click="reset('dataForm')" size="small">清空</el-button>
        <el-button type="primary" @click="addOrUpdateHandle()" size="small">添加工厂</el-button>
      </el-form-item>
    </el-form>
    <el-table :data="dataList" border size="small" v-loading="dataListLoading" style="width: 100%">
      <el-table-column prop="id" header-align="center" align="center" label="id" width="150">
      </el-table-column>
      <el-table-column prop="brandName" header-align="center" align="center" label="品牌">
      </el-table-column>
      <el-table-column prop="factoryName" header-align="center" align="center" label="工厂名称">
      </el-table-column>
      <el-table-column prop="factoryEname" header-align="center" align="center" label="英文缩写">
      </el-table-column>
      <el-table-column prop="factoryLogo" header-align="center" align="center" label="工厂图片">
        <template slot-scope="scope">
          <img :src="scope.row.factoryLogo" min-width="70" height="70" />
        </template>
      </el-table-column>
      <el-table-column prop="state" header-align="center" align="center" label="状态">
        <template slot-scope="scope">
          <span v-if="scope.row.state == '0'">有效</span>
          <span v-if="scope.row.state == '1'">失效</span>
        </template>
      </el-table-column>
      <el-table-column prop="createTime" header-align="center" align="center" label="创建时间">
      </el-table-column>
      <el-table-column fixed="right" header-align="center" align="center" width="180" label="操作">
        <template slot-scope="scope">
          <el-button type="text" size="small" @click="addOrUpdateHandle(scope.row.id)">编辑</el-button>
          <el-button type="text" size="small" @click="viewManagerHandle(scope.row.id)">查看账号</el-button>
          <el-button v-if="scope.row.state == '0'" type="text" style="color: green" size="small"
            @click="updateFactoryState(scope.row.id, 1)">关闭</el-button>
          <el-button v-if="scope.row.state == '1'" type="text" style="color: green" size="small"
            @click="updateFactoryState(scope.row.id, 0)">开通</el-button>
          <el-button type="text" size="small" style="color: red" @click="deleteHandle(scope.row.id)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination size="small" @size-change="sizeChangeHandle" @current-change="currentChangeHandle"
      :current-page="dataForm.pageNo" :page-sizes="[10, 20, 50, 100]" :page-size="dataForm.pageSize" :total="totalPage"
      layout="total, sizes, prev, pager, next, jumper">
    </el-pagination>
    <!-- 弹窗, 新增 / 修改 -->
    <add-or-update v-if="addOrUpdateVisible" ref="addOrUpdate" @refreshDataList="getDataList"></add-or-update>
    <view-manager v-if="viewManagerVisible" ref="viewManager" @refreshDataList="getDataList"></view-manager>
  </div>
</template>

<script>
import {
  queryFactoryApi,
  deleteFactoryApi,
  updateFactoryStateApi,
} from "@/api/modules/factory";
import { queryBrandApi } from "@/api/modules/brand";
import AddOrUpdate from "./add-or-update";
import ViewManager from "./view-manager";
export default {
  data() {
    return {
      dataForm: {
        factoryName: "",
        brandId: "",
        state: "",
        pageNo: 1,
        pageSize: 10,
      },
      states: [
        {
          value: "0",
          label: "开通",
        },
        {
          value: "1",
          label: "关闭",
        },
      ],
      dataList: [],
      totalPage: 0,
      dataListLoading: false,
      addOrUpdateVisible: false,
      viewManagerVisible: false,
      brandList: [], //品牌集合下拉
    };
  },
  components: {
    AddOrUpdate,
    ViewManager,
  },
  created() {
    this.getDataList();
    this.getbrandList();
  },
  methods: {
    //品牌下拉
    getbrandList() {
      queryBrandApi({ pageSize: 100000 }).then(({ records, totalRecords }) => {
        if (totalRecords > 0) {
          this.brandList = records;
        } else {
          this.brandList = [];
        }
      });
    },
    //重新查询
    restPage() {
      this.dataForm.pageNo = 1;
      this.getDataList();
    },
    reset(dataForm) {
      this.$refs.dataForm.resetFields();
      this.restPage();
    },
    // 获取数据列表
    getDataList() {
      this.dataListLoading = true;
      queryFactoryApi(this.dataForm).then(({ records, totalRecords }) => {
        if (totalRecords > 0) {
          this.dataList = records;
          this.totalPage = totalRecords;
        } else {
          this.dataList = [];
          this.totalPage = 0;
        }
        this.dataListLoading = false;
      });
    },
    // 每页数
    sizeChangeHandle(val) {
      this.dataForm.pageSize = val;
      this.dataForm.pageNo = 1;
      this.getDataList();
    },
    // 当前页
    currentChangeHandle(val) {
      this.dataForm.pageNo = val;
      this.getDataList();
    },
    // 新增 / 修改
    addOrUpdateHandle(id) {
      this.addOrUpdateVisible = true;
      this.$nextTick(() => {
        this.$refs.addOrUpdate.init(id);
      });
    },
    //0启用   1禁用
    updateFactoryState(id, state) {
      this.$confirm(`确定进行${state == "0" ? "开通" : "关闭"}操作?`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(async () => {
        await updateFactoryStateApi({ id: id, state: state });
        this.$baseMessage("操作成功", "success");
        this.restPage();
      });
    },
    viewManagerHandle(id) {
      this.viewManagerVisible = true;
      this.$nextTick(() => {
        this.$refs.viewManager.init(id);
      });
    },
    // 删除
    deleteHandle(id) {
      this.$confirm(`是否确认删除工厂?`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(async () => {
        await deleteFactoryApi(id);
        this.$baseMessage("删除成功", "success");
        this.getDataList();
      });
    },
  },
};
</script>
