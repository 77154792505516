<template>
  <div class="app-container">
    <el-form :inline="true" :model="dataForm" @keyup.enter.native="getDataList()">
      <el-form-item  prop="attrName">
        <el-input v-model="dataForm.attrName" placeholder="请输入属性名称" clearable></el-input>
      </el-form-item>
      <el-form-item>
        <el-button @click="restPage()">查询</el-button>
        <el-button @click="reset('dataForm')" >清空</el-button>
        <el-button v-if="isAuth('mall:mallgoodsattr:save')" type="primary" @click="addOrUpdateHandle()">新增</el-button>
        <el-button v-if="isAuth('mall:mallgoodsattr:delete')" type="danger" @click="deleteHandle()" :disabled="dataListSelections.length <= 0">批量删除</el-button>
      </el-form-item>
    </el-form>
    <el-table
      :data="dataList"
      border
      v-loading="dataListLoading"
      @selection-change="selectionChangeHandle"
      style="width: 100%;">
      <el-table-column
        type="selection"
        header-align="center"
        align="center"
        width="50">
      </el-table-column>
      <el-table-column
        prop="attrName"
        header-align="center"
        align="center"
        label="属性名称">
      </el-table-column>
      <el-table-column
              prop="currentSort"
              header-align="center"
              align="center"
              width="110"
              label="当前排序">
      </el-table-column>
      <el-table-column
        prop="createTime"
        header-align="center"
        align="center"
        width="260"
        label="创建时间">
      </el-table-column>
      <el-table-column
        fixed="right"
        header-align="center"
        align="center"
        width="250"
        label="操作">
        <template slot-scope="scope">
          <el-button v-if="isAuth('mall:mallgoodsattrvalue:list')" type="text" size="small" @click="attrValueListHandle(scope.row.mallGoodsAttrId)">属性值列表</el-button>
          <el-button v-if="isAuth('mall:mallgoodsattr:update')" type="text" size="small" @click="addOrUpdateHandle(scope.row.mallGoodsAttrId)">修改</el-button>
          <el-button v-if="isAuth('mall:mallgoodsattr:delete')" type="text" size="small" @click="deleteHandle(scope.row.mallGoodsAttrId)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      @size-change="sizeChangeHandle"
      @current-change="currentChangeHandle"
      :current-page="pageIndex"
      :page-sizes="[10, 20, 50, 100]"
      :page-size="pageSize"
      :total="totalPage"
      layout="total, sizes, prev, pager, next, jumper">
    </el-pagination>
    <!-- 弹窗, 新增 / 修改 -->
    <add-or-update v-if="addOrUpdateVisible" ref="addOrUpdate" @refreshDataList="getDataList"></add-or-update>
  </div>
</template>

<script>
import AddOrUpdate from "./components/attr-add-or-update";
import { mallGoodsAttrList, delMallGoodsAttr } from "@/api/sys";
export default {
  data() {
    return {
      dataForm: {
        attrName: ""
      },
      dataList: [],
      pageIndex: 1,
      pageSize: 10,
      totalPage: 0,
      dataListLoading: false,
      dataListSelections: [],
      addOrUpdateVisible: false
    };
  },
  components: {
    AddOrUpdate
  },
  methods: {
    // 查询清空 修改  by葛海军
    reset(dataForm) {
      this.dataForm.attrName = "";
      this.restPage();
    },
    // 跳到第一页进行查询
    restPage() {
      this.pageIndex = 1;
      this.getDataList();
    },
    // 获取数据列表
    async getDataList() {
      this.dataListLoading = true;
      let params = {
        page: this.pageIndex,
        limit: this.pageSize,
        attrName: this.dataForm.attrName
      };
      const { data } = await mallGoodsAttrList(params);
      if (data && data.code === 0) {
        this.dataList = data.page.list;
        this.totalPage = data.page.totalCount;
      } else {
        this.dataList = [];
        this.totalPage = 0;
      }
      this.dataListLoading = false;
    },
    // 每页数
    sizeChangeHandle(val) {
      this.pageSize = val;
      this.pageIndex = 1;
      this.getDataList();
    },
    // 当前页
    currentChangeHandle(val) {
      this.pageIndex = val;
      this.getDataList();
    },
    // 多选
    selectionChangeHandle(val) {
      this.dataListSelections = val;
    },
    // 新增 / 修改
    addOrUpdateHandle(id) {
      this.addOrUpdateVisible = true;
      this.$nextTick(() => {
        this.$refs.addOrUpdate.init(id);
      });
    },
    attrValueListHandle(id){
      this.$router.push({ path: "/mall/goods/attrValue", query:{"mallGoodsAttrId":id} });
    },
    // 删除
    deleteHandle(id) {
      var ids = id
        ? [id]
        : this.dataListSelections.map(item => {
            return item.mallGoodsAttrId;
          });
      this.$confirm(`确定进行[${id ? "删除" : "批量删除"}]操作?`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(async () => {
          // let params = {
          //   roleIds: ids
          // };
          const { data } = await delMallGoodsAttr(ids);
          if (data && data.code === 0) {
            this.$message({
              message: "操作成功",
              type: "success",
              duration: 1500,
              onClose: () => {
                this.getDataList();
              }
            });
          } else {
            this.$message.error(data.msg);
          }
        })
        .catch(() => {});
    }
  },
  mounted() {
    this.getDataList();
  }
};
</script>
