<template>
  <el-dialog
    :title="!dataForm.id ? '新增' : '修改'"
    :close-on-click-modal="false"
    :visible.sync="visible"
    width="500px"
  >
    <el-form
      :model="dataForm"
      :rules="dataRule"
      size="small"
      ref="dataForm"
      @keyup.enter.native="dataFormSubmit()"
      label-width="80px"
      v-loading="dataLoading"
      style="width: 100%"
      element-loading-text=""
      element-loading-spinner="el-icon-loading"
    >
      <el-form-item label="编码" prop="categoryNo">
        <el-input
          v-model="dataForm.categoryNo"
          placeholder="编码"
          size="small"
        ></el-input>
      </el-form-item>
      <el-form-item label="名称" prop="categoryName">
        <el-input
          v-model="dataForm.categoryName"
          placeholder="名称"
          size="small"
        ></el-input>
      </el-form-item>
      <el-form-item label="品牌" prop="colorBookBrandId">
        <el-select
          v-model="dataForm.colorBookBrandId"
          size="small"
          placeholder="请选择品牌"
        >
          <el-option
            v-for="item in dictInfoList"
            :key="item.id"
            :label="item.brandName"
            :value="item.id"
            size="small"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="备注" prop="remarks">
        <el-input
          v-model="dataForm.remarks"
          type="textarea"
          size="small"
          placeholder="备注"
          maxlength="100"
          show-word-limit
        ></el-input>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="visible = false" size="small">取消</el-button>
      <el-button
        type="primary"
        @click="dataFormSubmit()"
        size="small"
        :disabled="dataLoading"
        >确定</el-button
      >
    </span>
  </el-dialog>
</template>

<script>
import {
  getColorBookCategoryApi,
  addOrUpdateColorBookCategoryApi,
} from "@/api/modules/colorbookcategory";
import {
  queryColorBookBrandApi
} from "@/api/modules/colorBookBrand";
export default {
  data() {
    return {
      visible: false,
      dataLoading: false,
      dictInfoList: [],
      dataForm: {
        id: undefined,
        categoryNo: "",
        categoryName: "",
        colorBookBrandId: "",
        remarks: "",
      },
      dataRule: {
        categoryNo: [
          { required: true, message: "编码不能为空", trigger: "blur" },
        ],
        categoryName: [
          { required: true, message: "名称不能为空", trigger: "blur" },
        ],
        colorBookBrandId: [
          { required: true, message: "品牌不能为空", trigger: "blur" },
        ],
      },
    };
  },
  created() {
    this.getDictInfo();
  },
  methods: {
    getDictInfo() {
      queryColorBookBrandApi().then(({records, totalRecords}) => {
        if (totalRecords > 0) {
          this.dictInfoList = records;
        } else {
          this.dictInfoList = [];
        }
      });
    },
    init(id) {
      this.dataForm.id = id || undefined;
      this.visible = true;
      this.$nextTick(() => {
        this.$refs["dataForm"].resetFields();
        if (id) {
          getColorBookCategoryApi(id).then((response) => {
            this.dataForm = response;
          });
        }
      });
    },
    // 表单提交
    dataFormSubmit() {
      this.$refs["dataForm"].validate((valid) => {
        if (valid) {
          this.dataLoading = true;
          addOrUpdateColorBookCategoryApi(this.dataForm)
            .then(() => {
              this.visible = false;
              this.$emit("refreshDataList");
              this.$baseMessage("操作成功", "success");
            })
            .finally(() => {
              this.dataLoading = false;
            });
        }
      });
    },
  },
};
</script>
