<template>
  <el-dialog
    :title="!dataForm.id ? '新增' : '修改'"
    :close-on-click-modal="false"
    :visible.sync="visible"
    @click="close()"
    width="600px"
  >
    <el-form
      :model="dataForm"
      :rules="dataRule"
      size="small"
      ref="dataForm"
      @keyup.enter.native="dataFormSubmit()"
      label-width="100px"
      v-loading="dataLoading"
      style="width: 100%"
      element-loading-text=""
      element-loading-spinner="el-icon-loading"
    >
      <el-form-item label="色号品牌" prop="colorBookBrandId">
        <el-select
          v-model="dataForm.colorBookBrandId"
          size="small"
          placeholder="请选择品牌"
          @change="getColorBookCategory()"
        >
          <el-option
            v-for="item in brandList"
            :key="item.id"
            :label="item.brandName"
            :value="item.id"
            size="small"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="色号分类" prop="colorBookCategoryId">
        <el-select
          v-model="dataForm.colorBookCategoryId"
          size="small"
          placeholder="请选择色号分类"
        >
          <el-option
            v-for="item in colorBookCategoryList"
            :key="item.id"
            :label="item.categoryName"
            :value="item.id"
            size="small"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="色号" prop="colorNo">
        <el-input
          v-model="dataForm.colorNo"
          placeholder="色号"
          size="small"
        ></el-input>
      </el-form-item>
      <el-form-item label="颜色名称" prop="colorName">
        <el-input
          v-model="dataForm.colorName"
          placeholder="颜色名称"
          size="small"
        ></el-input>
      </el-form-item>
      <el-form-item label="测量LAB值">
        <div>
          <el-button
            size="small"
            type="primary"
            class="mr10"
            @click="getSetmessage"
            :loading="loading"
            >数据同步</el-button
          >
        </div>
      </el-form-item>
      <el-form-item label="L值" prop="l">
        <el-input
          v-model="dataForm.l"
          placeholder="L值"
          size="small"
          type="number"
        ></el-input>
      </el-form-item>
      <el-form-item label="A值" prop="a">
        <el-input
          v-model="dataForm.a"
          placeholder="A值"
          size="small"
          type="number"
        ></el-input>
      </el-form-item>
      <el-form-item label="B值" prop="b">
        <el-input
          v-model="dataForm.b"
          placeholder="B值"
          size="small"
          type="number"
        ></el-input>
      </el-form-item>
      <el-form-item label="">
        <el-collapse>
          <el-collapse-item>
            <span class="collapse-title" slot="title">查看测量数据</span>
            <div>
              <ul class="productLAB">
                <li>
                  <span v-for="x in productLAB.title" :key="x" class="title">{{
                    x
                  }}</span>
                </li>
                <li v-for="(x, index) in productLAB.data" :key="index">
                  <span class="title">{{ x }}</span>
                  <el-input
                    v-model="dataForm[`l${index + 1}`]"
                    placeholder="L值"
                    class="w100"
                    disabled="disabled"
                  ></el-input>
                  <el-input
                    v-model="dataForm[`a${index + 1}`]"
                    placeholder="A值"
                    class="w100"
                    disabled="disabled"
                  ></el-input>
                  <el-input
                    v-model="dataForm[`b${index + 1}`]"
                    placeholder="B值"
                    class="w100"
                    disabled="disabled"
                  ></el-input>
                </li>
              </ul>
            </div>
          </el-collapse-item>
        </el-collapse>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="close()" size="small">取消</el-button>
      <el-button
        type="primary"
        @click="dataFormSubmit()"
        size="small"
        :disabled="dataLoading"
        >确定</el-button
      >
    </span>
  </el-dialog>
</template>

<script>
import { syncDevice, searchDeviceData, gethex } from "@/api/modules/product";
import {
  getColorBookApi,
  addOrUpdateColorBookApi,
} from "@/api/modules/colorbook";
import { queryColorBookCategoryApi } from "@/api/modules/colorbookcategory";
import { queryColorBookBrandApi } from "@/api/modules/colorBookBrand";
import Cookies from "js-cookie";
export default {
  data() {
    var checkL = (rule, value, callback) => {
      if (!value) {
        callback();
      }
      else {
        if (value >= 0 && value <= 100) {
          callback();
        }
        else {
          callback(new Error("本值不在合格范围之内：[0,100]"));
        }
      }
    };
    var checkAB = (rule, value, callback) => {
      if (!value) {
        callback();
      }
      else {
        if (value >= -128 && value <= 127) {
          callback();
        }
        else {
          callback(new Error("本值不在合格范围之内：[-128,127]"));
        }
      }
    }
    return {
      loading: false,
      timer: null, //首先我在data函数里面进行定义定时器名称：
      timerNum: 5, // 设置定时器时间
      productLAB: {
        title: ["角度", "L值", "A值", "B值"],
        data: ["25度", "45度", "75度"],
      },
      visible: false,
      dataLoading: false,
      colorBookCategoryList: [],
      brandList: [],
      dataForm: {
        id: undefined,
        colorNo: "",
        colorName: "",
        colorBookBrandId: "",
        colorBookCategoryId: "",
        l: "",
        a: "",
        b: "",
        l25: "",
        a25: "",
        b25: "",
        l45: "",
        a45: "",
        b45: "",
        l75: "",
        a75: "",
        b75: "",
        rgbR: "",
        rgbG: "",
        rgbB: "",
        hex: "",
      },
      dataRule: {
        colorBookBrandId: [
          { required: true, message: "请选择色号品牌", trigger: "change" },
        ],
        colorNo: [{ required: true, message: "色号不能为空", trigger: "blur" }],
        colorName: [
          { required: true, message: "颜色名称不能为空", trigger: "blur" },
        ],
        l: [{ required: true, message: "L值不能为空", trigger: "blur" }, {validator: checkL, trigger: "blur"}],
        a: [{ required: true, message: "A值不能为空", trigger: "blur" }, {validator: checkAB, trigger: "blur"}],
        b: [{ required: true, message: "B值不能为空", trigger: "blur" }, {validator: checkAB, trigger: "blur"}],
      },
    };
  },
  methods: {
    close() {
      this.visible = false;
      //this.device = {};
      //清除缓存数据
      this.$refs["dataForm"].resetFields();
    },
    async getBrandList() {
      const { records, totalRecords } = await queryColorBookBrandApi();
      if (totalRecords > 0) {
        this.brandList = records;
      } else {
        this.brandList = [];
      }
      this.getColorBookCategory();
    },
    //分类下拉
    async getColorBookCategory() {
      this.dataForm.colorBookCategoryId = "";
      const params = {
        colorBookBrandId: this.dataForm.colorBookBrandId,
        pageSize: 100000,
      };
      const { records, totalRecords } = await queryColorBookCategoryApi(params);
      if (totalRecords > 0) {
        this.colorBookCategoryList = records;
      } else {
        this.colorBookCategoryList = [];
      }
    },
    async init(id) {
      await this.getBrandList();
      this.dataForm.id = id || 0;
      this.visible = true;
      this.$nextTick(() => {
        this.$refs["dataForm"].resetFields();
        if (id) {
          getColorBookApi(id).then((response) => {
            this.dataForm = response;
            if(response.colorBookCategoryId == 0) {
              this.dataForm.colorBookCategoryId = "";
            }
          });
        }
      });
    },
    gethexvalue() {
      var params = {
        lab: this.a,
      };
      gethex(params).then(({ data }) => {
        if (data && data.code === 0) {
          console.log(data);
          this.color1 = data.hex;
        }
      });
    },
    //获取设备
    getSetmessage() {
      let id = Cookies.get("deviceId");
      console.log(id);
      syncDevice({ deviceId: id }).then(({ data }) => {
        this.loading = true;
        if (data && data.code === 0) {
          this.inputType = "0";
          this.timer = setInterval(() => {
            //创建定时器
            if (this.timerNum === 0) {
              // 设置的定时器时间为0后执行的操作
              this.clearTimer(); // 关闭定时器
              this.loading = false;
              this.timerNum = 5;
            } else {
              //查看设备状态
              this.syncDeviceTime(id, this.timerNum);
            }
          }, 1000);
        }
        this.loading = false;
      });
    },
    syncDeviceTime(id, timerNum) {
      searchDeviceData({ deviceId: id }).then(({ data }) => {
        if (data && data.code === 0) {
          this.gethexvalue();
          this.getDataList();
          this.clearTimer(); // 关闭定时器
          this.loading = false;
        } else {
          this.timerNum = timerNum - 1; // 定时器减1
        }
      });
    },
    clearTimer() {
      //清除定时器
      window.clearInterval(this.timer);
      this.timer = null;
      this.timerNum = 5;
    },
    // 表单提交
    dataFormSubmit() {
      this.$refs["dataForm"].validate((valid) => {
        if (valid) {
          if (!this.dataForm.colorBookCategoryId) {
            this.dataForm.colorBookCategoryId = "0";
          }
          this.dataLoading = true;
          addOrUpdateColorBookApi(this.dataForm)
            .then(() => {
              this.visible = false;
              this.$emit("refreshDataList");
              this.$baseMessage("操作成功", "success");
            })
            .finally(() => {
              this.dataLoading = false;
            });
        }
      });
    },
  },
};
</script>
<style lang="scss">
.avatar-uploader {
  .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    margin-left: 20px;
  }
  .el-upload:hover {
    border-color: #409eff;
  }
}
</style>
<style lang="scss" scoped>
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 150px;
  height: 150px;
  line-height: 178px;
  text-align: center;
}

.ziti {
  font-size: 12px;
  margin: 0px;
  line-height: 25px;
}

.avatar {
  width: 178px;
  max-height: 178px;
  display: block;
}

.productLAB {
  margin: 0;
  padding: 0;
  margin-top: 5px;
  margin-left: -50px;
  li {
    list-style: none;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    margin-bottom: 10px;
    .title {
      display: inline-block;
      width: 100px;
      text-align: center;
      color: #606266;
      font-size: 700;
    }
  }
}

.w100 {
  width: 100px;
}
.w150 {
  width: 119px;
}
.mr10 {
  margin-right: 5px;
}
.collapse-title {
  flex: 1 0 90%;
  color: #4ab7bd;
  font-weight: bolder;
  text-align: center;
}

.el-form-item--mini.el-form-item,
.el-form-item--small.el-form-item {
  margin-bottom: 18px;
}
</style>
