<template>
    <div class="app-container">
        <el-form :inline="true" size="small" :model="dataForm" ref="dataForm" @keyup.enter.native="restPage()">
            <el-form-item prop="daterange">
                <el-date-picker type="daterange" v-model="dataForm.daterange" range-separator="~" format="yyyy-MM-dd"
                    value-format="yyyy-MM-dd" :editable="false" start-placeholder="时间从" end-placeholder="时间到"
                    style="width: 300px"></el-date-picker>
            </el-form-item>
            <!-- <el-form-item>
          <el-select
            v-model="dataForm.userId"
            filterable
            placeholder="人员名称"
            clearable
          >
            <el-option
              v-for="item in userList"
              :key="item.id"
              :label="item.userNickName"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item> -->
            <el-form-item>
                <el-button @click="restPage()" size="small">查询</el-button>
                <el-button @click="reset('dataForm')" size="small">清空</el-button>
            </el-form-item>
        </el-form>
        <el-table :data="dataList" border size="small" v-loading="dataListLoading" style="width: 100%">
            <el-table-column prop="operateUserName" header-align="center" align="center" label="用户">
            </el-table-column>
            <el-table-column prop="operateUserType" header-align="center" align="center" label="用户类型">
                <template slot-scope="scope">
                    <el-tag v-if="scope.row.loginPlatform == '1'">品牌</el-tag>
                    <el-tag v-else type="warning">工厂</el-tag>
                </template>
            </el-table-column>
            <el-table-column prop="brandName" header-align="center" align="center" label="品牌">
            </el-table-column>
            <el-table-column prop="factoryName" header-align="center" align="center" label="工厂">
            </el-table-column>
            <el-table-column prop="module" header-align="center" align="center" label="操作模块"
                :show-overflow-tooltip=true>
            </el-table-column>
            <el-table-column prop="content" header-align="center" align="center" label="操作内容"
                :show-overflow-tooltip=true>
            </el-table-column>
            <el-table-column prop="url" header-align="center" align="center" label="请求路径" :show-overflow-tooltip=true>
            </el-table-column>
            <el-table-column prop="ip" header-align="center" align="center" width="150px" label="IP">
                <template slot-scope="scope">
                    <el-tag size="medium">{{ scope.row.ip }}</el-tag>
                </template>
            </el-table-column>
            <el-table-column prop="ipRegion" header-align="center" align="center" width="200px" label="IP地区">
                <template slot-scope="scope">
                    <el-tag size="medium">{{ scope.row.ipRegion }}</el-tag>
                </template>
            </el-table-column>
            <el-table-column prop="userAgent" header-align="center" align="center" label="客户端"
                :show-overflow-tooltip=true>
            </el-table-column>
            <el-table-column prop="method" header-align="center" align="center" label="请求方法"
                :show-overflow-tooltip=true>
            </el-table-column>
            <el-table-column prop="successFlag" header-align="center" align="center" label="请求结果">
                <template slot-scope="scope">
                    <el-tag v-if="scope.row.successFlag" type="success">成功</el-tag>
                    <el-tag v-else type="danger">失败</el-tag>
                </template>
            </el-table-column>
            <el-table-column prop="createTime" header-align="center" width="150" align="center" label="请求时间">
            </el-table-column>
        </el-table>
        <el-pagination size="small" @size-change="sizeChangeHandle" @current-change="currentChangeHandle"
            :current-page="pageIndex" :page-sizes="[10, 20, 50, 100]" :page-size="pageSize" :total="totalPage"
            layout="total, sizes, prev, pager, next, jumper">
        </el-pagination>
    </div>
</template>

<script>
import { queryRequesMonitingApi } from '@/api/modules/requesMoniting.js'
export default {
    data() {
        return {
            dataForm: {
                daterange: []
            },
            dataList: [],
            pageIndex: 1,
            pageSize: 10,
            totalPage: 0,
            dataListLoading: false,
            dataListSelections: [],
            addOrUpdateVisible: false,
            userList: []
        }
    },
    mounted() {
        this.restPage()
    },
    methods: {
        //重新查询
        restPage() {
            this.pageIndex = 1
            this.getDataList()
        },
        reset(dataForm) {
            this.$refs.dataForm.resetFields()
            this.restPage()
        },
        // 获取数据列表
        getDataList() {
            this.dataListLoading = true
            var endDate = ''
            var startDate = ''
            if (this.dataForm.daterange && this.dataForm.daterange.length == 2) {
                startDate = this.dataForm.daterange[0] + ' 00:00:00'
                endDate = this.dataForm.daterange[1] + ' 23:59:59'
            }
            var params = {
                pageNo: this.pageIndex,
                pageSize: this.pageSize,
                createTimeFrom: startDate,
                createTimeTo: endDate
            }

            queryRequesMonitingApi(params).then(({ records, totalRecords }) => {
                if (totalRecords > 0) {
                    this.dataList = records
                    this.totalPage = totalRecords
                } else {
                    this.dataList = []
                    this.totalPage = 0
                }
                this.dataListLoading = false
            })
        },
        // 每页数
        sizeChangeHandle(val) {
            this.pageSize = val
            this.pageIndex = 1
            this.getDataList()
        },
        // 当前页
        currentChangeHandle(val) {
            this.pageIndex = val
            this.getDataList()
        },
        setsplit(val) {
            if (val) {
                let arr = val.split(';')
                return arr
            } else {
                return ''
            }
        }
    }
}
</script>