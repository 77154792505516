<template>
  <div class="app-container">
    <el-form :inline="true"  size="small" :model="dataForm" ref="dataForm"  @keyup.enter.native="restPage()">
      <el-form-item >
        <el-input v-model="dataForm.serialNumber" placeholder="交易流水编号" size="small" clearable></el-input>
      </el-form-item>
      <el-form-item >
        <el-input v-model="dataForm.sampleOrderNumber" placeholder="订单编号" size="small" clearable></el-input>
      </el-form-item>
      <el-form-item >
        <el-input v-model="dataForm.companyName" placeholder="商户名" size="small" clearable></el-input>
      </el-form-item>
      <el-form-item >
        <el-select v-model="dataForm.payStatu" placeholder="交易类型">
          <el-option label="全部" value=""></el-option>
          <el-option
                  v-for="item in payStatus"
                  :key="item.payStatuKey"
                  :label="item.payStatuValue"
                  :value="item.payStatuKey"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="交易日期:">
        <div class="block">
          <el-date-picker
                  v-model="dataForm.createDates"
                  value-format="yyyy-MM-dd"
                  type="daterange"
                  range-separator="-"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期">
          </el-date-picker>
        </div>
      </el-form-item>
      <el-form-item>
        <el-button @click="restPage()" size="small">筛选</el-button>
        <el-button @click="reset('dataForm')"  size="small">清空</el-button>
        <el-button @click="exportExcelProduct()"  type="primary" size="small">导出</el-button>
        <el-button v-if="isAuth('member:saorderflow:save')" type="primary" @click="addOrUpdateHandle()" size="small">新增</el-button>
        <el-button v-if="isAuth('member:saorderflow:delete')" type="danger" @click="deleteHandle()" :disabled="dataListSelections.length <= 0" size="small">批量删除</el-button>
      </el-form-item>
    </el-form>
    <el-table
      :data="dataList"
      border
      size="small"
      v-loading="dataListLoading"
      @selection-change="selectionChangeHandle"
      style="width: 100%;">
      <el-table-column
        type="selection"
        fixed="left"
        header-align="center"
        align="center"
        width="50">
      </el-table-column>
      <!--<el-table-column
        prop="orderFlowId"
        header-align="center"
        align="center"
        label="订单流水ID">
      </el-table-column>-->
      <el-table-column
        prop="serialNumber"
        header-align="center"
        align="center"
        label="交易流水编号">
      </el-table-column>
      <el-table-column
        prop="sampleOrderNumber"
        header-align="center"
        align="center"
        label="订单编号">
      </el-table-column>
      <el-table-column
        prop="companyName"
        header-align="center"
        align="center"
        label="商户名">
      </el-table-column>
      <el-table-column
        prop="payAmount"
        header-align="center"
        align="center"
        label="金额">
      </el-table-column>
      <el-table-column
        prop="payStatus"
        header-align="center"
        align="center"
        label="支付状态">
        <template slot-scope="scope">
          {{scope.row.payStatus === 'unpaid' ?'未支付'
          : scope.row.payStatus=== 'paid' ?'已支付'
          :scope.row.payStatus === 'refund'?'已退款'
          :'暂无'}}
        </template>
      </el-table-column>

      <el-table-column
              prop="payType"
              header-align="center"
              align="center"
              label="交易类型">
        <template slot-scope="scope">
          {{scope.row.payType === 'weixin' ?'微信支付'
            : scope.row.payType=== 'alipay' ?'支付宝支付'
                :scope.row.payType === 'offline'?'线下支付'
                    :'暂无'}}
        </template>
      </el-table-column>
      <!--<el-table-column
        prop="divideAmount"
        header-align="center"
        align="center"
        label="分成金额">
      </el-table-column>-->
      <!--<el-table-column
        prop="payType"
        header-align="center"
        align="center"
        label="支付类型（weixin：微信支付，alipay：支付宝支付）">
      </el-table-column>-->
      <!--<el-table-column
        prop="payStatus"
        header-align="center"
        align="center"
        label="支付状态（unpaid：未支付，paid：已支付，refund：已退款）">
      </el-table-column>-->
      <!--<el-table-column
        prop="payThirdNumber"
        header-align="center"
        align="center"
        label="支付三方流水号">
      </el-table-column>-->
      <el-table-column
        prop="payTime"
        header-align="center"
        align="center"
        label="支付时间">
      </el-table-column>
     <!-- <el-table-column
        prop="refundThirdNumber"
        header-align="center"
        align="center"
        label="退款三方流水号">
      </el-table-column>
      <el-table-column
        prop="refundTime"
        header-align="center"
        align="center"
        label="退款时间">
      </el-table-column>
      <el-table-column
        prop="userId"
        header-align="center"
        align="center"
        label="用户ID">
      </el-table-column>
      <el-table-column
        prop="storeId"
        header-align="center"
        align="center"
        label="门店ID">
      </el-table-column>
      <el-table-column
        prop="areaId"
        header-align="center"
        align="center"
        label="区域ID">
      </el-table-column>
      <el-table-column
        prop="createBy"
        header-align="center"
        align="center"
        label="创建人">
      </el-table-column>
      <el-table-column
        prop="createTime"
        header-align="center"
        align="center"
        label="创建时间">
      </el-table-column>
      <el-table-column
        prop="updateBy"
        header-align="center"
        align="center"
        label="更新人">
      </el-table-column>
      <el-table-column
        prop="updateTime"
        header-align="center"
        align="center"
        label="更新时间">
      </el-table-column>
      <el-table-column
        prop="version"
        header-align="center"
        align="center"
        label="版本号">
      </el-table-column>-->
      <el-table-column
        fixed="right"
        header-align="center"
        align="center"
        width="150"
        label="操作">
        <template slot-scope="scope">
          <el-button type="text" size="small" @click="addOrUpdateHandle(scope.row.orderFlowId)">查看详情</el-button>
          <!--<el-button type="text" size="small" @click="deleteHandle(scope.row.orderFlowId)">删除</el-button>-->
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      size="small"
      @size-change="sizeChangeHandle"
      @current-change="currentChangeHandle"
      :current-page="pageIndex"
      :page-sizes="[10, 20, 50, 100]"
      :page-size="pageSize"
      :total="totalPage"
      layout="total, sizes, prev, pager, next, jumper">
    </el-pagination>
    <!-- 弹窗, 新增 / 修改 -->
    <add-or-update v-if="addOrUpdateVisible" ref="addOrUpdate" @refreshDataList="getDataList"></add-or-update>
  </div>
</template>

<script>
  import {list,del} from '@/api/modules/saorderflow'
  import AddOrUpdate from './add-or-update';
  import {getToken} from "@/utils/auth";
  export default {
    data () {
      return {
        dataForm: {
          serialNumber: '',
          sampleOrderNumber: '',
          companyName: '',
          payStatu: '',
          createDates:[],
          daterange:[]
        },
        payStatus:[
          {payStatuKey:'unpaid', payStatuValue:'未支付'},
          {payStatuKey:'paid', payStatuValue:'已支付'},
          {payStatuKey:'refund', payStatuValue:'已退款'},
        ],
        dataList: [],
        pageIndex: 1,
        pageSize: 10,
        totalPage: 0,
        dataListLoading: false,
        dataListSelections: [],
        addOrUpdateVisible: false
      }
    },
    components: {
      AddOrUpdate
    },
    created() {
      this.getDataList()
    },
    activated () {
      this.getDataList()
    },
    methods: {
      //导出产品数据到excle
      exportExcelProduct(){
        var arrOrderTime = this.dataForm.createDates;
        var strOrderTime = "";
        var startDate = null;
        var endDate = null;
        if (arrOrderTime.length > 0) {
          startDate = arrOrderTime[0];
          endDate = arrOrderTime[1];
        }
        var params = {
            'serialNumber': this.dataForm.serialNumber,
            'sampleOrderNumber': this.dataForm.sampleOrderNumber,
            'companyName': this.dataForm.companyName,
            'payStatu': this.dataForm.payStatu,
          'startDate': startDate,
          'endDate': endDate
        }
        var url =`${process.env.VUE_APP_BASE_API}/member/syssampleorderflow/exportExcelFlow?startDate=`+params.startDate
            +'&endDate='+
            params.endDate
            +'&serialNumber='+
            params.serialNumber
            +'&sampleOrderNumber='+
            params.sampleOrderNumber
            +'&companyName='+
            params.companyName
            +'&payStatu='+
            params.payStatu
            +`&token=${getToken()}`
        // 模拟创建元素实现
        var alink = document.createElement('a');
        alink.href = url;
        alink.style = 'display:none';
        alink.click();
      },
      //重新查询
      restPage() {
        this.pageIndex = 1;
        this.getDataList();
      },
      reset(dataForm) {
        this.dataForm.serialNumber = '';
        this.dataForm.sampleOrderNumber = '';
        this.dataForm.companyName = '';
        this.dataForm.payStatu = [];
        this.dataForm.createDates = [];
       	this.$refs.dataForm.resetFields();
        this.restPage();
      },
      // 获取数据列表
      getDataList () {
        this.dataListLoading = true
        var arrOrderTime = this.dataForm.createDates;
        var strOrderTime = "";
        var startDate = null;
        var endDate = null;
        if (arrOrderTime.length > 0) {
          startDate = arrOrderTime[0];
          endDate = arrOrderTime[1];
        }
        var params = {
          page: this.pageIndex,
          limit: this.pageSize,
          'serialNumber': this.dataForm.serialNumber,
          'sampleOrderNumber': this.dataForm.sampleOrderNumber,
          'companyName': this.dataForm.companyName,
          'payStatu': this.dataForm.payStatu,
          'startDate': startDate,
          'endDate': endDate
        }
        list(params).then(({data}) => {
          if (data && data.code === 0) {
            this.dataList = data.page.list
            this.totalPage = data.page.totalCount
          } else {
            this.dataList = []
            this.totalPage = 0
          }
          this.dataListLoading = false
        })
      },
      // 每页数
      sizeChangeHandle (val) {
        this.pageSize = val
        this.pageIndex = 1
        this.getDataList()
      },
      // 当前页
      currentChangeHandle (val) {
        this.pageIndex = val
        this.getDataList()
      },
      // 多选
      selectionChangeHandle (val) {
        this.dataListSelections = val
      },
      // 新增 / 修改
      addOrUpdateHandle (id) {
        this.addOrUpdateVisible = true
        this.$nextTick(() => {
          this.$refs.addOrUpdate.init(id)
        })
      },
      // 删除
      deleteHandle (id) {
        var ids = id ? [id] : this.dataListSelections.map(item => {
          return item.orderFlowId
        })
        this.$confirm(`确定对[id=${ids.join(',')}]进行[${id ? '删除' : '批量删除'}]操作?`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          del(ids).then(({data}) => {
            if (data && data.code === 0) {
              this.$message({
                message: '操作成功',
                type: 'success',
                duration: 1500,
                onClose: () => {
                  this.getDataList()
                }
              })
            } else {
              this.$message.error(data.msg)
            }
          })
        })
      }
    }
  }
</script>
