<template>
  <el-dialog
    :title="!dataForm.id ? '新增' : '修改'"
    :close-on-click-modal="false"
    :visible.sync="visible">
    <el-form :model="dataForm" :rules="dataRule"  size="small" ref="dataForm" @keyup.enter.native="dataFormSubmit()" label-width="120px"
     v-loading="dataLoading" style="width:100%;"  element-loading-text=""  element-loading-spinner="el-icon-loading"
    >
      <el-form-item label="标签名称" prop="memberLabelName">
        <el-input v-model="dataForm.memberLabelName" placeholder="标签名称"  size="small" style="width: 80%"></el-input>
      </el-form-item>
      <!-- <el-form-item label="标签英文名称" prop="memberLabelEname">
        <el-input v-model="dataForm.memberLabelEname" placeholder="标签英文名称"  size="small" style="width: 80%"></el-input>
      </el-form-item>
      <el-form-item label="标签别名" prop="memberLabelAlias">
        <el-input v-model="dataForm.memberLabelAlias" placeholder="标签别名"  size="small" style="width: 80%"></el-input>
      </el-form-item> -->
      <el-form-item label="状态" prop="isShow">
          <template>
            <el-radio v-model="dataForm.isShow" label="0">启用</el-radio>
            <el-radio v-model="dataForm.isShow" label="1">禁用</el-radio>
          </template>
      </el-form-item>
      <!-- <el-form-item label="当前排序" prop="currentSort">
        <el-input v-model="dataForm.currentSort" placeholder="当前排序"  size="small" style="width: 80%"></el-input>
      </el-form-item> -->
      <el-form-item label="标签说明" prop="description">
        <el-input v-model="dataForm.description" maxlength="150" type="textarea" :rows="4" placeholder="标签说明"  size="small" style="width: 80%"></el-input>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="visible = false" size="small">取消</el-button>
      <el-button type="primary" @click="dataFormSubmit()" size="small"  :disabled="dataLoading">确定</el-button>
    </span>
  </el-dialog>
</template>

<script>
  import {info,add,update} from '@/api/modules/memberlabel'
  export default {
    data () {
      return {
        visible: false,
        dataLoading: false,
        dataForm: {
          productLabelId: 0,
          memberLabelName: '',
          // memberLabelEname: '',
          // memberLabelAlias: '',
          isShow: '0',
          // currentSort: '',
          description: '',
        },
        dataRule: {
          memberLabelName: [
            { required: true, message: '标签名称不能为空', trigger: 'blur' }
          ],
          isShow: [
            { required: true, message: '标签状态不能为空', trigger: 'blur' }
          ],
          // currentSort: [
          //   { required: true, message: '当前排序不能为空', trigger: 'blur' }
          // ],
          // description: [
          //   { required: true, message: '标签说明不能为空', trigger: 'blur' }
          // ]
        }
      }
    },
    methods: {
      init (id) {
        this.dataForm.productLabelId = id || 0
        this.visible = true
        this.$nextTick(() => {
          this.$refs['dataForm'].resetFields()
          if (this.dataForm.productLabelId) {
            info({productLabelId:this.dataForm.productLabelId}).then(({data}) => {
              if (data && data.code === 0) {
                this.dataForm.memberLabelName = data.memberLabel.memberLabelName
                // this.dataForm.memberLabelEname = data.memberLabel.memberLabelEname
                // this.dataForm.memberLabelAlias = data.memberLabel.memberLabelAlias
                this.dataForm.isShow = data.memberLabel.isShow
                // this.dataForm.currentSort = data.memberLabel.currentSort
                this.dataForm.description = data.memberLabel.description
              }
            })
          }
        })
      },
      // 表单提交
      dataFormSubmit () {
        this.$refs['dataForm'].validate((valid) => {
          if (valid) {
          	this.dataLoading = true;
            var params = {
              'productLabelId': this.dataForm.productLabelId || undefined,
              'memberLabelName': this.dataForm.memberLabelName,
              // 'memberLabelEname': this.dataForm.memberLabelEname,
              // 'memberLabelAlias': this.dataForm.memberLabelAlias,
              'isShow': this.dataForm.isShow,
              // 'currentSort': this.dataForm.currentSort,
              'description': this.dataForm.description
            }
            var tick = !this.dataForm.productLabelId ? add(params) : update(params)
            tick.then(({data}) => {
              if (data && data.code === 0) {
                this.$message({
                  message: '操作成功',
                  type: 'success',
                  duration: 1500,
                  onClose: () => {
                    this.visible = false;
	              	this.dataLoading = false;
                    this.$emit('refreshDataList')
                  }
                })
              } else {
                this.$message.error(data.msg)
                this.dataLoading = false;
              }
            })
          }
        })
      }
    }
  }
</script>
