<template>
  <el-dialog
    :title="!dataForm.id ? '新增' : '修改'"
    :close-on-click-modal="false"
    :visible.sync="visible"
  width="500px">
    <el-form :model="dataForm" :rules="dataRule"  size="small" ref="dataForm" @keyup.enter.native="dataFormSubmit()" label-width="80px"
     v-loading="dataLoading" style="width:100%;"  element-loading-text=""  element-loading-spinner="el-icon-loading"
    >

      <el-form-item label="选择品牌" prop="brandId">
          <el-select v-model="dataForm.brandId" size="small" placeholder="请选择品牌" @change="changeBrand" style="width: 90%">
              <el-option
                      v-for="item in brandList"
                      :key="item.id"
                      :label="item.brandName"
                      :value="item.id"
                      size="small">
              </el-option>
          </el-select>
      </el-form-item>
        <el-form-item label="样粉类型" prop="conditionId">
          <el-select v-model="dataForm.conditionId" size="small" placeholder="请选择样粉类型" style="width: 90%">
              <el-option
                      v-for="item in valuationConditionList"
                      :key="item.conditionId"
                      :label="item.conditionName"
                      :value="item.conditionId"
                      size="small">
              </el-option>
          </el-select>
      </el-form-item>
      <el-form-item label="样粉重量" prop="quantity">
        <el-input v-model="dataForm.quantity" placeholder="样粉重量" oninput ="value=value.replace(/[^0-9.]/g,'')" size="small" style="width: 90%"></el-input>
          <!-- <el-select v-model="dataForm.quantity" size="small" placeholder="请选择样粉重量" style="width: 90%">
              <el-option
                      v-for="item in testList"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                      size="small"
              ></el-option>
          </el-select> -->
      </el-form-item>
      <el-form-item label="样粉价格" prop="price">
        <el-input v-model="dataForm.price" placeholder="价格"  size="small" style="width: 90%">
            <template slot="append">元</template>
        </el-input>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="visible = false" size="small">取消</el-button>
      <el-button type="primary" @click="dataFormSubmit()" size="small"  :disabled="dataLoading">确定</el-button>
    </span>
  </el-dialog>
</template>

<script>
  import {powderDetail,savePowder,updatePowder,getCategory,getIsValuationCondition} from '@/api/modules/samplepricerule'
  import {getBrand} from '@/api/modules/product'
  export default {
    data () {
      return {
        visible: false,
        dataLoading: false,
          categoryList:[],
          brandList: [],
          valuationConditionList:[],
          testList: [
              {
                  value: '0.8',
                  label: '0.8'
              },
              {
                  value: '2.5',
                  label: '2.5'
              },
             ],
        dataForm: {
          id: 0,
          type: '',
          quantity: '',
            conditionId: '',
            brandId: '',
          price: ''
        },
        dataRule: {
          /*type: [
            { required: true, message: '类型(0.样板，1.样粉)不能为空', trigger: 'blur' }
          ],*/
          quantity: [
            { required: true, message: '样粉重量不能为空', trigger: 'blur' }
          ],
            conditionId: [
            { required: true, message: '样粉类型不能为空', trigger: 'blur' }
          ],
          price: [
            { required: true, message: '样粉价格不能为空', trigger: 'blur' }
          ],
          brandId: [
            { required: true, message: '品牌不能为空', trigger: 'blur' }
          ]
        }
      }
    },
      mounted(){
        this.getCaxl();
        this.getBrandList();
      },
    methods: {
        //获取打样规则条件下拉
        getCaxl(){
            getCategory().then(({ data }) => {
                if (data && data.code === 0) {
                    this.categoryList = data.categoryList;
                } else {
                    this.categoryList = [];
                }
            });
        },
        changeBrand(){
            this.dataForm.conditionId = "";
            this.getIsValuationCondition();
        },
        //获取打样规则条件下拉
        getIsValuationCondition(){
            let params = {
                brandId : this.dataForm.brandId
            }
            getIsValuationCondition(params).then(({ data }) => {
                if (data && data.code === 0) {
                    this.valuationConditionList = data.list;
                } else {
                    this.valuationConditionList = [];
                }
            });
        },

        //获取品牌列表
        getBrandList() {
            let params = {}
            getBrand(params).then(({data}) => {
                if (data && data.code === 0) {
                    this.brandList = data.list
                } else {
                    this.brandList = []
                }
            })
        },
      init (id) {
        this.valuationConditionList = []
        this.dataForm.id = id || 0
        this.visible = true
        this.getBrandList();
        this.$nextTick(() => {
          this.$refs['dataForm'].resetFields()
          if (this.dataForm.id) {
              powderDetail({id:this.dataForm.id}).then(({data}) => {
              if (data && data.code === 0) {
                this.dataForm.brandId = data.powderDetail.brandId
                this.dataForm.type = data.powderDetail.type
                this.dataForm.quantity = data.powderDetail.quantity
                this.dataForm.categoryId = data.powderDetail.categoryId
                this.getIsValuationCondition();
                this.dataForm.conditionId = data.powderDetail.conditionId
                this.dataForm.price = data.powderDetail.price
              }
            })
          }
        })
      },
      // 表单提交
      dataFormSubmit () {
        this.$refs['dataForm'].validate((valid) => {
          if (valid) {
            this.dataLoading = true;
            var params = {
              'priceruleId': this.dataForm.id || undefined,
              'num': this.dataForm.quantity,
              'conditionId': this.dataForm.conditionId,
              'price': this.dataForm.price,
              'brandId': this.dataForm.brandId
            }
            var tick = !this.dataForm.id ? savePowder(params) : updatePowder(params)
            tick.then(({data}) => {
              if (data && data.code === 0) {
                this.$message({
                  message: '操作成功',
                  type: 'success',
                  duration: 1500,
                  onClose: () => {
                    this.visible = false;
                    this.dataLoading = false;
                    this.$emit('refreshDataList')
                      this.categoryList = [];
                  }
                })
              } else {
                this.$message.error(data.msg)
                this.dataLoading = false;
              }
            })
          }
        })
      }
    }
  }
</script>
