<template>
  <div class="app-container">
    <el-form :inline="true" size="small" :model="dataForm" ref="dataForm" @keyup.enter.native="restPage()">
      <el-form-item prop="daterange" label="登录时间">
        <el-date-picker type="daterange" v-model="dataForm.daterange" range-separator="~" format="yyyy-MM-dd"
          value-format="yyyy-MM-dd" :editable="false" start-placeholder="时间从" end-placeholder="时间到"
          style="width: 300px"></el-date-picker>
      </el-form-item>
      <el-form-item label="登录平台">
        <el-select v-model="dataForm.loginPlatform" placeholder="登录平台">
          <el-option label="全部" value=""></el-option>
          <el-option label="C端" value="c"></el-option>
          <el-option label="M端" value="m"></el-option>
        </el-select>
      </el-form-item>
      <!-- <el-form-item>
        <el-select
          v-model="dataForm.userId"
          filterable
          placeholder="人员名称"
          clearable
        >
          <el-option
            v-for="item in userList"
            :key="item.id"
            :label="item.userNickName"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item> -->
      <el-form-item>
        <el-button @click="restPage()" size="small">查询</el-button>
        <el-button @click="reset('dataForm')" size="small">清空</el-button>
        <el-button type="primary" @click="exportExcelLoginData()">导出</el-button>
      </el-form-item>
    </el-form>
    <el-table :data="dataList" border size="small" v-loading="dataListLoading" style="width: 100%">
      <el-table-column prop="loginPlatform" header-align="center" width="150" align="center" label="登录平台">
        <template slot-scope="scope">
          <el-tag v-if="scope.row.loginPlatform == 'm'">M端</el-tag>
          <el-tag v-else>C端</el-tag>
        </template>
      </el-table-column>
      <el-table-column prop="loginType" header-align="center" align="center" label="登录终端">
        <template slot-scope="scope">
          <el-tag v-if="scope.row.loginType == '0'">云管理平台</el-tag>
          <el-tag v-else-if="scope.row.loginType == '1'">颜色管理系统</el-tag>
          <el-tag v-else-if="scope.row.loginType == '2'">微信小程序</el-tag>
          <el-tag v-else-if="scope.row.loginType == '3'">苹果应用</el-tag>
          <el-tag v-else-if="scope.row.loginType == '4'">安卓应用</el-tag>
          <el-tag v-else>未知</el-tag>
        </template>
      </el-table-column>
      <el-table-column prop="loginIp" header-align="center" align="center" label="登录IP">
      </el-table-column>
      <el-table-column prop="createTime" header-align="center" width="150" align="center" label="登录时间">
      </el-table-column>
      <el-table-column prop="userNickName" header-align="center" align="center" label="用户名称">
      </el-table-column>
      <el-table-column prop="behaviorType" header-align="center" width="150" align="center" label="行为类型">
        <template slot-scope="scope">
          <el-tag v-if="scope.row.behaviorType == '0'" type="success">登录</el-tag>
          <el-tag v-else type="warning">退出</el-tag>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination size="small" @size-change="sizeChangeHandle" @current-change="currentChangeHandle"
      :current-page="pageIndex" :page-sizes="[10, 20, 50, 100]" :page-size="pageSize" :total="totalPage"
      layout="total, sizes, prev, pager, next, jumper">
    </el-pagination>
  </div>
</template>

<script>
// import { queryUserApi } from '@/api/modules/user'
import { queryUserLoginApi } from '@/api/modules/userLogin'
import { axiosDownFile } from "@/utils";
export default {
  data() {
    return {
      dataForm: {
        loginPlatform: '',
        daterange: [],
        userId: '',
        userNickName: ''
      },
      dataList: [],
      pageIndex: 1,
      pageSize: 10,
      totalPage: 0,
      dataListLoading: false,
      dataListSelections: [],
      addOrUpdateVisible: false,
      userList: []
    }
  },
  activated() {
    this.getUserList()
  },
  mounted() {
    this.restPage()
  },
  methods: {
    getUserList() {
      queryUserApi({ pageSize: 10000 }).then(({ records, totalRecords }) => {
        if (totalRecords > 0) {
          this.userList = records
        } else {
          this.userList = []
        }
        this.getDataList()
      })
    },

    exportExcelLoginData() {
      this.dataListLoading = true
      var endDate = ''
      var startDate = ''
      if (this.dataForm.daterange && this.dataForm.daterange.length == 2) {
        startDate = this.dataForm.daterange[0] + ' 00:00:00'
        endDate = this.dataForm.daterange[1] + ' 23:59:59'
      }
      var params = {
        pageNo: this.pageIndex,
        limit: this.pageSize,
        userId: this.dataForm.userId,
        createTimeFrom: startDate,
        createTimeTo: endDate,
        loginPlatform: this.dataForm.loginPlatform
      }
      axiosDownFile("/api/m/userLogin/exportExcelLoginData", params);
      this.dataListLoading = false
    },
    //重新查询
    restPage() {
      this.pageIndex = 1
      this.getDataList()
    },
    reset(dataForm) {
      this.$refs.dataForm.resetFields()
      this.restPage()
    },
    // 获取数据列表
    getDataList() {
      this.dataListLoading = true
      var endDate = ''
      var startDate = ''
      if (this.dataForm.daterange && this.dataForm.daterange.length == 2) {
        startDate = this.dataForm.daterange[0] + ' 00:00:00'
        endDate = this.dataForm.daterange[1] + ' 23:59:59'
      }
      var params = {
        pageNo: this.pageIndex,
        pageSize: this.pageSize,
        userId: this.dataForm.userId,
        createTimeFrom: startDate,
        createTimeTo: endDate,
        loginPlatform: this.dataForm.loginPlatform
      }

      queryUserLoginApi(params).then(({ records, totalRecords }) => {
        if (totalRecords > 0) {
          this.dataList = records
          this.totalPage = totalRecords
        } else {
          this.dataList = []
          this.totalPage = 0
        }
        this.dataListLoading = false
      })
    },
    // 每页数
    sizeChangeHandle(val) {
      this.pageSize = val
      this.pageIndex = 1
      this.getDataList()
    },
    // 当前页
    currentChangeHandle(val) {
      this.pageIndex = val
      this.getDataList()
    },
    setsplit(val) {
      if (val) {
        let arr = val.split(';')
        return arr
      } else {
        return ''
      }
    }
  }
}
</script>
