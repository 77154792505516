<template>
  <el-dialog
    :title="!dataForm.id ? '新增' : '修改'"
    :close-on-click-modal="false"
    :visible.sync="visible"
    width="800px">
    <el-form :model="dataForm" :rules="dataRule"  size="small" ref="dataForm" @keyup.enter.native="dataFormSubmit()" label-width="20%"
     v-loading="dataLoading" style="width:100%;"  element-loading-text=""  element-loading-spinner="el-icon-loading"
    >
      <el-form-item label="名称" prop="name">
        <el-input v-model="dataForm.name" placeholder="名称"  size="small"></el-input>
      </el-form-item>
      <el-form-item label="产品类型" prop="name">
        <el-select v-model="dataForm.condition" placeholder="请选择">
          <el-option
                  v-for="item in cartegryList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>
      <!--<el-form-item label="最小值" prop="min">
        <el-input v-model="dataForm.min" placeholder="最小值"  size="small"></el-input>
      </el-form-item>-->
      <el-form-item label="最小数量" prop="min">
        <el-input v-model="dataForm.min" placeholder="订购数量"  size="small"><template slot="append">公斤</template></el-input>
      </el-form-item>
      <el-form-item label="最大数量" prop="max">
        <el-input v-model="dataForm.max" placeholder="订购数量"  size="small"><template slot="append">公斤</template></el-input>
      </el-form-item>
      <el-form-item label="优惠系数" prop="discount">
        <el-input v-model="dataForm.discount" placeholder="条件"  size="small"></el-input>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="visible = false" size="small">取消</el-button>
      <el-button type="primary" @click="dataFormSubmit()" size="small"  :disabled="dataLoading">确定</el-button>
    </span>
  </el-dialog>
</template>

<script>
  import {list,info,add,updatecar} from '@/api/modules/samplepredictrule'
  export default {
    data () {
      return {
        visible: false,
        dataLoading: false,
        dataForm: {
          id: 0,
          name: '',
          min: '',
          max: '',
          condition: '',
          discount: '',
          type:'5',
        },
        cartegryList:'',
        dataRule: {
          name: [
            { required: true, message: '名称不能为空', trigger: 'blur' }
          ],
          min: [
            { required: true, message: '最小值不能为空', trigger: 'blur' }
          ],
          max: [
            { required: true, message: '最大值不能为空', trigger: 'blur' }
          ],
          condition: [
            { required: true, message: '条件不能为空', trigger: 'blur' }
          ],
          discount: [
            { required: true, message: '折扣不能为空', trigger: 'blur' }
          ]
        }
      }
    },
    created() {
      this.getCartegryList();
    },
    methods: {
      init (id) {
        this.dataForm.id = id || 0
        this.visible = true
        this.$nextTick(() => {
          this.$refs['dataForm'].resetFields()
          if (this.dataForm.id) {
            info({id:this.dataForm.id}).then(({data}) => {
              if (data && data.code === 0) {
                this.dataForm.name = data.samplePredictrule.name
                this.dataForm.min = data.samplePredictrule.min
                this.dataForm.max = data.samplePredictrule.max
                this.dataForm.condition = data.samplePredictrule.condition
                this.dataForm.discount = data.samplePredictrule.discount
              }
            })
          }
        })
      },
      //获取产品类型列表
      getCartegryList () {
        var params = {
          page: this.pageIndex,
          limit: this.pageSize,
          type: this.dataForm.type
        }
        list(params).then(({data}) => {
          if (data && data.code === 0) {
            this.cartegryList = data.page.list
            this.totalPage = data.page.totalCount
          } else {
            this.cartegryList = []
            this.totalPage = 0
          }
          this.dataListLoading = false
        })
      },
      // 表单提交
      dataFormSubmit () {
        this.$refs['dataForm'].validate((valid) => {
          if (valid) {
          	this.dataLoading = true;
            var params = {
              'id': this.dataForm.id || undefined,
              'name': this.dataForm.name,
              'min': this.dataForm.min,
              'max': this.dataForm.max,
              'condition': this.dataForm.condition,
              'discount':this.dataForm.discount,
            }
            var tick = !this.dataForm.id ? add(params) : updatecar(params)
            tick.then(({data}) => {
              if (data && data.code === 0) {
                this.$message({
                  message: '操作成功',
                  type: 'success',
                  duration: 1500,
                  onClose: () => {
                    this.visible = false;
	              	this.dataLoading = false;
                    this.$emit('refreshDataList')
                  }
                })
              } else {
                this.$message.error(data.msg)
                this.dataLoading = false;
              }
            })
          }
        })
      }
    }
  }
</script>
