<template>
  <el-dialog
    :title="!dataForm.id ? '物流信息' : '物流信息'"
    :close-on-click-modal="false"
    :visible.sync="visible">

    <!--<div v-if="this.hasSample == 1">-->
      <!--<p style="font-size: 17px">样件物流   样件物流公司：{{yangJanLogisticsName}} 样件物流单号：{{yangJanLogisticsNo}}</p>-->

    <!-- <div v-if="this.hasSample == 1">
      <p style="font-size: 17px">样板/样粉物流</p>

      <el-timeline>
        <el-timeline-item
                v-for="(activity, index) in yangJanList"
                :key="index"
                :timestamp="activity.ftime">
          {{activity.context}}{{activity.time}}
        </el-timeline-item>
        <p v-if="yangJanList == null || yangJanList.length == 0 " style="text-align: center;color: #cccccc;font-size: 20px">暂无样件物流信息</p>
      </el-timeline>
      <el-divider></el-divider>
    </div> -->
    <!-- <div>
      <p style="font-size: 17px">样板/样粉物流 样板物流公司：{{yangBanLogisticsName}} 样板物流单号：{{yangBanLogisticsNo}}</p>
      <el-timeline>
        <el-timeline-item
                v-for="(activity, index) in yangBanList"
                :key="index"
                :timestamp="activity.ftime">
          {{activity.context}}{{activity.time}}
        </el-timeline-item>
        <p v-if="yangBanList == null || yangBanList.length == 0 " style="text-align: center;color: #cccccc;font-size: 20px">暂无样板/样粉物流信息</p>
      </el-timeline>
    </div> -->
    <!-- <el-divider></el-divider> -->
    <div>
      <p v-if="yangFenList == null || yangFenList.length == 0 " style="font-size: 17px">样板/样粉物流  样板物流公司：{{yangBanLogisticsName}} 样板物流单号：{{yangBanLogisticsNo}}</p>
      <el-timeline>
        <el-timeline-item
                v-for="(activity, index) in yangFenList"
                :key="index"
                :timestamp="activity.ftime">
          {{activity.context}}{{activity.time}}
        </el-timeline-item>
        <p v-if="yangFenList == null || yangFenList.length == 0 " style="text-align: center;color: #cccccc;font-size: 20px">暂无样粉物流信息</p>
      </el-timeline>
    </div>

    <span slot="footer" class="dialog-footer">
      <el-button @click="visible = false" size="small">返回</el-button>
    </span>
  </el-dialog>
</template>

<script>
  import {update,selectLoginic} from '@/api/modules/sampleorder';
  import {getToken} from '@/utils/auth';
  import Tinymce from '@/components/Tinymce'

  export default {
    components: {
      Tinymce
    },
    data () {
      return {
        action: `${process.env.VUE_APP_BASE_API}/sys/oss/upload?token=${getToken()}`,
        visible: false,
        dataLoading: false,
        loginicListL:[],
        yangJanList:[],
        yangBanList:[],
        yangFenList:[],
          yangBanLogisticsName: '',
          yangBanLogisticsNo: '',
          yangFenLogisticsName: "",
          yangFenLogisticsNo: '',
          yangJanLogisticsNo: "",
          yangJanLogisticsName: '',
        hasSample:'',
        dataForm: {
          id: 0,
        },
      }
    },
    methods: {
      init (orderId,hasSample) {
        this.dataForm.id = orderId || 0;
        this.hasSample = hasSample;
        this.visible = true
        this.$nextTick(() => {
          if (this.dataForm.id) {
            let params ={
              id:this.dataForm.id
            }
            selectLoginic(params).then(({data}) => {
              if (data && data.code === 0) {
                this.loginicListL = data.dataList
                this.yangJanList =this.loginicListL.yangJan
                this.yangBanList = this.loginicListL.yangBan
                this.yangFenList = this.loginicListL.yangFen
                  this.yangBanLogisticsName=this.loginicListL.logisticsName
                      this.yangBanLogisticsNo=this.loginicListL.logisticsNo
                      this.yangFenLogisticsName=this.loginicListL.logisticsName
                      this.yangFenLogisticsNo=this.loginicListL.logisticsNo
                      this.yangJanLogisticsNo=this.loginicListL.logisticsNo
                      this.yangJanLogisticsName=this.loginicListL.logisticsName
              } else {
                this.loginicListL = []
              }
            })
          }
        })
      },
      // 表单提交
      dataFormSubmit () {
        this.$refs['dataForm'].validate((valid) => {
          if (valid) {
          this.dataLoading = true;
            var params = {
              'id': this.dataForm.id || undefined,
            }
            var tick = !this.dataForm.id ? update(params): update(params)
            tick.then(({data}) => {
              if (data && data.code === 0) {
                this.$message({
                  message: '操作成功',
                  type: 'success',
                  duration: 1500,
                  onClose: () => {
                    this.visible = false;
                    this.dataLoading = false;
                    this.$emit('refreshDataList')
                  }
                })
              } else {
                this.$message.error(data.msg)
                this.dataLoading = false;
              }
            })
          }
        })
      }
    }
  }
</script>