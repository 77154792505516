<template>
  <div>
    <div v-show="step == 'index'" class="app-container">
        <el-form :inline="true" size="small" :model="dataForm" ref="dataForm" @keyup.enter.native="resetPage()">
            <el-form-item prop="name" label="客服名称">
                <el-input v-model="dataForm.name" placeholder="请输入客服名称" size="small" clearable></el-input>
            </el-form-item>
            <el-form-item>
                <el-button @click="resetPage()" size="small">查询</el-button>
                <el-button @click="reset('dataForm')" size="small">清空</el-button>
                <el-button type="primary" @click="addOrUpdateHandle()" size="small">新增客服</el-button>
            </el-form-item>
        </el-form>
      <el-table
        :data="dataList"
        border
        size="small"
        v-loading="dataListLoading"
        @selection-change="selectionChangeHandle"
        style="width: 100%;">
        <el-table-column
          prop="name"
          header-align="center"
          align="center"
          label="客服名称">
        </el-table-column>
        <el-table-column
          prop="avatar"
          header-align="center"
          align="center"
          label="头像">
          <template slot-scope="scope">
            <img :src="scope.row.avatar" min-width="70" height="70"/>
          </template>
        </el-table-column>
        <el-table-column
          prop="kfUrl"
          header-align="center"
          align="center"
          label="客服请求地址">
        </el-table-column>
        <el-table-column
          prop="brandName"
          header-align="center"
          align="center"
          label="所属品牌">
        </el-table-column>
        <el-table-column
          fixed="right"
          header-align="center"
          align="center"
          width="150"
          label="操作">
          <template slot-scope="scope">
            <el-button type="text" size="small" @click="kfServicerListHandle(scope.row.id)">查看接线员</el-button>
            <el-button type="text" size="small" @click="addOrUpdateHandle(scope.row.id)">修改</el-button>
            <el-button type="text" size="small" @click="deleteHandle(scope.row.id)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        size="small"
        @size-change="sizeChangeHandle"
        @current-change="currentChangeHandle"
        :current-page="pageIndex"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="pageSize"
        :total="totalPage"
        layout="total, sizes, prev, pager, next, jumper">
      </el-pagination>
      <!-- 弹窗, 新增 / 修改 -->
      <add-or-update v-if="addOrUpdateVisible" ref="addOrUpdate" @refreshDataList="getDataList"></add-or-update>
    </div>
    <KFServicerList v-if="step == 'kfServicerList'" ref="kfServicerList"></KFServicerList>
  </div>
</template>

<script>
  import {list,del} from '@/api/modules/kfaccount'
  import AddOrUpdate from './add-or-update'
  import KFServicerList from './kfServicerList'
  export default {
    data () {
      return {
        step: 'index',
        dataForm: {
          key: '',
          daterange:[]
        },
        dataList: [],
        pageIndex: 1,
        pageSize: 10,
        totalPage: 0,
        dataListLoading: false,
        dataListSelections: [],
        addOrUpdateVisible: false
      }
    },
    components: {
      AddOrUpdate,
      KFServicerList
    },
    mounted () {
      this.getDataList()
    },
    methods: {
      //重新查询
      resetPage() {
        this.pageIndex = 1;
        this.getDataList();
      },
      reset(dataForm) {
       	this.$refs.dataForm.resetFields();
        this.resetPage();
      },
      // 获取数据列表
      getDataList () {
        this.dataListLoading = true
        var params = {
          page: this.pageIndex,
          limit: this.pageSize,
          name: this.dataForm.name
        }
        list(params).then(({data}) => {
          if (data && data.code === 0) {
            this.dataList = data.page.list
            this.totalPage = data.page.totalCount
          } else {
            this.dataList = []
            this.totalPage = 0
          }
          this.dataListLoading = false
        })
      },
      // 每页数
      sizeChangeHandle (val) {
        this.pageSize = val
        this.pageIndex = 1
        this.getDataList()
      },
      // 当前页
      currentChangeHandle (val) {
        this.pageIndex = val
        this.getDataList()
      },
      // 多选
      selectionChangeHandle (val) {
        this.dataListSelections = val
      },
      // 新增 / 修改
      addOrUpdateHandle (id) {
        this.addOrUpdateVisible = true
        this.$nextTick(() => {
          this.$refs.addOrUpdate.init(id)
        })
      },
      // 删除
      deleteHandle (id) {
        var ids = id ? [id] : this.dataListSelections.map(item => {
          return item.id
        })
        this.$confirm(`确定进行删除操作?`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          del(ids).then(({data}) => {
            if (data && data.code === 0) {
              this.$message({
                message: '操作成功',
                type: 'success',
                duration: 1500,
                onClose: () => {
                  this.getDataList()
                }
              })
            } else {
              this.$message.error(data.msg)
            }
          })
        })
      },
      // 设置通道
      kfServicerListHandle(kfId) {
        this.step = 'kfServicerList'
        this.$nextTick(() => {
          this.$refs.kfServicerList.getDataList(kfId)
        })
      }
    }
  }
</script>
