<template>
  <el-dialog
    :title="'设备授权'"
    :close-on-click-modal="false"
    :visible.sync="visible"
    width="500px"
  >
    <el-form
      :model="dataForm"
      size="small"
      ref="dataForm"
      :rules="dataRule"
      @keyup.enter.native="dataFormSubmit()"
      label-width="130px"
      v-loading="dataLoading"
      element-loading-text=""
      element-loading-spinner="el-icon-loading"
    >
      <el-form-item label="设备型号" prop="deviceModelName">
        <el-input
          v-model="dataForm.deviceModelName"
          placeholder="设备型号"
          size="small"
          style="width: 260px"
          disabled
        ></el-input>
      </el-form-item>
      <el-form-item label="设备编号" prop="deviceNo">
        <el-input
          v-model="dataForm.deviceNo"
          placeholder="设备编号"
          size="small"
          style="width: 260px"
          disabled
        ></el-input>
      </el-form-item>
      <el-form-item label="用户" prop="userId">
        <el-select
          v-model="dataForm.userId"
          clearable
          placeholder="选择用户"
          style="width: 260px"
        >
          <el-option
            v-for="item in userList"
            :key="item.id"
            :label="item.nickName"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="用户手机号" prop="userMobile">
        <el-autocomplete
          class="inline-input"
          v-model="dataForm.memberId"
          placeholder="用户手机号"
          :trigger-on-focus="false"
          style="width: 260px"
          size="small"
        ></el-autocomplete>
      </el-form-item>
      <el-form-item label="授权期限" prop="time">
        <el-date-picker
          v-model="dataForm.time"
          type="daterange"
          align="right"
          range-separator="——"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          format="yyyy-MM-dd"
          value-format="yyyy-MM-dd"
          style="width: 260px"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item label="授权状态" prop="state">
        <template>
          <el-radio v-model="dataForm.state" :label="0">启用</el-radio>
          <el-radio v-model="dataForm.state" :label="1">停用</el-radio>
        </template>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="closeSubmit()" size="small">取消</el-button>
      <el-button
        type="primary"
        @click="dataFormSubmit()"
        size="small"
        :disabled="dataLoading"
        >确定</el-button
      >
    </span>
  </el-dialog>
</template>

<script>
import { addSysUserDeviceApi } from "@/api/modules/deviceuser";
import { userList } from "@/api/sys";
import { deepClone } from "@/utils/index";

export default {
  data() {
    return {
      roleTypeList: [],
      userList: [],
      visible: false,
      dataLoading: false,
      dataForm: {
        deviceId: "",
        deviceModelName: "",
        deviceNo: "",
        userId: "",
        state: 0,
        time: null,
      },
      dataRule: {
        userId: [{ required: true, message: "请选择用户", trigger: "blur" }],
        time: [
          {
            type: "array",
            required: true,
            message: "请选择授权期限",
            trigger: "change",
          },
        ],
      },
    };
  },
  methods: {
    init(row) {
      this.visible = true;
      this.$nextTick(() => {
        this.$refs["dataForm"].resetFields();
        this.dataForm.deviceId = row.id;
        this.dataForm.deviceModelName = row.deviceModelName;
        this.dataForm.deviceNo = row.deviceNo;
        this.getUserList();
      });
    },
    //获取用户下拉列表
    getUserList() {
      let params = {
        pageSize: 100000,
      };
      userList(params).then(({ records, totalRecords }) => {
        if (totalRecords > 0) {
          this.userList = records;
        } else {
          this.userList = [];
        }
      });
    },
    // 表单提交
    dataFormSubmit() {
      this.$refs["dataForm"].validate(async (valid) => {
        if (valid) {
          this.dataLoading = true;
          this.dataForm.startDate = this.dataForm.time[0];
          this.dataForm.endDate = this.dataForm.time[1];
          await addSysUserDeviceApi(this.dataForm)
            .then(() => {
              this.$baseMessage("操作成功", "success");
              this.visible = false;
              this.modelList = [];
            })
            .finally(() => {
              this.dataLoading = false;
            });
        }
      });
    },
    //关闭
    closeSubmit() {
      //   this.dataForm.userId = "";
      //   this.dataForm.roleName = "";
      this.visible = false;
    },
  },
};
</script>
<style scoped>
.div {
  /*width:400px;*/
  /*height:120px;*/
  border: 2px solid #ececec;
  box-shadow: 10px 10px 5px #888888;
  padding: 15px 0;
  margin-bottom: 35px;
}
.div1 {
  /*width:400px;*/
  /*height:150px;*/
  border: 2px solid #ececec;
  -moz-box-shadow: 10px 10px 5px #888888; /* 老的 Firefox */
  box-shadow: 10px 10px 5px #888888;
  padding: 15px 0;
  margin: 35px 0;
}
</style>