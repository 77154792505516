<template>
    <div class="app-container">
        <el-form :inline="true" size="small" :model="dataForm" ref="dataForm" @keyup.enter.native="restPage()">
            <el-form-item prop="title">
                <el-input v-model="dataForm.title" placeholder="文章标题" size="small" clearable></el-input>
            </el-form-item>
            <el-form-item prop="state" >
                <el-select v-model="dataForm.state" placeholder="文章状态">
                    <el-option label="未发布" value="0"></el-option>
                    <el-option label="待审核" value="1"></el-option>
                    <el-option label="审核失败" value="2"></el-option>
                    <el-option label="已发布" value="3"></el-option>
                    <el-option label="已下架" value="4"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item prop="application" >
                <el-select v-model="dataForm.application" placeholder="显示位置">
                    <el-option label="小程序" value="0"></el-option>
                    <el-option label="网站" value="1"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item prop="type">
                <el-select v-model="dataForm.type" size="small" placeholder="文章类型">
                    <el-option
                            v-for="item in ArticleCategoryList"
                            :key="item.id"
                            :label="item.name"
                            :value="item.id"
                            size="small"
                    ></el-option>
                </el-select>
            </el-form-item>
            <el-form-item prop="daterange">
                <el-date-picker type="daterange" v-model="dataForm.daterange" range-separator="~" format="yyyy-MM-dd" value-format="yyyy-MM-dd"
                :editable="false" start-placeholder="创建时间从" end-placeholder="创建时间到" clearable></el-date-picker>
            </el-form-item>
             <el-form-item prop="brandId">
                <el-select v-model="dataForm.brandId" size="small" placeholder="所属品牌">
                    <el-option label="全部" value=""></el-option>
                    <el-option label="平台" value="-1"></el-option>
                    <el-option
                    v-for="item in  brandList"
                    :key="item.id"
                    :label="item.brandName"
                    :value="item.id"
                    size="small">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item>
                <el-button @click="restPage()" size="small">查询</el-button>
                <el-button @click="reset('dataForm')" size="small">清空</el-button>
                <el-button v-if="isAuth('article:article:save')" type="primary" @click="addOrUpdateHandle()"
                           size="small">新增
                </el-button>
                <!-- <el-button v-if="isAuth('member:article:delete')" type="danger" @click="deleteHandle()"
                           :disabled="dataListSelections.length <= 0" size="small">批量删除
                </el-button> -->
            </el-form-item>
        </el-form>
        <el-table
                :data="dataList"
                border
                size="small"
                v-loading="dataListLoading"
                @selection-change="selectionChangeHandle"
                style="width: 100%;">
            <!-- <el-table-column
                    type="selection"
                    fixed="left"
                    header-align="center"
                    align="center"
                    width="50">
            </el-table-column> -->
            <el-table-column
                    type="index"
                    header-align="center"
                    align="center"
                    label="序号">
            </el-table-column>
            <el-table-column
                    prop="title"
                    header-align="center"
                    align="center"
                    label="文章标题">
            </el-table-column>
            <el-table-column
                    prop=""
                    header-align="center"
                    align="center"
                    label="所属品牌">
                <template slot-scope="scope">
                    <span v-if="scope.row.brandId">{{selectBrand(scope.row.brandId)}}</span>
                    <span v-else>平台</span>
                </template>   
            </el-table-column>
            <el-table-column
                    prop="application"
                    header-align="center"
                    align="center"
                    label="显示位置">
                <template slot-scope="scope">
                    <span v-if="scope.row.application=='0'">小程序</span>
                    <span v-if="scope.row.application=='1'">门户网站</span>
                </template>
            </el-table-column>
            <el-table-column
                    prop="picUrl"
                    header-align="center"
                    align="center"
                    label="文章图片">
                <template slot-scope="scope">
                    <img :src="scope.row.picUrl" min-width="70" height="70"/>
                </template>
            </el-table-column>
            <el-table-column
                    prop="name"
                    header-align="center"
                    align="center"
                    label="文章类型">
            </el-table-column>
            <el-table-column
                    prop="state"
                    header-align="center"
                    align="center"
                    label="状态">
                <template slot-scope="scope">
                    <span v-if="scope.row.state=='0'">未发布</span>
                    <span v-if="scope.row.state=='1'">待审核</span>
                    <span v-if="scope.row.state=='2'">审核失败</span>
                    <span v-if="scope.row.state=='3'">已发布</span>
                    <span v-if="scope.row.state=='4'">已下架</span>
                </template>
            </el-table-column>
            <el-table-column
                    prop="createDate"
                    header-align="center"
                    align="center"
                    label="创建时间">
            </el-table-column>
            <el-table-column
                    fixed="right"
                    header-align="center"
                    align="center"
                    width="150"
                    label="操作">
                <template slot-scope="scope">
                    <el-button type="text" v-if="!scope.row.brandId" size="small" @click="addOrUpdateHandle(scope.row.id)">修改</el-button>
                    <el-button type="text" v-if="!scope.row.brandId" size="small" @click="deleteHandle(scope.row.id)">删除</el-button>
                    <el-button type="text" v-if="!scope.row.brandId" size="small" @click="stopArticleState(scope.row.id,scope.row.state)"> {{scope.row.state == '3' ? "下架" : "上架"}}</el-button>
                    <el-button type="text" v-if="scope.row.brandId && scope.row.state=='3'" size="small" @click="setArticleState(scope.row,'3','下架')">下架</el-button>
                    <el-button type="text" v-if="scope.row.brandId && scope.row.state=='1'" size="small" @click="dialogVisible=true,params=scope.row">审核拒绝</el-button>
                    <el-button type="text" v-if="scope.row.brandId && scope.row.state=='1'" size="small" @click="setArticleState(scope.row,'5','审核通过')">审核通过</el-button>
                    <el-button type="text" v-if="scope.row.brandId" size="small" @click="addOrUpdateHandle(scope.row.id,'1')">查看</el-button>
                </template>
            </el-table-column>
        </el-table>
        <el-pagination
                size="small"
                @size-change="sizeChangeHandle"
                @current-change="currentChangeHandle"
                :current-page="pageIndex"
                :page-sizes="[10, 20, 50, 100]"
                :page-size="pageSize"
                :total="totalPage"
                layout="total, sizes, prev, pager, next, jumper">
        </el-pagination>
        <el-dialog
            :visible.sync="dialogVisible"
            title="请输入拒绝原因"
            @close="reviewContent = ''"
            width="30%">
            <el-input v-model="reviewContent" placeholder="" size="small" type="textarea" :maxlength="200"> </el-input>
            <template #footer>
            <span class="dialog-footer">
                <el-button @click="dialogVisible = false,reviewContent = ''" size="small">取消</el-button>
                <el-button type="primary" @click="dialogVisible = false,setArticleState(params,'6','审核拒绝')" :disabled="reviewContent ? false : true" size="small">确定</el-button>
            </span>
            </template>
        </el-dialog>
        <!-- 弹窗, 新增 / 修改 -->
        <add-or-update v-if="addOrUpdateVisible" ref="addOrUpdate" @refreshDataList="getDataList"></add-or-update>
    </div>
</template>

<script>
    import {list, del,stopState,fetchArticleCategory,updateBrandArticle} from '@/api/modules/article'
    import AddOrUpdate from './add-or-update'
    import {getBrand} from '@/api/modules/product'
    export default {
        data() {
            return {
                dataForm: {
                    title:'',
                    state:'',
                    application:'',
                    type:'',
                    daterange:[],
                    brandId:'',
                },
                ArticleCategoryList:[],
                dataList: [],
                pageIndex: 1,
                pageSize: 10,
                totalPage: 0,
                dataListLoading: false,
                dataListSelections: [],
                addOrUpdateVisible: false,
                dialogVisible:false,
                reviewContent:'',
                params:{},
                brandList: [],
            }
        },
        components: {
            AddOrUpdate
        },
        created() {
            this.getDataList();
            this.getCategory();
            this.getBrandList()
        },
        /*mounted(){
            this.getCategory()
        },*/
        methods: {
            selectBrand(id){
                for(let index = 0; index < this.brandList.length; index++) {
                    if(this.brandList[index].id === id){
                        return this.brandList[index].brandName
                    }
                }
            },
            //文章类别下拉
            getCategory(){
                fetchArticleCategory().then(({data}) => {
                    if (data && data.code === 0) {
                        this.ArticleCategoryList = data.ArticleCategory
                    } else {
                        this.ArticleCategoryList = []
                    }
                })
            },
            getBrandList(){
                let params = {}
                getBrand(params).then(({data}) => {
                    if (data && data.code === 0) {
                        this.brandList = data.list
                    } else {
                        this.brandList = []
                    }
                })
            },
            //重新查询
            restPage() {
                this.pageIndex = 1;
                this.getDataList();
            },
            reset(dataForm) {
                this.$refs.dataForm.resetFields();
                this.restPage();
            },
            // 获取数据列表
            getDataList() {
                this.dataListLoading = true
                var params = {
                    page: this.pageIndex,
                    limit: this.pageSize,
                    state: this.dataForm.state,
                    title:this.dataForm.title,
                    application:this.dataForm.application,
                    type:this.dataForm.type,
                    startDate: this.dataForm.daterange && this.dataForm.daterange.length > 0 ? this.dataForm.daterange[0]+' 00:00:00' : '',
                    endDate: this.dataForm.daterange && this.dataForm.daterange.length > 0 ? this.dataForm.daterange[1]+' 23:59:59' : '',
                    brandId:this.dataForm.brandId
                }
                list(params).then(({data}) => {
                    if (data && data.code === 0) {
                        this.dataList = data.page.list
                        this.totalPage = data.page.totalCount
                    } else {
                        this.dataList = []
                        this.totalPage = 0
                    }
                    this.dataListLoading = false
                })
            },
            // 每页数
            sizeChangeHandle(val) {
                this.pageSize = val
                this.pageIndex = 1
                this.getDataList()
            },
            // 当前页
            currentChangeHandle(val) {
                this.pageIndex = val
                this.getDataList()
            },
            // 多选
            selectionChangeHandle(val) {
                this.dataListSelections = val
            },
            // 新增 / 修改
            addOrUpdateHandle(id,type) {
                this.addOrUpdateVisible = true
                this.$nextTick(() => {
                    this.$refs.addOrUpdate.init(id,type)
                })
            },
            // 删除
            deleteHandle(id) {
                var ids = id ? [id] : this.dataListSelections.map(item => {
                    return item.id
                })
                this.$confirm(`是否删除?`, '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    del(ids).then(({data}) => {
                        if (data && data.code === 0) {
                            this.$message({
                                message: '操作成功',
                                type: 'success',
                                duration: 1500,
                                onClose: () => {
                                    this.getDataList()
                                }
                            })
                        } else {
                            this.$message.error(data.msg)
                        }
                    })
                })
            },
            stopArticleState(id,state){
                var params = {
                    'id': id,
                    'state':state== '3'? '4':'3'
                };
                this.$confirm(`是否进行${state==0 ? '下架' : '上架'}操作?`, '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    stopState(params).then(({data}) => {
                        if (data && data.code === 0) {
                            this.$message({
                                message: '操作成功',
                                type: 'success',
                                duration: 1500,
                                onClose: () => {
                                    this.getDataList()
                                }
                            })
                        } else {
                            this.$message.error(data.msg)
                        }
                    })
                })
            },
            setArticleState(obj,type,text){
                var params = obj;
                params.articleOperationType = type;
                if (type === '6') {
                    params.reviewContent = this.reviewContent
                    updateBrandArticle(params).then(({data}) => {
                        if (data && data.code === 0) {
                            this.$message({
                                message: '操作成功',
                                type: 'success',
                                duration: 1500,
                                onClose: () => {
                                    this.getDataList()
                                }
                            })
                        } else {
                            this.$message.error(data.msg)
                        }
                        this.reviewContent = ''
                    })
                }else{
                    this.$confirm(`是否`+text +`?`, '提示', {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        type: 'warning'
                    }).then(() => {
                        updateBrandArticle(params).then(({data}) => {
                            if (data && data.code === 0) {
                                this.$message({
                                    message: '操作成功',
                                    type: 'success',
                                    duration: 1500,
                                    onClose: () => {
                                        this.getDataList()
                                    }
                                })
                            } else {
                                this.$message.error(data.msg)
                            }
                        })
                    })
                }
            },
        }
    }
</script>
