import request from '@/utils/request'

// 获取列表
export function list (params) {
  return request({
    url: '/sample/sampleaftersale/list',
    method: 'get',
    params: params
  })
}

// 获取信息
export function info (params) {
  return request({
    url: '/sample/sampleaftersale/info',
    method: 'get',
    params: params
  })
}

// 添加
export function add (params) {
  return request({
    url: '/sample/sampleaftersale/save',
    method: 'post',
    data: params
  })
}

// 修改
export function update (params) {
  return request({
    url: '/sample/sampleaftersale/update',
    method: 'post',
    data: params
  })
}

// 删除
export function del (params) {
  return request({
    url: '/sample/sampleaftersale/delete',
    method: 'post',
    data: params
  })
}

//审核按钮
export function checkOrder (params) {
    return request({
        url: '/sample/sampleaftersale/checkOrder',
        method: 'post',
        params: params
    })
}

//退货
export function refundOrTake (params) {
    return request({
        url: '/sample/sampleaftersale/refundOrTake',
        method: 'post',
        params: params
    })
}
//退款按钮
export function ruturnTake (params) {
    return request({
        url: '/sample/sampleaftersale/ruturnTake',
        method: 'post',
        params: params
    })
}

//物流查询按钮
export function logisticsQuery (params) {
    return request({
        url: '/sample/sampleaftersale/logisticsQuery',
        method: 'post',
        params: params
    })
}

// 获取订单状态信息
export function getCheckState (params) {
    return request({
        url: '/sample/sampleaftersale/getCheckState',
        method: 'get',
        params: params
    })
}
// 退款审核回显
export function checkRefund (params) {
    return request({
        url: '/sample/sampleaftersale/checkRefund',
        method: 'get',
        params: params
    })
}
// 退货退款审核回显接口
export function refundOrReturns (params) {
    return request({
        url: '/sample/sampleaftersale/refundOrReturns',
        method: 'get',
        params: params
    })
}
// 重新打样审核回显接口
export function checkSample (params) {
    return request({
        url: '/sample/sampleaftersale/checkSample',
        method: 'get',
        params: params
    })
}
// 退款详情回显
export function afterRefund (params) {
    return request({
        url: '/sample/sampleaftersale/afterRefund',
        method: 'get',
        params: params
    })
}

// 退货退款详情回显
export function afterRefundOrReturns (params) {
    return request({
        url: '/sample/sampleaftersale/afterRefundOrReturns',
        method: 'get',
        params: params
    })
}
// 重新打样详情回显
export function afterSamlp (params) {
    return request({
        url: '/sample/sampleaftersale/afterSamlp',
        method: 'get',
        params: params
    })
}

//样板样粉补发
export function modelMaterial (params) {
    return request({
        url: '/sample/sampleaftersale/modelMaterial',
        method: 'get',
        params: params
    })
}
//样板样粉物流单号填写
export function insertSampleOrderNo (params) {
    return request({
        url: '/sample/sampleaftersale/insertSampleOrderNo',
        method: 'post',
        params: params
    })
}
