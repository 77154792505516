<template>
    <el-dialog
            :title="'授权列表'"
            :close-on-click-modal="false"
            :visible.sync="visible">
        <el-form :model="dataForm"  size="small" ref="dataForm"  label-width="120px"
                 v-loading="dataLoading" style="width:100%;"  element-loading-text=""  element-loading-spinner="el-icon-loading"
        >
            <el-table
                    :data="dataForm.dataList"
                    border
                    size="small"
                    v-loading="dataListLoading"
                    style="width: 100%;">
                <el-table-column
                        prop="loginName"
                        header-align="center"
                        align="center"
                        label="会员名">
                </el-table-column>
                <el-table-column
                        prop="deviceNo"
                        header-align="center"
                        align="center"
                        label="设备编号">
                </el-table-column>
                <el-table-column
                        prop="deviceName"
                        header-align="center"
                        align="center"
                        label="设备名称">
                </el-table-column>
                <el-table-column
                        prop="state"
                        header-align="center"
                        align="center"
                        label="状态">
                    <template slot-scope="scope">
                        <span v-if="scope.row.state=='0'">未授权</span>
                        <span v-if="scope.row.state=='1'">已授权</span>
                    </template>
                </el-table-column>
                <!-- <el-table-column
                  prop="invalidDate"
                  header-align="center"
                  align="center"
                  label="失效时间">
                </el-table-column> -->
                <el-table-column
                        prop="createDate"
                        header-align="center"
                        align="center"
                        label="创建时间">
                </el-table-column>
                <el-table-column
                        fixed="right"
                        header-align="center"
                        align="center"
                        width="150"
                        label="操作">
                    <template slot-scope="scope">
                            <el-button type="text" size="small" @click="deleteHandle(scope.row.id)">撤销授权</el-button>
                    </template>
                </el-table-column>
            </el-table>
        </el-form>
        <span slot="footer" class="dialog-footer">
    </span>
    </el-dialog>
</template>

<script>
    import {list,revocationAuth} from '@/api/modules/memberdeviceauth'
    export default {
        data () {
            return {
                visible: false,
                dataLoading: false,
                dataForm: {
                    id: 0,
                    deviceNo: '',
                    factoryName: '',
                    solTime: '',
                    laboratory: '',
                    leadMan: '',
                    dataList: [],
                },
                dataListLoading: false,
            }
        },
        methods: {
            init (id) {
                this.dataForm.id = id || 0
                this.visible = true
                this.getDataList (id);
/*                this.$nextTick(() => {
                    this.$refs['dataForm'].resetFields()
                    if (this.dataForm.id) {
                        list({id:this.dataForm.id}).then(({data}) => {
                            if (data && data.code === 0) {
                                this.dataForm.deviceNo = data.deviceIn.deviceNo
                                this.dataForm.factoryName = data.deviceIn.factoryName
                                this.dataForm.laboratory = data.deviceIn.laboratory
                                this.dataForm.leadMan = data.deviceIn.leadMan
                            }
                        })
                    }
                    this.getDataList (id);
                })*/
            },
            //获取数据列表
            getDataList (id) {
                if (this.dataForm.id) {
                    let params = {
                        id: this.dataForm.id
                    }
                    list(params).then(({data}) => {
                        if (data && data.code === 0) {
                            this.dataForm.dataList = data.page.list
                            this.totalPage = data.page.totalCount
                        } else {
                            this.dataList = []
                            this.totalPage = 0
                        }
                        this.dataListLoading = false
                    })
                }
            },
            //撤销授权
            deleteHandle (id) {
                this.$confirm(`是否确认撤销授权操作?`, '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    revocationAuth({id: id}).then(({data}) => {
                        if (data && data.code === 0) {
                            this.$message({
                                message: '操作成功',
                                type: 'success',
                                duration: 1500,
                                onClose: () => {
                                    this.getDataList()
                                }
                            })
                        } else {
                            this.$message.error(data.msg)
                        }
                    })
                })
            }
        }
    }
</script>
<style>
    .one{
        display: block;
        float:left;
        margin:0 30px 0 0;
    }
</style>