<template>
    <el-dialog
            title="库存文件上传"
            :close-on-click-modal="false"
            @close="closeHandle"
            :visible.sync="visible">
        <el-select v-model="dataForm.factoryId" size="small" placeholder="请选择工厂" style="width: 50%;margin-left: 25%"
                   >
            <el-option
                    v-for="item in factoryList"
                    :key="item.id"
                    :label="item.factoryName"
                    :value="item.id"
                    size="small"
            ></el-option>
        </el-select>
        <el-upload
                drag
                action="13"
                :http-request="uploadExcel"
                :before-upload="beforeUploadHandle"
                multiple
                :file-list="fileList"
                :show-file-list="false"
                style="text-align: center;margin-top: 30px">
            <i class="el-icon-upload"></i>
            <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
            <div class="el-upload__tip" slot="tip">只支持excel！</div>
        </el-upload>
    </el-dialog>
</template>

<script>
    import {getToken} from "@/utils/auth";
    import {importExcel as excelImport, importNumExcel, getFactory} from '@/api/modules/product'

    export default {
        data() {
            return {
                visible: false,
                successNum: 0,
                fileList: [],
                factoryList: [],
                dataForm: {
                    factoryId: '',
                }
            };
        },
        methods: {
            init(id) {
                this.dataForm.factoryId="";
                this.visible = true;
                this.getFactoryList();
            },

            //获取工厂列表
            getFactoryList() {
                let params = {
                    brandId: '',
                };
                getFactory(params).then(({data}) => {
                    if (data && data.code === 0) {
                        this.factoryList = data.list;
                    } else {
                        this.factoryList = [];
                    }
                });
            },
            // 上传之前
            beforeUploadHandle(file) {
                if(this.dataForm.factoryId=='' || this.dataForm.factoryId==null || this.dataForm.factoryId==undefined){
                    this.$message.error("请先选择工厂!");
                    return;
                }
                const isExcel = file.name.toString().indexOf(".xls") > 0 || file.name.toString().indexOf(".xlsx") > 0;
                const isLt10M = file.size / 1024 / 1024 < 10;
                if (!isExcel) {
                    this.$message.error("请上传文件格式不正确!");
                }
                if (!isLt10M) {
                    this.$message.error("上传文件大小不能超过 10MB!");
                }
                return isExcel && isLt10M;
            },
            // 弹窗关闭时
            closeHandle() {
                this.fileList = [];
                this.$emit("refreshDataList");
            },
            //upload Excel
            uploadExcel(item) {
                if(this.dataForm.factoryId=='' || this.dataForm.factoryId==null || this.dataForm.factoryId==undefined){
                    this.$message.error("请先选择工厂!");
                    return;
                }
                let importData = new FormData();
                importData.append("file", item.file);
                importData.append("factoryId", this.dataForm.factoryId);
                importNumExcel(importData).then(({data}) => {
                    if (data && data.code === 0) {
                        this.$message({
                            message: "数据导入成功",
                            type: "success",
                            duration: 1500,
                            onClose: () => {
                                this.visible = false
                                this.$emit("refreshDataList");
                            }
                        });
                    } else {
                        return this.$alert(data.msg, "", {
                            confirmButtonText: "确定",
                            callback: action => {
                            }
                        });
                    }
                });
            }

        }
    };
</script>
