<template>
  <el-dialog
    :title="!dataForm.id ? '新增会员' : '编辑会员'"
    :close-on-click-modal="false"
    :visible.sync="visible"
    width="800px"
  >
    <el-form
      :model="dataForm"
      :rules="dataRule"
      size="small"
      ref="dataForm"
      @keyup.enter.native="dataFormSubmit()"
      label-width="100px"
      v-loading="dataLoading"
      style="width: 100%"
      element-loading-text=""
      element-loading-spinner="el-icon-loading"
    >
      <el-form-item label="会员昵称" prop="wxNickName">
        <el-input
          v-model="dataForm.wxNickName"
          placeholder="会员昵称"
          size="small"
          :disabled="dataForm.id ? true : false"
        ></el-input>
      </el-form-item>

      <!-- <el-form-item label="会员标签" prop="memberLabelIdList">
        <el-select
          v-model="dataForm.memberLabelIdList"
          multiple
          placeholder="请选择"
          size="small"
          style="width: 100%"
        >
          <el-option
            v-for="item in memberLabelList"
            :key="item.id"
            :label="item.labelName"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item> -->

      <el-form-item label="备注" prop="remarks">
        <el-input
          v-model="dataForm.remarks"
          placeholder="备注"
          size="small"
        ></el-input>
      </el-form-item>

      <el-form-item label="公司" prop="company">
        <el-input
          v-model="dataForm.company"
          placeholder="公司"
          size="small"
        ></el-input>
      </el-form-item>

      <el-form-item label="联系人" prop="userName">
        <el-input
          v-model="dataForm.userName"
          placeholder="联系人"
          size="small"
        ></el-input>
      </el-form-item>

      <el-form-item label="联系人电话" prop="mobile">
        <el-input
          v-model="dataForm.mobile"
          placeholder="联系人电话"
          size="small"
        ></el-input>
      </el-form-item>

      <area-map :msgVal="sendMsg" ref="areaMapMsg" v-if="sendMsg"></area-map>

      <el-form-item label="公司信息" prop="companyInfo">
        <el-input
          v-model="dataForm.companyInfo"
          placeholder="公司信息"
          size="small"
        ></el-input>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="visible = false" size="small">取消</el-button>
      <el-button
        type="primary"
        @click="dataFormSubmit()"
        size="small"
        :disabled="dataLoading"
        >确定</el-button
      >
    </span>
  </el-dialog>
</template>

<script>
import { getMemberApi, updateMemberApi } from "@/api/modules/member";
import ElFormItem from "../../../node_modules/element-ui/packages/form/src/form-item.vue";
import AreaMap from "./area-map";
import { queryMemberLabelApi } from "@/api/modules/memberlabel";
export default {
  components: { ElFormItem, AreaMap },
  data() {
    return {
      sendMsg: null,
      visible: false,
      dataLoading: false,
      memberLabelList: [],
      dataForm: {
        id: undefined,
        userNo: "",
        userName: "",
        password: "",
        memberLabelIdList: [],
        salt: "",
        userType: "",
        headPic: "",
        email: "",
        mobile: "",
        isFreeze: "",
        company: "", //公司
        remarks: "", //beizhu
        contactsName: "", //联系人
        companyInfo: "", //公司信息
        contactsAddress: "", //收货地址
        companyLng: "", //经度
        companyLat: "", //纬度
        wxNickName: "",
      },
      dataRule: {
        isFreeze: [
          { required: true, message: "是否冻结不能为空", trigger: "blur" },
        ],
        mobile: [
          { required: true, message: "手机号不能为空", trigger: "blur" },
        ],
      },
    };
  },
  created() {
    this.getMemberLabel();
  },
  methods: {
    async init(id) {
      this.sendMsg = null;
      this.dataForm.id = id || undefined;
      this.visible = true;
      await this.getMemberLabel();
      this.$nextTick(() => {
        this.$refs["dataForm"].resetFields();
        if (id) {
          getMemberApi(id).then((response) => {
            this.dataForm = response;
            this.sendMsg = this.dataForm;
            this.sendMsg.address = response.contactsAddress;
            this.sendMsg.lng = response.companyLng;
            this.sendMsg.lat = response.companyLat;
          });
        }
      });
    },
    // 表单提交
    dataFormSubmit() {
      this.$refs["dataForm"].validate((valid) => {
        if (valid) {
          this.dataLoading = true;
          this.dataForm.companyLng = this.$refs.areaMapMsg.lng;
          this.dataForm.companyLat = this.$refs.areaMapMsg.lat;
          this.dataForm.contactsAddress = this.$refs.areaMapMsg.address;
          updateMemberApi(this.dataForm)
            .then(() => {
              this.visible = false;
              this.dataLoading = false;
              this.$emit("refreshDataList");
            })
            .finally(() => {
              this.dataLoading = false;
            });
        }
      });
    },
    async getMemberLabel() {
      queryMemberLabelApi().then(({ records, totalRecords }) => {
        if (totalRecords > 0) {
          this.memberLabelList = records;
        } else {
          this.memberLabelList = [];
        }
      });
    },
  },
};
</script>
