<template>
    <el-dialog
            :title="!dataForm.id ? '调价' : '调价'"
            :close-on-click-modal="false"
            :visible.sync="visible"
            width="600px">
        <el-form :model="dataForm"   size="small" ref="dataForm"  label-width="70px"
                 v-loading="dataLoading" style="width:100%;"  element-loading-text=""  element-loading-spinner="el-icon-loading"
        >
            <!--<el-form-item prop="state">
              <template>
                <el-radio v-model="dataForm.state" label="4" @change="consent()">同意</el-radio>
                <el-radio v-model="dataForm.state" label="1" @change="callOff()">取消</el-radio>
              </template>
            </el-form-item>-->
            <div>
                <div style="margin-left: 70px">
                    <p>订单编号：{{dataForm.sampleOrderNo}}</p>
                    <p>订单价格：{{dataForm.orderPrice}}</p>
                    <p>下单用户：{{dataForm.createByName}}</p>
                </div>
                <el-form-item>
                    调整价格： 0 元
<!--                    <el-input type="number"   v-model="dataForm.price" style="width: 50%" oninput="if(isNaN(value)) { value = null } if(value.indexOf('.')>0){value=value.slice(0,value.indexOf('.')+3)}" ></el-input>-->
                </el-form-item>
            </div>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="visible = false" size="small" >取消</el-button>
          <el-button type="primary" @click="dataFormSubmit" size="small"  :disabled="dataLoading" >确认</el-button>
        </span>
    </el-dialog>
</template>

<script>
    import {priceAdjustment} from '@/api/modules/sampleorder'
    export default {
        data () {
            return {
                visible: false,
                dataLoading: false,
                dataForm: {
                    id: 0,
                    sampleOrderNo: '',
                    orderPrice:'',
                    mobile:'',
                    price:'',
                }
            }
        },
        methods: {
            init (id,sampleOrderNo,orderPrice,createByName) {
                this.dataForm.id = id || 0
                this.dataForm.sampleOrderNo = sampleOrderNo;
                this.dataForm.orderPrice = orderPrice;
                this.dataForm.createByName = createByName;
                this.visible = true;
                this.dataForm.price = '';
            },
            // 表单提交
            dataFormSubmit () {
                this.$refs['dataForm'].validate((valid) => {
                    if (valid) {
                        this.dataLoading = true;
                        var params = {
                            'id': this.dataForm.id || undefined,
                            'price': "0"
                        }
                        var tick = !this.dataForm.id ? priceAdjustment(params) : priceAdjustment(params)
                        tick.then(({data}) => {
                            if (data && data.code === 0) {
                                this.$message({
                                    message: '操作成功',
                                    type: 'success',
                                    duration: 1500,
                                    onClose: () => {
                                        this.dataForm.price = '';
                                        this.visible = false;
                                        this.dataLoading = false;
                                        this.$emit('refreshDataList')
                                    }
                                })
                            } else {
                                this.$message.error(data.msg)
                                this.dataLoading = false;
                            }
                        })
                    }
                })
            }
        }
    }
</script>
