<template>
  <el-dialog
    :title="!dataForm.id ? '审核详情' : '审核详情'"
    :close-on-click-modal="false"
    :visible.sync="visible">
    <el-form :model="dataForm" :rules="dataRule"  size="small" ref="dataForm" @keyup.enter.native="dataFormSubmit()" label-width="80px"
     v-loading="dataLoading" style="width:100%;"  element-loading-text=""  element-loading-spinner="el-icon-loading"
    >
      <div>
        <p>申请人姓名：{{dataForm.name}}</p>
        <p>申请人公司：{{dataForm.company}}</p>
        <p>申请人手机号：{{dataForm.phone}}</p>
        <p v-if="dataForm.state=='0'">状态：待审核</p>
        <p v-if="dataForm.state=='1'">状态：已批准</p>
        <p>审核备注：</p>
        <el-input v-model="dataForm.remark" type="textarea" size="small" placeholder="" maxlength="50" show-word-limit></el-input>
      </div>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="visible = false"  size="small">返回</el-button>
      <!--<el-button type="primary" @click="callOff()" size="small"  :disabled="dataLoading">审核通过</el-button>-->
    </span>
  </el-dialog>
</template>

<script>
  import {info,add,update,checkApply} from '@/api/modules/applyprobation'
  export default {
    data () {
      return {
        visible: false,
        dataLoading: false,
        dataForm: {
          applyProbation: 0,
          name: '',
          company: '',
          phone: '',
          state: '',
            remark: '',
            isNo: '',
        },
        dataRule: {
          name: [
            { required: true, message: '姓名不能为空', trigger: 'blur' }
          ],
          company: [
            { required: true, message: '公司不能为空', trigger: 'blur' }
          ],
          phone: [
            { required: true, message: '手机号不能为空', trigger: 'blur' }
          ],
          state: [
            { required: true, message: '申请状态', trigger: 'blur' }
          ],
        }
      }
    },
    methods: {
      init (id) {
        this.dataForm.applyProbation = id || 0
        this.visible = true
        this.$nextTick(() => {
          this.$refs['dataForm'].resetFields()
          if (this.dataForm.applyProbation) {
            info({applyProbation:this.dataForm.applyProbation}).then(({data}) => {
              if (data && data.code === 0) {
                this.dataForm.name = data.apply.name
                this.dataForm.company = data.apply.company
                this.dataForm.phone = data.apply.phone
                this.dataForm.state = data.apply.state
                  this.dataForm.remark = data.apply.remark
              }
            })
          }
        })
      },
      // 表单提交
      dataFormSubmit () {
        this.$refs['dataForm'].validate((valid) => {
          if (valid) {
            this.dataLoading = true;
            var params = {
              'applyProbation': this.dataForm.applyProbation || undefined,
              'name': this.dataForm.name,
              'company': this.dataForm.company,
              'phone': this.dataForm.phone,
              'state': this.dataForm.state,
            }
            var tick = !this.dataForm.applyProbation ? checkApply(params) : checkApply(params)
            tick.then(({data}) => {
              if (data && data.code === 0) {
                this.$message({
                  message: '操作成功',
                  type: 'success',
                  duration: 1500,
                  onClose: () => {
                    this.visible = false;
                    this.dataLoading = false;
                    this.$emit('refreshDataList')
                  }
                })
              } else {
                this.$message.error(data.msg)
                this.dataLoading = false;
              }
            })
          }
        })
      }
    }
  }
</script>
