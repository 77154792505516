<template>
  <div class="app-container">
    <el-form :inline="true"  size="small" :model="dataForm" ref="dataForm"  @keyup.enter.native="restPage()">
      <el-form-item prop="sampleOrderNo">
        <el-input v-model="dataForm.sampleOrderNo" placeholder="打样订单号" size="small" clearable></el-input>
      </el-form-item>
      <!--<el-form-item prop="daterange">
        <el-date-picker type="daterange" v-model="dataForm.daterange" range-separator="~" format="yyyy-MM-dd" value-format="yyyy-MM-dd HH:mm:ss"
          :editable="false" start-placeholder="开始时间" end-placeholder="结束时间"></el-date-picker>
      </el-form-item>-->
      <el-form-item>
        <el-button @click="restPage()" size="small">查询</el-button>
        <el-button @click="reset('dataForm')"  size="small">清空</el-button>
        <!--<el-button v-if="isAuth('member:memberinvoice:save')" type="primary" @click="addOrUpdateHandle()" size="small">新增</el-button>
        <el-button v-if="isAuth('member:memberinvoice:delete')" type="danger" @click="deleteHandle()" :disabled="dataListSelections.length <= 0" size="small">批量删除</el-button>-->
      </el-form-item>
    </el-form>
    <el-table
      :data="dataList"
      border
      size="small"
      v-loading="dataListLoading"
      @selection-change="selectionChangeHandle"
      style="width: 100%;">
      <!--<el-table-column
        type="selection"
        fixed="left"
        header-align="center"
        align="center"
        width="50">
      </el-table-column>-->
      <!--<el-table-column
        prop="memberInvoiceId"
        header-align="center"
        align="center"
        label="会员发票信息id">
      </el-table-column>-->
      <el-table-column
              prop="sampleOrderNo"
              header-align="center"
              align="center"
              label="打样单号">
      </el-table-column>
      <el-table-column
              prop="price"
              header-align="center"
              align="center"
              label="实付金额(元)">
      </el-table-column>
      <el-table-column
        prop="invoiceType"
        header-align="center"
        align="center"
        label="发票类型">
        <template slot-scope="scope">
          <span v-if="scope.row.invoiceType=='0'">普通发票</span>
          <span v-if="scope.row.invoiceType=='1'">增值税发票</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="titleType"
        header-align="center"
        align="center"
        label="抬头类型">
        <template slot-scope="scope">
          <span v-if="scope.row.invoiceType=='0'">个人</span>
          <span v-if="scope.row.invoiceType=='1'">企业</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="invoiceTitle"
        header-align="center"
        align="center"
        label="发票抬头">
      </el-table-column>
      <el-table-column
        prop="dutyParagraph"
        header-align="center"
        align="center"
        label="纳税识别号">
      </el-table-column>
      <el-table-column
        prop="bankName"
        header-align="center"
        align="center"
        label="开户银行">
      </el-table-column>
      <el-table-column
        prop="phoneNumber"
        header-align="center"
        align="center"
        label="企业电话">
      </el-table-column>
      <el-table-column
        prop="bankAddress"
        header-align="center"
        align="center"
        label="公司地址">
      </el-table-column>
      <el-table-column
              prop="state"
              header-align="center"
              align="center"
              label="申请状态">
        <template slot-scope="scope">
          <span v-if="scope.row.state=='0'">未申请</span>
          <span v-if="scope.row.state=='1'">申请中</span>
          <span v-if="scope.row.state=='2'">已开票</span>
          <span v-if="scope.row.state=='3'">已完成</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="createDate"
        header-align="center"
        align="center"
        label="申请时间">
      </el-table-column>
      <el-table-column
        fixed="right"
        header-align="center"
        align="center"
        width="150"
        label="操作">
        <template slot-scope="scope">
          <el-button type="text" size="small" @click="addOrUpdateHandle(scope.row.memberInvoiceId)">详情</el-button>
          <el-button type="text" size="small" @click="makeInvoiceMethod(scope.row.memberInvoiceId)" v-if="scope.row.state=='1'">开票</el-button>
          <el-button type="text" size="small" @click="mailInvoiceMethod(scope.row.memberInvoiceId)" v-if="scope.row.state=='2'">寄发票</el-button>
          <el-button type="text" size="small" @click="makeInvoiceDetailMethod(scope.row.memberInvoiceId)" v-if="scope.row.state=='2'||scope.row.state=='3'">开票详情</el-button>
          <el-button type="text" size="small" @click="mailInvoiceDetailMethod(scope.row.memberInvoiceId)" v-if="scope.row.state=='3'">寄发票详情</el-button>
         <!-- <el-button type="text" size="small" @click="deleteHandle(scope.row.memberInvoiceId)">删除</el-button>-->
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      size="small"
      @size-change="sizeChangeHandle"
      @current-change="currentChangeHandle"
      :current-page="pageIndex"
      :page-sizes="[10, 20, 50, 100]"
      :page-size="pageSize"
      :total="totalPage"
      layout="total, sizes, prev, pager, next, jumper">
    </el-pagination>
    <!-- 弹窗, 新增 / 修改 -->
    <add-or-update v-if="addOrUpdateVisible" ref="addOrUpdate" @refreshDataList="getDataList"></add-or-update>
    <!-- 开票 -->
    <make-invoice v-if="makeInvoiceVisible" ref="makeInvoice" @refreshDataList="getDataList"></make-invoice>
    <!-- 邮寄发票 -->
    <mail-invoice v-if="mailInvoiceVisible" ref="mailInvoice" @refreshDataList="getDataList"></mail-invoice>
    <!-- 开票详情 -->
    <make-invoice-detail v-if="MakeInvoiceDetailVisible" ref="makeInvoiceDetail" @refreshDataList="getDataList"></make-invoice-detail>
    <!-- 邮寄详情 -->
    <mail-invoice-detail v-if="MailInvoiceDetailVisible" ref="mailInvoiceDetail" @refreshDataList="getDataList"></mail-invoice-detail>
  </div>
</template>

<script>
  import {list,del} from '@/api/modules/memberinvoice'
  import AddOrUpdate from './add-or-update'
  import MakeInvoice from './make-invoice'
  import MailInvoice from './mail-invoice'
  import MakeInvoiceDetail from './make-invoice-detail'
  import MailInvoiceDetail from './mail-invoice-detail'
  export default {
    data () {
      return {
        dataForm: {
            sampleOrderNo: '',
          daterange:[]
        },
        dataList: [],
        pageIndex: 1,
        pageSize: 10,
        totalPage: 0,
        dataListLoading: false,
        dataListSelections: [],
        addOrUpdateVisible: false,
          makeInvoiceVisible:false,
          mailInvoiceVisible:false,
          MakeInvoiceDetailVisible:false,
            MailInvoiceDetailVisible:false
      }
    },
    components: {
      AddOrUpdate,
        MakeInvoice,
        MailInvoice,
        MakeInvoiceDetail,
        MailInvoiceDetail
    },
    activated () {
      this.getDataList()
    },
      mounted(){
          this.getDataList()
      },
    methods: {
        //开票详情
        makeInvoiceDetailMethod(id){
            this.MakeInvoiceDetailVisible = true
            this.$nextTick(() => {
                this.$refs.makeInvoiceDetail.init(id)
            })
        },
        //邮寄发票详情
        mailInvoiceDetailMethod(id){
            this.MailInvoiceDetailVisible = true
            this.$nextTick(() => {
                this.$refs.mailInvoiceDetail.init(id)
            })
        },
        //邮寄发票
        mailInvoiceMethod(id){
            this.mailInvoiceVisible = true
            this.$nextTick(() => {
                this.$refs.mailInvoice.init(id)
            })
        },
        //开票跳转
        makeInvoiceMethod(id){
            this.makeInvoiceVisible = true
            this.$nextTick(() => {
                this.$refs.makeInvoice.init(id)
            })
        },
      //重新查询
      restPage() {
        this.pageIndex = 1;
        this.getDataList();
      },
      reset(dataForm) {
       	this.$refs.dataForm.resetFields();
        this.restPage();
      },
      // 获取数据列表
      getDataList () {
        this.dataListLoading = true
        var params = {
          page: this.pageIndex,
          limit: this.pageSize,
            sampleOrderNo: this.dataForm.sampleOrderNo
        }
        list(params).then(({data}) => {
          if (data && data.code === 0) {
            this.dataList = data.page.list
            this.totalPage = data.page.totalCount
          } else {
            this.dataList = []
            this.totalPage = 0
          }
          this.dataListLoading = false
        })
      },
      // 每页数
      sizeChangeHandle (val) {
        this.pageSize = val
        this.pageIndex = 1
        this.getDataList()
      },
      // 当前页
      currentChangeHandle (val) {
        this.pageIndex = val
        this.getDataList()
      },
      // 多选
      selectionChangeHandle (val) {
        this.dataListSelections = val
      },
      // 新增 / 修改
      addOrUpdateHandle (id) {
        this.addOrUpdateVisible = true
        this.$nextTick(() => {
          this.$refs.addOrUpdate.init(id)
        })
      },
      // 删除
      deleteHandle (id) {
        var ids = id ? [id] : this.dataListSelections.map(item => {
          return item.memberInvoiceId
        })
        this.$confirm(`确定对[id=${ids.join(',')}]进行[${id ? '删除' : '批量删除'}]操作?`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          del(ids).then(({data}) => {
            if (data && data.code === 0) {
              this.$message({
                message: '操作成功',
                type: 'success',
                duration: 1500,
                onClose: () => {
                  this.getDataList()
                }
              })
            } else {
              this.$message.error(data.msg)
            }
          })
        })
      }
    }
  }
</script>
