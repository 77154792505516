<template>
    <div v-show="step=='kfServicerList'" class="app-container">
        <el-form :inline="true" :model="dataForm" ref="dataForm" @keyup.enter.native="resetPage()">
            <el-form-item>
                <el-button type="primary" @click="addKFServicerHandle()">添加接线员</el-button>
                <el-button @click="goBack()">返回</el-button>
            </el-form-item>
        </el-form>
        <el-table :data="dataList" border v-loading="dataListLoading" style="width: 100%;">
            <el-table-column prop="userid" header-align="center" align="center" label="接线员帐号"> </el-table-column>
            <el-table-column prop="status" header-align="center" align="center" label="接线员状态" :formatter="statusFormat"> </el-table-column>
            <el-table-column  fixed="right" header-align="center" align="center" width="150"  label="操作">
              <template slot-scope="scope">
                  <el-button type="text" size="small" @click="delHandle(scope.row.userid)">删除</el-button>
              </template>
        </el-table-column>
        </el-table>
        <!-- <el-pagination @size-change="sizeChangeHandle" @current-change="currentChangeHandle" :current-page="pageIndex" :page-sizes="[10, 20, 50, 100]" :page-size="pageSize" :total="totalPage" layout="total, sizes, prev, pager, next, jumper">
        </el-pagination> -->
        <AddKFServicer v-if="addKFServicerVisible" ref="addKFServicer" @refreshDataList(kfid)="getDataList(kfid)"></AddKFServicer>
    </div>
</template>

<script>
import { listKFServicer, delKFServicer } from '@/api/modules/kfaccount'
import AddKFServicer from './addKFServicer'
export default {
    data() {
        return {
            step: 'kfServicerList',
            kfid: '',
            dataForm: {
                userid: "",
                status: ""
            },
            dataList: [],
            pageIndex: 1,
            pageSize: 10,
            totalPage: 0,
            dataListLoading: false,
            dataListSelections: [],
            addKFServicerVisible: false        
        }
    },
    components: {
        AddKFServicer
    },
    created() {
    },
    methods: {
        reset(dataForm) {
        this.$refs.dataForm.resetFields();
        this.resetPage();
    },
    // 接线员状态转换
    statusFormat: function(row, column) {
        if (row.status == 1) {
            return "停止接待";
        } else {
            return "接待中";
        }
    },
     // 跳到第一页进行查询
    resetPage() {
      this.pageIndex = 1;
      this.getDataList(this.kfid);
    },
       // 获取数据列表
        getDataList(kfid) {
            this.kfid = kfid ? kfid : '';
            this.dataListLoading = true
            var params = {
                // page: this.pageIndex,
                // limit: this.pageSize,
                kfId: this.kfid,
            }
            listKFServicer(params).then(({ data }) => {
                if (data && data.code === 0) {
                    this.dataList = data.list
                    //this.totalPage = data.page.totalCount
                } else {
                    this.dataList = []
                    //this.totalPage = 0
                }
                this.dataListLoading = false
            })
        },
        // 每页数
        sizeChangeHandle(val) {
            //this.pageSize = val
            //this.pageIndex = 1
            this.getDataList(this.kfid)
        },
        // 当前页
        currentChangeHandle(val) {
            //this.pageIndex = val
            this.getDataList(this.kfid)
        },
        // 返回
        goBack() {
            this.$parent.step = 'index';
            this.$parent.resetPage();
        },

        // 添加接线员
        addKFServicerHandle() {
            this.addKFServicerVisible = true;
            let kfid = this.kfid;
            this.$nextTick(() => {
                this.$refs.addKFServicer.init(kfid);
            })
        },

        // 删除接线员
        delHandle(userid) {
            var params = {
                'userid': userid,
                'kfid': this.kfid
            }
            this.$confirm(`确定进行删除操作?`, '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
            }).then(() => {
            delKFServicer(params).then(({data}) => {
                if (data && data.code === 0) {
                    this.$message({
                        message: '操作成功',
                        type: 'success',
                        duration: 1500,
                        onClose: () => {
                            this.getDataList(this.kfid)
                        }
                    })
                } else {
                    this.$message.error(data.msg)
                }
            })
            })
        },
    }
}
</script>
