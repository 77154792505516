<template>
  <el-dialog
    :title="!dataForm.memberInvoiceId ? '寄发票' : '寄发票'"
    :close-on-click-modal="false"
    :visible.sync="visible"
  width="40%">
    <el-form :model="dataForm" :rules="dataRule"  size="small" ref="dataForm" @keyup.enter.native="dataFormSubmit()" label-width="80px"
     v-loading="dataLoading" style="width:100%;"  element-loading-text=""  element-loading-spinner="el-icon-loading"
    >
      <!--<el-form-item label="发票类型" prop="invoiceType">
        <el-input v-model="dataForm.invoiceType" placeholder="发票类型"  size="small"></el-input>
      </el-form-item>
      <el-form-item label="抬头类型" prop="titleType">
        <el-input v-model="dataForm.titleType" placeholder="抬头类型"  size="small"></el-input>
      </el-form-item>
      <el-form-item label="发票抬头" prop="invoiceTitle">
        <el-input v-model="dataForm.invoiceTitle" placeholder="发票抬头"  size="small"></el-input>
      </el-form-item>
      <el-form-item label="税号" prop="dutyParagraph">
        <el-input v-model="dataForm.dutyParagraph" placeholder="税号"  size="small"></el-input>
      </el-form-item>
      <el-form-item label="账号" prop="accountNumber">
        <el-input v-model="dataForm.accountNumber" placeholder="账号"  size="small"></el-input>
      </el-form-item>
      <el-form-item label="银行账号" prop="bankNumber">
        <el-input v-model="dataForm.bankNumber" placeholder="银行账号"  size="small"></el-input>
      </el-form-item>
      <el-form-item label="开户行" prop="bankName">
        <el-input v-model="dataForm.bankName" placeholder="开户行"  size="small"></el-input>
      </el-form-item>
      <el-form-item label="银行地址" prop="bankAddress">
        <el-input v-model="dataForm.bankAddress" placeholder="银行地址"  size="small"></el-input>
      </el-form-item>
      <el-form-item label="邮编" prop="postcode">
        <el-input v-model="dataForm.postcode" placeholder="邮编"  size="small"></el-input>
      </el-form-item>
      <el-form-item label="手机号" prop="phoneNumber">
        <el-input v-model="dataForm.phoneNumber" placeholder="手机号"  size="small"></el-input>
      </el-form-item>
      <el-form-item label="是否默认（0：否，1：是）" prop="isDefault">
        <el-input v-model="dataForm.isDefault" placeholder="是否默认（0：否，1：是）"  size="small"></el-input>
      </el-form-item>
      <el-form-item label="会员表id" prop="memberId">
        <el-input v-model="dataForm.memberId" placeholder="会员表id"  size="small"></el-input>
      </el-form-item>
      <el-form-item label="创建人" prop="createBy">
        <el-input v-model="dataForm.createBy" placeholder="创建人"  size="small"></el-input>
      </el-form-item>
      <el-form-item label="创建时间" prop="createDate">
        <el-input v-model="dataForm.createDate" placeholder="创建时间"  size="small"></el-input>
      </el-form-item>
      <el-form-item label="更新人" prop="updateBy">
        <el-input v-model="dataForm.updateBy" placeholder="更新人"  size="small"></el-input>
      </el-form-item>
      <el-form-item label="更新时间" prop="updateDate">
        <el-input v-model="dataForm.updateDate" placeholder="更新时间"  size="small"></el-input>
      </el-form-item>
      <el-form-item label="版本号" prop="version">
        <el-input v-model="dataForm.version" placeholder="版本号"  size="small"></el-input>
      </el-form-item>-->
      <div style="margin: 0px 0 0 10%">
        <p>收件人：{{dataForm.contactsName}}</p>
        <p>联系电话：{{dataForm.contactsPhone}}</p>
        <p>收件地址：{{dataForm.contactsAddress}}</p>
        <div style="display: flex">
          <p>物流编号：</p>
          <el-input v-model="dataForm.logisticsNo"  size="mini" placeholder=""  show-word-limit style="width: 60%"></el-input>
        </div>
        <div style="display: flex">
          <p>物流单号：</p>
          <el-input v-model="dataForm.logisticsOrderno"  size="mini" placeholder=""  show-word-limit style="width: 60%"></el-input>
        </div>
      </div>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="visible = false" size="small">取消</el-button>
     <el-button type="primary" @click="dataFormSubmit()" size="small"  :disabled="dataLoading">确定</el-button>
    </span>
  </el-dialog>
</template>

<script>
  import {info,add,update,mailInvoice} from '@/api/modules/memberinvoice'
  export default {
    data () {
      return {
          state:'',
        visible: false,
        dataLoading: false,
        dataForm: {
            logisticsNo:'',//物流编号
            logisticsOrderno:'',//物流单号
          memberInvoiceId: 0,
          invoiceType: '',
          titleType: '',
          invoiceTitle: '',
          dutyParagraph: '',
          accountNumber: '',
          bankNumber: '',
          bankName: '',
          bankAddress: '',
          postcode: '',
          phoneNumber: '',
          isDefault: '',
          memberId: '',
          createBy: '',
          createDate: '',
          updateBy: '',
          updateDate: '',
          contactsName:'',
          contactsPhone:'',
          contactsAddress:'',
          version: ''

        },
        dataRule: {
          invoiceType: [
            { required: true, message: '发票类型不能为空', trigger: 'blur' }
          ],
          titleType: [
            { required: true, message: '抬头类型不能为空', trigger: 'blur' }
          ],
          dutyParagraph: [
            { required: true, message: '税号不能为空', trigger: 'blur' }
          ],
          accountNumber: [
            { required: true, message: '账号不能为空', trigger: 'blur' }
          ],
          bankNumber: [
            { required: true, message: '银行账号不能为空', trigger: 'blur' }
          ],
          bankName: [
            { required: true, message: '开户行不能为空', trigger: 'blur' }
          ],
          bankAddress: [
            { required: true, message: '银行地址不能为空', trigger: 'blur' }
          ],
          postcode: [
            { required: true, message: '邮编不能为空', trigger: 'blur' }
          ],
          phoneNumber: [
            { required: true, message: '手机号不能为空', trigger: 'blur' }
          ],
          isDefault: [
            { required: true, message: '是否默认（0：否，1：是）不能为空', trigger: 'blur' }
          ],
          memberId: [
            { required: true, message: '会员表id不能为空', trigger: 'blur' }
          ],
          createBy: [
            { required: true, message: '创建人不能为空', trigger: 'blur' }
          ],
          createDate: [
            { required: true, message: '创建时间不能为空', trigger: 'blur' }
          ],
          updateBy: [
            { required: true, message: '更新人不能为空', trigger: 'blur' }
          ],
          updateDate: [
            { required: true, message: '更新时间不能为空', trigger: 'blur' }
          ],
          version: [
            { required: true, message: '版本号不能为空', trigger: 'blur' }
          ]
        }
      }
    },
    methods: {
      init (id) {
        this.dataForm.memberInvoiceId = id || 0
        this.visible = true
        this.$nextTick(() => {
          this.$refs['dataForm'].resetFields()
          if (this.dataForm.memberInvoiceId) {
            info({memberInvoiceId:this.dataForm.memberInvoiceId}).then(({data}) => {
              if (data && data.code === 0) {
                this.dataForm.invoiceType = data.memberInvoice.invoiceType
                this.dataForm.titleType = data.memberInvoice.titleType
                this.dataForm.invoiceTitle = data.memberInvoice.invoiceTitle
                this.dataForm.dutyParagraph = data.memberInvoice.dutyParagraph
                this.dataForm.accountNumber = data.memberInvoice.accountNumber
                this.dataForm.bankNumber = data.memberInvoice.bankNumber
                this.dataForm.bankName = data.memberInvoice.bankName
                this.dataForm.bankAddress = data.memberInvoice.bankAddress
                this.dataForm.postcode = data.memberInvoice.postcode
                this.dataForm.phoneNumber = data.memberInvoice.phoneNumber
                this.dataForm.isDefault = data.memberInvoice.isDefault
                this.dataForm.memberId = data.memberInvoice.memberId
                this.dataForm.createBy = data.memberInvoice.createBy
                this.dataForm.createDate = data.memberInvoice.createDate
                this.dataForm.updateBy = data.memberInvoice.updateBy
                this.dataForm.updateDate = data.memberInvoice.updateDate
                this.dataForm.version = data.memberInvoice.version
                  this.dataForm.contactsName = data.memberInvoice.contactsName
                  this.dataForm.contactsPhone = data.memberInvoice.contactsPhone
                  this.dataForm.contactsAddress = data.memberInvoice.contactsAddress
                  this.dataForm.state = data.memberInvoice.state
              }
            })
          }
        })
      },
      // 表单提交
      dataFormSubmit () {
        this.$refs['dataForm'].validate((valid) => {
          if (valid) {
          	this.dataLoading = true;
            var params = {
              'memberInvoiceId': this.dataForm.memberInvoiceId || undefined,
              'logisticsNo': this.dataForm.logisticsNo,//物流编号
                'logisticsOrderno': this.dataForm.logisticsOrderno,//物流单号
            }
            var tick = !this.dataForm.memberInvoiceId ? mailInvoice(params) : mailInvoice(params)
            tick.then(({data}) => {
              if (data && data.code === 0) {
                this.$message({
                  message: '操作成功',
                  type: 'success',
                  duration: 1500,
                  onClose: () => {
                    this.visible = false;
	              	this.dataLoading = false;
                    this.$emit('refreshDataList')
                  }
                })
              } else {
                this.$message.error(data.msg)
                this.dataLoading = false;
              }
            })
          }
        })
      }
    }
  }
</script>
