<template>
  <el-dialog
          :title="!dataForm.id ? '补发' : '补发'"
          :close-on-click-modal="false"
          :visible.sync="visible"
          append-to-body
          width="35%"
          top="20vh"
          @close="close()">
    <el-form :model="dataForm"  size="small" ref="dataForm" @keyup.enter.native="dataFormSubmit()" label-width="80px"
             v-loading="dataLoading" style="width:100%;"  element-loading-text=""  element-loading-spinner="el-icon-loading" class="dialogForm"
    >
      <div>
        <div style="display: flex">
        <p>收货人：{{dataForm.modelName}}</p>
        <p style="margin-left: 20%">联系电话：{{dataForm.modelMobile}}</p>
        </div>
        <p>收货地址：{{dataForm.modelAddress}}</p>
        <div style="display: flex">
        <p>物流单号：</p>
          <el-input v-model="dataForm.modelOrderno"  size="mini" placeholder=""  show-word-limit style="width: 60%"></el-input>
        </div>
       <!-- <div style="display: flex">
          <p>样粉收货人：{{dataForm.materialName}}</p>
          <p style="margin-left: 20%">联系电话：{{dataForm.materialMobile}}</p>
        </div>
        <p>样粉收货地址：{{dataForm.materialAddress}}</p>
        <div style="display: flex">
        <p>样粉物流单号：</p>
        <el-input v-model="dataForm.materialOrderno"  size="mini" placeholder=""  show-word-limit style="width: 60%"></el-input>
        </div>-->
      </div>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="visible = false" size="small">取消</el-button>
      <el-button type="primary" @click="callOff()" size="small"  :disabled="dataLoading">确定</el-button>
    </span>
  </el-dialog>
</template>

<script>
    import {checkOrder,getCheckState,checkRefund,ruturnTake,modelMaterial,insertSampleOrderNo} from '@/api/modules/sampleaftersale'
    export default {
        data () {
            return {
                showBzu:false,
                visible: false,
                dataLoading: false,
                dataForm: {
                    id: 0,
                    modelName: '',//样板收货人
                    materialName: '',//样粉收货人
                    modelMobile:'',//样板联系方式
                    materialMobile:'',//样粉联系方式
                    modelAddress:'',//样板详细地址
                    materialAddress:'',//样粉详细地址
                    modelId:'',//样板物流id
                    materialId:'',//样粉物流id
                    materialOrderno:'',//样粉物流单号
                    modelOrderno:'',//样板物流单号
                    createDate:'',
                    customerReply:'',
                    isNo:'',//是否同意
                    state: ''
                },
            }
        },
        methods: {
            close(){
                // console.log(this.$parent)
                this.$parent.$parent.getDataMessage(this.dataForm.id)
            },
            //取消显示备注信息填写
            //补发
            callOff(){
                let params = {
                    'afterSampleId':this.dataForm.id,
                    'modelId': this.dataForm.modelId,
                    'materialId':this.dataForm.materialId,
                    'materialOrderno':this.dataForm.materialOrderno,
                    'modelOrderno':this.dataForm.modelOrderno,
                }
                insertSampleOrderNo(params).then(({data})=>{
                    if (data && data.code === 0) {
                        this.$message({
                            message: '操作成功',
                            type: 'success',
                            duration: 1500,
                            onClose: () => {
                                this.visible = false;
                                this.dataLoading = false;
                                this.$emit('refreshDataList')
                            }
                        })
                    }else {
                        this.$message.error(data.msg)
                        this.dataLoading = false;
                    }
                })
            },
            init (id) {
                this.dataForm.id = id || 0
                //= ""
                console.log(this.dataForm.state)

                this.visible = true
                this.$nextTick(() => {
                    this.$refs['dataForm'].resetFields()
                    if (this.dataForm.id) {
                        modelMaterial({id:this.dataForm.id}).then(({data}) => {
                            if (data && data.code === 0) {
                                this.dataForm.modelName = data.modelMaterial.modelsu.personalName
                                this.dataForm.modelMobile = data.modelMaterial.modelsu.personalMobile
                                this.dataForm.modelAddress = data.modelMaterial.modelsu.address
                                this.dataForm.modelId = data.modelMaterial.modelsu.logisticsId
                                this.dataForm.materialName = data.modelMaterial.material.personalName
                                this.dataForm.materialMobile = data.modelMaterial.material.personalMobile
                                this.dataForm.materialAddress = data.modelMaterial.material.address
                                this.dataForm.materialId = data.modelMaterial.material.logisticsId
                            }
                        })
                    }
                })
            },
            // 表单提交
            dataFormSubmit () {
                this.$refs['dataForm'].validate((valid) => {
                    if (valid) {
                        this.dataLoading = true;
                        var params = {
                            'aftersaleId': this.dataForm.id || undefined,
                            'description': this.dataForm.description,
                            'state': this.dataForm.state,
                        }
                        var tick = !this.dataForm.id ? checkOrder(params) : checkOrder(params)
                        tick.then(({data}) => {
                            if (data && data.code === 0) {
                                this.$message({
                                    message: '操作成功',
                                    type: 'success',
                                    duration: 1500,
                                    onClose: () => {
                                        this.visible = false;
                                        //    this.showBzu = false;
                                        this.dataLoading = false;
                                        this.$emit('refreshDataList')
                                    }
                                })
                            } else {
                                this.$message.error(data.msg)
                                this.dataLoading = false;
                            }
                        })
                    }
                })
            }
        }
    }
</script>
<style>
  .dialogForm p{
    margin: 10px 0;
    padding: 0;
  }
</style>
