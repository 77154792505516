<template>
  <el-dialog
    class="dyweproDialog"
    title="续费"
    :close-on-click-modal="false"
    :visible.sync="visible"
    width="600px"
    @click="close()"
  >
    <el-form
      :model="dataForm"
      :rules="dataRule"
      size="small"
      ref="dataForm"
      label-width="140px"
      v-loading="dataLoading"
      style="width: 100%"
      element-loading-text=""
      element-loading-spinner="el-icon-loading"
    >
      <el-form-item label="服务有效期" prop="endTime">
        <div>
          <el-date-picker
            v-model="dataForm.endTime"
            value-format="yyyy-MM-dd HH:mm:ss"
            style="width: 250px"
            type="datetime"
            placeholder="有效日期"
          >
          </el-date-picker>
        </div>
      </el-form-item>
      <el-form-item label="续费备注" prop="remarks">
        <div>
          <el-input
            v-model="dataForm.remarks"
            placeholder=""
            style="width: 250px"
            size="small"
            maxlength="50"
          ></el-input>
        </div>
      </el-form-item>
    </el-form>
    <div style="width: 100%; text-align: center; margin-bottom: 30px">
      <el-button
        type="primary"
        :loading="dataLoading"
        @click="dataFormSubmit()"
        size="small"
        >确定续约</el-button
      >
    </div>
    <h4>续费记录</h4>
    <p
      style="width: 100px; border-bottom: 1px solid #cfcfcf; margin-top: -10px"
    ></p>
    <el-table
      :data="dataList"
      size="small"
      :header-cell-style="headClass"
      :cell-style="rowClass"
      style="width: 100%"
    >
      <el-table-column
        prop="endTime"
        header-align="center"
        align="center"
        label="服务有效期"
      >
      </el-table-column>
      <el-table-column
        prop="remarks"
        header-align="center"
        align="center"
        label="续费备注"
      >
      </el-table-column>
      <el-table-column
        prop="createNickName"
        header-align="center"
        align="center"
        label="操作人"
      >
      </el-table-column>
      <el-table-column
        prop="createTime"
        header-align="center"
        align="center"
        label="操作时间"
      >
      </el-table-column>
    </el-table>
    <el-pagination
      size="small"
      @size-change="sizeChangeHandle"
      @current-change="currentChangeHandle"
      :current-page="queryForm.pageNo"
      :page-sizes="[10, 20, 50, 100]"
      :page-size="queryForm.pageSize"
      :total="totalPage"
      layout="total, sizes, prev, pager, next, jumper"
    >
    </el-pagination>
  </el-dialog>
</template>

<script>
import { renewInfoAddApi, selectRenewListInfo } from '@/api/modules/brand'
export default {
  data() {
    return {
      visible: false,
      dataLoading: false,
      dataList: [],
      dataForm: {
        brandId: undefined,
        endTime: '',
        remarks: '',
        pageNo: 1,
        pageSize: 10
      },
      queryForm: {
        brandId: undefined,
        pageNo: 1,
        pageSize: 10
      },
      totalPage: 0,
      dataRule: {
        endTime: [
          { required: true, message: '服务有效期不能为空', trigger: 'blur' }
        ],
        remarks: [
          { required: true, message: '续费备注不能为空', trigger: 'blur' }
        ]
      }
    }
  },
  methods: {
    init(row) {
      this.visible = true
      this.dataForm.brandId = row.brandId
      this.queryForm.brandId = row.brandId
      this.dataForm.endTime = row.endTime
      this.dataForm.remarks = ''
      this.dataList = []
      this.getDataList()
    },
    getDataList() {
      selectRenewListInfo(this.queryForm).then(({ records, totalRecords }) => {
        if (totalRecords > 0) {
          this.dataList = records
          this.totalPage = totalRecords
        } else {
          this.dataList = []
          this.totalPage = 0
        }
      })
    },
    headClass() {
      return 'text-align: center;background:#eef1f6;'
    },
    rowClass({ row, rowIndex }) {
      // 注意，这里返回的是一个对象
      let rowBackground = {}
      if (rowIndex % 2 == 1) {
        rowBackground.background = '#eef1f6'
        return rowBackground
      }
    },
    dataFormSubmit() {
      this.$refs['dataForm'].validate((valid) => {
        if (valid) {
          this.dataLoading = true
          var params = {
            brandId: this.dataForm.brandId,
            remarks: this.dataForm.remarks,
            endTime: this.dataForm.endTime
          }
          renewInfoAddApi(params)
            .then(() => {
              this.$baseMessage('续费成功', 'success')
              this.visible = false
              this.$emit('refreshDataList')
            })
            .finally(() => {
              this.dataLoading = false
            })
        }
      })
    },
    close() {
      this.visible = false
      this.dataForm = {
        id: undefined,
        remarks: '',
        endTime: ''
      }
      this.dataList = []
    },
    // 每页数
    sizeChangeHandle(val) {
      this.queryForm.pageSize = val
      this.queryForm.pageNo = 1
      this.getDataList()
    },
    // 当前页
    currentChangeHandle(val) {
      this.queryForm.pageNo = val
      this.getDataList()
    }
  }
}
</script>
<style lang="scss" scoped>
.dyweproDialog {
  /deep/.el-table {
    border: 0;
    th,
    tr,
    td {
      border: 0;
    }
    &::before {
      height: 0px;
    }
    &::after {
      width: 0;
    }
    .el-table__fixed:before {
      height: 0;
    }
  }
}
</style>
