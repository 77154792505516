<template>
  <div class="app-container">
    <el-form
      :inline="true"
      size="small"
      :model="dataForm"
      ref="dataForm"
      @keyup.enter.native="restPage()"
    >
      <el-form-item prop="deviceNo">
        <el-input
          v-model="dataForm.deviceNo"
          placeholder="设备编号"
          size="small"
          clearable
        ></el-input>
      </el-form-item>
      <el-form-item prop="deviceName">
        <el-input
          v-model="dataForm.deviceName"
          placeholder="设备名称"
          size="small"
          clearable
        ></el-input>
      </el-form-item>
      <el-form-item prop="mobile">
        <el-input
          v-model="dataForm.mobile"
          placeholder="手机号"
          size="small"
          clearable
        ></el-input>
      </el-form-item>
      <el-form-item>
        <el-button @click="restPage()" size="small">查询</el-button>
        <el-button @click="reset('dataForm')" size="small">清空</el-button>
        <el-button
          v-if="isAuth('sys:role:save')"
          type="primary"
          @click="addHandle()"
          >新增</el-button
        >
      </el-form-item>
    </el-form>
    <el-table
      :data="dataList"
      border
      size="small"
      v-loading="dataListLoading"
      @selection-change="selectionChangeHandle"
      style="width: 100%"
    >
      <el-table-column
        type="selection"
        fixed="left"
        header-align="center"
        align="center"
        width="50"
      >
      </el-table-column>
      <el-table-column
        prop="deviceNo"
        header-align="center"
        align="center"
        label="设备编号"
      >
      </el-table-column>
      <el-table-column
        prop="deviceName"
        header-align="center"
        align="center"
        label="设备名称"
      >
      </el-table-column>
      <el-table-column
        prop="picUrl"
        header-align="center"
        align="center"
        label="设备图片"
      >
        <template slot-scope="scope">
          <img :src="scope.row.picUrl" min-width="70" height="70" />
        </template>
      </el-table-column>
      <el-table-column
        prop="deviceModelName"
        header-align="center"
        align="center"
        label="设备型号"
      >
      </el-table-column>
      <el-table-column
        prop="mobile"
        header-align="center"
        align="center"
        label="手机号"
      >
      </el-table-column>
      <el-table-column
        prop="userName"
        header-align="center"
        align="center"
        label="姓名"
      >
      </el-table-column>
      <el-table-column
        prop="wxNickName"
        header-align="center"
        align="center"
        label="昵称"
      >
      </el-table-column>
      <el-table-column
        fixed="right"
        header-align="center"
        align="center"
        width="120"
        label="操作"
      >
        <template slot-scope="scope">
          <el-button
            type="text"
            size="small"
            @click="deleteHandle(scope.row.id)"
            >撤销授权</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      size="small"
      @size-change="sizeChangeHandle"
      @current-change="currentChangeHandle"
      :current-page="pageIndex"
      :page-sizes="[10, 20, 50, 100]"
      :page-size="pageSize"
      :total="totalPage"
      layout="total, sizes, prev, pager, next, jumper"
    >
    </el-pagination>
    <!-- 弹窗, 新增 / 修改 -->
    <add-or-update
      v-if="addOrUpdateVisible"
      ref="addOrUpdate"
      @refreshDataList="getDataList"
    ></add-or-update>
    <!--添加授权-->
    <device-user-add
      v-if="addOrUpdateVisible"
      ref="deviceUserAdd"
      @refreshDataList="getDataList"
    ></device-user-add>
    <!--授权列表-->
    <device-user-list
      v-if="addOrUpdateVisible"
      ref="deviceUserList"
      @refreshDataList="getDataList"
    ></device-user-list>
  </div>
</template>

<script>
import {
  queryMemberDeviceApi,
  deleteMemberDeviceApi,
} from "@/api/modules/memberdevice";
import AddOrUpdate from "./add-or-update";
import DeviceUserAdd from "./device-user-add";
import DeviceUserList from "./device-user-index";
export default {
  data() {
    return {
      dataForm: {
        deviceNo: "",
        deviceName: "",
        daterange: [],
        mobile: "",
      },
      dataList: [],
      pageIndex: 1,
      pageSize: 10,
      totalPage: 0,
      dataListLoading: false,
      dataListSelections: [],
      addOrUpdateVisible: false,
    };
  },
  components: {
    AddOrUpdate,
    DeviceUserAdd,
    DeviceUserList,
  },
  created() {
    this.getDataList();
  },
  methods: {
    //重新查询
    restPage() {
      this.pageIndex = 1;
      this.getDataList();
    },
    reset(dataForm) {
      this.$refs.dataForm.resetFields();
      this.restPage();
    },
    // 获取数据列表
    getDataList() {
      this.dataListLoading = true;
      queryMemberDeviceApi(this.dataForm).then(({ records, totalRecords }) => {
        if (totalRecords > 0) {
          this.dataList = records;
          this.totalPage = totalRecords;
        } else {
          this.dataList = [];
          this.totalPage = 0;
        }
        this.dataListLoading = false;
      });
    },
    // 每页数
    sizeChangeHandle(val) {
      this.pageSize = val;
      this.pageIndex = 1;
      this.getDataList();
    },
    // 当前页
    currentChangeHandle(val) {
      this.pageIndex = val;
      this.getDataList();
    },
    // 多选
    selectionChangeHandle(val) {
      this.dataListSelections = val;
    },
    addHandle() {
      this.addOrUpdateVisible = true;
      this.$nextTick(() => {
        this.$refs.deviceUserAdd.init();
      });
    },
    //设备授权
    deviceUserAddHandle(id) {
      this.addOrUpdateVisible = true;
      this.$nextTick(() => {
        this.$refs.deviceUserAdd.init(id);
      });
    },
    //授权列表
    deviceUserListHandle(id) {
      this.addOrUpdateVisible = true;
      this.$nextTick(() => {
        this.$refs.deviceUserList.init(id);
      });
    },
    // 删除
    deleteHandle(id) {
      this.$confirm(`是否确认撤销授权操作?`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        deleteMemberDeviceApi(id).then(() => {
          this.$baseMessage("撤销授权成功", "success");
          this.getDataList();
        });
      });
    },
  },
};
</script>
