import request from '@/utils/request'

// 获取列表
export function list (params) {
  return request({
    url: '/member/membersearchhistory/list',
    method: 'get',
    params: params
  })
}

// 获取信息
export function info (params) {
  return request({
    url: '/member/membersearchhistory/info',
    method: 'get',
    params: params
  })
}

// 添加
export function add (params) {
  return request({
    url: '/member/membersearchhistory/save',
    method: 'post',
    data: params
  })
}

// 修改
export function update (params) {
  return request({
    url: '/member/membersearchhistory/update',
    method: 'post',
    data: params
  })
}

// 删除
export function del (params) {
  return request({
    url: '/member/membersearchhistory/delete',
    method: 'post',
    data: params
  })
}
// 搜索统计
export function searchStatistics (params) {
  return request({
    url: '/member/membersearchhistory/searchStatistics',
    method: 'get',
    data: params
  })
}

// 搜索统计 折线
export function findSearchCount (params) {
  return request({
    url: '/member/membersearchhistory/findSearchCount',
    method: 'get',
    params: params
  })

}
// 搜索统计 柱状
export function findBarSearch (params) {
  return request({
    url: '/member/membersearchhistory/findBarSearch',
    method: 'get',
    params: params
  })

}
// 搜索统计 雷达
export function findRadarSearch (params) {
  return request({
    url: '/member/membersearchhistory/findRadarSearch',
    method: 'get',
    params: params
  })
}
// 搜索类型统计
export function findSearchTypeRanking (params) {
  return request({
    url: '/member/membersearchhistory/findSearchTypeRanking',
    method: 'get',
    params: params
  })

}
// 搜索类型统计趋势图
export function findSearchTypeRankingTrend (params) {
  return request({
    url: '/member/membersearchhistory/findSearchTypeRankingTrend',
    method: 'get',
    params: params
  })

}
// 搜索条件统计趋势图
export function findSearchConditionTrend (params) {
  return request({
    url: '/member/membersearchhistory/findSearchConditionTrend',
    method: 'get',
    params: params
  })

}


// 搜索统计 获取年份
export function findYearSearch (params) {
  return request({
    url: '/member/membersearchhistory/findYearSearch',
    method: 'get',
    params: params
  })

}

export function exportExcel(form){
  return request({ // 用axios发送post请求
      method: 'post',
      url: '/member/membersearchhistory/exportExcelSearchHistory', // 请求地址
      data: form, // 参数
      responseType: 'blob', // 表明返回服务器返回的数据类型
      headers: {
          'Content-Type': 'application/json'
      }
  })
}