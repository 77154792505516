<template>
  <div class="app-container">
    <el-form :inline="true" :model="dataForm" size="small">
      <el-form-item>
        <el-button v-if="isAuth('sys:menu:add')" type="primary" @click="addOrUpdateHandle()">新增</el-button>
      </el-form-item>
    </el-form>
    <el-table v-loading="dataListLoading" :data="dataList" border style="width: 100%" size="small">
      <table-tree-column prop="resourceName" header-align="center" treeKey="id" width="380"
        label="名称"></table-tree-column>
      <!--      <el-table-column prop="parentName" header-align="center" align="center" width="120" label="上级菜单"></el-table-column>-->
      <el-table-column header-align="center" align="center" label="图标">
        <template slot-scope="scope">
          <svg-icon :icon-class="scope.row.icon || ''"></svg-icon>
        </template>
      </el-table-column>
      <el-table-column prop="type" header-align="center" align="center" label="类型">
        <template slot-scope="scope">
          <el-tag v-if="scope.row.resourceType === 0" size="small">目录</el-tag>
          <el-tag v-else-if="scope.row.resourceType === 1" size="small" type="success">菜单</el-tag>
          <el-tag v-else-if="scope.row.resourceType === 2" size="small" type="info">接口</el-tag>
        </template>
      </el-table-column>
      <el-table-column prop="sortNumber" header-align="center" align="center" label="排序号"></el-table-column>
      <el-table-column prop="url" header-align="center" align="center" width="150" :show-overflow-tooltip="true"
        label="菜单URL"></el-table-column>
      <el-table-column prop="resourceCode" header-align="center" align="center" width="150"
        :show-overflow-tooltip="true" label="授权标识"></el-table-column>
      <el-table-column prop="isEnable" header-align="center" align="center" label="状态" width="100">
        <template slot-scope="scope">
          <el-tag v-if="scope.row.isEnable" type="success">正常</el-tag>
          <el-tag v-else type="danger">禁用</el-tag>
        </template>
      </el-table-column>
      <el-table-column fixed="right" header-align="center" align="center" width="150" label="操作">
        <template slot-scope="scope">
          <el-button v-if="isAuth('sys:menu:edit')" type="text" size="small"
            @click="addOrUpdateHandle(scope.row.id)">修改</el-button>
          <el-button v-if="isAuth('sys:menu:del')" type="text" size="small"
            @click="deleteHandle(scope.row.id)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <!-- 弹窗, 新增 / 修改 -->
    <add-or-update v-if="addOrUpdateVisible" ref="addOrUpdate" @refreshDataList="getDataList"></add-or-update>
  </div>
</template>

<script>
import TableTreeColumn from "@/views/sys/menu/components/table-tree-column";
import AddOrUpdate from "./components/menu-add-or-update";
import { treeDataTranslate } from "@/utils";
import { menuList, menuDelete } from "@/api/sys";

export default {
  data() {
    return {
      dataForm: {},
      dataList: [],
      dataListLoading: false,
      addOrUpdateVisible: false,
    };
  },
  components: {
    TableTreeColumn,
    AddOrUpdate,
  },
  methods: {
    // 获取数据列表
    async getDataList() {
      this.dataListLoading = true;
      const { records } = await menuList({ pageSize: 100000 });
      this.dataList = treeDataTranslate(records);
      this.dataListLoading = false;
    },
    // 新增 / 修改
    addOrUpdateHandle(id) {
      this.addOrUpdateVisible = true;
      this.$nextTick(() => {
        this.$refs.addOrUpdate.init(id);
      });
    },
    // 删除
    deleteHandle(id) {
      this.$confirm(`确定进行删除操作?`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(async () => {
        await menuDelete(id);
        this.$baseMessage("删除成功", "success");
        this.getDataList();
      });
    },
  },
  mounted() {
    this.getDataList();
  },
};
</script>
