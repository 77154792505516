<template>
    <div class="app-container">
        <el-form :inline="true" size="small" :model="dataForm" ref="dataForm" @keyup.enter.native="restPage()">
            <el-form-item prop="productName">
                产品名称
                <el-input v-model="dataForm.productName" placeholder="产品名称" size="small" clearable
                          style="width: 60%"></el-input>
            </el-form-item>
            <el-form-item prop="productNo">
                产品编号
                <el-input v-model="dataForm.productNo" placeholder="产品编号" size="small" clearable
                          style="width: 60%"></el-input>
            </el-form-item>
            <el-form-item label="LAB值">
                <el-input v-model="dataForm.lAve" placeholder="L值" class="w100 mr10" size="small" clearable></el-input>
                <el-input v-model="dataForm.aAve" placeholder="A值" class="w100 mr10" size="small" clearable></el-input>
                <el-input v-model="dataForm.bAve" placeholder="B值" class="w100 mr10" size="small" clearable></el-input>
            </el-form-item>
            <!--其他性能选择标签-->
<!--            <el-form-item prop="otherPerformance">-->
<!--                其他性能-->
<!--                <el-select v-model="dataForm.otherPerformance" size="small" placeholder="请选择状态"-->
<!--                           style="width: 60%">-->
<!--                    <el-option-->
<!--                            v-for="item in otherPerformanceList"-->
<!--                            :key="item.id"-->
<!--                            :label="item.name"-->
<!--                            :value="item.id"-->
<!--                            size="small"-->
<!--                    ></el-option>-->
<!--                </el-select>-->
<!--            </el-form-item>-->
<!--            &lt;!&ndash;产品表面选择标签&ndash;&gt;-->
<!--            <el-form-item prop="surface">-->
<!--                产品表面-->
<!--                <el-select v-model="dataForm.surface" size="small" placeholder="请选择状态" style="width: 60%">-->
<!--                    <el-option-->
<!--                            v-for="item in productSurfaceList"-->
<!--                            :key="item.id"-->
<!--                            :label="item.name"-->
<!--                            :value="item.id"-->
<!--                            size="small"-->
<!--                    ></el-option>-->
<!--                </el-select>-->
<!--            </el-form-item>-->
<!--            &lt;!&ndash;产品光泽选择标签&ndash;&gt;-->
<!--            <el-form-item prop="gloss">-->
<!--                产品光泽-->
<!--                <el-select v-model="dataForm.gloss" size="small" placeholder="请选择状态" style="width: 60%">-->
<!--                    <el-option-->
<!--                            v-for="item in productGlossList"-->
<!--                            :key="item.id"-->
<!--                            :label="item.name"-->
<!--                            :value="item.id"-->
<!--                            size="small"-->
<!--                    ></el-option>-->
<!--                </el-select>-->
<!--            </el-form-item>-->

            <el-form-item>
                <el-button @click="restPage()" size="small" type="primary">筛选</el-button>
                <el-button @click="reset('dataForm')" size="small" type="primary">清空</el-button>
                <el-button type="success" @click="uploadHandle()" >库存导入</el-button>
                <!--<el-button v-if="isAuth('product:product:save')" type="primary" @click="addOrUpdateHandle()"
                           size="small">添加产品
                </el-button>
                <el-button  type="primary" @click="exportExcelHandle()"
                           size="small">导出EXCEL模板
                </el-button>
                <el-button v-if="isAuth('product:product:delete')" type="danger" @click="deleteHandle()"
                           :disabled="dataListSelections.length <= 0" size="small">批量删除
                </el-button>-->
            </el-form-item>
        </el-form>
        <el-table
                :data="dataList"
                border
                size="small"
                v-loading="dataListLoading"
                @selection-change="selectionChangeHandle"
                style="width: 100%;">
            <el-table-column
                    type="selection"
                    fixed="left"
                    header-align="center"
                    align="center"
                    width="50">
            </el-table-column>
            <el-table-column
                    prop="productNo"
                    header-align="center"
                    align="center"
                    label="产品编号">
            </el-table-column>
            <el-table-column
                    prop="qrUrl"
                    header-align="center"
                    align="center"
                    label="产品二维码">
                <!-- 图片的显示 -->
                <template slot-scope="scope">
                    <img :src="scope.row.qrUrl" min-width="70" height="70"/>
                </template>
            </el-table-column>

            <el-table-column
                    prop="productName"
                    header-align="center"
                    align="center"
                    label="产品名称">
            </el-table-column>
            <el-table-column
                    prop="picUrl"
                    header-align="center"
                    align="center"
                    label="产品样图">
                <!-- 图片的显示 -->
                <template slot-scope="scope">
                    <img :src="scope.row.picUrl" min-width="70" height="70"/>
                </template>
            </el-table-column>
            <el-table-column
                    prop="labAve"
                    header-align="center"
                    align="center"
                    label="LAB值"
                    width="260">
                <template slot-scope="scope">
                    <span>25°L:{{scope.row.l1|numFilter}}</span>
                   <span>|A:{{scope.row.a1|numFilter}}|</span>
                    <span>B:{{scope.row.b1|numFilter}}</span><p></p>
                    <span>45°L:{{scope.row.l2|numFilter}}</span>
                    <span>|A:{{scope.row.a2|numFilter}}|</span>
                    <span>B:{{scope.row.b2|numFilter}}</span><p></p>
                    <span>75°L:{{scope.row.l3|numFilter}}</span>
                    <span>|A:{{scope.row.a3|numFilter}}|</span>
                    <span>B:{{scope.row.b3|numFilter}}</span>
                </template>
            </el-table-column>
            <el-table-column
                    prop="lAve"
                    header-align="center"
                    align="center"
                    label="45度LAB平均值"
                    min-width="100">
                <template slot-scope="scope">
                    <span>L：{{scope.row.lAve|numFilter}}</span>
                    <p><span>A：{{scope.row.aAve|numFilter}}</span></p>
                    <p><span>B：{{scope.row.bAve|numFilter}}</span></p>
                </template>
            </el-table-column>
<!--            <el-table-column-->
<!--                    prop="otherPerformance"-->
<!--                    header-align="center"-->
<!--                    align="center"-->
<!--                    label="其他性能">-->
<!--            </el-table-column>-->
<!--            <el-table-column-->
<!--                    prop="gloss"-->
<!--                    header-align="center"-->
<!--                    align="center"-->
<!--                    label="产品光泽">-->
<!--            </el-table-column>-->
<!--            <el-table-column-->
<!--                    prop="surface"-->
<!--                    header-align="center"-->
<!--                    align="center"-->
<!--                    label="产品表面">-->
<!--            </el-table-column>-->

            <el-table-column
                    prop="productNum"
                    header-align="center"
                    align="center"
                    label="库存">
            </el-table-column>

            <el-table-column
                    fixed="right"
                    header-align="center"
                    align="center"
                    width="150"
                    label="操作">
                <template slot-scope="scope">
                    <el-button type="text" size="small" @click="addOrUpdateHandle(scope.row.id)">详情</el-button>
                    <el-button type="text" size="small" @click="changeProductNumHandle(scope.row.id,scope.row.productNum)">修改库存</el-button>
                    <!--点击配方，跳转到展示产品配方的页面，显示当前产品的配方，应该不可以做修改，只是展示 ，传递过去的值应该是产品ID,根据产品ID查询配方，调涂春明的接口和页面-->
                   <!-- <el-button type="text" size="small" @click="deleteHandle(scope.row.id)">删除</el-button>-->
                </template>
            </el-table-column>
        </el-table>
        <el-pagination
                size="small"
                @size-change="sizeChangeHandle"
                @current-change="currentChangeHandle"
                :current-page="pageIndex"
                :page-sizes="[10, 20, 50, 100]"
                :page-size="pageSize"
                :total="totalPage"
                layout="total, sizes, prev, pager, next, jumper">
        </el-pagination>
        <!-- 弹窗, 新增 / 修改 -->
        <add-or-update v-if="addOrUpdateVisible" ref="addOrUpdate" @refreshDataList="getDataList"></add-or-update>
        <upload v-if="uploadVisible" ref="upload" @refreshDataList="getDataList"></upload>
        <ChangeProductNum v-if="changVisible" ref="changeProductNum" @refreshDataList="getDataList"></ChangeProductNum>
    </div>
</template>

<script>
    import {list, del, getDict} from '@/api/modules/product'
    import AddOrUpdate from './add-or-update'
    import {getToken} from "@/utils/auth";
    import Upload from "./oss-upload";
    import ChangeProductNum from "./chang-product-num";
    import {getProductAttrValue} from '@/api/modules/productAttributeValue'

    export default {
        data() {
            return {
                changVisible:false,
                uploadVisible : false,
                dataForm: {
                    productName: '',
                    id: '',
                    productNo: '',
                    picUrl: '',
                    qrUrl: '',
                    ralColorNo: '',
                    gloss: '',
                    glossValue: '',
                    surface: '',
                    colorSeries: '',
                    productPerformance: '',
                    otherPerformance: '',
                    productCategory: '',
                    productHierarchy: '',
                    industryId: '',
                    factoryId: '',
                    brandId: '',
                    description: '',
                    labAve:'',
                    l1: '',
                    a1: '',
                    b1: '',
                    l2: '',
                    a2: '',
                    b2: '',
                    l3: '',
                    a3: '',
                    b3: '',
                    lAve: '',
                    aAve: '',
                    bAve: '',
                    isOpen: ''

                },
                dataList: [],
                performanceList: [],
                productSurfaceList: [],
                productGlossList: [],
                otherPerformanceList: [],
                pageIndex: 1,
                pageSize: 10,
                totalPage: 0,
                dataListLoading: false,
                dataListSelections: [],
                addOrUpdateVisible: false
            }
        },
        components: {
            AddOrUpdate,
            Upload,
            ChangeProductNum
        },
        created() {
            this.getDataList();
            /*this.getProductSurfaceList();
            this.getDictList();
            this.getProductGlossList();*/
            this.getProductAttrValue();
        },
        //写个过滤器，保留小数点后两位
        filters: {
            numFilter (value) {
                let realVal = ''
                if (!isNaN(value) && value!== '') {
                    // 截取当前数据到小数点后两位
                    realVal = parseFloat(value).toFixed(2)
                } else {
                    realVal = '--'
                }
                return realVal
            }
        },
        methods: {
            getProductAttrValue(){
                let params = {};
                getProductAttrValue(params).then(({data}) => {
                    if (data && data.code === 0) {
                        for(var i=0;i<data.list.length;i++){
                            if(data.list[i].productAttrType==='1'){//表面
                                this.productSurfaceList=data.list[i].productAttrValue;
                            }else if(data.list[i].productAttrType==='3'){//系列
                                this.productSeriesList=data.list[i].productAttrValue;
                            } else if(data.list[i].productAttrType==='6'){//主题
                                this.productHierarchyList=data.list[i].productAttrValue;
                            } else if(data.list[i].productAttrType==='2'){//色系
                                this.colorSeriesList=data.list[i].productAttrValue;
                            } else if(data.list[i].productAttrType==='7'){//性能
                                this.performanceList=data.list[i].productAttrValue;
                            } else if(data.list[i].productAttrType==='4'){//应用
                                this.industryList=data.list[i].productAttrValue;
                            } else if(data.list[i].productAttrType==='8'){//功能
                                this.otherPerformanceList=data.list[i].productAttrValue;
                            } else if(data.list[i].productAttrType==='5'){//分类
                                this.productCategoryList=data.list[i].productAttrValue;
                            } else if(data.list[i].productAttrType==='9'){//光泽
                                this.productGlossList=data.list[i].productAttrValue;
                            }

                        }
                    } else {

                    }
                })
            },
            changeProductNumHandle(id,productNum){
                this.changVisible = true;
                this.$nextTick(() => {
                    this.$refs.changeProductNum.init(id,productNum);
                });
            },

            uploadHandle(){
                this.uploadVisible = true;
                this.$nextTick(() => {
                    this.$refs.upload.init();
                });
            },
            //重新查询
            restPage() {
                this.pageIndex = 1;
                this.getDataList();
                /*this.getProductSurfaceList();
                this.getDictList();
                this.getProductGlossList();*/
                this.getProductAttrValue();
            },
            reset(dataForm) {
                this.$refs.dataForm.resetFields();
                this.dataForm.productPerformance = '';
                this.dataForm.surface = '';
                this.dataForm.gloss = '';
                this.dataForm.lAve = '';
                this.dataForm.aAve = '';
                this.dataForm.bAve = '';
                this.restPage();
            },
            // 获取数据列表
            getDataList() {
                this.dataListLoading = true
                var params = {
                    lAve: this.dataForm.lAve,
                    aAve: this.dataForm.aAve,
                    bAve: this.dataForm.bAve,
                    page: this.pageIndex,
                    limit: this.pageSize,
                    productName: this.dataForm.productName,
                    productNo:this.dataForm.productNo,
                    otherPerformance: this.dataForm.otherPerformance,
                    surface: this.dataForm.surface,
                    gloss: this.dataForm.gloss,
                }
                list(params).then(({data}) => {
                    if (data && data.code === 0) {
                        this.dataList = data.page.list
                        this.totalPage = data.page.totalCount
                    } else {
                        this.dataList = []
                        this.totalPage = 0
                    }
                    this.dataListLoading = false
                })
            },
            //获取其他性能
            getDictList() {
                let params = {
                    type: '1'
                }
                getDict(params).then(({data}) => {
                    if (data && data.code === 0) {
                        this.otherPerformanceList = data.list
                    } else {
                        this.otherPerformanceList = []
                    }
                })
            },

            //获取产品表面选项
            getProductSurfaceList() {
                let params = {
                    type:'2'
                }
                getDict(params).then(({data}) => {
                    if (data && data.code === 0) {
                        this.productSurfaceList = data.list
                    } else {
                        this.productSurfaceList = []
                    }
                })
            },

            //获取产品光泽选项
            getProductGlossList() {
                let params = {
                    type:'3'
                }
                getDict(params).then(({data}) => {
                    if (data && data.code === 0) {
                        this.productGlossList = data.list
                    } else {
                        this.productGlossList = []
                    }
                })
            },
            //导出excel表格模板
            exportExcelHandle(){
                var params = {}
                var url = `${process.env.VUE_APP_BASE_API}/product/product/exportExcel`+`?token=${getToken()}`
                // 模拟创建元素实现
                var alink = document.createElement('a');
                alink.href = url;
                alink.style = 'display:none';
                alink.click();
            },

            // 每页数
            sizeChangeHandle(val) {
                this.pageSize = val
                this.pageIndex = 1
                this.getDataList()
            },
            // 当前页
            currentChangeHandle(val) {
                this.pageIndex = val
                this.getDataList()
            },
            // 多选
            selectionChangeHandle(val) {
                this.dataListSelections = val
            },
            // 新增 / 修改
            addOrUpdateHandle(id) {
                this.addOrUpdateVisible = true
                this.$nextTick(() => {
                    this.$refs.addOrUpdate.init(id)
                })
            },
            //配方详情
            addRecipeHandle(id) {
                this.addRecipeVisible = true
                this.$nextTick(() => {
                    this.$refs.addRecipe.init(id)
                })
            },
            // 删除
            deleteHandle(id) {
                var ids = id ? [id] : this.dataListSelections.map(item => {
                    return item.id
                })
                this.$confirm(`确定对[id=${ids.join(',')}]进行[${id ? '删除' : '批量删除'}]操作?`, '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    del(ids).then(({data}) => {
                        if (data && data.code === 0) {
                            this.$message({
                                message: '操作成功',
                                type: 'success',
                                duration: 1500,
                                onClose: () => {
                                    this.getDataList()
                                }
                            })
                        } else {
                            this.$message.error(data.msg)
                        }
                    })
                })
            }
        }
    }
</script>
<style lang="scss" scoped>
.w100{
    width: 100px;
}
.mr10 {
    margin-right: 10px;
}
</style>
