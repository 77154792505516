import request from '@/utils/request'

const url = '/m/memberDevice';

// 获取列表
export function queryMemberDeviceApi (data) {
  return request({
    url: `${url}/query`,
    method: 'post',
    data
  })
}

// // 获取信息
// export function info (params) {
//   return request({
//     url: '/member/memberdevice/info',
//     method: 'get',
//     params: params
//   })
// }

// 添加
export function addMemberDeviceApi (data) {
  return request({
    url: `${url}/add`,
    method: 'put',
    data
  })
}

// 删除
export function deleteMemberDeviceApi (id) {
  return request({
    url: `${url}/delete/${id}`,
    method: 'delete'
  })
}

// // 设备授权设备信息获取
// export function deviceInfo (id) {
//   return request({
//     url: '/member/memberdevice/deviceInfo',
//     method: 'get',
//     params: id
//   })
// }

