<template>
  <el-dialog
    :title="!dataForm.id ? '新增' : '修改'"
    width="500px"
    :close-on-click-modal="false"
    :visible.sync="visible"
  >
    <el-form
      :model="dataForm"
      :rules="dataRule"
      ref="dataForm"
      @keyup.enter.native="dataFormSubmit()"
      label-width="80px"
      size="small"
    >
      <el-form-item label="角色名称" prop="roleName">
        <el-input
          v-model="dataForm.roleName"
          placeholder="角色名称"
          maxlength="30"
        ></el-input>
      </el-form-item>
      <el-form-item label="备注" prop="remarks">
        <el-input
          v-model="dataForm.remarks"
          type="textarea"
          :rows="2"
          placeholder="备注"
          maxlength="150"
        ></el-input>
      </el-form-item>
      <el-form-item size="mini" label="授权">
        <el-tree
          :data="menuList"
          :props="menuListTreeProps"
          node-key="id"
          ref="menuListTree"
          :default-expand-all="true"
          check-strictly
          show-checkbox
        >
        </el-tree>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="visible = false" size="small">取消</el-button>
      <el-button
        type="primary"
        :loading="loading"
        @click="dataFormSubmit()"
        size="small"
        >确定</el-button
      >
    </span>
  </el-dialog>
</template>

<script>
import { treeDataTranslate } from "@/utils";
import { menuList, roleDataFormSubmit, roleInfo } from "@/api/sys";
export default {
  data() {
    return {
      loading: false,
      visible: false,
      menuList: [],
      menuListTreeProps: {
        label: "resourceName",
        children: "children",
      },
      dataForm: {
        id: undefined,
        roleName: "",
        remarks: "",
      },
      dataRule: {
        roleName: [
          { required: true, message: "角色名称不能为空", trigger: "blur" },
        ],
      },
    };
  },
  methods: {
    async init(id) {
      this.dataForm.id = id || undefined;
      const { records } = await menuList({ pageSize: 100000 });
      this.menuList = treeDataTranslate(records);
      this.visible = true;
      this.$nextTick(() => {
        this.$refs.dataForm.resetFields();
        this.$refs.menuListTree.setCheckedKeys([]);
        if (id) {
          roleInfo(id).then((response) => {
            this.dataForm.roleName = response.roleName;
            this.dataForm.remarks = response.remarks;
            this.$refs.menuListTree.setCheckedKeys(response.resourceIdList);
          });
        }
      });
    },
    // 表单提交
    dataFormSubmit() {
      this.$refs["dataForm"].validate(async (valid) => {
        if (valid) {
          this.loading = true;
          this.dataForm.resourceIdList = [].concat(
            this.$refs.menuListTree.getCheckedKeys(),
            this.$refs.menuListTree.getHalfCheckedKeys()
          );
          await roleDataFormSubmit(this.dataForm);
          this.$baseMessage("操作成功", "success");
          this.loading = false;
          this.visible = false;
          this.$emit("refreshDataList");
        }
      });
    },
  },
};
</script>
