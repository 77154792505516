<template>
    <el-dialog
            title="库存修改"
            :close-on-click-modal="false"
            @close="closeHandle"
            :visible.sync="visible">

        <el-form :model="dataForm"   size="small" ref="dataForm"  label-width="120px"
                 v-loading="dataLoading" style="width:100%;"  element-loading-text=""  element-loading-spinner="el-icon-loading"
        >
            <el-form-item label="当前库存" prop="nowNum">
                <el-input v-model="dataForm.nowNum" placeholder="当前库存" disabled=""  size="small" maxlength="30"><template slot="append">kg</template></el-input>
            </el-form-item>
            <el-form-item label="修改库存" prop="changeNum">
                <el-input v-model="dataForm.changeNum" placeholder="修改库存"  size="small" maxlength="30"><template slot="append">kg</template></el-input>
            </el-form-item>

        </el-form>
        <span slot="footer" class="dialog-footer">
      <el-button @click="visible = false" size="small">取消</el-button>
      <el-button type="primary" @click="dataFormSubmit()" size="small"  :disabled="dataLoading">确定</el-button>
    </span>
    </el-dialog>
</template>

<script>
    import {getToken} from "@/utils/auth";
    import {updateNum} from '@/api/modules/product'

    export default {
        data() {
            return {
                productId:'',
                dataLoading: false,
                visible:false,
                dataForm:{
                    nowNum:'',
                    changeNum:'',
                },
            };
        },
        methods: {
            init(id,productNum) {
                this.visible = true;
                this.productId=id;
                this.dataForm.nowNum=productNum;
            },

            // 表单提交
            dataFormSubmit () {
                this.$refs['dataForm'].validate((valid) => {
                    if (valid) {
                        this.dataLoading = true;
                        var params = {
                            'id': this.productId || undefined,
                            productNum:this.dataForm.changeNum
                        };
                        var tick = !this.productId ? updateNum(params) : updateNum(params)
                        tick.then(({data}) => {
                            if (data && data.code === 0) {
                                this.$message({
                                    message: '操作成功',
                                    type: 'success',
                                    duration: 1500,
                                    onClose: () => {
                                        this.visible = false;
                                        this.dataLoading = false;
                                        this.$emit('refreshDataList')
                                    }
                                })
                            } else {
                                this.$message.error(data.msg);
                                this.dataLoading = false;
                            }
                        })

                    }
                })
            },
            // 弹窗关闭时
            closeHandle() {
                this.productId="";
                this.dataForm.nowNum = "";
                this.dataForm.changeNum = "";
                this.$emit("refreshDataList");
            },
        }
    };
</script>
