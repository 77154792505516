<template>
  <!--<el-dialog
    :title="!dataForm.id ? '新增' : '修改'"
    :close-on-click-modal="false"
    :visible.sync="visible">-->
  <sides-lip ref="SidesLip" @click="close()">
    <div slot="soltForm">
      <el-form
        :model="dataForm"
        :rules="dataRule"
        size="small"
        ref="dataForm"
        label-width="120px"
        v-loading="dataLoading"
        style="width: 100%"
        element-loading-text=""
        element-loading-spinner="el-icon-loading"
      >
        <el-form-item label="所属品牌" prop="brandId">
          <el-select
            v-model="dataForm.brandId"
            clearable
            placeholder="请选择"
            :disabled="!!dataForm.id"
          >
            <el-option
              v-for="item in brandList"
              :key="item.brandId"
              :label="item.brandName"
              :value="item.brandId"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="工厂名称" prop="factoryName">
          <el-input
            v-model="dataForm.factoryName"
            placeholder="工厂名称"
            size="small"
            maxlength="30"
          ></el-input>
        </el-form-item>
        <el-form-item label="英文缩写" prop="factoryEname">
          <el-input
            v-model="dataForm.factoryEname"
            placeholder="英文缩写"
            size="small"
            maxlength="30"
          ></el-input>
        </el-form-item>
        <el-form-item label="工厂LOGO" prop="factoryLogo">
          <el-upload
            class="avatar-uploader"
            :action="action"
            :headers="headers"
            :show-file-list="false"
            :on-success="handleAvatarSuccess"
            :before-upload="beforeAvatarUpload"
          >
            <img
              v-if="dataForm.factoryLogo"
              :src="dataForm.factoryLogo"
              class="avatar"
            />
            <i
              v-else
              class="el-icon-plus avatar-uploader-icon"
              title="点击上传"
            ></i>
          </el-upload>
        </el-form-item>
        <el-form-item label="工厂状态" prop="state">
          <template>
            <el-radio v-model="dataForm.state" :label="0">正常</el-radio>
            <el-radio v-model="dataForm.state" :label="1">冻结</el-radio>
          </template>
        </el-form-item>
        <area-map :msgVal="sendMsg" ref="areaMapMsg" v-if="sendMsg"></area-map>
        <el-form-item label="管理员姓名" prop="managerName">
          <el-input
            v-model="dataForm.managerName"
            placeholder="管理员姓名"
            size="small"
            maxlength="50"
          ></el-input>
        </el-form-item>
        <el-form-item label="管理员邮箱" prop="managerEmail">
          <el-input
            v-model="dataForm.managerEmail"
            placeholder="管理员邮箱"
            size="small"
            maxlength="50"
          ></el-input>
        </el-form-item>
        <el-form-item label="管理员手机号" prop="managerMobile">
          <el-input
            v-model="dataForm.managerMobile"
            placeholder="管理员手机号"
            size="small"
            maxlength="50"
          ></el-input>
        </el-form-item>
        <el-form-item label="工厂信息" prop="remarks">
          <el-input
            type="textarea"
            :rows="5"
            placeholder="工厂信息"
            maxlength="150"
            show-word-limit
            v-model="dataForm.remarks"
          >
          </el-input>
        </el-form-item>
      </el-form>
    </div>
    <div slot="soltFooter">
      <span slot="footer" class="dialog-footer">
        <el-button @click="sideClose()" size="small">返回</el-button>
        <el-button
          type="primary"
          @click="dataFormSubmit()"
          size="small"
          :disabled="dataLoading"
          >确定</el-button
        >
      </span>
    </div>
  </sides-lip>
  <!--  </el-dialog>-->
</template>

<script>
import { getFactoryApi, addOrUpdateFactoryApi } from "@/api/modules/factory";
import { queryBrandApi } from "@/api/modules/brand";
import { getToken } from "@/utils/auth";
import Tinymce from "@/components/Tinymce";
import AreaMap from "./area-map";
import SidesLip from "@/components/SidesLip";
export default {
  components: {
    AreaMap,
    Tinymce,
    SidesLip,
  },
  data() {
    return {
      action: `/api/m/file/upload`,
      headers: { Authorization: getToken() },
      visible: false,
      dataLoading: false,
      sendMsg: null,
      brandList: [],
      dataForm: {
        id: "",
        factoryName: "",
        factoryEname: "",
        factoryLogo: "",
        lng: "",
        lat: "",
        laboratory: "",
        leadMan: "",
        remarks: "",
        brandId: "",
        managerName: "",
        managerMobile: "",
        managerEmail: "",
        state: 0,
      },
      dataRule: {
        factoryName: [
          { required: true, message: "工厂名称不能为空", trigger: "blur" },
        ],
        factoryEname: [
          { required: true, message: "英文缩写不能为空", trigger: "blur" },
        ],
        factoryLogo: [
          { required: true, message: "工厂LOGO不能为空", trigger: "blur" },
        ],
        brandId: [
          { required: true, message: "品牌id不能为空", trigger: "blur" },
        ],
        state: [{ required: true, message: "工厂状态要选择", trigger: "blur" }],
      },
    };
  },
  methods: {
    init(id) {
      this.$refs.SidesLip.init(id ? "修改" : "新增", "700px", "block");
      this.getBrandList();
      this.dataForm.id = id || undefined;
      this.visible = true;
      this.sendMsg = null;
      this.$nextTick(() => {
        this.$refs["dataForm"].resetFields();
        if (id) {
          getFactoryApi(id).then((response) => {
            this.sendMsg = response;
            if (!this.sendMsg.longitude) {
              this.sendMsg = {};
            }
            this.dataForm = response;
            this.sendMsg.address = response.address;
            this.sendMsg.lng = response.lng;
            this.sendMsg.lat = response.lat;
          });
        } else {
          this.sendMsg = {};
        }
      });
    },
    // 表单提交
    dataFormSubmit() {
      this.$refs["dataForm"].validate(async (valid) => {
        if (valid) {
          this.dataLoading = true;
          this.dataForm.address = this.$refs.areaMapMsg.address;
          this.dataForm.lng = this.$refs.areaMapMsg.lng;
          this.dataForm.lat = this.$refs.areaMapMsg.lat;
          await addOrUpdateFactoryApi(this.dataForm)
            .then(() => {
              this.$baseMessage("操作成功", "success");
              this.$emit("refreshDataList");
              this.visible = false;
              this.$refs.SidesLip.sideClose();
            })
            .finally(() => {
              this.dataLoading = false;
            });
        }
      });
    },
    //图片上传
    handleAvatarSuccess(res, file, type) {
      if (res.data) {
        this.dataForm.factoryLogo = res.data;
      }
    },
    beforeAvatarUpload(file) {
      const isJPG =
        file.type === "image/jpeg" ||
        file.type === "image/bmp" ||
        file.type === "image/png";
      const isLt2M = file.size / 1024 / 1024 < 8;
      if (!isJPG) {
        this.$message.error("上传图片格式不支持!");
      }
      if (!isLt2M) {
        this.$message.error("上传图片大小不能超过 8MB!");
      }
      return isJPG && isLt2M;
    },
    //获取品牌下拉
    getBrandList() {
      queryBrandApi({ pageSize: 100000 }).then(({ records, totalRecords }) => {
        if (totalRecords > 0) {
          this.brandList = records;
        } else {
          this.brandList = [];
        }
      });
    },
    sideClose() {
      this.$refs.SidesLip.sideClose();
    },
    //---------------------------------地区插件&地图插件---------------------------
  },
};
</script>
<style lang="scss">
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
.class_map_lib {
  font-weight: 800;
}
.sides-space {
  h2 {
    margin: 20px 0;
    font-weight: 800;
  }
}
</style>
