import request from '@/utils/request'

// 获取样板列表
export function list (params) {
  return request({
    url: '/sample/samplepricerule/list',
    method: 'get',
    params: params
  })
}
// 获取样粉列表
export function powderList (params) {
    return request({
        url: '/sample/samplepricerule/powderList',
        method: 'get',
        params: params
    })
}

// 获取样板信息
export function info (params) {
  return request({
    url: '/sample/samplepricerule/sampleDetail',
    method: 'get',
    params: params
  })
}
// 获取样粉信息
export function powderDetail (params) {
    return request({
        url: '/sample/samplepricerule/powderDetail',
        method: 'get',
        params: params
    })
}

// 添加样板
export function add (params) {
  return request({
    url: '/sample/samplepricerule/saveSample',
    method: 'post',
    data: params
  })
}

// 添加样粉
export function savePowder (params) {
    return request({
        url: '/sample/samplepricerule/savePowder',
        method: 'post',
        data: params
    })
}
// 修改样板
export function update (params) {
  return request({
    url: '/sample/samplepricerule/updateSample',
    method: 'post',
    data: params
  })
}
// 修改样板
export function updatePowder (params) {
    return request({
        url: '/sample/samplepricerule/updatePowder',
        method: 'post',
        data: params
    })
}

// 删除样板
export function del (params) {
  return request({
    url: '/sample/samplepricerule/delete',
    method: 'post',
    data: params
  })
}

// 打样规则条件下拉
export function getCategory (params) {
    return request({
        url: '/sample/samplepricerule/getCategory',
        method: 'get',
        params: params
    })
}
// 打样规则条件下拉
export function getIsValuationCondition (params) {
    return request({
        url: '/sample/samplepricerule/getIsValuationCondition',
        method: 'get',
        params: params
    })
}
