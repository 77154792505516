<template>
  <div class="app-container">
    <el-form
      :inline="true"
      size="small"
      :model="dataForm"
      ref="dataForm"
      @keyup.enter.native="restPage()"
    >
      <el-form-item prop="colorNo">
        <el-input
          v-model="dataForm.colorNo"
          placeholder="请输入色号"
          size="small"
          clearable
        ></el-input>
      </el-form-item>
      <el-form-item prop="colorName">
        <el-input
          v-model="dataForm.colorName"
          placeholder="请输入色号名称"
          size="small"
          clearable
        ></el-input>
      </el-form-item>
      <el-form-item prop="colorBookBrandId">
        <el-select
          v-model="dataForm.colorBookBrandId"
          size="small"
          placeholder="请选择品牌"
          clearable
          @change="getColorBookCategory(false)"
        >
          <el-option
            v-for="item in brandList"
            :key="item.id"
            :label="item.brandName"
            :value="item.id"
            size="small"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item prop="colorBookCategoryId">
        <el-select
          v-model="dataForm.colorBookCategoryId"
          size="small"
          placeholder="请选择色号分类"
          clearable
        >
          <el-option
            v-for="item in colorBookCategoryList"
            :key="item.id"
            :label="item.categoryName"
            :value="item.id"
            size="small"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button @click="restPage()" size="small">查询</el-button>
        <el-button @click="reset('dataForm')" size="small">清空</el-button>
        <el-button type="primary" @click="addOrUpdateHandle()" size="small"
          >新增</el-button
        >
        <el-button type="primary" @click="exportExcelColorBook()" size="small"
          >导出数据
        </el-button>
        <el-button type="primary" @click="exportExcel()" size="small"
          >导出EXCEL模板
        </el-button>
        <el-button
          type="success"
          @click="uploadHandle()"
          :disabled="dataList.length < 0"
          >导入</el-button
        >
      </el-form-item>
    </el-form>
    <el-table
      :data="dataList"
      border
      size="small"
      v-loading="dataListLoading"
      style="width: 100%"
    >
      <!--<el-table-column
        prop="id"
        header-align="center"
        align="center"
        label="id">
      </el-table-column>-->
      <el-table-column
        prop="colorNo"
        header-align="center"
        align="center"
        label="色号"
      >
      </el-table-column>
      <el-table-column
        prop="colorName"
        header-align="center"
        align="center"
        label="色号名称"
      >
      </el-table-column>
      <el-table-column
        prop="picUrl"
        header-align="center"
        align="center"
        label="产品样图"
      >
        <!-- 图片的显示 -->
        <template slot-scope="scope">
          <img :src="scope.row.picUrl" min-width="70" height="70" />
        </template>
      </el-table-column>
      <el-table-column
        prop="brandName"
        header-align="center"
        align="center"
        label="色号品牌"
      >
      </el-table-column>
      <el-table-column
        prop="categoryName"
        header-align="center"
        align="center"
        label="色号分类"
      >
      </el-table-column>
      <el-table-column
        prop="l"
        header-align="center"
        align="center"
        label="LAB值"
        width="120"
      >
        <template slot-scope="scope">
          <span>L：{{ scope.row.l | numFilter }}</span>
          <p></p>
          <span>A：{{ scope.row.a | numFilter }}</span>
          <p></p>
          <span>B：{{ scope.row.b | numFilter }}</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="createTime"
        header-align="center"
        align="center"
        label="创建时间"
        width="150"
      >
      </el-table-column>
      <el-table-column
        fixed="right"
        header-align="center"
        align="center"
        width="150"
        label="操作"
      >
        <template slot-scope="scope">
          <el-button
            type="text"
            size="small"
            @click="addOrUpdateHandle(scope.row.id)"
            >编辑</el-button
          >
          <el-button
            type="text"
            size="small"
            @click="deleteHandle(scope.row.id)"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      size="small"
      @size-change="sizeChangeHandle"
      @current-change="currentChangeHandle"
      :current-page="dataForm.pageNo"
      :page-sizes="[10, 20, 50, 100]"
      :page-size="dataForm.pageSize"
      :total="totalPage"
      layout="total, sizes, prev, pager, next, jumper"
    >
    </el-pagination>
    <!-- 弹窗, 新增 / 修改 -->
    <add-or-update
      v-if="addOrUpdateVisible"
      ref="addOrUpdate"
      @refreshDataList="getDataList"
    ></add-or-update>
    <upload
      v-if="uploadVisible"
      ref="upload"
      @refreshDataList="getDataList"
    ></upload>
  </div>
</template>

<script>
import {
  queryColorBookApi,
  deleteColorBookApi,
  getCategoryList,
} from "@/api/modules/colorbook";
import { queryColorBookCategoryApi } from "@/api/modules/colorbookcategory";
import { queryColorBookBrandApi } from "@/api/modules/colorBookBrand";
import AddOrUpdate from "./add-or-update";
import { getToken } from "@/utils/auth";
import Upload from "./oss-upload";
import { axiosDownFile } from "@/utils";
export default {
  data() {
    return {
      dataForm: {
        colorBookBrandId: "",
        colorName: "",
        colorNo: "",
        colorBookCategoryId: "",
        pageNo: 1,
        pageSize: 10,
      },
      brandList: [],
      dataList: [],
      colorBookCategoryList: [],
      totalPage: 0,
      uploadVisible: false,
      dataListLoading: false,
      addOrUpdateVisible: false,
    };
  },
  components: {
    Upload,
    AddOrUpdate,
  },
  mounted() {
    this.getBrandList();
  },
  //写个过滤器，保留小数点后两位
  filters: {
    numFilter(value) {
      let realVal = "";
      if (!isNaN(value) && value !== "") {
        // 截取当前数据到小数点后两位
        realVal = parseFloat(value).toFixed(2);
      } else {
        realVal = "--";
      }
      return realVal;
    },
  },
  methods: {
    // 上传文件
    uploadHandle() {
      this.uploadVisible = true;
      this.$nextTick(() => {
        this.$refs.upload.init();
      });
    },
    //导出数据
    exportExcelColorBook() {
      axiosDownFile("/api/m/colorBook/exportExcelColorBook", this.dataForm);
    },
    //下载模板
    exportExcel() {
      axiosDownFile("/api/m/colorBook/getExcelTemplate");
    },
    //重新查询
    restPage() {
      this.dataForm.pageNo = 1;
      this.getDataList();
    },
    reset() {
      this.$refs.dataForm.resetFields();
      this.restPage();
    },
    getBrandList() {
      queryColorBookBrandApi().then(({ records, totalRecords }) => {
        if (totalRecords > 0) {
          this.brandList = records;
        } else {
          this.brandList = [];
        }
        this.getColorBookCategory(true);
      });
    },
    //分类下拉
    getColorBookCategory(dataFlag) {
      const params = {
        colorBookBrandId: this.dataForm.colorBookBrandId,
        pageSize: 100000,
      };
      queryColorBookCategoryApi(params).then(({ records, totalRecords }) => {
        if (totalRecords > 0) {
          this.colorBookCategoryList = records;
        } else {
          this.colorBookCategoryList = [];
        }
        if (dataFlag) {
          this.getDataList();
        }
      });
    },
    //根据色号类型匹配色号分类数据
    getCategoryList(e) {
      let params = {
        id: e,
      };
      getCategoryList(params).then(({ data }) => {
        if (data && data.code === 0) {
          if (data.list.length > 0) {
            this.dataForm.colorBookCategory = "";
            this.colorBookCategoryList = data.list;
          } else {
            this.dataForm.colorBookCategory = "";
            this.colorBookCategoryList = [];
          }
        }
      });
    },
    // 获取数据列表
    getDataList() {
      this.dataListLoading = true;
      queryColorBookApi(this.dataForm).then(({ records, totalRecords }) => {
        if (totalRecords > 0) {
          this.dataList = records;
          this.totalPage = totalRecords;
        } else {
          this.dataList = [];
          this.totalPage = 0;
        }
        this.dataListLoading = false;
      });
    },
    // 每页数
    sizeChangeHandle(val) {
      this.dataForm.pageSize = val;
      this.dataForm.pageNo = 1;
      this.getDataList();
    },
    // 当前页
    currentChangeHandle(val) {
      this.dataForm.pageNo = val;
      this.getDataList();
    },
    // 新增 / 修改
    addOrUpdateHandle(id) {
      this.addOrUpdateVisible = true;
      this.$nextTick(() => {
        this.$refs.addOrUpdate.init(id);
      });
    },
    // 删除
    deleteHandle(id) {
      this.$confirm(`确定删除该条数据?`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        deleteColorBookApi(id).then(() => {
          this.$baseMessage("删除成功", "success");
          this.getDataList();
        });
      });
    },
  },
};
</script>
