<template>
  <div class="app-container">
    <el-form :inline="true"  size="small" :model="dataForm" ref="dataForm"  @keyup.enter.native="restPage()">
      <el-form-item prop="name">
        <el-input v-model="dataForm.name" placeholder="申请人姓名" size="small" clearable></el-input>
      </el-form-item>
      <el-form-item prop="phone">
        <el-input v-model="dataForm.phone" placeholder="申请人电话" size="small" clearable></el-input>
      </el-form-item>
      <el-form-item prop="company">
        <el-input v-model="dataForm.company" placeholder="申请人公司" size="small" clearable></el-input>
      </el-form-item>
      <el-form-item prop="state">
        <el-select v-model="dataForm.state" placeholder="审核状态">
          <el-option label="全部" value=""></el-option>
          <el-option
                  v-for="item in stateArry"
                  :key="item.stateKey"
                  :label="item.stateValue"
                  :value="item.stateKey"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button @click="restPage()" size="small">查询</el-button>
        <el-button @click="reset('dataForm')"  size="small">清空</el-button>
      </el-form-item>
    </el-form>
    <el-table
      :data="dataList"
      border
      size="small"
      v-loading="dataListLoading"
      @selection-change="selectionChangeHandle"
      style="width: 100%;">
      <el-table-column
              type="index"
              width="50"
              label="序号">
      </el-table-column>
      <!--<el-table-column
        prop="applyProbation"
        header-align="center"
        align="center"
        label="申请试用表id">
      </el-table-column>-->
      <el-table-column
        prop="name"
        header-align="center"
        align="center"
        label="申请人姓名">
      </el-table-column>
      <el-table-column
        prop="company"
        header-align="center"
        align="center"
        label="申请人公司名称">
      </el-table-column>
      <el-table-column
        prop="phone"
        header-align="center"
        align="center"
        label="申请人联系电话">
      </el-table-column>
      <el-table-column
        prop="state"
        header-align="center"
        align="center"
        label="状态">
        <template slot-scope="scope">
          <span v-if="scope.row.state=='0'">待审核</span>
          <span v-if="scope.row.state=='1'">审核通过</span>
          <span v-if="scope.row.state=='2'">审核拒绝</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="createDate"
        header-align="center"
        align="center"
        label="申请时间">
      </el-table-column>
      <el-table-column
        fixed="right"
        header-align="center"
        align="center"
        width="150"
        label="操作">
        <template slot-scope="scope">
          <el-button type="text" size="small" @click="addOrUpdateHandle(scope.row.applyProbation)" v-if="scope.row.state=='0'">审核</el-button>
          <el-button type="text" size="small" @click="applyDetail(scope.row.applyProbation)" v-if="scope.row.state!='0'">审核详情</el-button>
          <!--<el-button type="text" size="small" @click="deleteHandle(scope.row.applyProbation)">删除</el-button>-->
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      size="small"
      @size-change="sizeChangeHandle"
      @current-change="currentChangeHandle"
      :current-page="pageIndex"
      :page-sizes="[10, 20, 50, 100]"
      :page-size="pageSize"
      :total="totalPage"
      layout="total, sizes, prev, pager, next, jumper">
    </el-pagination>
    <!-- 弹窗, 新增 / 修改 -->
    <add-or-update v-if="addOrUpdateVisible" ref="addOrUpdate" @refreshDataList="getDataList"></add-or-update>
    <!--审核详情-->
    <ApplyDetail v-if="applyDetailVisible" ref="applyDetail" @refreshDataList="getDataList"></ApplyDetail>
  </div>
</template>

<script>
  import {list,del} from '@/api/modules/applyprobation'
  import AddOrUpdate from './add-or-update'
  import ApplyDetail from './applyDetail'
  export default {
    data () {
      return {
        dataForm: {
            name: '',
            phone: '',
            company: '',
            state: '',
          daterange:[]
        },
          stateArry:[
              {stateKey:'0', stateValue:'待审核'},
              {stateKey:'1', stateValue:'审核通过'},
              {stateKey:'2', stateValue:'审核拒绝'},
          ],
        dataList: [],
        pageIndex: 1,
        pageSize: 10,
        totalPage: 0,
        dataListLoading: false,
        dataListSelections: [],
        addOrUpdateVisible: false,
          applyDetailVisible:false,
      }
    },
    components: {
      AddOrUpdate,
        ApplyDetail
    },
    activated () {
      this.getDataList()
    },
      mounted(){
          this.getDataList()
      },
    methods: {
        //审核详情
        applyDetail (id) {
            this.applyDetailVisible = true
            this.$nextTick(() => {
                this.$refs.applyDetail.init(id)
            })
        },
      //重新查询
      restPage() {
        this.pageIndex = 1;
        this.getDataList();
      },
      reset(dataForm) {
       	this.$refs.dataForm.resetFields();
        this.restPage();
      },
      // 获取数据列表
      getDataList () {
        this.dataListLoading = true
        var params = {
          page: this.pageIndex,
          limit: this.pageSize,
            name: this.dataForm.name,
            phone: this.dataForm.phone,
            company: this.dataForm.company,
            state: this.dataForm.state,
        }
        list(params).then(({data}) => {
          if (data && data.code === 0) {
            this.dataList = data.page.list
            this.totalPage = data.page.totalCount
          } else {
            this.dataList = []
            this.totalPage = 0
          }
          this.dataListLoading = false
        })
      },
      // 每页数
      sizeChangeHandle (val) {
        this.pageSize = val
        this.pageIndex = 1
        this.getDataList()
      },
      // 当前页
      currentChangeHandle (val) {
        this.pageIndex = val
        this.getDataList()
      },
      // 多选
      selectionChangeHandle (val) {
        this.dataListSelections = val
      },
      // 新增 / 修改
      addOrUpdateHandle (id) {
        this.addOrUpdateVisible = true
        this.$nextTick(() => {
          this.$refs.addOrUpdate.init(id)
        })
      },
      // 删除
      deleteHandle (id) {
        var ids = id ? [id] : this.dataListSelections.map(item => {
          return item.applyProbation
        })
        this.$confirm(`确定对[id=${ids.join(',')}]进行[${id ? '删除' : '批量删除'}]操作?`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          del(ids).then(({data}) => {
            if (data && data.code === 0) {
              this.$message({
                message: '操作成功',
                type: 'success',
                duration: 1500,
                onClose: () => {
                  this.getDataList()
                }
              })
            } else {
              this.$message.error(data.msg)
            }
          })
        })
      }
    }
  }
</script>
