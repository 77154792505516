<template>
  <el-dialog
    :title="!dataForm.id ? '新增会员标签' : '编辑会员标签'"
    :close-on-click-modal="false"
    :visible.sync="visible"
    width="500px"
  >
    <el-form
      :model="dataForm"
      :rules="dataRule"
      size="small"
      ref="dataForm"
      @keyup.enter.native="dataFormSubmit()"
      label-width="80px"
      style="width: 100%"
    >
      <el-form-item label="标签名称" prop="labelName">
        <el-input
          v-model="dataForm.labelName"
          placeholder="标签名称"
          size="small"
        ></el-input>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="visible = false" size="small">取消</el-button>
      <el-button
        type="primary"
        @click="dataFormSubmit()"
        size="small"
        :disabled="dataLoading"
        >确定</el-button
      >
    </span>
  </el-dialog>
</template>

<script>
import { getMemberLabelList, addOrUpdateMemberLabelApi, getMemberLabelApi } from "@/api/modules/memberlabel";
export default {
  data() {
    return {
      visible: false,
      dataLoading: false,
      memberLabelList: [],
      dataForm: {
        id: undefined,
        labelName: "",
      },
      dataRule: {
        labelName: [
          { required: true, message: "标签名称不能为空", trigger: "blur" },
        ],
      },
    };
  },
  methods: {
    init(id) {
      this.dataForm.id = id || undefined;
      this.visible = true;
      this.$nextTick(() => {
        this.$refs["dataForm"].resetFields();
        if (id) {
          getMemberLabelApi(id).then((response) => {
            this.dataForm = response;
          });
        }
      });
    },
    // 表单提交
    dataFormSubmit() {
      this.$refs["dataForm"].validate((valid) => {
        if (valid) {
          this.dataLoading = true;
          addOrUpdateMemberLabelApi(this.dataForm)
            .then(() => {
              this.$baseMessage("操作成功", "success");
              this.visible = false;
              this.$emit("refreshDataList");
            })
            .finally(() => {
              this.dataLoading = false;
            });
        }
      });
    },
    getMemberLabel() {
      //获取标签列表
      let params = {};
      getMemberLabelList(params).then(({ data }) => {
        if (data && data.code === 0) {
          // console.log("data:"+data)
          this.memberLabelList = data.data;
        } else {
          this.memberLabelList = [];
        }
      });
    },
  },
};
</script>
