import request from '@/utils/request'

const url = '/m/combo';

// 获取列表
export function queryComboApi (data) {
  if(!data) {
    data = {pageSize: 100000}
  }
  return request({
    url: `${url}/query`,
    method: 'post',
    data
  })
}

// 获取信息
export function getComboApi (id) {
  return request({
    url: `${url}/get/${id}`,
    method: 'get',
  })
}

// 添加
export function addOrUpdateComboApi (data) {
  return request({
    url: `${url}/addOrUpdate`,
    method: 'put',
    data
  })
}


// 删除
export function deleteComboApi (id) {
  return request({
    url: `${url}/delete/${id}`,
    method: 'delete',
  })
}

