<template>
  <div class="app-container">
    <el-form
      :inline="true"
      size="small"
      :model="dataForm"
      ref="dataForm"
      @keyup.enter.native="restPage()"
    >
      <el-form-item prop="productAttributeId" label="产品属性">
        <el-select
          v-model="dataForm.productAttributeId"
          size="small"
          placeholder="产品属性"
          clearable
        >
          <el-option
            v-for="item in productAttributeList"
            :key="item.id"
            :label="item.attributeName"
            :value="item.id"
            size="small"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item prop="valueNo" label="属性值编号">
        <el-input
          v-model="dataForm.valueNo"
          placeholder="属性值编号"
          size="small"
          clearable
        ></el-input>
      </el-form-item>

      <el-form-item prop="valueName" label="属性值名称">
        <el-input
          v-model="dataForm.valueName"
          placeholder="属性值名称"
          size="small"
          clearable
        ></el-input>
      </el-form-item>

      <el-form-item prop="valueAlias" label="属性值别名">
        <el-input
          v-model="dataForm.valueAlias"
          placeholder="属性值别名"
          size="small"
          clearable
        ></el-input>
      </el-form-item>
      <el-form-item prop="isListShow" label="是否显示">
        <el-select
          v-model="dataForm.isShow"
          size="small"
          placeholder="是否显示"
          style="width: 120px"
          clearable
        >
          <el-option label="是" :value="true" size="small"> </el-option>
          <el-option label="否" :value="false" size="small"> </el-option>
        </el-select>
      </el-form-item>

      <el-form-item>
        <el-button @click="restPage()" size="small">查询</el-button>
        <el-button @click="reset('dataForm')" size="small" type="info"
          >清空</el-button
        >
        <el-button
          type="primary"
          @click="addOrUpdateHandle()"
          size="small"
          >新增属性值</el-button
        >
      </el-form-item>
    </el-form>
    <el-table
      :data="dataList"
      border
      size="small"
      v-loading="dataListLoading"
      @selection-change="selectionChangeHandle"
      style="width: 100%"
    >
      <el-table-column
        prop="valueNo"
        header-align="center"
        align="center"
        label="属性值编号"
      >
      </el-table-column>
      <el-table-column
        prop="valueName"
        header-align="center"
        align="center"
        label="属性值名称"
      >
      </el-table-column>
      <el-table-column
        prop="valueAlias"
        header-align="center"
        align="center"
        label="属性值别名"
      >
      </el-table-column>
      <el-table-column
        prop="parentValueName"
        header-align="center"
        align="center"
        label="上级属性值"
      >
      </el-table-column>

      <el-table-column
        prop="attributeName"
        header-align="center"
        align="center"
        label="关联属性"
      >
      </el-table-column>
      <el-table-column
        prop="relationAttributeName"
        header-align="center"
        align="center"
        label="关联通用属性"
      >
      </el-table-column>
      <el-table-column
        prop="relationValueName"
        header-align="center"
        align="center"
        label="关联通用属性值"
      >
      </el-table-column>
      <el-table-column
        prop="sortNumber"
        header-align="center"
        align="center"
        label="排序"
      >
      </el-table-column>
      <el-table-column
        prop="isShow"
        header-align="center"
        align="center"
        label="是否显示"
      >
        <template slot-scope="scope">
          <el-tag v-if="scope.row.isShow" type="success">是</el-tag>
          <el-tag v-else type="danger">否</el-tag>
        </template>
      </el-table-column>
      <el-table-column
        prop="createTime"
        header-align="center"
        align="center"
        label="创建时间"
        width="150"
      >
      </el-table-column>

      <el-table-column
        fixed="right"
        header-align="center"
        align="center"
        width="150"
        label="操作"
      >
        <template slot-scope="scope">
          <el-button
            type="text"
            size="small"
            @click="copyHandle(scope.row.id, scope.row.brandId)"
            style="color: #67c23a"
            >复制</el-button
          >
          <el-button
            type="text"
            size="small"
            @click="addOrUpdateHandle(scope.row.id)"
            >编辑</el-button
          >
          <el-button
            type="text"
            size="small"
            style="color: #f56c6c"
            @click="deleteHandle(scope.row.id, scope.row.brandId)"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      size="small"
      @size-change="sizeChangeHandle"
      @current-change="currentChangeHandle"
      :current-page="dataForm.pageNo"
      :page-sizes="[10, 20, 50, 100]"
      :page-size="dataForm.pageSize"
      :total="totalPage"
      layout="total, sizes, prev, pager, next, jumper"
    >
    </el-pagination>
    <!-- 弹窗, 新增 / 修改 -->
    <add-or-update
      v-if="addOrUpdateVisible"
      ref="addOrUpdate"
      @refreshDataList="getDataList"
    ></add-or-update>
  </div>
</template>

<script>
import {
  queryProductAttributeValueApi,
  deleteProductAttributeValueApi,
} from "@/api/modules/productAttributeValue";
import { queryProductAttributeApi } from "@/api/modules/productAttribute";
import AddOrUpdate from "./add-or-update";
export default {
  data() {
    return {
      dataForm: {
        productAttrId: "",
        valueNo: "",
        valueName: "",
        valueAlias: "",
        isOpen: "",
        daterange: [],
        pageNo: 1,
        pageSize: 10,
      },
      role: "",
      dataList: [],
      totalPage: 0,
      dataListLoading: false,
      dataListSelections: [],
      addOrUpdateVisible: false,
      // 产品属性列表
      productAttributeList: [],
    };
  },
  components: {
    AddOrUpdate,
  },
  mounted() {
    const params = this.$route.query.params;
    if (params === "valAdd") {
      setTimeout(() => {
        this.addOrUpdateVisible = true;
        this.$nextTick(() => {
          this.$refs.addOrUpdate.init("", "", this.role);
        });
      }, 1000);
    }
  },
  created() {
    this.getProductAttributeList();
  },
  methods: {
    // 获取产品属性列表
    async getProductAttributeList() {
      const { records } = await queryProductAttributeApi();
      if (records && records.length > 0) {
        this.productAttributeList = records;
      } else {
        this.productAttributeList = [];
      }
      this.getDataList();
    },
    //重新查询
    restPage() {
      this.dataForm.pageNo = 1;
      this.getDataList();
    },
    reset() {
      this.$refs.dataForm.resetFields();
      (this.dataForm.valueNo = ""),
        (this.dataForm.valueName = ""),
        (this.dataForm.valueAlias = ""),
        (this.dataForm.brandId = "");
      (this.dataForm.isOpen = ""), (this.dataForm.productAttrId = "");
      this.restPage();
    },
    // 获取数据列表
    getDataList() {
      this.dataListLoading = true;
      queryProductAttributeValueApi(this.dataForm).then(
        ({ records, totalRecords }) => {
          if (totalRecords > 0) {
            this.dataList = records;
            this.totalPage = totalRecords;
          } else {
            this.dataList = [];
            this.totalPage = 0;
          }
          this.dataListLoading = false;
        }
      );
    },
    // 每页数
    sizeChangeHandle(val) {
      this.dataForm.pageSize = val;
      this.dataForm.pageNo = 1;
      this.getDataList();
    },
    // 当前页
    currentChangeHandle(val) {
      this.dataForm.pageNo = val;
      this.getDataList();
    },
    // 多选
    selectionChangeHandle(val) {
      this.dataListSelections = val;
    },
    // 复制
    copyHandle(id, brandId) {
      this.addOrUpdateVisible = true;
      this.$nextTick(() => {
        this.$refs.addOrUpdate.init(id, true);
      });
    },
    // 新增 / 修改
    addOrUpdateHandle(id) {
      this.addOrUpdateVisible = true;
      this.$nextTick(() => {
        this.$refs.addOrUpdate.init(id, false);
      });
    },

    deleteHandle(id) {
      this.$confirm(`是否删除属性值?`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        deleteProductAttributeValueApi(id).then(() => {
          this.$baseMessage("删除成功", "success");
          this.getDataList();
        });
      });
    },
  },
};
</script>
