<template>
  <el-dialog
    :title="!dataForm.id ? '新增' : '修改'"
    :close-on-click-modal="false"
    :visible.sync="visible">
    <el-form :model="dataForm" :rules="dataRule"  size="small" ref="dataForm" @keyup.enter.native="dataFormSubmit()" label-width="120px"
     v-loading="dataLoading" style="width:100%;"  element-loading-text=""  element-loading-spinner="el-icon-loading"
    >
      <el-form-item label="所属品牌" prop="brandId">
          <el-select v-model="dataForm.brandId" clearable placeholder="请选择品牌" style="width: 80%">
            <el-option
                    v-for="item in brandList"
                    :key="item.brandId"
                    :label="item.brandName"
                    :value="item.brandId">
            </el-option>
          </el-select>
      </el-form-item>
      <el-form-item label="客服名称" prop="name">
        <el-input v-model="dataForm.name" placeholder="名字"  size="small" style="width: 80%"></el-input>
      </el-form-item>
      <el-form-item label="头像" prop="avatar">
          <el-upload
                  class="avatar-uploader"
                 :action="action"
                  :show-file-list="false"
                  :on-success="handleAvatarSuccess"
                  :before-upload="beforeAvatarUpload">
            <img v-if="dataForm.avatar" :src="dataForm.avatar" class="avatar">
            <i v-else class="el-icon-plus avatar-uploader-icon" title="点击上传"></i>
          </el-upload>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="visible = false" size="small">取消</el-button>
      <el-button type="primary" @click="dataFormSubmit()" size="small"  :disabled="dataLoading">确定</el-button>
    </span>
  </el-dialog>
</template>

<script>
  import {info,add,update} from '@/api/modules/kfaccount'
  import {brandList} from '@/api/modules/factory'
  import {getToken} from '@/utils/auth'
  export default {
    data () {
      return {
        action: `${process.env.VUE_APP_BASE_API}/kf/kfaccount/upload?token=${getToken()}`,
        visible: false,
        dataLoading: false,
        dataForm: {
          id: 0,
          name: '',
          avatar: '',
          brandId: '',
          openKfid: '',
          mediaid: ''
        },
        brandList:[],
        dataRule: {
          name: [
            { required: true, message: '名字不能为空', trigger: 'blur' }
          ],
          avatar: [
            { required: true, message: '头像不能为空', trigger: 'blur' }
          ],
          brandId: [
            { required: true, message: '品牌id不能为空', trigger: 'blur' }
          ]
        }
      }
    },
    methods: {
      init (id) {
        this.getBrandList();
        this.dataForm.id = id || 0
        this.visible = true
        this.$nextTick(() => {
          this.$refs['dataForm'].resetFields()
          if (this.dataForm.id) {
            info({id:this.dataForm.id}).then(({data}) => {
              if (data && data.code === 0) {
                this.dataForm.name = data.kfAccount.name
                this.dataForm.avatar = data.kfAccount.avatar
                this.dataForm.brandId = data.kfAccount.brandId
                this.dataForm.openKfid = data.kfAccount.openKfid
              }
            })
          }
        })
      },
      beforeAvatarUpload(file) {
        const isJPG =
                file.type === "image/jpeg" ||
                file.type === "image/bmp" ||
                file.type === "image/png";
        const isLt2M = file.size / 1024 / 1024 < 8;
        if (!isJPG) {
          this.$message.error("上传图片格式不支持!");
        }
        if (!isLt2M) {
          this.$message.error("上传图片大小不能超过 8MB!");
        }
        return isJPG && isLt2M;
      },
      //图片上传
      handleAvatarSuccess(res, file, type) {
        if (res && res.code === 0) {
          this.dataForm.avatar = res.data.fileUrl;
          this.dataForm.mediaid = res.data.mediaid;
        } else {
          this.$message.error(res.msg);
        }
      },
      //获取品牌下拉
      getBrandList(){
        brandList({id:this.dataForm.id}).then(res => {
          let { list } = res.data
          this.brandList = list
        })
      },

      // 表单提交
      dataFormSubmit () {
        this.$refs['dataForm'].validate((valid) => {
          if (valid) {
          	this.dataLoading = true;
            var params = {
              'id': this.dataForm.id || undefined,
              'name': this.dataForm.name,
              'avatar': this.dataForm.avatar,
              'brandId': this.dataForm.brandId,
              'openKfid': this.dataForm.openKfid,
              'mediaid': this.dataForm.mediaid
            }
            var tick = !this.dataForm.id ? add(params) : update(params)
            tick.then(({data}) => {
              if (data && data.code === 0) {
                this.$message({
                  message: '操作成功',
                  type: 'success',
                  duration: 1500,
                  onClose: () => {
                    this.visible = false;
	              	this.dataLoading = false;
                    this.$emit('refreshDataList')
                  }
                })
              } else {
                this.$message.error(data.msg)
                this.dataLoading = false;
              }
            })
          }
        })
      }
    }
  }
</script>
<style lang="scss">
  .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409EFF;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
  }
  .avatar {
    width: 178px;
    height: 178px;
    display: block;
  }
  .class_map_lib{
      font-weight:800;
  }
</style>
