import request from '@/utils/request'

// 获取列表
export function list (params) {
  return request({
    url: '/member/applyprobation/list',
    method: 'get',
    params: params
  })
}

// 获取信息
export function info (params) {
  return request({
    url: '/member/applyprobation/info',
    method: 'get',
    params: params
  })
}

// 添加
export function add (params) {
  return request({
    url: '/member/applyprobation/save',
    method: 'post',
    data: params
  })
}

// 修改
export function update (params) {
  return request({
    url: '/member/applyprobation/update',
    method: 'post',
    data: params
  })
}

// 删除
export function del (params) {
  return request({
    url: '/member/applyprobation/delete',
    method: 'post',
    data: params
  })
}

// 审核
export function checkApply (params) {
    return request({
        url: '/member/applyprobation/checkApply',
        method: 'post',
        data: params
    })
}
