import request from '@/utils/request'

const url = '/m/role';

// 获取列表
export function queryRoleApi (data) {
  if(!data) {
    data = {pageSize: 100000}
  }
  return request({
    url: `${url}/query`,
    method: 'post',
    data
  })
}

export function getRoleApi(id) {
  return request({
    url: `${url}/get/${id}`,
    method: 'get'
  })
}

export function addOrUpdateRoleApi(data) {
  return request({
    url: `${url}/addOrUpdate`,
    method: 'put',
    data
  })
}

export function deleteRoleApi(data) {
  return request({
    url: `${url}/delete`,
    method: 'delete',
    data
  })
}