<template>
  <div class="app-container" style="border: groove">
    <el-row :gutter="12" >
      <el-col :span="12">
        <el-tabs v-model="searchStatistics">
          <div>每日概况</div>
        </el-tabs>
        <el-row :gutter="20">
          <el-col :span="10"><div class="grid-content bg-purple margin blue style">今日搜索次数<div class="font">{{searchStatistics.searchCount}}</div></div></el-col>
          <el-col :span="10"><div class="grid-content bg-purple margin blue style">今日搜索匹配次数<div class="font">{{searchStatistics.matchingCount}}</div></div></el-col>
          <el-col :span="10"><div class="grid-content bg-purple margin red style">今日搜索不匹配次数<div class="font">{{searchStatistics.noMatchingCount}}</div></div></el-col>
          <el-col :span="10"><div class="grid-content bg-purple margin green style">今日搜索匹配率<div class="font">{{searchStatistics.matchingProbability}}%</div></div></el-col>
          <el-col :span="10"><div class="grid-content bg-purple margin blue style">今日搜索人数<div class="font">{{searchStatistics.peopleCount}}</div></div></el-col>
          <el-col :span="10"><div class="grid-content bg-purple margin blue style">今日人均搜索次数<div class="font">{{searchStatistics.averageOfPeopleCount}}</div></div></el-col>
        </el-row>

        </el-col>
      <!-- 搜索统计-->
      <el-col :span="12">
        <el-tabs v-model="activeNameDiscount"  type="card" @tab-click="handleClick">
          <el-tab-pane label="近7天" name="first"></el-tab-pane>
          <el-tab-pane label="近30天" name="second"></el-tab-pane>
        </el-tabs>
        <el-card shadow="never">
          <div id="activeDiscount"  style="width: 100%;height: 500px"></div>
        </el-card>
      </el-col>

      <!-- 搜索方式排行-->
      <el-col :span="12">
        <el-tabs v-model="searchTypeRankingName"  type="card" @tab-click="handleSearchType">
          <el-tab-pane label="近7天" name="first"></el-tab-pane>
          <el-tab-pane label="近30天" name="second"></el-tab-pane>
          <el-tab-pane label="近一年" name="third"></el-tab-pane>
        </el-tabs>
        <el-card shadow="never">
          <div id="searchTypeRanking"  style="width: 100%;height: 500px"></div>
        </el-card>
      </el-col>

      <!-- 搜索方式趋势图-->
      <el-col :span="12">
        <el-tabs v-model="searchTypeRankingTrendName"  type="card" @tab-click="handleSearchTypeRankingTrend">
          <el-tab-pane label="近7天" name="first"></el-tab-pane>
          <el-tab-pane label="近30天" name="second"></el-tab-pane>
        </el-tabs>
        <el-card shadow="never">
          <div id="searchTypeRankingTrend"  style="width: 100%;height: 500px"></div>
        </el-card>
      </el-col>
    </el-row>
    <div>产品搜索统计</div>
    <el-row :gutter="12">
      <!-- 雷达图-->
      <el-col :span="12">
        <el-tabs v-model="activeName" type="card" @tab-click="getDateSearch">
            <el-select v-model="value" placeholder="请选择" @change="getDateSearch(value)">
              <el-option
                      v-for="val in dateList"
                      :key="val.value"
                      :label="val.name"
                      :value="val.value">
              </el-option>
            </el-select>
        </el-tabs>
        <el-card shadow="never">
          <div id="activeRadar"  style="width: 100%;height: 500px"></div>
        </el-card>
      </el-col>
      <!-- 产品搜索条件趋势图-->
      <el-col :span="12">
        <el-tabs v-model="trendNameDiscount"  type="card">
          <el-tab-pane label="月" name="first"></el-tab-pane>
        </el-tabs>
        <el-card shadow="never">
          <div id="activeSearchTrend"  style="width: 100%;height: 500px"></div>
        </el-card>
      </el-col>
      <!-- 表面-->
      <el-col :span="12">
        <el-tabs v-model="surfaceActiveName" type="card" @tab-click="handleBarSurfaceClick">
          <el-tab-pane label="月" name="first"></el-tab-pane>
          <el-tab-pane label="季度" name="second"></el-tab-pane>
          <el-tab-pane label="年" name="third"></el-tab-pane>
        </el-tabs>
        <el-card shadow="never">
          <div id="activeBarSurface"  style="width: 100%;height: 500px"></div>
        </el-card>
      </el-col>
      <!-- 色系 -->
      <!-- <el-col :span="12">
        <el-tabs v-model="colorSeriesActiveName" type="card" @tab-click="handleBarColorSeriesClick">
          <el-tab-pane label="月" name="first"></el-tab-pane>
          <el-tab-pane label="季度" name="second"></el-tab-pane>
          <el-tab-pane label="年" name="third"></el-tab-pane>
        </el-tabs>
        <el-card shadow="never">
          <div id="activeBarColorSeries"  style="width: 100%;height: 500px"></div>
        </el-card>
      </el-col> -->

      <!-- 产品系列 -->
      <!-- <el-col :span="12">
        <el-tabs v-model="productSeriesActiveName" type="card" @tab-click="handleBarProductSeriesClick">
          <el-tab-pane label="月" name="first"></el-tab-pane>
          <el-tab-pane label="季度" name="second"></el-tab-pane>
          <el-tab-pane label="年" name="third"></el-tab-pane>
        </el-tabs>
        <el-card shadow="never">
          <div id="activeBarProductSeries"  style="width: 100%;height: 500px"></div>
        </el-card>
      </el-col> -->
      <!-- 产品性能 -->
      <el-col :span="12">
        <el-tabs v-model="performanceIdActiveName" type="card" @tab-click="handleBarPerformanceIdClick">
          <el-tab-pane label="月" name="first"></el-tab-pane>
          <el-tab-pane label="季度" name="second"></el-tab-pane>
          <el-tab-pane label="年" name="third"></el-tab-pane>
        </el-tabs>
        <el-card shadow="never">
          <div id="activeBarPerformanceId"  style="width: 100%;height: 500px"></div>
        </el-card>
      </el-col>

    <!-- 行业（应用场景） -->
      <el-col :span="12">
        <el-tabs v-model="industryIdActiveName" type="card" @tab-click="handleBarIndustryIdClick">
          <el-tab-pane label="月" name="first"></el-tab-pane>
          <el-tab-pane label="季度" name="second"></el-tab-pane>
          <el-tab-pane label="年" name="third"></el-tab-pane>
        </el-tabs>
        <el-card shadow="never">
          <div id="activeBarIndustryId"  style="width: 100%;height: 500px"></div>
        </el-card>
      </el-col>

      <!-- 光泽） -->
      <el-col :span="12">
        <el-tabs v-model="glossActiveName" type="card" @tab-click="handleBarGlossClick">
          <el-tab-pane label="月" name="first"></el-tab-pane>
          <el-tab-pane label="季度" name="second"></el-tab-pane>
          <el-tab-pane label="年" name="third"></el-tab-pane>
        </el-tabs>
        <el-card shadow="never">
          <div id="activeBarGloss"  style="width: 100%;height: 500px"></div>
        </el-card>
      </el-col>
    </el-row>
  </div>
</template>

<script>
  import {searchStatistics,findSearchCount,findBarSearch,findRadarSearch,findYearSearch
  ,findSearchTypeRanking,findSearchTypeRankingTrend,findSearchConditionTrend} from '@/api/modules/membersearchhistory'
  import {formatDate} from "../../utils";
  export default {
    data() {
      return {
        dataList:[],
        dateList:[],
        value:'',
        searchYear:formatDate(new Date(),'yyyy'),
        activeNameDiscount:'first',
        searchTypeRankingName:'first',
        searchTypeRankingTrendName:'first',
        activeName: 'first',
        surfaceActiveName: 'first',
        colorSeriesActiveName: 'first',
        productSeriesActiveName: 'first',
        performanceIdActiveName: 'first',
        industryIdActiveName: 'first',
        glossActiveName: 'first',
        activeNameDiscountType:'1',
        activeNamePie:'1',
        searchPieHistoryVo:'',
        searchBarSurfaceVos:'',// 表面
        searchBarColorSeriesVos:'',// 色系
        searchBarProductSeriesVos:'',// 系列
        searchBarPerformanceIdVos:'',//性能标准
        searchBarIndustryIdVos:'',//行业
        searchBarGlossVos:'',// 光泽
        searchRadarVos:'',// 雷达
        searchTypeRankingVos:[],// 搜索类型
        searchTypeRankingTrendVos:'',// 搜索类型趋势
        searchConditionTrendVos:'',// 搜索条件趋势
        searchStatistics:{
          searchCount: 0,
          matchingCount: 0,
          noMatchingCount: 0,
          matchingProbability: 0,
          peopleCount: 0,
          averageOfPeopleCount: 0,
        },
      }
    },

    activated () {

    },
    mounted(){
       this.activeNameBar = 1;
      this.getStatistics();
      this.getDataList();
      this.getDataBarSurface();
      this.getDataBarColorSeries();
      this.getDataBarProductSeries();
      this.getDataBarIndustryId();
      this.getDataBarGloss();
      this.getDataBarPerformanceId();
      this.getDataRadar();
      this.getDateList();
      this.getSearchTypeRanking();
      this.searchTypeRankingTrend = 1;
      this.getSearchTypeTrendList();
      this.getSearchConditionTrend();
    },

    methods: {

      // 获取数据列表
      getStatistics() {
        searchStatistics().then(({data}) => {
          if (data && data.code === 0) {
            this.searchStatistics.searchCount=data.searchStatistics.searchCount;
            this.searchStatistics.matchingCount=data.searchStatistics.matchingCount;
            this.searchStatistics.noMatchingCount=data.searchStatistics.noMatchingCount;
            this.searchStatistics.matchingProbability=data.searchStatistics.matchingProbability;
            this.searchStatistics.peopleCount=data.searchStatistics.peopleCount;
            this.searchStatistics.averageOfPeopleCount=data.searchStatistics.averageOfPeopleCount;
          } else {
          }

        })
      },
      // 搜索统计
      initActiveDiscount(){
        var activeDiscount = this.$echarts.init(document.getElementById('activeDiscount'));
        var searchDate = new Array();
        var searchCount = new Array();
        var searchAccuracy = new Array();
        var matchingProbability = new Array();
        for(var i=0;i<this.dataList.length;i++){
          searchDate[i]=this.dataList[i].searchDate;
          searchCount[i]=this.dataList[i].searchCount;
          searchAccuracy[i]=this.dataList[i].matchingCount;
          matchingProbability[i]=this.dataList[i].matchingProbability;
        }
        let option = null;
        option = {
          title: {
            left: "center",
            text: (this.activeNameDiscount=="first"?'近7日产品搜索统计':'近30日产品搜索统计')
          },
          tooltip: {
            trigger: 'axis'
          },
          legend: {
            data: ['搜索总次数', '搜索匹配次数', '搜索匹配率'],
            y:'bottom',     //可设定图例在上、下、居中
          },
          grid: {
            left: '3%',
            right: '4%',
            bottom: '5%',
            containLabel: true
          },
          toolbox: {
            feature: {
              saveAsImage: {}
            }
          },
          xAxis: {
            type: 'category',
            boundaryGap: false,
            data: searchDate
          },
          yAxis: {
            type: 'value'
          },
          series: [
            {
              name: '搜索总次数',
              type: 'line',

              data: searchCount
            },
            {
              name: '搜索匹配次数',
              type: 'line',

              data: searchAccuracy
            },
            {
              name: '搜索匹配率',
              type: 'line',

              data: matchingProbability
            }
          ]
        };
        // 使用刚指定的配置项和数据显示图表。
        if (option && typeof option === "object") {
          activeDiscount.setOption(option, true);
        }
      },
      // 搜索条件趋势统计
      initSearchConditionTrend(){
        var activeSearchTrend = this.$echarts.init(document.getElementById('activeSearchTrend'));
        var searchDate = new Array();
        var colorSeriesCount = new Array();
        var glossCount = new Array();
        var industryIdCount = new Array();
        var performanceIdCount = new Array();
        var productSeriesCount = new Array();
        var surfaceCount = new Array();
        for(var i=0;i<this.searchConditionTrendVos.length;i++){
          searchDate[i]=this.searchConditionTrendVos[i].searchDate;
          colorSeriesCount[i]=this.searchConditionTrendVos[i].colorSeriesCount;
          glossCount[i]=this.searchConditionTrendVos[i].glossCount;
          industryIdCount[i]=this.searchConditionTrendVos[i].industryIdCount;
          performanceIdCount[i]=this.searchConditionTrendVos[i].performanceIdCount;
          productSeriesCount[i]=this.searchConditionTrendVos[i].productSeriesCount;
          surfaceCount[i]=this.searchConditionTrendVos[i].surfaceCount;

        }
        let option = null;
        option = {
          title: {
            left: "center",
            text: (this.activeNameDiscount=="first"?'产品搜索条件趋势图':'产品搜索条件趋势图')
          },
          tooltip: {
            trigger: 'axis'
          },
          legend: {
            data: ['产品光泽', '产品色系', '产品系列','产品性能','产品行业','产品表面'],
            y:'bottom',     //可设定图例在上、下、居中
          },
          grid: {
            left: '3%',
            right: '4%',
            bottom: '5%',
            containLabel: true
          },
          toolbox: {
            feature: {
              saveAsImage: {}
            }
          },
          xAxis: {
            type: 'category',
            boundaryGap: false,
            data: searchDate
          },
          yAxis: {
            type: 'value'
          },
          series: [
            {
              name: '产品光泽',
              type: 'line',
              smooth: true,
              data: glossCount
            },
            {
              name: '产品色系',
              type: 'line',
              smooth: true,
              data: colorSeriesCount
            },
            {
              name: '产品系列',
              type: 'line',
              smooth: true,
              data: productSeriesCount
            },
            {
              name: '产品性能',
              type: 'line',
              smooth: true,
              data: performanceIdCount
            },
            {
              name: '产品行业',
              type: 'line',
              smooth: true,
              data: industryIdCount
            },
            {
              name: '产品表面',
              type: 'line',
              smooth: true,
              data: surfaceCount
            }
          ]
        };
        // 使用刚指定的配置项和数据显示图表。
        if (option && typeof option === "object") {
          activeSearchTrend.setOption(option, true);
        }
      },
      // 搜索统计bar
      initSearchTypeRanking(){
        var searchTypeRanking = this.$echarts.init(document.getElementById('searchTypeRanking'));
        var searchTypeName = new Array();
        var searchTypeCount = new Array();
        for(var i=0;i<this.searchTypeRankingVos.length;i++){
          searchTypeName[i]=this.searchTypeRankingVos[i].searchTypeName;
          searchTypeCount[i]=this.searchTypeRankingVos[i].searchTypeCount;
        }
        let option = null;
        var titleName="";
        option = {
          color: ['#3398DB'],
          tooltip: {
            trigger: 'axis',
            axisPointer: {            // 坐标轴指示器，坐标轴触发有效
              type: 'shadow'        // 默认为直线，可选为：'line' | 'shadow'
            }
          },
          title: {
            text: '搜索方式排行'
          },
          grid: {
            left: '3%',
            right: '4%',
            bottom: '3%',
            containLabel: true
          },
          xAxis: [
            {
              type: 'category',
              data: searchTypeName,
              axisTick: {
                alignWithLabel: true
              }
            }
          ],
          yAxis: [
            {
              type: 'value'
            }
          ],
          series: [
            {
              // name: searchTypeName,
              type: 'bar',
              barWidth: '60%',
              data: searchTypeCount
              ,
              itemStyle: {
                normal: {
                  //这里是重点
                  color: function(params) {
                    //注意，如果颜色太少的话，后面颜色不会自动循环，最好多定义几个颜色
                    var colorList = ['#c23531','#2f4554', '#61a0a8', '#d48265', '#91c7ae','#749f83', '#ca8622', '#61a0a8', '#d48265','#91c7ae'];
                    return colorList[params.dataIndex]
                  }
                }
              }
            }
          ]
        };
        if (option && typeof option === "object") {
          searchTypeRanking.setOption(option, true);
        }
      },
      // 搜索统计 趋势图
      initSearchTypeRankingTrend(){
        var searchTypeRankingTrend = this.$echarts.init(document.getElementById('searchTypeRankingTrend'));
        var searchDate = new Array();
        var instrumentSearch = new Array();
        var boardSearch = new Array();
        var pictureSearch = new Array();
        var ralSearch = new Array();
        var pantoneSearch = new Array();
        for(var i=0;i<this.searchTypeRankingTrendVos.length;i++){
          searchDate[i]=this.searchTypeRankingTrendVos[i].searchDate;
          instrumentSearch[i]=this.searchTypeRankingTrendVos[i].instrumentSearch;
          boardSearch[i]=this.searchTypeRankingTrendVos[i].boardSearch;
          pictureSearch[i]=this.searchTypeRankingTrendVos[i].pictureSearch;
          ralSearch[i]=this.searchTypeRankingTrendVos[i].ralSearch;
          pantoneSearch[i]=this.searchTypeRankingTrendVos[i].pantoneSearch;
        }
        let option = null;
        var titleName="";
        option = {
          title: {
            text: '搜索方式趋势图'
          },
          tooltip: {
            trigger: 'axis'
          },
          legend: {
            data: ['仪器查色', '色板查色', '以图查色', 'RAL查色', 'Pantone查色'],
            y:'bottom',
          },
          grid: {
            left: '3%',
            right: '4%',
            bottom: '5%',
            containLabel: true
          },
          toolbox: {
            feature: {
              saveAsImage: {}
            }
          },
          xAxis: {
            type: 'category',
            boundaryGap: false,
            data: searchDate
          },
          yAxis: {
            type: 'value'
          },
          series: [
            {
              name: '仪器查色',
              type: 'line',
              stack: '总量',
              data: instrumentSearch
            },
            {
              name: '色板查色',
              type: 'line',
              stack: '总量',
              data: boardSearch
            },
            {
              name: '以图查色',
              type: 'line',
              stack: '总量',
              data: pictureSearch
            },
            {
              name: 'RAL查色',
              type: 'line',
              stack: '总量',
              data: ralSearch
            },
            {
              name: 'Pantone查色',
              type: 'line',
              stack: '总量',
              data: pantoneSearch
            }
          ]

        };

        if (option && typeof option === "object") {
          searchTypeRankingTrend.setOption(option, true);
        }
      },


      initActiveRadar(){
        var activeRadar = this.$echarts.init(document.getElementById('activeRadar'));
        var attrName = new Array();
        var searchAttrCount = new Array();
        var isUseFulCount = new Array();
        var maxCount = new Array();
        for(var i=0;i<this.searchRadarVos.length;i++){
          attrName[i]=this.searchRadarVos[i].attrName;
          searchAttrCount[i]=this.searchRadarVos[i].searchAttrCount;
          isUseFulCount[i]=this.searchRadarVos[i].isUseFulCount;
        }
        maxCount = this.searchRadarVos[0].maxCount;
        let option = null;
        var titleName="";
        if(this.activeName==="first"){
          titleName="搜索历史月";
        }else if(this.activeName==="second"){
          titleName="搜索历史季度";
        }else if(this.activeName==="third"){
          titleName="搜索历史年";
        }
        option = {
          title: {
            text: '搜索条件雷达图'
          },
          tooltip: {},
          legend: {
            data: ['总搜索条件次数','匹配搜索条件次数']
          },
          radar: {
            // shape: 'circle',
            name: {
              textStyle: {
                color: '#fff',
                backgroundColor: '#999',
                borderRadius: 3,
                padding: [3, 5]
              }
            },
            indicator: [
              {
                name: attrName[0],
                max: maxCount
              },
              {
                name: attrName[1],
                max: maxCount
              },
              {
                name: attrName[2],
                max: maxCount
              },
              {
                name: attrName[3],
                max: maxCount
              },
              {
                name: attrName[4],
                max: maxCount
              },
              {
                name: attrName[5],
                max: maxCount
              },
            ]
          },
          series: [{
            name: '总搜索条件次数 vs 匹配搜索条件次数',
            type: 'radar',
            // areaStyle: {normal: {}},
            data: [
              {
                value: searchAttrCount,
                name: '总搜索条件次数'
              },
              {
                value: isUseFulCount,
                name: '匹配搜索条件次数'
              }
            ]
          }]
        };

        if (option && typeof option === "object") {
          activeRadar.setOption(option, true);
        }
      },
      initActiveBarSurface(){
        var activeBarSurface = this.$echarts.init(document.getElementById('activeBarSurface'));
        var attrName = new Array();
        var searchAttrCount = new Array();
        for(var i=0;i<this.searchBarSurfaceVos.length;i++){
          attrName[i]=this.searchBarSurfaceVos[i].attrName;
          searchAttrCount[i]=this.searchBarSurfaceVos[i].searchAttrCount;
        }
        let option = null;
        var titleName="";
        if(this.activeName==="first"){
          titleName="搜索历史月";
        }else if(this.activeName==="second"){
          titleName="搜索历史季度";
        }else if(this.activeName==="third"){
          titleName="搜索历史年";
        }
        option = {
          title: {
            text: '表面搜索排行',
            subtext: ''
          },
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'shadow'
            }
          },
          legend: {
            data: ['表面搜索']
          },
          grid: {
            left: '3%',
            right: '4%',
            bottom: '3%',
            containLabel: true
          },
          xAxis: {
            type: 'value',
            boundaryGap: [0, 0.01]
          },
          yAxis: {
            type: 'category',
            data: attrName
          },
          series: [
            {
              name: '表面搜索',
              type: 'bar',
              data: searchAttrCount,
              itemStyle: {
                normal: {
                  //这里是重点
                  color: function(params) {
                    //注意，如果颜色太少的话，后面颜色不会自动循环，最好多定义几个颜色
                    var colorList = ['#c23531','#2f4554', '#61a0a8', '#d48265', '#91c7ae','#749f83', '#ca8622', '#61a0a8', '#d48265','#91c7ae'];
                    return colorList[params.dataIndex]
                  }
                }
              }
            }
          ]
        };

        if (option && typeof option === "object") {
          activeBarSurface.setOption(option, true);
        }
      },
      initActiveBarColorSeries(){
        var activeBarColorSeries = this.$echarts.init(document.getElementById('activeBarColorSeries'));
        var attrName = new Array();
        var searchAttrCount = new Array();
        for(var i=0;i<this.searchBarColorSeriesVos.length;i++){
          attrName[i]=this.searchBarColorSeriesVos[i].attrName;
          searchAttrCount[i]=this.searchBarColorSeriesVos[i].searchAttrCount;
        }
        let option = null;
        var titleName="";
        if(this.activeName==="first"){
          titleName="搜索历史月";
        }else if(this.activeName==="second"){
          titleName="搜索历史季度";
        }else if(this.activeName==="third"){
          titleName="搜索历史年";
        }
        option = {
          title: {
            text: '色系搜索排行',
            subtext: ''
          },
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'shadow'
            }
          },
          legend: {
            data: ['色系搜索']
          },
          grid: {
            left: '3%',
            right: '4%',
            bottom: '3%',
            containLabel: true
          },
          xAxis: {
            type: 'value',
            boundaryGap: [0, 0.01]
          },
          yAxis: {
            type: 'category',
            data: attrName
          },
          series: [
            {
              name: '色系搜索',
              type: 'bar',
              data: searchAttrCount,
              itemStyle: {
                normal: {
                  //这里是重点
                  color: function(params) {
                    //注意，如果颜色太少的话，后面颜色不会自动循环，最好多定义几个颜色
                    var colorList = ['#c23531','#2f4554', '#61a0a8', '#d48265', '#91c7ae','#749f83', '#ca8622', '#61a0a8', '#d48265','#91c7ae'];
                    return colorList[params.dataIndex]
                  }
                }
              }
            }
          ]
        };

        if (option && typeof option === "object") {
          activeBarColorSeries.setOption(option, true);
        }
      },
      initActiveBarProductSeries(){
        var activeBarProductSeries = this.$echarts.init(document.getElementById('activeBarProductSeries'));
        var attrName = new Array();
        var searchAttrCount = new Array();
        for(var i=0;i<this.searchBarProductSeriesVos.length;i++){
          attrName[i]=this.searchBarProductSeriesVos[i].attrName;
          searchAttrCount[i]=this.searchBarProductSeriesVos[i].searchAttrCount;
        }
        let option = null;
        var titleName="";
        if(this.activeName==="first"){
          titleName="搜索历史月";
        }else if(this.activeName==="second"){
          titleName="搜索历史季度";
        }else if(this.activeName==="third"){
          titleName="搜索历史年";
        }
        option = {
          title: {
            text: '产品系列搜索排行',
            subtext: ''
          },
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'shadow'
            }
          },
          legend: {
            data: ['产品系列搜索']
          },
          grid: {
            left: '3%',
            right: '4%',
            bottom: '3%',
            containLabel: true
          },
          xAxis: {
            type: 'value',
            boundaryGap: [0, 0.01]
          },
          yAxis: {
            type: 'category',
            data: attrName
          },
          series: [
            {
              name: '产品系列搜索',
              type: 'bar',
              data: searchAttrCount,
              itemStyle: {
                normal: {
                  //这里是重点
                  color: function(params) {
                    //注意，如果颜色太少的话，后面颜色不会自动循环，最好多定义几个颜色
                    var colorList = ['#c23531','#2f4554', '#61a0a8', '#d48265', '#91c7ae','#749f83', '#ca8622', '#61a0a8', '#d48265','#91c7ae'];
                    return colorList[params.dataIndex]
                  }
                }
              }
            }
          ]
        };

        if (option && typeof option === "object") {
          activeBarProductSeries.setOption(option, true);
        }
      },
      initActiveBarPerformanceId(){
        var activeBarPerformanceId = this.$echarts.init(document.getElementById('activeBarPerformanceId'));
        var attrName = new Array();
        var searchAttrCount = new Array();
        for(var i=0;i<this.searchBarPerformanceIdVos.length;i++){
          attrName[i]=this.searchBarPerformanceIdVos[i].attrName;
          searchAttrCount[i]=this.searchBarPerformanceIdVos[i].searchAttrCount;
        }
        let option = null;
        var titleName="";
        if(this.activeName==="first"){
          titleName="搜索历史月";
        }else if(this.activeName==="second"){
          titleName="搜索历史季度";
        }else if(this.activeName==="third"){
          titleName="搜索历史年";
        }
        option = {
          title: {
            text: '性能搜索排行',
            subtext: ''
          },
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'shadow'
            }
          },
          legend: {
            data: ['性能搜索']
          },
          grid: {
            left: '3%',
            right: '4%',
            bottom: '3%',
            containLabel: true
          },
          xAxis: {
            type: 'value',
            boundaryGap: [0, 0.01]
          },
          yAxis: {
            type: 'category',
            data: attrName
          },
          series: [
            {
              name: '性能搜索',
              type: 'bar',
              data: searchAttrCount,
              itemStyle: {
                normal: {
                  //这里是重点
                  color: function(params) {
                    //注意，如果颜色太少的话，后面颜色不会自动循环，最好多定义几个颜色
                    var colorList = ['#c23531','#2f4554', '#61a0a8', '#d48265', '#91c7ae','#749f83', '#ca8622', '#61a0a8', '#d48265','#91c7ae'];
                    return colorList[params.dataIndex]
                  }
                }
              }
            }
          ]
        };

        if (option && typeof option === "object") {
          activeBarPerformanceId.setOption(option, true);
        }
      },
      initActiveBarIndustryId(){
        var activeBarIndustryId = this.$echarts.init(document.getElementById('activeBarIndustryId'));
        var attrName = new Array();
        var searchAttrCount = new Array();
        for(var i=0;i<this.searchBarIndustryIdVos.length;i++){
          attrName[i]=this.searchBarIndustryIdVos[i].attrName;
          searchAttrCount[i]=this.searchBarIndustryIdVos[i].searchAttrCount;
        }
        let option = null;
        var titleName="";
        if(this.activeName==="first"){
          titleName="搜索历史月";
        }else if(this.activeName==="second"){
          titleName="搜索历史季度";
        }else if(this.activeName==="third"){
          titleName="搜索历史年";
        }
        option = {
          title: {
            text: '应用行业搜索排行',
            subtext: ''
          },
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'shadow'
            }
          },
          legend: {
            data: ['应用行业搜索']
          },
          grid: {
            left: '3%',
            right: '4%',
            bottom: '3%',
            containLabel: true
          },
          xAxis: {
            type: 'value',
            boundaryGap: [0, 0.01]
          },
          yAxis: {
            type: 'category',
            data: attrName
          },
          series: [
            {
              name: '应用行业搜索',
              type: 'bar',
              data: searchAttrCount,
              itemStyle: {
                normal: {
                  //这里是重点
                  color: function(params) {
                    //注意，如果颜色太少的话，后面颜色不会自动循环，最好多定义几个颜色
                    var colorList = ['#c23531','#2f4554', '#61a0a8', '#d48265', '#91c7ae','#749f83', '#ca8622', '#61a0a8', '#d48265','#91c7ae'];
                    return colorList[params.dataIndex]
                  }
                }
              }
            }
          ]
        };

        if (option && typeof option === "object") {
          activeBarIndustryId.setOption(option, true);
        }
      },
      initActiveBarGloss(){
        var activeBarGloss = this.$echarts.init(document.getElementById('activeBarGloss'));
        var attrName = new Array();
        var searchAttrCount = new Array();
        for(var i=0;i<this.searchBarGlossVos.length;i++){
          attrName[i]=this.searchBarGlossVos[i].attrName;
          searchAttrCount[i]=this.searchBarGlossVos[i].searchAttrCount;
        }
        let option = null;
        var titleName="";
        if(this.activeName==="first"){
          titleName="搜索历史月";
        }else if(this.activeName==="second"){
          titleName="搜索历史季度";
        }else if(this.activeName==="third"){
          titleName="搜索历史年";
        }
        option = {
          title: {
            text: '光泽搜索排行',
            subtext: ''
          },
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'shadow'
            }
          },
          legend: {
            data: ['光泽搜索']
          },
          grid: {
            left: '3%',
            right: '4%',
            bottom: '3%',
            containLabel: true
          },
          xAxis: {
            type: 'value',
            boundaryGap: [0, 0.01]
          },
          yAxis: {
            type: 'category',
            data: attrName
          },
          series: [
            {
              name: '光泽搜索',
              type: 'bar',
              data: searchAttrCount,
              itemStyle: {
                normal: {
                  //这里是重点
                  color: function(params) {
                    //注意，如果颜色太少的话，后面颜色不会自动循环，最好多定义几个颜色
                    var colorList = ['#c23531','#2f4554', '#61a0a8', '#d48265', '#91c7ae','#749f83', '#ca8622', '#61a0a8', '#d48265','#91c7ae'];
                    return colorList[params.dataIndex]
                  }
                }
              }
            }
          ]
        };

        if (option && typeof option === "object") {
          activeBarGloss.setOption(option, true);
        }
      },


      // 获取数据列表
      getDataList() {

        var params = {
          type: this.activeNameDiscountType
        }

        findSearchCount(params).then(({data}) => {
          if (data && data.code === 0) {
            this.dataList=data.list;
            this.initActiveDiscount();
          } else {
          }

        })
      },
      // 搜索方式统计趋势
      getSearchTypeTrendList() {
        var params = {
          type: this.searchTypeRankingTrend
        }

        findSearchTypeRankingTrend(params).then(({data}) => {
          if (data && data.code === 0) {
            this.searchTypeRankingTrendVos=data.searchTypeRankingTrendVos;
            this.initSearchTypeRankingTrend();
          } else {
          }

        })
      },

      // 搜索方式统计bar图
      getSearchTypeList() {

        var params = {
          type: this.searchTypeRanking
        }

        findSearchTypeRanking(params).then(({data}) => {
          if (data && data.code === 0) {
            this.searchTypeRankingVos=data.searchTypeRankingVos;
            this.initSearchTypeRanking();
          } else {
          }

        })
      },

      // 获取年份列表
      getDateList() {
        var params = {
          type: this.activeNameBar,
          attrId:1
        }
        findYearSearch(params).then(({data}) => {
          if (data && data.code === 0) {
            this.dateList=data.dateList;
          } else {
          }

        })
      },
      // 获取雷达数据列表
      getDataRadar() {
        var params = {
          year:this.searchYear,
          type: this.activeNameBar,
          attrId:1
        }
        findRadarSearch(params).then(({data}) => {
          if (data && data.code === 0) {
            this.searchRadarVos=data.searchBarHistoryVos;
            this.initActiveRadar();
          } else {
          }

        })
      },

      // 获取搜索条件数据列表
      getSearchConditionTrend() {
        var params = {
          year:this.searchYear,
          type: this.activeNameBar,
        }
        findSearchConditionTrend(params).then(({data}) => {
          if (data && data.code === 0) {
            this.searchConditionTrendVos=data.searchConditionTrendVos;
            this.initSearchConditionTrend();
          } else {
          }

        })
      },
      // 获取表面数据列表
      getDataBarSurface() {
        var params = {
          year:this.searchYear,
          type: this.activeNameBar,
          attrId:1
        }
        findBarSearch(params).then(({data}) => {
          if (data && data.code === 0) {
            this.searchBarSurfaceVos=data.searchBarHistoryVos;
            this.initActiveBarSurface();
          } else {
          }

        })
      },
      // 获取色系数据列表
      getDataBarColorSeries() {
        var params = {
          year:this.searchYear,
          type: this.activeNameBar,
          attrId:2
        }
        findBarSearch(params).then(({data}) => {
          if (data && data.code === 0) {
            this.searchBarColorSeriesVos=data.searchBarHistoryVos;
            this.initActiveBarColorSeries();
          } else {
          }

        })
      },
      // 获取产品系列数据列表
      getDataBarProductSeries() {
        var params = {
          year:this.searchYear,
          type: this.activeNameBar,
          attrId:3
        }
        findBarSearch(params).then(({data}) => {
          if (data && data.code === 0) {
            this.searchBarProductSeriesVos=data.searchBarHistoryVos;
            this.initActiveBarProductSeries();
          } else {
          }

        })
      },
      // 获取搜索类型排行
      getSearchTypeRanking() {
        var params = {
          type: this.activeNameBar,
        }
        findSearchTypeRanking(params).then(({data}) => {
          if (data && data.code === 0) {
            this.searchTypeRankingVos=data.searchTypeRankingVos;
            this.initSearchTypeRanking();
          } else {
          }

        })
      },
      // 获取产品性能数据列表
      getDataBarPerformanceId() {
        var params = {
          year:this.searchYear,
          type: this.activeNameBar,
          attrId:7
        }
        findBarSearch(params).then(({data}) => {
          if (data && data.code === 0) {
            this.searchBarPerformanceIdVos=data.searchBarHistoryVos;
            this.initActiveBarPerformanceId();
          } else {
          }

        })
      },
      // 获取行业(应用场景)数据列表
      getDataBarIndustryId() {
        var params = {
          year:this.searchYear,
          type: this.activeNameBar,
          attrId:4
        }
        findBarSearch(params).then(({data}) => {
          if (data && data.code === 0) {
            this.searchBarIndustryIdVos=data.searchBarHistoryVos;
            this.initActiveBarIndustryId();
          } else {
          }

        })
      },
      // 获取光泽数据列表
      getDataBarGloss() {
        var params = {
          year:this.searchYear,
          type: this.activeNameBar,
          attrId:9
        }
        findBarSearch(params).then(({data}) => {
          if (data && data.code === 0) {
            this.searchBarGlossVos=data.searchBarHistoryVos;
            this.initActiveBarGloss();
          } else {
          }

        })
      },

      handleClick(tab, event) {
        if(this.activeNameDiscount ==="first"){
          this.activeNameDiscountType="1"
        }else if(this.activeNameDiscount ==="second"){
          this.activeNameDiscountType="2"
        }
        this.getDataList();

      },
      // 搜索方式
      handleSearchType(){
        if(this.searchTypeRankingName ==="first"){
          this.searchTypeRanking="1"
        }else if(this.searchTypeRankingName ==="second"){
          this.searchTypeRanking="2"
        }else if(this.searchTypeRankingName ==="third"){
          this.searchTypeRanking="3"
        }
        this.getSearchTypeList();
      },
      // 搜索方式趋势图
      handleSearchTypeRankingTrend(){
        if(this.searchTypeRankingTrendName ==="first"){
          this.searchTypeRankingTrend="1"
        }else if(this.searchTypeRankingTrendName ==="second"){
          this.searchTypeRankingTrend="2"
        }
        this.getSearchTypeTrendList();
      },

      // 选择年份查询所有
      getDateSearch(value) {
        this.searchYear = value;
        this.activeNameBar = 1;
        this.surfaceActiveName = "first";
        this.colorSeriesActiveName = "first";
        this.productSeriesActiveName = "first";
        this.performanceIdActiveName = "first";
        this.industryIdActiveName = "first";
        this.glossActiveName = "first";

        this.getDataBarSurface();
        this.getDataBarColorSeries();
        this.getDataBarProductSeries();
        this.getDataBarIndustryId();
        this.getDataBarGloss();
        this.getDataBarPerformanceId();
        this.getDataRadar();
        this.getSearchConditionTrend();

      },
      // 雷达图
      handleRadarClick(tab, event){

        if(this.activeName ==="first"){
          this.activeNameBar="1"
        }else if(this.activeName ==="second"){
          this.activeNameBar="2"
        }else if(this.activeName ==="third"){
          this.activeNameBar="3"
        }
        this.getDataRadar();

      },
      // 产品表面
      handleBarSurfaceClick(tab, event){

        if(this.surfaceActiveName ==="first"){
          this.activeNameBar="1"
        }else if(this.surfaceActiveName ==="second"){
          this.activeNameBar="2"
        }else if(this.surfaceActiveName ==="third"){
          this.activeNameBar="3"
        }
        this.getDataBarSurface();

      },
      // 产品系列
      handleBarProductSeriesClick(tab, event){

        if(this.productSeriesActiveName ==="first"){
          this.activeNameBar="1"
        }else if(this.productSeriesActiveName ==="second"){
          this.activeNameBar="2"
        }else if(this.productSeriesActiveName ==="third"){
          this.activeNameBar="3"
        }
        this.getDataBarProductSeries();

      },
      // 产品性能
      handleBarPerformanceIdClick(tab, event){

        if(this.performanceIdActiveName ==="first"){
          this.activeNameBar="1"
        }else if(this.performanceIdActiveName ==="second"){
          this.activeNameBar="2"
        }else if(this.performanceIdActiveName ==="third"){
          this.activeNameBar="3"
        }
        this.getDataBarPerformanceId();

      },
      // 行业（应用场景）
      handleBarIndustryIdClick(tab, event){

        if(this.industryIdActiveName ==="first"){
          this.activeNameBar="1"
        }else if(this.industryIdActiveName ==="second"){
          this.activeNameBar="2"
        }else if(this.industryIdActiveName ==="third"){
          this.activeNameBar="3"
        }
        this.getDataBarIndustryId();

      },
      // 光泽
      handleBarGlossClick(tab, event){

        if(this.glossActiveName ==="first"){
          this.activeNameBar="1"
        }else if(this.glossActiveName ==="second"){
          this.activeNameBar="2"
        }else if(this.glossActiveName ==="third"){
          this.activeNameBar="3"
        }
        this.getDataBarGloss();

      },
      // 色系
      handleBarColorSeriesClick(tab, event){

        if(this.colorSeriesActiveName ==="first"){
          this.activeNameBar="1"
        }else if(this.colorSeriesActiveName ==="second"){
          this.activeNameBar="2"
        }else if(this.colorSeriesActiveName ==="third"){
          this.activeNameBar="3"
        }
        this.getDataBarColorSeries();
      }
    }
  }
</script>

<style>
  .el-row {
    margin-bottom: 20px;
  }


  .el-col {
    border-radius: 4px;
  }
  .bg-purple-dark {
    background: #99a9bf;
  }
  .bg-purple {
    background: #d3dce6;
  }
  .bg-purple-light {
    background: #e5e9f2;
  }
  .grid-content {
    border-radius: 4px;
    min-height: 36px;
  }
  .row-bg {
    padding: 10px 0;
    background-color: #f9fafc;
  }
  .margin{
    margin-top: 28%;
    height: 80px;
    margin-left: 25%;
  }
  .style{
    left: 329px;
    top: 184px;
    width: 200px;
    height: 100px;
    line-height: 20px;
    border-radius: 10px;
    text-align: center;
    /*border: 1px solid rgba(1, 1, 1, 1);*/
  }
  .white{
    color: white;
    background-color: rgb(255, 255, 255);
  }
  .red{
    color: white;
    background-color: rgb(235, 51, 51);
  }
  .blue{
    color: white;
    background-color: rgb(65, 158, 255);
  }
  .green{
    color: white;
    background-color: rgb(109, 184, 35);
  }
  .font{
    padding-top: 11%;
    font-size: 25px;
  }

</style>