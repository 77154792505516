<template>
  <el-dialog
    :title="!dataForm.id ? '新增' : '修改'"
    :close-on-click-modal="false"
    :visible.sync="visible"
    width="500px">
    <el-form :model="dataForm" :rules="dataRule"  size="small" ref="dataForm" @keyup.enter.native="dataFormSubmit()" label-width="80px"
     v-loading="dataLoading" style="width:100%;"  element-loading-text=""  element-loading-spinner="el-icon-loading"
    >
      <el-form-item label="选择品牌" prop="brandId">
        <el-select v-model="dataForm.brandId" size="small" placeholder="请选择品牌" style="width: 90%">
          <el-option
                  v-for="item in brandList"
                  :key="item.id"
                  :label="item.brandName"
                  :value="item.id"
                  size="small">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="样板数量" prop="quantity">
        <el-input v-model="dataForm.quantity" placeholder="填写样板数量" oninput="if(value>15)value=15" onkeyup="value=value.replace(/[^\d]/g,'')"  size="small" style="width: 90%"></el-input>
        <!-- <el-select v-model="dataForm.quantity" size="small" placeholder="请选择样板数量" style="width: 90%">
          <el-option
                  v-for="item in testList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                  size="small"
          ></el-option>
        </el-select> -->
      </el-form-item>
      <el-form-item label="样板价格" prop="price">
        <el-input v-model="dataForm.price" placeholder="价格"  size="small" style="width: 90%">
          <template slot="append">元</template>
        </el-input>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="visible = false" size="small">取消</el-button>
      <el-button type="primary" @click="dataFormSubmit()" size="small"  :disabled="dataLoading">确定</el-button>
    </span>
  </el-dialog>
</template>

<script>
  import {info,add,update} from '@/api/modules/samplepricerule'
  import {getBrand} from '@/api/modules/product'
  export default {
    data () {
      return {
        brandList: [],
        valuationConditionList:[],
          testList: [
              {
                  value: '1',
                  label: '1'
              },
              {
                  value: '2',
                  label: '2'
              },
              {
                  value: '3',
                  label: '3'
              },
              {
                  value: '4',
                  label: '4'
              },
          ],
        visible: false,
        dataLoading: false,
        dataForm: {
          id: 0,
          type: '',
          quantity: '',
          condition: '',
          price: '',
          brandId: '',
        },
        dataRule: {
          type: [
            { required: true, message: '类型(0.样板，1.样粉)不能为空', trigger: 'blur' }
          ],
          quantity: [
            { required: true, message: '样板数量不能为空', trigger: 'blur' }
          ],
          condition: [
            { required: true, message: '条件不能为空', trigger: 'blur' }
          ],
          price: [
            { required: true, message: '样板价格不能为空', trigger: 'blur' }
          ],
          brandId: [
            { required: true, message: '品牌不能为空', trigger: 'blur' }
          ]
        }
      }
    },
    methods: {
      mounted(){
        this.getBrandList();
      },
      //获取品牌列表
      getBrandList() {
        let params = {}
        getBrand(params).then(({data}) => {
          if (data && data.code === 0) {
            this.brandList = data.list
          } else {
            this.brandList = []
          }
        })
      },
      init (id) {
        this.dataForm.id = id || 0
        this.visible = true
        this.getBrandList();
        this.$nextTick(() => {
          this.$refs['dataForm'].resetFields()
          if (this.dataForm.id) {
            info({id:this.dataForm.id}).then(({data}) => {
              if (data && data.code === 0) {
                this.dataForm.brandId = data.sampleDetail.brandId
                this.dataForm.quantity = data.sampleDetail.quantity
                this.dataForm.price = data.sampleDetail.price
              }
            })
          }
        })
      },
      // 表单提交
      dataFormSubmit () {
        this.$refs['dataForm'].validate((valid) => {
          if (valid) {
            this.dataLoading = true;
            var params = {
              'priceruleId': this.dataForm.id || undefined,
              'num': this.dataForm.quantity,
              'price': this.dataForm.price,
              'brandId': this.dataForm.brandId
            }
            var tick = !this.dataForm.id ? add(params) : update(params)
            tick.then(({data}) => {
              if (data && data.code === 0) {
                this.$message({
                  message: '操作成功',
                  type: 'success',
                  duration: 1500,
                  onClose: () => {
                    this.visible = false;
                    this.dataLoading = false;
                    this.$emit('refreshDataList')
                  }
                })
              } else {
                this.$message.error(data.msg)
                this.dataLoading = false;
              }
            })
          }
        })
      }
    }
  }
</script>
