<template>
  <el-dialog
    :title="!dataForm.id ? '发货' : '发货'"
    :close-on-click-modal="false"
    :visible.sync="visible"
  width="500px">
    <el-form :model="dataForm"  size="small" ref="dataForm" @keyup.enter.native="dataFormSubmit()" label-width="25%"
     v-loading="dataLoading" style="width:100%;"  element-loading-text=""  element-loading-spinner="el-icon-loading"
    >
      <!--<el-form-item  label="单发样粉">
        <el-radio v-model="dataForm.radio" label="1">是</el-radio>
        <el-radio v-model="dataForm.radio" label="2">否</el-radio>
      </el-form-item>-->
      <!--<div v-if="dataForm.radio =='2'">-->
      <div>
        <p>产品编号：{{this.sampleOrderInfoVo.productNo}}</p>
        <p>样板数量：{{this.sampleOrderInfoVo.modelSum}}张</p>
        <p>样粉数量：{{this.sampleOrderInfoVo.materialSum}}kg</p>
        <p>收货人：{{this.sampleOrderInfoVo.userNameF}}</p>
        <p>联系方式：{{this.sampleOrderInfoVo.phoneF}}</p>
        <p>收货地址：{{this.sampleOrderInfoVo.addressF}}</p>
      </div>
      <el-divider></el-divider>
      <div>
        <el-form-item prop="" label="物流公司">
          <el-select v-model="dataForm.logisticsName" size="small"  style="width: 64%">
            <el-option
                    v-for="item in dataList"
                    :key="item.logisticsCode"
                    :label="item.logisticsName"
                    :value="item.logisticsCode"
                    size="small"
            ></el-option>
          </el-select>
          <!--<el-input v-model="dataForm.logisticsName" placeholder="请输入物流名称"></el-input>-->
        </el-form-item>
        <el-form-item prop="" label="物流单号">
          <el-input v-model="dataForm.logisticsOrderno" placeholder="请输入物流单号" style="width: 64%"></el-input>
        </el-form-item>
      </div>
      <!--<div v-if="dataForm.radio =='1'">
        <el-form-item prop="" label="样粉物流单号">
          <el-input v-model="dataForm.logisticsOrderno" placeholder="请输入物流单号"></el-input>
        </el-form-item>
        <el-form-item prop="" label="样粉物流公司">

          <el-input v-model="dataForm.logisticsName" placeholder="请输入物流名称"></el-input>
        </el-form-item>
      </div>-->
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="visible = false" size="small">取消</el-button>
      <el-button type="primary" @click="dataFormSubmit()" size="small"  :disabled="dataLoading">确定</el-button>
    </span>
  </el-dialog>
</template>

<script>
  import {info,sampleLogisticsUpdate} from '@/api/modules/sampleorder';
  import {getToken} from '@/utils/auth';
  import Tinymce from '@/components/Tinymce'
  import {listall} from '@/api/modules/salogistics'

  export default {
    components: {
      Tinymce
    },
    data () {
      return {
        action: `${process.env.VUE_APP_BASE_API}/sys/oss/upload?token=${getToken()}`,
        visible: false,
        dataLoading: false,
        factoryList:[],
          dataList:[],
        sampleOrderInfoVo:[],
        dataForm: {
          id: 0,
          orderId:'',
          logisticsOrderno:"",
          logisticsName:"",
          radio:'2',
        },
      }
    },
    methods: {
      init (orderId) {
          this.salogistics();
        this.dataForm.orderId = orderId || 0
        this.visible = true
        this.$nextTick(() => {
          this.$refs['dataForm'].resetFields()
          if (this.dataForm.orderId) {
            info({id:this.dataForm.orderId}).then(({data}) => {
              if (data && data.code === 0) {
                this.sampleOrderInfoVo = data.sampleOrderInfoVo
              }
            })
          }
        })
      },
        salogistics(){
            listall().then(({data})=>{
                if (data && data.code === 0) {
                    console.log(data.list);
                    this.dataList=data.list;
                }
            })
        },
      // 表单提交
      dataFormSubmit () {
        this.$refs['dataForm'].validate((valid) => {
          if (valid) {
          this.dataLoading = true;
            var params = {
              'orderId': this.dataForm.orderId,
              'logisticsOrderno': this.dataForm.logisticsOrderno,
              'logisticsName':this.dataForm.logisticsName,
            }
            var tick = !this.dataForm.id ? sampleLogisticsUpdate(params): sampleLogisticsUpdate(params)
            tick.then(({data}) => {
              if (data && data.code === 0) {
                this.$message({
                  message: '操作成功',
                  type: 'success',
                  duration: 1500,
                  onClose: () => {
                    this.visible = false;
                    this.dataLoading = false;
                    this.$emit('refreshDataList')
                  }
                })
              } else {
                this.$message.error(data.msg)
                this.dataLoading = false;
              }
            })
          }
        })
      }
    }
  }
</script>
