<template>
  <div class="app-container">
    <el-form :inline="true"  size="small" :model="dataForm" ref="dataForm"  @keyup.enter.native="restPage()">
      <el-form-item label="手机号">
        <el-input v-model="dataForm.mobile" placeholder="手机号" size="small" clearable></el-input>
      </el-form-item>
      <el-form-item label="搜索方式">
        <el-select v-model="dataForm.searchType" placeholder="搜索方式" clearable>
          <el-option label="全部" value=""></el-option>
          <el-option label="快捷搜索" value="0"></el-option>
          <el-option label="仪器查色" value="1"></el-option>
          <el-option label="色板查色" value="2"></el-option>
          <el-option label="以图查色" value="3"></el-option>
          <el-option label="色号快捷查色" value="4"></el-option>
          <el-option label="色库查色" value="5"></el-option>
          <el-option label="关键字搜索" value="6"></el-option>
          <el-option label="看产品详情" value="7"></el-option>
          <el-option label="相似色" value="8"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="标签">
        <el-select v-model="dataForm.memberLabelId" placeholder="标签" clearable>
          <el-option label="全部" value=""></el-option>
          <el-option
            v-for="item in memberLabelList"
            :key="item.productLabelId"
            :label="item.memberLabelName"
            :value="item.productLabelId">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="备注">
        <el-input v-model="dataForm.remarks" placeholder="备注" size="small" clearable></el-input>
      </el-form-item>
      <el-form-item label="日期">
        <div class="block">
          <el-date-picker
            v-model="dataForm.createDates"
            value-format="yyyy-MM-dd"
            type="daterange"
            range-separator="-"
            start-placeholder="开始日期"
            end-placeholder="结束日期">
          </el-date-picker>
        </div>
      </el-form-item>
      <el-form-item>
        <el-button @click="restPage()" type="primary" size="small">筛选</el-button>
        <el-button @click="reset('dataForm')"  size="small">清空</el-button>
        <el-button @click="exportExcelProduct()"  type="primary" size="small" :loading="loading">导出</el-button>
      </el-form-item>
    </el-form>
    <el-table
      :data="dataList"
      border
      size="small"
      v-loading="dataListLoading"
      @selection-change="selectionChangeHandle"
      style="width: 100%;">
      <el-table-column
        prop="mobile"
        header-align="center"
        align="center"
        label="手机号">
      </el-table-column>
      <el-table-column
        prop="memberLabel"
        header-align="center"
        align="center"
        label="标签">
        <template slot-scope="scope">
          <div type="text" v-for="(item,index) in scope.row.memberLabelRelationVoList" :key="index" size="small">{{item.memberLabelName}}</div>
        </template>
      </el-table-column>
      <el-table-column
        prop="remarks"
        header-align="center"
        align="center"
        label="备注">
      </el-table-column>
      <!-- <el-table-column
        prop="remarks"
        header-align="center"
        align="center"
        label="色板参数">
      </el-table-column> -->
      <el-table-column
        prop="searchTypeName"
        header-align="center"
        align="center"
        label="搜索方式">
      </el-table-column>
      <el-table-column
        prop="condition"
        header-align="center"
        align="center"
        label="搜索参数">
      </el-table-column>
      <el-table-column
        prop="screenCondition"
        header-align="center"
        align="center"
        label="筛选条件">
      </el-table-column>
      <el-table-column
        prop="searchDate"
        header-align="center"
        align="center"
        label="操作时间">
      </el-table-column>
    </el-table>
    <el-pagination
      size="small"
      @size-change="sizeChangeHandle"
      @current-change="currentChangeHandle"
      :current-page="pageIndex"
      :page-sizes="[10, 20, 50, 100]"
      :page-size="pageSize"
      :total="totalPage"
      layout="total, sizes, prev, pager, next, jumper">
    </el-pagination>
  </div>
</template>

<script>
  import {list,exportExcel} from '@/api/modules/membersearchhistory'
  import {getToken} from "@/utils/auth";
  import {getMemberLabelList} from '@/api/modules/memberlabel';
  export default {
    data () {
      return {
        dataForm: {
          mobile: '',
          searchType: '',
          memberLabelId: '',
          createDates:[],
          remarks:''
        },
        dataList: [],
        pageIndex: 1,
        pageSize: 10,
        totalPage: 0,
        dataListLoading: false,
        loading:false,
        dataListSelections: [],
        memberLabelList:[],
      }
    },
    created() {
      this.getMemberLabel()
      this.dataForm.createDates = [this.getBeforeDate(7),this.getBeforeDate(0)]
      this.getDataList()
    },
    activated () {
      this.getDataList()
    },
    methods: {
      //日期时间计算
      getBeforeDate(n) {
        var d = new Date();
        var year = d.getFullYear();
        var mon = d.getMonth() + 1;
        var day = d.getDate();
        if(day <= n) {
            if(mon > 1) {
                mon = mon - 1;
            } else {
                year = year - 1;
                mon = 12;
            }
        }
        d.setDate(d.getDate() - n);
        year = d.getFullYear();
        mon = d.getMonth() + 1;
        day = d.getDate();
        var s = ''
        s = year + "-" + (mon < 10 ? ('0' + mon) : mon) + "-" + (day < 10 ? ('0' + day) : day);
        return s;
      },
      //导出产品数据到excle
      exportExcelProduct(){
        this.loading = true;
        var arrOrderTime = this.dataForm.createDates;
        var startDate = null;
        var endDate = null;
        if (arrOrderTime && arrOrderTime.length > 0) {
          startDate = arrOrderTime[0];
          endDate = arrOrderTime[1];
        }
        var params = {
          'mobile': this.dataForm.mobile,
          'searchType': this.dataForm.searchType,
          'remarks': this.dataForm.remarks,
          'memberLabelId': this.dataForm.memberLabelId,
          'startDate': startDate ? startDate + ' 00:00:00' : '',
          'endDate': endDate ? endDate + ' 23:59:59' : ''
        }
        exportExcel(params).then(res => { // 处理返回的文件流
          var blob = new Blob([res.data], {type: "application/vnd.ms-excel;charset=utf-8"})
          var downloadElement = document.createElement('a');
          var href = window.URL.createObjectURL(blob); //创建下载的链接
          downloadElement.href = href;
          var name = this.format()
          downloadElement.download = name; //下载后文件名
          document.body.appendChild(downloadElement);
          downloadElement.click(); //点击下载
          document.body.removeChild(downloadElement); //下载完成移除元素
          setTimeout(() => {
          this.loading = false;
        }, 0);
        })
      },
      format(){
        var time = new Date();
        var y = time.getFullYear();
        var m = time.getMonth()+1;
        var d = time.getDate();
        var h = time.getHours();
        var mm = time.getMinutes();
        var s = time.getSeconds();
        return y+this.add0(m)+this.add0(d)+this.add0(h)+this.add0(mm)+this.add0(s)
      },
      add0(m){
        return m<10?'0'+m:m 
      },
      getMemberLabel(){
        //获取标签列表
        let params = {
        }
        getMemberLabelList(params).then(({data}) => {
          if (data && data.code === 0) {
            // console.log("data:"+data)
            this.memberLabelList = data.data
          } else {
            this.memberLabelList = []
          }
        })
      },
      //重新查询
      restPage() {
        this.pageIndex = 1;
        this.getDataList();
      },
      reset(dataForm) {
        this.dataForm.createDates = [this.getBeforeDate(7),this.getBeforeDate(0)]
       	this.dataForm.mobile = '',
        this.dataForm.searchType = '',
        this.dataForm.remarks = '',
        this.dataForm.memberLabelId = '',
        this.restPage();
      },
      // 获取数据列表
      getDataList () {
        console.log(this.dataForm.createDates)
        this.dataListLoading = true
        var arrOrderTime = this.dataForm.createDates;
        var startDate = null;
        var endDate = null;
        if (arrOrderTime && arrOrderTime.length > 0) {
          startDate = arrOrderTime[0];
          endDate = arrOrderTime[1];
        }
        var params = {
          page: this.pageIndex,
          limit: this.pageSize,
          'mobile': this.dataForm.mobile,
          'searchType': this.dataForm.searchType,
          'remarks': this.dataForm.remarks,
          'memberLabelId': this.dataForm.memberLabelId,
          'startDate': startDate ? startDate + ' 00:00:00' : '',
          'endDate': endDate ? endDate + ' 23:59:59' : '',
        }
        list(params).then(({data}) => {
          if (data && data.code === 0) {
            this.dataList = data.page.list
            this.totalPage = data.page.totalCount
          } else {
            this.dataList = []
            this.totalPage = 0
          }
          this.dataListLoading = false
        })
      },
      // 每页数
      sizeChangeHandle (val) {
        this.pageSize = val
        this.pageIndex = 1
        this.getDataList()
      },
      // 当前页
      currentChangeHandle (val) {
        this.pageIndex = val
        this.getDataList()
      },
      // 多选
      selectionChangeHandle (val) {
        this.dataListSelections = val
      }
    }
  }
</script>
