<template>
  <sides-lip ref="SidesLip">
    <div slot="soltForm">
      <el-form
        :model="dataForm"
        :rules="dataRule"
        ref="dataForm"
        @keyup.enter.native="dataFormSubmit()"
        label-width="120px"
        size="small"
      >
        <el-form-item label="品牌名称" prop="brandName">
          <el-input
            v-model="dataForm.brandName"
            placeholder="品牌名称"
            size="small"
            maxlength="50"
          ></el-input>
        </el-form-item>
        <el-form-item label="英文名称" prop="brandEname">
          <el-input
            v-model="dataForm.brandEname"
            placeholder="英文名称"
            size="small"
            maxlength="50"
          ></el-input>
        </el-form-item>
        <el-form-item label="品牌LOGO" prop="brandLogo">
          <el-upload
            class="avatar-uploader"
            v-bind:action="action"
            :show-file-list="false"
            :headers="headers"
            :on-success="handleAvatarSuccess"
            :before-upload="beforeAvatarUpload"
          >
            <img
              v-if="dataForm.brandLogo"
              :src="dataForm.brandLogo"
              class="avatar"
            />
            <i
              v-else
              class="el-icon-plus avatar-uploader-icon"
              title="点击上传"
            ></i>
          </el-upload>
        </el-form-item>
        <el-form-item label="验证级别" prop="verificationLevel">
          <el-radio-group
            v-model="dataForm.verificationLevel"
            size="small"
            placeholder="请选择验证级别"
          >
            <el-radio :label="1">不手机验证</el-radio>
            <el-radio :label="2">ip变更时手机验证</el-radio>
            <el-radio :label="3">每次登录手机验证</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="品牌套餐" prop="comboId">
          <el-select
            v-model="dataForm.comboId"
            size="small"
            placeholder="请选择品牌套餐"
          >
            <el-option
              v-for="item in comboList"
              :label="item.comboName"
              :value="item.id"
              :key="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="品牌状态" prop="state">
          <template>
            <el-radio v-model="dataForm.state" :label="0">正常</el-radio>
            <el-radio v-model="dataForm.state" :label="1">冻结</el-radio>
            <el-radio v-model="dataForm.state" :label="2">过期</el-radio>
          </template>
        </el-form-item>
        <el-form-item label="生效时间" prop="orderTime">
          <div class="block">
            <el-date-picker
              :disabled="dataForm.brandId ? true : false"
              v-model="dataForm.orderTime"
              value-format="yyyy-MM-dd"
              type="daterange"
              range-separator="至"
              start-placeholder="有效时间开始"
              end-placeholder="有效时间结束"
            ></el-date-picker>
          </div>
        </el-form-item>
        <el-form-item label="">
          <el-collapse @change="handleChange">
            <el-collapse-item>
              <span class="collapse-title" slot="title">查看套餐标准</span>
              <div style="display: flex">
                <el-table
                  :data="tableData"
                  border
                  style="width: 80%"
                  :span-method="objectSpanMethod"
                >
                  <el-table-column prop="title" label="对比项" width="180">
                  </el-table-column>
                  <el-table-column prop="V0" label="品牌精简版本" width="120">
                  </el-table-column>
                  <el-table-column
                    prop="V0value"
                    label="80000元/年"
                    width="130"
                  >
                  </el-table-column>
                  <el-table-column prop="V1" label="品牌基础版本" width="120">
                  </el-table-column>
                  <el-table-column
                    prop="V1value"
                    label="80000元/年"
                    width="130"
                  >
                  </el-table-column>
                  <el-table-column prop="V2" label="品牌高级版本" width="120">
                  </el-table-column>
                  <el-table-column
                    prop="V2value"
                    label="180000元/年"
                    width="130"
                  >
                  </el-table-column>
                </el-table>
              </div>
            </el-collapse-item>
          </el-collapse>
        </el-form-item>
        <el-form-item label="开通小程序" prop="isShow">
          <template>
            <el-radio v-model="dataForm.isShow" :label="false">不开通</el-radio>
            <el-radio v-model="dataForm.isShow" :label="true">开通</el-radio>
          </template>
        </el-form-item>
        <el-form-item label="是否热门" prop="isHot">
          <template>
            <el-radio v-model="dataForm.isHot" :label="false">否</el-radio>
            <el-radio v-model="dataForm.isHot" :label="true">是</el-radio>
          </template>
        </el-form-item>
        <el-form-item label="色差下限" prop="chromaticAberration">
          <el-input
            v-model="dataForm.chromaticAberration"
            placeholder="设置色差下限"
            @input="input()"
            size="small"
            maxlength="50"
          ></el-input>
        </el-form-item>
        <el-form-item label="排序" prop="sortNumber">
          <el-input
            v-model="dataForm.sortNumber"
            placeholder="设置排序"
            size="small"
            maxlength="50"
          ></el-input>
        </el-form-item>
        <el-form-item label="管理员姓名" prop="managerName">
          <el-input
            v-model="dataForm.managerName"
            placeholder="管理员姓名"
            size="small"
            maxlength="50"
          ></el-input>
        </el-form-item>
        <el-form-item label="管理员邮箱" prop="managerEmail">
          <el-input
            v-model="dataForm.managerEmail"
            placeholder="管理员邮箱"
            size="small"
            maxlength="50"
          ></el-input>
        </el-form-item>
        <el-form-item label="管理员手机号" prop="managerMobile">
          <el-input
            v-model="dataForm.managerMobile"
            placeholder="管理员手机号"
            size="small"
            maxlength="50"
          ></el-input>
        </el-form-item>
        <el-form-item label="品牌信息" prop="description">
          <el-input
            type="textarea"
            :rows="5"
            v-model="dataForm.description"
            placeholder="品牌信息"
            maxlength="100"
            size="small"
          >
          </el-input>
        </el-form-item>
      </el-form>
    </div>
    <div slot="soltFooter">
      <el-button @click="sideClose">取消</el-button>
      <el-button type="primary" :loading="dataLoading" @click="dataFormSubmit()"
        >确定</el-button
      >
    </div>
  </sides-lip>
</template>

<script>
import { getBrandApi, addOrUpdateApi } from "@/api/modules/brand";
import { queryComboApi } from "@/api/modules/combo";
import { getToken } from "@/utils/auth";
import { deepClone } from "@/utils/index";
import Tinymce from "@/components/Tinymce";
import SidesLip from "@/components/SidesLip";
export default {
  components: {
    Tinymce,
    SidesLip,
  },
  data() {
    return {
      visible: false,
      action: `/api/m/file/upload`,
      headers: { Authorization: getToken() },
      // 套餐列表
      comboList: [],
      dataLoading: false,
      dataForm: {
        brandId: 0,
        brandName: "",
        brandEname: "",
        brandLogo: "",
        description: "",
        delFlag: "",
        createBy: "",
        createDate: "",
        updateBy: "",
        updateDate: "",
        remarks: "",
        imageUrl: "",
        orderTime: [],
        state: 0,
        verificationLevel: 1,
        startTime: "",
        endTime: "",
        managerName: "",
        managerMobile: "",
        managerEmail: "",
        comboId: "",
        isShow: true,
        isHot: false,
        chromaticAberration: 10,
        sortNumber: 1,
      },
      dataRule: {
        brandName: [
          { required: true, message: "品牌名称不能为空", trigger: "blur" },
        ],
        brandEname: [
          { required: true, message: "英文名称不能为空", trigger: "blur" },
        ],
        comboId: [
          { required: true, message: "品牌套餐不能为空", trigger: "change" },
        ],
        orderTime: [
          { required: true, message: "生效时间不能为空", trigger: "blur" },
        ],
      },
      tableData: [
        {
          title: "功能对比",
          V0: "",
          V0value: "",
          V1: "搜索",
          V1value: "搜索产品",
          V2: "搜索",
          V2value: "搜索产品",
        },
        {
          title: "",
          V0: "",
          V0value: "",
          V1: "",
          V1value: "色差比较",
          V2: "",
          V2value: "色差比较",
        },
        {
          title: "",
          V0: "",
          V0value: "",
          V1: "",
          V1value: "搜索历史",
          V2: "",
          V2value: "搜索历史",
        },
        {
          title: "",
          V0: "",
          V0value: "",
          V1: "",
          V1value: "色号查询",
          V2: "",
          V2value: "色号查询",
        },
        {
          title: "",
          V0: "",
          V0value: "",
          V1: "",
          V1value: "搜色统计",
          V2: "",
          V2value: "搜色统计",
        },
        {
          title: "",
          V0: "账号管理",
          V0value: "品牌账号",
          V1: "账号管理",
          V1value: "品牌账号",
          V2: "账号管理",
          V2value: "品牌账号",
        },
        {
          title: "",
          V0: "",
          V0value: "工厂账号",
          V1: "",
          V1value: "工厂账号",
          V2: "",
          V2value: "工厂账号",
        },
        {
          title: "",
          V0: "",
          V0value: "员工账号",
          V1: "",
          V1value: "员工账号",
          V2: "",
          V2value: "员工账号",
        },
        {
          title: "",
          V0: "",
          V0value: "",
          V1: "色差仪管理",
          V1value: "色差仪管理",
          V2: "色差仪管理",
          V2value: "色差仪管理",
        },
        {
          title: "",
          V0: "",
          V0value: "",
          V1: "配方管理",
          V1value: "类别管理",
          V2: "配方管理",
          V2value: "类别管理",
        },
        {
          title: "",
          V0: "",
          V0value: "",
          V1: "",
          V1value: "材料管理",
          V2: "",
          V2value: "材料管理",
        },
        {
          title: "",
          V0: "",
          V0value: "",
          V1: "",
          V1value: "配方管理",
          V2: "",
          V2value: "配方管理",
        },
        {
          title: "",
          V0: "产品管理",
          V0value: "产品库",
          V1: "产品管理",
          V1value: "产品库",
          V2: "产品管理",
          V2value: "产品库",
        },
        {
          title: "",
          V0: "",
          V0value: "生产产品库",
          V1: "",
          V1value: "生产产品库",
          V2: "",
          V2value: "生产产品库",
        },
        {
          title: "",
          V0: "",
          V0value: "实验产品库",
          V1: "",
          V1value: "实验产品库",
          V2: "",
          V2value: "实验产品库",
        },
        {
          title: "",
          V0: "",
          V0value: "属性管理",
          V1: "",
          V1value: "属性管理",
          V2: "",
          V2value: "属性管理",
        },
        {
          title: "",
          V0: "",
          V0value: "属性值管理",
          V1: "",
          V1value: "属性值管理",
          V2: "",
          V2value: "属性值管理",
        },
        {
          title: "",
          V0: "",
          V0value: "",
          V1: "",
          V1value: "",
          V2: "",
          V2value: "性能组管理",
        },
        {
          title: "",
          V0: "",
          V0value: "",
          V1: "",
          V1value: "",
          V2: "",
          V2value: "固化条件管理",
        },
        {
          title: "",
          V0: "",
          V0value: "",
          V1: "",
          V1value: "",
          V2: "打样管理",
          V2value: "订单管理",
        },
        {
          title: "",
          V0: "",
          V0value: "",
          V1: "",
          V1value: "",
          V2: "",
          V2value: "未分配订单",
        },
        {
          title: "",
          V0: "",
          V0value: "",
          V1: "",
          V1value: "",
          V2: "",
          V2value: "待验证订单",
        },
        {
          title: "",
          V0: "",
          V0value: "",
          V1: "",
          V1value: "",
          V2: "",
          V2value: "待结单订单",
        },
        {
          title: "",
          V0: "生产产品",
          V0value: "20000个",
          V1: "生产产品",
          V1value: "20000个",
          V2: "生产产品",
          V2value: "50000个",
        },
        {
          title: "",
          V0: "实验产品",
          V0value: "20000个",
          V1: "实验产品",
          V1value: "20000个",
          V2: "实验产品",
          V2value: "50000个",
        },
        {
          title: "色差仪",
          V0: "色差仪",
          V0value: "0台",
          V1: "色差仪",
          V1value: "1台",
          V2: "色差仪",
          V2value: "3台",
        },
        {
          title: "称料一体机",
          V0: "——",
          V0value: "",
          V1: "——",
          V1value: "",
          V2: "称料一体机",
          V2value: "三台",
        },
        {
          title: "账号",
          V0: "品牌账号",
          V0value: "1个",
          V1: "品牌账号",
          V1value: "1个",
          V2: "品牌账号",
          V2value: "1个",
        },
        {
          title: "",
          V0: "工厂账号",
          V0value: "1个",
          V1: "工厂账号",
          V1value: "1个",
          V2: "工厂账号",
          V2value: "3个",
        },
        {
          title: "",
          V0: "员工账号",
          V0value: "10个",
          V1: "员工账号",
          V1value: "10个",
          V2: "员工账号",
          V2value: "30个",
        },
      ],
    };
  },
  methods: {
    async init(id) {
      this.dataForm.brandId = id || undefined;
      this.visible = true;
      this.$refs.SidesLip.init(
        id ? "修改" : "新增",
        "700px",
        "block"
      );
      await this.getComboList();
      this.$nextTick(() => {
        this.$refs["dataForm"].resetFields();
        if (id) {
          getBrandApi(id).then((response) => {
            this.dataForm = response;
            this.dataForm.orderTime = [response.startTime, response.endTime];
            this.dataForm.chromaticAberration = response.chromaticAberration
              ? response.chromaticAberration
              : 10;
          });
        }
      });
    },
    // 获取套餐列表
    async getComboList() {
      const { records } = await queryComboApi();
      this.comboList = records;
    },

    input() {
      this.dataForm.chromaticAberration =
        this.dataForm.chromaticAberration.replace(/[^\d.]/g, ""); // 清除“数字”和“.”以外的字符
      this.dataForm.chromaticAberration =
        this.dataForm.chromaticAberration.replace(/\.{2,}/g, "."); //只保留第一个. 清除多余的
      this.dataForm.chromaticAberration = this.dataForm.chromaticAberration
        .replace(".", "$#$")
        .replace(/\./g, "")
        .replace("$#$", ".");
      this.dataForm.chromaticAberration =
        this.dataForm.chromaticAberration.replace(
          /^(\-)*(\d+)\.(\d\d).*$/,
          "$1$2.$3"
        ); //只能输入两个小数
      if (
        this.dataForm.chromaticAberration.indexOf(".") < 0 &&
        this.dataForm.chromaticAberration != ""
      ) {
        //以上已经过滤，此处控制的是如果没有小数点，首位不能为类似于 01、02的金额
        this.dataForm.chromaticAberration = parseFloat(
          this.dataForm.chromaticAberration
        );
      }
    },
    format() {
      var time = new Date();
      var y = time.getFullYear();
      var m = time.getMonth() + 1;
      var d = time.getDate();
      var h = time.getHours();
      var mm = time.getMinutes();
      var s = time.getSeconds();
      return (
        y.toString() +
        "-" +
        this.add0(m).toString() +
        "-" +
        this.add0(d).toString() +
        " " +
        this.add0(h).toString() +
        ":" +
        this.add0(mm).toString() +
        ":" +
        this.add0(s).toString()
      );
    },
    add0(m) {
      return m < 10 ? "0" + m : m;
    },
    // 表单提交
    dataFormSubmit() {
      this.$refs["dataForm"].validate(async (valid) => {
        if (valid) {
          this.dataLoading = true;
          var arrOrderTime = this.dataForm.orderTime;
          var startDate = null;
          var endDate = null;
          if (arrOrderTime && arrOrderTime.length > 0) {
            startDate = arrOrderTime[0];
            endDate = arrOrderTime[1];
            var timeStr = this.format();
            var nowtime = new Date(timeStr).getTime();
            var formatTimeS = new Date(endDate + " 23:59:59").getTime();
            console.log(timeStr, endDate);
            console.log(nowtime - formatTimeS > 0);
            if (nowtime - formatTimeS > 0) {
              this.$message({
                message: "服务有效期不能早于当前时间",
                type: "error",
                duration: 1500,
              });
              this.dataLoading = false;
            } else {
              let params = deepClone(this.dataForm);
              (params.startTime = this.dataForm.brandId
                ? startDate
                : startDate + " 00:00:00"),
                (params.endTime = this.dataForm.brandId
                  ? endDate
                  : endDate + " 23:59:59"),
                (params.chromaticAberration = this.dataForm.chromaticAberration
                  ? this.dataForm.chromaticAberration
                  : 10);
              await addOrUpdateApi(params)
                .then(() => {
                  this.$baseMessage("操作成功", "success");
                  this.$emit("refreshDataList");
                  this.visible = false;
                  this.sideClose();
                })
                .finally(() => {
                  this.dataLoading = false;
                });
            }
          }
        }
      });
    },
    sideClose() {
      this.$refs.SidesLip.sideClose();
    },
    handleChange(val) {
      console.log(val);
    },
    objectSpanMethod({ row, column, rowIndex, columnIndex }) {},
    //图片上传
    handleAvatarSuccess(res, file, type) {
      if (res.data) {
        this.dataForm.brandLogo = res.data;
      }
    },
    beforeAvatarUpload(file) {
      const isJPG =
        file.type === "image/jpeg" ||
        file.type === "image/bmp" ||
        file.type === "image/png";
      const isLt2M = file.size / 1024 / 1024 < 8;
      if (!isJPG) {
        this.$message.error("上传图片格式不支持!");
      }
      if (!isLt2M) {
        this.$message.error("上传图片大小不能超过 8MB!");
      }
      return isJPG && isLt2M;
    },
  },
};
</script>
<style>
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
</style>
