<template>
    <el-dialog
            class="proDialog"
            :title="!dataForm.id ? '查看账号' : '查看账号'"
            :close-on-click-modal="false"
            :visible.sync="visible"
            width="500px"
            @click="close()">
        <el-form :model="dataForm" :rules="dataRule" size="small" ref="dataForm"
                 label-width="140px"
                 v-loading="dataLoading" style="width:100%;" element-loading-text=""
                 element-loading-spinner="el-icon-loading"
        >
        <el-form-item label="颜色管理系统链接：" prop="" style="margin-bottom: 1px;">
          <label>https://www.colorkite.com.cn/#/login</label>
        </el-form-item>
        <el-form-item label="品牌管理员用户名：" prop="" style="margin-bottom: 1px;">
          <label>{{dataForm.brandEname + 'Admin'}}</label>
        </el-form-item>
        <el-form-item label="品牌管理员姓名：" prop="" style="margin-bottom: 1px;">
          <label>{{dataForm.managerName}}</label>
        </el-form-item>
        <el-form-item label="品牌管理员邮箱：" prop="" style="margin-bottom: 1px;">
          <label>{{dataForm.managerEmail}}</label>
        </el-form-item>
        <el-form-item label="品牌管理员手机号：" prop="" style="margin-bottom: 1px;">
          <label>{{dataForm.managerMobile}}</label>
        </el-form-item>
        <el-form-item label="账号默认密码：" prop="" style="margin-bottom: 1px;">
          <label>123456</label>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="close()" size="small">返回</el-button>
        <!-- <el-button type="primary" @click="dataFormSubmit()" size="small" :disabled="dataLoading">发送邮件</el-button> -->
      </span>
    </el-dialog>
</template>

<script>
  import {info,add,update} from '@/api/modules/brand';
  import {getToken} from '@/utils/auth';
  import Tinymce from '@/components/Tinymce';

  export default {
    components: {
      Tinymce
    },
    data () {
      return {
        visible: false,
        dataLoading: false,
        dataForm: {
          id: 0,
          brandName: '',
          brandEname: '',
          brandLogo: '',
          managerName: '',
          managerMobile: '',
          managerEmail: '',
          userRoleCode: ''
        },
        dataRule: {
        }
      }
    },
    methods: {
      init (id) {
        // this.dataForm.id = id
        this.visible = true;
        this.dataForm = {
          id:id,
          brandName: '',
          brandEname: '',
          brandLogo: '',
          managerName: '',
          managerMobile: '',
          managerEmail: '',
          userRoleCode: ''
        },
        this.$nextTick(() => {
          if (this.dataForm.id) {
            info({id:this.dataForm.id}).then(({data}) => {
              if (data && data.code === 0) {
                this.dataForm.brandName = data.brand.brandName
                this.dataForm.brandEname = data.brand.brandEname
                this.dataForm.brandLogo = data.brand.brandLogo
                this.dataForm.userRoleCode = data.brand.userRoleCode
                this.dataForm.managerName = data.brand.managerName
                this.dataForm.managerMobile = data.brand.managerMobile
                this.dataForm.managerEmail = data.brand.managerEmail
              }
            })
          }
        })
      },
      close(){
        this.visible = false;
        this.dataForm = {
          id: 0,
          brandName: '',
          brandEname: '',
          brandLogo: '',
          managerName: '',
          managerMobile: '',
          managerEmail: '',
          userRoleCode: ''
        }
      },
    }
  }
</script>