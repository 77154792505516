<template>
  <div class="app-container">
    <el-form :inline="true"  size="small" :model="dataForm" ref="dataForm"  @keyup.enter.native="restPage()">
      <el-form-item prop="sampleOrderNo">
        <el-input v-model="dataForm.sampleOrderNo" placeholder="打样订单号" size="small" clearable></el-input>
      </el-form-item>
      <el-form-item prop="state">
        <el-select v-model="dataForm.state" size="small" placeholder="请选择售后状态" @change="restPage()">
          <el-option
                  v-for="item in testList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                  size="small"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item prop="daterange">
        <el-date-picker type="daterange" v-model="dataForm.daterange" range-separator="~" format="yyyy-MM-dd" value-format="yyyy-MM-dd HH:mm:ss"
          :editable="false" start-placeholder="下单开始时间" end-placeholder="下单结束时间"></el-date-picker>
      </el-form-item>
      <el-form-item>
        <el-button @click="restPage()" size="small">查询</el-button>
        <el-button @click="reset('dataForm')"  size="small">清空</el-button>
<!--        <el-button v-if="isAuth('member:sampleaftersale:save')" type="primary" @click="addOrUpdateHandle()" size="small">新增</el-button>
        <el-button v-if="isAuth('member:sampleaftersale:delete')" type="danger" @click="deleteHandle()" :disabled="dataListSelections.length <= 0" size="small">批量删除</el-button>-->
      </el-form-item>
    </el-form>
    <el-table
      :data="dataList"
      border
      size="small"
      v-loading="dataListLoading"
      @selection-change="selectionChangeHandle"
      style="width: 100%;">
      <el-table-column
        type="selection"
        fixed="left"
        header-align="center"
        align="center"
        width="50">
      </el-table-column>
      <!--<el-table-column
        prop="id"
        header-align="center"
        align="center"
        label="售后订单id">
      </el-table-column>-->
      <!--<el-table-column
        prop="sampleOrderId"
        header-align="center"
        align="center"
        label="打样订单id">
      </el-table-column>-->
      <el-table-column
        prop="sampleOrderNo"
        header-align="center"
        align="center"
        label="打样订单编号">
      </el-table-column>
      <el-table-column
              prop="aftersaleType"
              header-align="center"
              align="center"
              label="售后类型">
        <template slot-scope="scope">
          <span v-if="scope.row.aftersaleType == 0">退款</span>
          <span v-if="scope.row.aftersaleType == 1">退款退货</span>
          <span v-if="scope.row.aftersaleType == 2">重新打样</span>
        </template>
      </el-table-column>
      <!--<el-table-column
        header-align="center"
        align="center"
        label="退款金额">
        <template slot-scope="scope">
          {{scope.row.returnMoney}}元
        </template>
      </el-table-column>-->
      <el-table-column
        prop="reason"
        header-align="center"
        align="center"
        label="售后原因">
      </el-table-column>
      <el-table-column
        prop="returnPic"
        header-align="center"
        align="center"
        label="售后凭证">
        <!-- 图片的显示 -->
        <template slot-scope="scope">
          <img :src="scope.row.returnPic" min-width="70" height="70"/>
        </template>
      </el-table-column>
     <el-table-column
        prop="description"
        header-align="center"
        align="center"
        label="备注">
      </el-table-column>
      <el-table-column
        prop="state"
        header-align="center"
        align="center"
        label="售后状态">
      </el-table-column>
      <!--<el-table-column
        prop="customerReply"
        header-align="center"
        align="center"
        label="客服处理">
      </el-table-column>-->
      <!--<el-table-column
        prop="delFlag"
        header-align="center"
        align="center"
        label="是否删除（0.未删除，1.删除）">
      </el-table-column>-->
      <!--<el-table-column
        prop="createBy"
        header-align="center"
        align="center"
        label="下单人">
      </el-table-column>-->
      <el-table-column
              prop="customerReply"
              header-align="center"
              align="center"
              label="客服处理备注">
      </el-table-column>
      <el-table-column
        prop="createDate"
        header-align="center"
        align="center"
        label="下单时间">
      </el-table-column>
      <!--<el-table-column
        prop="updateBy"
        header-align="center"
        align="center"
        label="修改人">
      </el-table-column>
      <el-table-column
        prop="updateDate"
        header-align="center"
        align="center"
        label="修改时间">
      </el-table-column>-->
      <el-table-column
        fixed="right"
        header-align="center"
        align="center"
        width="150"
        label="操作">
        <template slot-scope="scope">
          <el-button type="text" size="small" @click="addOrUpdateHandle(scope.row.id)">订单详情</el-button>
          <el-button type="text" size="small" @click="afterDetail(scope.row)" v-if="scope.row.state !='申请售后'">售后详情</el-button>
          <!--<el-button type="text" size="small" @click="deleteHandle(scope.row.id)">删除</el-button>&ndash;&gt;-->
          <el-button type="text" size="small" @click="check(scope.row)" v-if="scope.row.state =='申请售后'">审核</el-button>
         <!-- <el-button type="text" size="small" @click="take(scope.row.id)" v-if="scope.row.state !='已取消'&&scope.row.state !='待退款'&&scope.row.state !='已完成'&&scope.row.state !='申请售后'">收货</el-button>-->
         <!-- <el-button type="text" size="small"  @click="checkLogisticsHandle(scope.row.id)" v-if="scope.row.orederState !=15&&scope.row.orederState !=16&&scope.row.orederState !=17">物流查询</el-button>-->
         <!-- <el-button type="text" size="small" @click="refund(scope.row.id)" v-if="scope.row.state !='已取消'&&scope.row.state !='待退货'&&scope.row.state !='已完成'&&scope.row.state !='申请售后'">退款</el-button>-->
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      size="small"
      @size-change="sizeChangeHandle"
      @current-change="currentChangeHandle"
      :current-page="pageIndex"
      :page-sizes="[10, 20, 50, 100]"
      :page-size="pageSize"
      :total="totalPage"
      layout="total, sizes, prev, pager, next, jumper">
    </el-pagination>
    <!-- 弹窗, 新增 / 修改 -->
    <add-or-update v-if="addOrUpdateVisible" ref="addOrUpdate" @refreshDataList="getDataList"></add-or-update>
    <!--审核弹窗-->
    <check-page v-if="checkVisible" ref="checkPage" @refreshDataList="getDataList"></check-page>
    <!--物流查询-->
    <check-logistics v-if="checkLogisticsVisible" ref="checkLogistics" @refreshDataList="getDataList"></check-logistics>
    <!--退款审核-->
    <check-refund v-if="checkRefundVisible" ref="checkRefund" @refreshDataList="getDataList"></check-refund>
    <!--退款退货审核-->
    <refundOrReturns v-if="refundOrReturnsVisible" ref="refundOrReturns" @refreshDataList="getDataList"></refundOrReturns>
    <!--重新打样审核-->
    <check-sample v-if="checkSampleVisible" ref="checkSample" @refreshDataList="getDataList"></check-sample>
      <!--退款详情-->
      <after-refund v-if="afterRefundVisible" ref="afterRefund" @refreshDataList="getDataList"></after-refund>
      <!--退货退款详情-->
      <after-refundOrReturns v-if="afterRefundOrReturnsVisible" ref="afterRefundOrReturns" @refreshDataList="getDataList"></after-refundOrReturns>
      <!--重新打样详情-->
      <after-sample v-if="afterSampleVisible" ref="afterSample" @refreshDataList="getDataList"></after-sample>
  </div>
</template>

<script>
  import {list,del,refundOrTake,ruturnTake} from '@/api/modules/sampleaftersale'
  import AddOrUpdate from './add-or-update'
  import CheckPage from './checkPage'
  import CheckLogistics from "./check-logistics";
  import CheckRefund from "./check-refund";//退款审核
  import RefundOrReturns from "./check-refundOrReturns";//退款退货
  import CheckSample from "./check-sample";//重新打样
  import AfterRefund from "./after-refund";//退款详情
  import AfterRefundOrReturns from "./after-refundOrReturns";//退款退货详情
  import AfterSample from "./after-sample";//重新打样详情
  export default {
    data () {
      return {
          testList: [
              {
                  value: '0',
                  label: '申请售后'
              },
              {
                  value: '1',
                  label: '已取消'
              },
              {value: '2',
                  label: '待退货'
              },
              {value: '3',
                  label: '待退款'
              },
              {value: '4',
                  label: '已完成'
              },
              {value: '5',
                  label: '重新打样'
              }
          ],
        dataForm: {
            sampleOrderNo: '',
            state:'',
          daterange:[]
        },
        dataList: [],
        pageIndex: 1,
        pageSize: 10,
        totalPage: 0,
        dataListLoading: false,
        dataListSelections: [],
          checkVisible:false,
          checkLogisticsVisible: false,
          checkRefundVisible:false,//审核退款
          refundOrReturnsVisible:false,//退款退货申请
          checkSampleVisible:false,//重新打样
          afterRefundVisible:false,//退款详情
          afterRefundOrReturnsVisible:false,//退货退款详情
          afterSampleVisible:false,//重新打样详情
        addOrUpdateVisible: false
      }
    },
    components: {
        CheckLogistics,
      AddOrUpdate,
        CheckPage,
        CheckRefund,
        RefundOrReturns,
        CheckSample,
        AfterRefund,
        AfterRefundOrReturns,
        AfterSample,
    },
    activated () {
      this.getDataList()
    },
      mounted(){
          this.getDataList()
      },
    methods: {
        //订单详情跳转
        findDetail(id){
            this.$router.push({ path: "/mall/goods/main/detail", query:{"orderId":id} });
        },
        //查看物流信息
        checkLogisticsHandle(id) {
            this.checkLogisticsVisible = true
            this.$nextTick(() => {
                this.$refs.checkLogistics.init(id)
            })
        },
        //售后详情
        afterDetail(row){
            //aftersaleType(售后类型)判断弹出的详情页面
            //退款详情
            if (row.aftersaleType == 0) {
                this.afterRefundVisible = true
                this.$nextTick(() => {
                    this.$refs.afterRefund.init(row.id)
                })
            }
            //退款退货详情
            if (row.aftersaleType == 1) {
                this.afterRefundOrReturnsVisible = true
                this.$nextTick(() => {
                    this.$refs.afterRefundOrReturns.init(row.id)
                })
            }
            //重新打样详情
            if (row.aftersaleType == 2) {
                this.afterSampleVisible = true
                this.$nextTick(() => {
                    this.$refs.afterSample.init(row.id)
                })
            }
        },
        //审核按钮
        check(row){
            //根据aftersaleType(售后类型)判断弹出的审核页面
            //退款审核
            if (row.aftersaleType == 0) {
                this.checkRefundVisible = true
                this.$nextTick(() => {
                    this.$refs.checkRefund.init(row.id)
                })
            }
            //退款退货审核
            if(row.aftersaleType == 1){
                this.refundOrReturnsVisible = true
                this.$nextTick(() => {
                    this.$refs.refundOrReturns.init(row.id)
                })
            }
            //重新打样审核
            if (row.aftersaleType == 2) {
                this.checkSampleVisible = true
                this.$nextTick(() => {
                    this.$refs.checkSample.init(row.id)
                })
            }
        },
        //退款按钮
        refund(id){
            let params = {
                aftersaleId:id,
            }
            //console.log(params)
            ruturnTake(params).then(({data}) => {
                if (data && data.code === 0) {
                    // this.$message.error(data.msg);
                    this.restPage();
                } else {
                    this.$message.error(data.msg);
                }
            })
        },
        //收货按钮
        take(id){
            let params = {
                aftersaleId:id,
            }
            refundOrTake(params).then(({data}) => {
                if (data && data.code === 0) {
                    // this.$message.error(data.msg);
                    this.restPage();
                } else {
                    this.$message.error(data.msg);
                }
            })
        },
      //重新查询
      restPage() {
        this.pageIndex = 1;
        this.getDataList();
      },
      reset(dataForm) {
        this.$refs.dataForm.resetFields();
          this.dataForm.state = '';
      //this.testList=[];
        this.restPage();
      },
      // 获取数据列表
      getDataList () {
        this.dataListLoading = true
          var arrOrderTime = this.dataForm.daterange;
        var startDate = null;
        var endDate = null;
          if (arrOrderTime.length > 0) {
              startDate = arrOrderTime[0];
              endDate = arrOrderTime[1];
          }
        let params = {
          page: this.pageIndex,
          limit: this.pageSize,
            sampleOrderNo: this.dataForm.sampleOrderNo,
            state: this.dataForm.state,
            startDate: startDate,
            endDate: endDate
        }
        list(params).then(({data}) => {
          if (data && data.code === 0) {
            this.dataList = data.page.list
            this.totalPage = data.page.totalCount
          } else {
            this.dataList = []
            this.totalPage = 0
          }
          this.dataListLoading = false
        })
      },
      // 每页数
      sizeChangeHandle (val) {
        this.pageSize = val
        this.pageIndex = 1
        this.getDataList()
      },
      // 当前页
      currentChangeHandle (val) {
        this.pageIndex = val
        this.getDataList()
      },
      // 多选
      selectionChangeHandle (val) {
        this.dataListSelections = val
      },
      // 新增 / 修改
      addOrUpdateHandle (id) {
        this.addOrUpdateVisible = true
        this.$nextTick(() => {
          this.$refs.addOrUpdate.init(id)
        })
      },
      // 删除
      deleteHandle (id) {
        var ids = id ? [id] : this.dataListSelections.map(item => {
          return item.id
        })
        this.$confirm(`确定对[id=${ids.join(',')}]进行[${id ? '删除' : '批量删除'}]操作?`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          del(ids).then(({data}) => {
            if (data && data.code === 0) {
              this.$message({
                message: '操作成功',
                type: 'success',
                duration: 1500,
                onClose: () => {
                  this.getDataList()
                }
              })
            } else {
              this.$message.error(data.msg)
            }
          })
        })
      }
    }
  }
</script>
