import request from '@/utils/request'

// 获取列表
export function list (params) {
  return request({
    url: '/kf/kfaccount/list',
    method: 'get',
    params: params
  })
}

// 获取信息
export function info (params) {
  return request({
    url: '/kf/kfaccount/info',
    method: 'get',
    params: params
  })
}

// 添加
export function add (params) {
  return request({
    url: '/kf/kfaccount/save',
    method: 'post',
    data: params
  })
}

// 修改
export function update (params) {
  return request({
    url: '/kf/kfaccount/update',
    method: 'post',
    data: params
  })
}

// 删除
export function del (params) {
  return request({
    url: '/kf/kfaccount/delete',
    method: 'post',
    data: params
  })
}

//获取接待人员列表
export function listKFServicer (params) {
  return request({
    url: '/kf/kfaccount/listKFServicer',
    method: 'get',
    params: params
  })
}

//获取部门列表
export function getDepartmentList (params) {
  return request({
    url: '/kf/kfaccount/getDepartmentList',
    method: 'get',
    params: params
  })
}

//获取部门成员
export function getSimpleList (params) {
  return request({
    url: '/kf/kfaccount/getSimpleList',
    method: 'get',
    params: params
  })
}

// 添加接线员
export function addKFServicer (params) {
  return request({
    url: '/kf/kfaccount/addKFServicer',
    method: 'post',
    data: params
  })
}

// 删除接线员
export function delKFServicer (params) {
  return request({
    url: '/kf/kfaccount/delKFServicer',
    method: 'post',
    data: params
  })
}

