<template>
  <el-tabs v-model="activeName" type="card" @tab-click="handleClick">
    <el-tab-pane label="性能价格配置" name="tal_0"></el-tab-pane>
    <el-tab-pane label="色系价格配置" name="tal_1"></el-tab-pane>
    <el-tab-pane label="功能价格配置" name="tal_2"></el-tab-pane>
    <el-tab-pane label="阶梯价格配置" name="tal_3"></el-tab-pane>
    <!-- 弹窗, 新增 / 修改 -->
    <performanceIndex v-if="performanceIndexVisible" ref="performanceIndex"></performanceIndex>
    <colorSeriesIndex v-if="colorSeriesIndexVisible" ref="colorSeriesIndex"></colorSeriesIndex>
    <productSeriesIndex v-if="productSeriesIndexVisible" ref="productSeriesIndex"></productSeriesIndex>
    <cartegryIndex v-if="cartegryIndexVisible" ref="cartegryIndex"></cartegryIndex>
  </el-tabs>
</template>

<script>
  import PerformanceIndex from "./performanceIndex";
  import ColorSeriesIndex from "./colorSeriesIndex";
  import CartegryIndex from "./cartegryIndex";
  import ProductSeriesIndex from "./productSeriesIndex";
  export default {
    data () {
      return {
        activeName: 'tal_0',
        dataForm: {
          key: '',
          daterange:[]
        },
        subIndex:'',
        dataList: [],
        pageIndex: 1,
        pageSize: 10,
        totalPage: 0,
        dataListLoading: false,
        dataListSelections: [],
        addOrUpdateVisible: false,
        performanceIndexVisible: false,
        colorSeriesIndexVisible:false,
        productSeriesIndexVisible:false,
        cartegryIndexVisible:false
      }
    },
    components: {
      PerformanceIndex,
      ColorSeriesIndex,
      CartegryIndex,
      ProductSeriesIndex,
    },
    activated () {
      this.addPerformanceHandle(1);
    },
    created() {
      this.addPerformanceHandle(1);
    },
    methods: {
      handleClick(tab, event) {
        // console.log(tab, event);
        // console.log(tab.index, event);
        this.activeName = "tal_" + tab.index;
        console.log(this.activeName);
        if(tab.index == 0){
          //this.addPerformanceHandle(tab.activeName);
          this.performanceIndexVisible = true
          this.colorSeriesIndexVisible = false
          this.productSeriesIndexVisible =false
          this.cartegryIndexVisible =false
        } else if(tab.index == 1) {
          //this.addColorSeriesHandle(tab.activeName);
          this.performanceIndexVisible = false
          this.colorSeriesIndexVisible = true
          this.productSeriesIndexVisible =false
          this.cartegryIndexVisible =false
        } else if(tab.index == 2) {
          //this.addProductSeriesHandle(tab.activeName);
          this.performanceIndexVisible = false
          this.colorSeriesIndexVisible = false
          this.productSeriesIndexVisible =true
          this.cartegryIndexVisible =false
        } else {
          //this.addCartegryHandle(tab.activeName);
          this.performanceIndexVisible = false
          this.colorSeriesIndexVisible = false
          this.productSeriesIndexVisible =false
          this.cartegryIndexVisible =true
        }
      },
      //性能价格页面跳转
      addPerformanceHandle(index) {
        console.log(index)
        this.activeName = 'tal_0';
        this.subIndex = index;
        this.performanceIndexVisible = true
        this.colorSeriesIndexVisible = false
        this.productSeriesIndexVisible =false
        this.cartegryIndexVisible =false
      },
      //色系价格页面跳转
      addColorSeriesHandle(index) {
        this.activeName = 'tal_1';
        this.subIndex = index;
        this.performanceIndexVisible = false
        this.colorSeriesIndexVisible = true
        this.productSeriesIndexVisible =false
        this.cartegryIndexVisible =false
      },
      //功能价格页面跳转
      addProductSeriesHandle(index) {
        console.log(index)
        this.activeName = 'tal_2';
        this.subIndex = index;
        this.performanceIndexVisible = false
        this.colorSeriesIndexVisible = false
        this.productSeriesIndexVisible =true
        this.cartegryIndexVisible =false
      },
      //阶梯价格页面跳转
      addCartegryHandle(index) {
        this.subIndex = index;
        this.activeName = 'tal_3';
        this.performanceIndexVisible = false
        this.colorSeriesIndexVisible = false
        this.productSeriesIndexVisible =false
        this.cartegryIndexVisible =true
      },
      //重新查询
      restPage() {
        this.pageIndex = 1;
        this.getDataList();
      },
      reset(dataForm) {
       	this.$refs.dataForm.resetFields();
        this.restPage();
      },
      // 获取数据列表
      getDataList () {
        this.dataListLoading = true
        var params = {
          page: this.pageIndex,
          limit: this.pageSize,
          key: this.dataForm.key
        }
      },
      // 每页数
      sizeChangeHandle (val) {
        this.pageSize = val
        this.pageIndex = 1
        this.getDataList()
      },
      // 当前页
      currentChangeHandle (val) {
        this.pageIndex = val
        this.getDataList()
      },
      // 多选
      selectionChangeHandle (val) {
        this.dataListSelections = val
      },
      // 新增 / 修改
      addOrUpdateHandle (id) {
        this.addOrUpdateVisible = true
        this.$nextTick(() => {
          this.$refs.addOrUpdate.init(id)
        })
      },
      // 删除
      deleteHandle (id) {
        var ids = id ? [id] : this.dataListSelections.map(item => {
          return item.id
        })
        this.$confirm(`确定对[id=${ids.join(',')}]进行[${id ? '删除' : '批量删除'}]操作?`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          del(ids).then(({data}) => {
            if (data && data.code === 0) {
              this.$message({
                message: '操作成功',
                type: 'success',
                duration: 1500,
                onClose: () => {
                  this.getDataList()
                }
              })
            } else {
              this.$message.error(data.msg)
            }
          })
        })
      }
    }
  }
</script>
<style>
  .d1{
    background-color: #4AB7BD;
  }
  .d2{
    border-bottom:1px solid;
    border-color: #99a9bf;
  }
</style>
