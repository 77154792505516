import request from '@/utils/request'

const url = '/m/colorBookCategory';

// 获取列表
export function queryColorBookCategoryApi (data) {
  if(!data) {
    data = {pageSize: 100000}
  }
  return request({
    url: `${url}/query`,
    method: 'post',
    data
  })
}

// 获取信息
export function getColorBookCategoryApi (id) {
  return request({
    url: `${url}/get/${id}`,
    method: 'get',
  })
}

// 添加
export function addOrUpdateColorBookCategoryApi (data) {
  return request({
    url: `${url}/addOrUpdate`,
    method: 'put',
    data
  })
}


// 删除
export function deleteColorBookCategoryApi (id) {
  return request({
    url: `${url}/delete/${id}`,
    method: 'delete',
  })
}

//类型下拉
export function getTypeDictInfoApi () {
    return request({
        url: `${url}/getTypeDictInfo`,
        method: 'get'
    })
}
