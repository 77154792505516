<template>
  <div class="app-container">
    <el-form :inline="true"  size="small" :model="dataForm" ref="dataForm"  @keyup.enter.native="restPage()">
      <el-form-item prop="key" label="订单号">
        <el-input v-model="dataForm.sampleOrderNo" placeholder="订单号" size="small" clearable></el-input>
      </el-form-item>
      <el-form-item label="订单状态" >
        <el-select v-model="dataForm.state" placeholder="订单状态" @change="restPage()">
          <el-option label="全部订单" value=""></el-option>
          <el-option
                  v-for="item in States"
                  :key="item.stateKey"
                  :label="item.stateValue"
                  :value="item.stateKey"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="订单类型">
        <el-select v-model="dataForm.sampleType" placeholder="订单类型">
          <el-option label="全部类型" value=""></el-option>
          <el-option
                  v-for="item in sampleTypes"
                  :key="item.sampleTypeKey"
                  :label="item.sampleTypeValue"
                  :value="item.sampleTypeKey"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="支付类型">
        <el-select v-model="dataForm.payType" placeholder="支付类型">
          <el-option label="全部" value=""></el-option>
          <el-option
                  v-for="item in payTypes"
                  :key="item.payTypeKey"
                  :label="item.payTypeValue"
                  :value="item.payTypeKey"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="订单日期">
        <div class="block">
          <el-date-picker
                  v-model="dataForm.createDates"
                  value-format="yyyy-MM-dd"
                  type="daterange"
                  range-separator="-"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期">
          </el-date-picker>
        </div>
      </el-form-item>
      <el-form-item>
        <el-button @click="restPage()" size="small">查询</el-button>
        <el-button @click="reset('dataForm')"  size="small">清空</el-button>
        <el-button v-if="isAuth('sample:sampleorder:delete')" type="danger" @click="deleteHandle()" :disabled="dataListSelections.length <= 0" size="small">批量删除</el-button>
      </el-form-item>
    </el-form>
    <el-table
      :data="dataList"
      border
      size="small"
      v-loading="dataListLoading"
      @selection-change="selectionChangeHandle"
      style="width: 100%;">
      <el-table-column
        type="selection"
        fixed="left"
        header-align="center"
        align="center"
        width="50">
      </el-table-column>
      <el-table-column
        prop="sampleOrderNo"
        header-align="center"
        align="center"
        label="订单号">
      </el-table-column>
      <el-table-column
        prop="sapNo"
        header-align="center"
        align="center"
        label="企业订单号">
      </el-table-column>
      <el-table-column
              prop="sampleType"
              header-align="center"
              align="center"
              label="订单类型">
        <template slot-scope="scope">
          {{ scope.row.sampleType === '0' ? '打样订单' : '样品订单' }}
        </template>
      </el-table-column>
      <el-table-column
              prop="productNo"
              header-align="center"
              align="center"
              label="产品编号">
      </el-table-column>
      <el-table-column
        prop="materialSum"
        header-align="center"
        align="center"
        label="样粉数量(公斤)">
      </el-table-column>
      <el-table-column
        prop="modelSum"
        header-align="center"
        align="center"
        label="样板数量(块)">
      </el-table-column>
      <el-table-column
              prop="orderPrice"
              header-align="center"
              align="center"
              label="订单价格">
      </el-table-column>
      <el-table-column
              prop="createByName"
              header-align="center"
              align="center"
              label="下单客户">
      </el-table-column>
      <el-table-column
        prop="hasSample"
        header-align="center"
        align="center"
        label="是否有样件">
        <template slot-scope="scope">
          {{ scope.row.hasSample === '0' ? '否' : '是' }}
        </template>
      </el-table-column>
      <el-table-column
        prop="payType"
        header-align="center"
        align="center"
        label="支付类型">
      </el-table-column>
      <el-table-column
        prop="statusName"
        header-align="center"
        align="center"
        label="订单状态">
      </el-table-column>
      <el-table-column
        prop="deliverDate"
        header-align="center"
        align="center"
        label="交付日期">
      </el-table-column>
      <el-table-column
        fixed="right"
        header-align="center"
        align="center"
        width="150"
        label="操作">
        <template slot-scope="scope">
          <el-button type="text" size="small" @click="UpdateHandle(scope.row.id,scope.row.brandId)"  v-if="(scope.row.state ==='1' || scope.row.state ==='2') && isAuth('sampleorder:sampleorder:assign')">分派工厂</el-button>
          <el-button type="text" size="small" @click="shipmentsHandle(scope.row.id)" v-if="(scope.row.state ==='4' || (scope.row.state ==='1' && scope.row.sampleType === '1')) && isAuth('sampleorder:sampleorder:sendOut')" >发货</el-button>
          <el-button type="text" size="small" v-if=" isAuth('sampleorder:sampleorder:detail')" @click="addOrUpdateHandle(scope.row.id) ">详情</el-button>
          <!--<el-button type="text" size="small" @click="deleteHandle(scope.row.id)">删除</el-button>-->
          <el-button type="text" size="small" v-if="scope.row.state != 0  && isAuth('sampleorder:sampleorder:viewLogistics')" @click="checkLogisticsHandle(scope.row.id,scope.row.hasSample)">查看物流</el-button>
          <el-button type="text" size="small" v-if="scope.row.state == 19" @click="queRenShouKuan(scope.row.id)">确认收款</el-button>
          <el-button type="text" size="small" v-if="(scope.row.state == 0 || scope.row.state == 19) && scope.row.payType === '线下支付' && isAuth('sampleorder:sampleorder:payType')" @click="gatherHandle(scope.row.id)">付款证明</el-button>
          <el-button type="text" size="small" v-if="scope.row.state == 7 && isAuth('sampleorder:sampleorder:userRating')" @click="userEvaluateHandle(scope.row.id)">用户评价</el-button>
          <el-button type="text" size="small" v-if="scope.row.state == 0 && isAuth('sampleorder:sampleorder:modifyPrice')" @click="priceAdjustment(scope.row.id,scope.row.sampleOrderNo,scope.row.orderPrice,scope.row.createByName)">调价</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      size="small"
      @size-change="sizeChangeHandle"
      @current-change="currentChangeHandle"
      :current-page="pageIndex"
      :page-sizes="[10, 20, 50, 100]"
      :page-size="pageSize"
      :total="totalPage"
      layout="total, sizes, prev, pager, next, jumper">
    </el-pagination>
    <!-- 弹窗, 新增 / 修改 -->
    <add-or-update v-if="addOrUpdateVisible" ref="addOrUpdate" @refreshDataList="getDataList"></add-or-update>
    <assign-factory v-if="UpdateVisible" ref="assignFactory" @refreshDataList="getDataList"></assign-factory>
    <shipments v-if="shipmentsVisible" ref="shipments" @refreshDataList="getDataList"></shipments>
    <check-logistics v-if="checkLogisticsVisible" ref="checkLogistics" @refreshDataList="getDataList"></check-logistics>
    <gathering v-if="gatheringVisible" ref="gathering" @refreshDataList="getDataList"></gathering>
    <user-evaluate v-if="userEvaluateVisible" ref="userevaluate" @refreshDataList="getDataList"></user-evaluate>
    <price-adjustment v-if="priceAdjustmentVisible" ref="priceAdjustment" @refreshDataList="getDataList"></price-adjustment>
  </div>
</template>

<script>
  import {list,del,payment} from '@/api/modules/sampleorder'
  import AddOrUpdate from './add-or-update'
  import AssignFactory from './assign-factory'
  import Shipments from "./shipments";
  import CheckLogistics from "./check-logistics";
  import Gathering from "./gathering"
  import UserEvaluate from "./user-evaluate"
  import priceAdjustment from "./price-adjustment"
  export default {
    data () {
      return {
        dataForm: {
          key: '',
          payType:'',
          sampleType:'',
          state:'',
          createDates:[],
          sampleOrderNo:'',
          daterange:[]
        },
        States:[
          {stateKey:'0', stateValue:'未支付'},
          {stateKey:'1', stateValue:'待派单'},
          {stateKey:'2', stateValue:'寄样件'},
          {stateKey:'3', stateValue:'打样中'},
          {stateKey:'4', stateValue:'待发货'},
          {stateKey:'5', stateValue:'待签收'},
          {stateKey:'6', stateValue:'已完成'},
          {stateKey:'7', stateValue:'已评价'},
          {stateKey:'8', stateValue:'已取消'},
          {stateKey:'19', stateValue:'付款证明待确认'},
        ],
        sampleTypes:[
          {sampleTypeKey:'0', sampleTypeValue:'打样订单'},
          {sampleTypeKey:'1', sampleTypeValue:'样品订单'},
        ],
        payTypes:[
          {payTypeKey:'0', payTypeValue:'线下支付'},
          {payTypeKey:'1', payTypeValue:'微信支付'},
          {payTypeKey:'2', payTypeValue:'支付宝支付'},
          {payTypeKey:'3', payTypeValue:'银联'},
        ],
        dataList: [],
        pageIndex: 1,
        pageSize: 10,
        totalPage: 0,
        dataListLoading: false,
        dataListSelections: [],
        addOrUpdateVisible: false,
        UpdateVisible: false,
        shipmentsVisible: false,
        checkLogisticsVisible: false,
        gatheringVisible:false,
        userEvaluateVisible: false,
        priceAdjustmentVisible: false,
      }
    },
    components: {
      CheckLogistics, AddOrUpdate,AssignFactory,Shipments,Gathering,UserEvaluate,priceAdjustment,
    },
    activated () {
      this.getDataList()
    },
    created() {
      this.getDataList()
    },
    methods: {
      //重新查询
      restPage() {
        this.pageIndex = 1;
        this.getDataList();
      },
      reset(dataForm) {
        this.dataForm.sampleOrderNo='',
        this.dataForm.state='',
        this.dataForm.sampleType='',
        this.dataForm.payType='',
        this.dataForm.createDates=[],
       	this.$refs.dataForm.resetFields();
        this.restPage();
      },
      // 获取数据列表
      getDataList () {
        this.dataListLoading = true
        var arrOrderTime = this.dataForm.createDates;
        var strOrderTime = "";
        var startDate = null;
        var endDate = null;
        if (arrOrderTime.length > 0) {
          startDate = arrOrderTime[0];
          endDate = arrOrderTime[1];
        }
        var params = {
          page: this.pageIndex,
          limit: this.pageSize,
          'sampleOrderNo': this.dataForm.sampleOrderNo,
          'state': this.dataForm.state,
          'sampleType': this.dataForm.sampleType,
          'payType': this.dataForm.payType,
          'startDate': startDate,
          'endDate': endDate
        }
        list(params).then(({data}) => {
          if (data && data.code === 0) {
            this.dataList = data.page.list
            this.totalPage = data.page.totalCount
          } else {
            this.dataList = []
            this.totalPage = 0
          }
          this.dataListLoading = false
        })
      },
      // 每页数
      sizeChangeHandle (val) {
        this.pageSize = val
        this.pageIndex = 1
        this.getDataList()
      },
      // 当前页
      currentChangeHandle (val) {
        this.pageIndex = val
        this.getDataList()
      },
      // 多选
      selectionChangeHandle (val) {
        this.dataListSelections = val
      },
      // 新增 / 修改
      addOrUpdateHandle (id) {
        this.addOrUpdateVisible = true
        this.$nextTick(() => {
          this.$refs.addOrUpdate.init(id)
        })
      },
      //调价
      priceAdjustment(id,sampleOrderNo,orderPrice,createByName){
        this.priceAdjustmentVisible = true
        this.$nextTick(() => {
          this.$refs.priceAdjustment.init(id,sampleOrderNo,orderPrice,createByName)
        })
      },
      //付款证明
      gatherHandle(id){
        this.gatheringVisible = true
        this.$nextTick(() => {
          this.$refs.gathering.init(id)
        })
      },
      //用户评价
      userEvaluateHandle(id){
        this.userEvaluateVisible = true
        this.$nextTick(() => {
          this.$refs.userevaluate.init(id)
        })
      },
      // 分派工厂
      UpdateHandle (id,brandId) {
        this.UpdateVisible = true
        this.$nextTick(() => {
          this.$refs.assignFactory.init(id,brandId)
        })
      },
      //确认收款
      queRenShouKuan(id){
        this.$confirm(`是否确认收款?`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          payment({id:id}).then(({data}) => {
            console.log(id)
            if (data && data.code === 0) {
              this.$message({
                message: '操作成功',
                type: 'success',
                duration: 1500,
                onClose: () => {
                  this.getDataList()
                }
              })
            } else {
              this.$message.error(data.msg)
            }
          })
        })
      },
      //发货
      shipmentsHandle(id) {
        this.shipmentsVisible = true
        this.$nextTick(() => {
          this.$refs.shipments.init(id)
        })
      },
      //查看物流单号
      checkLogisticsHandle(id,hasSample) {
        this.checkLogisticsVisible = true
        this.$nextTick(() => {
          this.$refs.checkLogistics.init(id,hasSample)
        })
      },
      // 删除
      deleteHandle (id) {
        var ids = id ? [id] : this.dataListSelections.map(item => {
          return item.id
        })
        this.$confirm(`确定对[id=${ids.join(',')}]进行[${id ? '删除' : '批量删除'}]操作?`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          del(ids).then(({data}) => {
            if (data && data.code === 0) {
              this.$message({
                message: '操作成功',
                type: 'success',
                duration: 1500,
                onClose: () => {
                  this.getDataList()
                }
              })
            } else {
              this.$message.error(data.msg)
            }
          })
        })
      }
    }
  }
</script>
