<template>
  <el-dialog
    :title="!dataForm.id ? '物流信息' : '物流信息'"
    :close-on-click-modal="false"
    :visible.sync="visible">
    <el-timeline>
      <el-timeline-item
              v-for="(activity, index) in loginicListL"
              :key="index"
              :timestamp="activity.ftime">
        {{activity.context}}{{activity.time}}
      </el-timeline-item>
      <p v-if="loginicListL.length===0" style="text-align: center;color: #cccccc;font-size: 25px">暂无物流信息</p>
    </el-timeline>
    <span slot="footer" class="dialog-footer">
      <el-button @click="visible = false" size="small">返回</el-button>
      <!--<el-button type="primary" @click="visible = false" size="small"  :disabled="dataLoading">确定</el-button>-->
    </span>
  </el-dialog>
</template>

<script>
  import {logisticsQuery} from '@/api/modules/sampleaftersale';
  import {getToken} from '@/utils/auth';
  import Tinymce from '@/components/Tinymce'

  export default {
    components: {
      Tinymce
    },
    data () {
      return {
        action: `${process.env.VUE_APP_BASE_API}/sys/oss/upload?token=${getToken()}`,
        visible: false,
        dataLoading: false,
        loginicListL:[],
        dataForm: {
          id: 0,
        },
      }
    },
    methods: {
      init (orderId) {
        this.dataForm.id = orderId || 0
        this.visible = true
        this.$nextTick(() => {
          if (this.dataForm.id) {
            let params ={
              id:this.dataForm.id
            }
              logisticsQuery(params).then(({data}) => {

              if (data && data.code === 0) {
                this.loginicListL = data.DATA
              } else {
                this.loginicListL = []
              }
            })
          }
        })
      },
      // 表单提交
      dataFormSubmit () {
        this.$refs['dataForm'].validate((valid) => {
          if (valid) {
          this.dataLoading = true;
            var params = {
              'id': this.dataForm.id || undefined,
              'factoryId': this.dataForm.factoryId,
              'deliverDate':this.dataForm.deliverDate,
            }
            var tick = !this.dataForm.id ? update(params): update(params)
            tick.then(({data}) => {
              if (data && data.code === 0) {
                this.$message({
                  message: '操作成功',
                  type: 'success',
                  duration: 1500,
                  onClose: () => {
                    this.visible = false;
                    this.dataLoading = false;
                    this.$emit('refreshDataList')
                  }
                })
              } else {
                this.$message.error(data.msg)
                this.dataLoading = false;
              }
            })
          }
        })
      }
    }
  }
</script>
