<template>
  <div class="app-container">
    <el-form
      :inline="true"
      size="small"
      :model="dataForm"
      ref="dataForm"
      @keyup.enter.native="restPage()"
    >
      <el-form-item prop="comboName">
        <el-input
          v-model="dataForm.comboName"
          placeholder="套餐名称"
          size="small"
          clearable
        ></el-input>
      </el-form-item>
      <el-form-item>
        <el-button @click="restPage()" size="small">查询</el-button>
        <el-button @click="reset('dataForm')" size="small">清空</el-button>
        <el-button type="primary" @click="addOrUpdateHandle()" size="small"
          >新增</el-button
        >
      </el-form-item>
    </el-form>
    <el-table
      :data="dataList"
      border
      size="small"
      v-loading="dataListLoading"
      @selection-change="selectionChangeHandle"
      style="width: 100%"
    >
      <el-table-column
        prop="id"
        header-align="center"
        align="center"
        label="id"
        width="120"
      >
      </el-table-column>
      <el-table-column
        prop="comboName"
        header-align="center"
        align="center"
        label="套餐名称"
      >
      </el-table-column>
      <el-table-column
        prop="roleName"
        header-align="center"
        align="center"
        label="角色名称"
      >
      </el-table-column>
      <el-table-column
        prop="productQuantity"
        header-align="center"
        align="center"
        label="生产产品数量"
      >
      </el-table-column>
      <el-table-column
        prop="labProductQuantity"
        header-align="center"
        align="center"
        label="实验产品数量"
      >
      </el-table-column>
      <el-table-column
        prop="colorimeterQuantity"
        header-align="center"
        align="center"
        label="色差仪数量"
      >
      </el-table-column>
      <el-table-column
        prop="labScaleQuantity"
        header-align="center"
        align="center"
        label="称料一体机数量"
      >
      </el-table-column>
      <el-table-column
        prop="brandAccountQuantity"
        header-align="center"
        align="center"
        label="品牌账号数量"
      >
      </el-table-column>
      <el-table-column
        prop="factoryAccountQuantity"
        header-align="center"
        align="center"
        label="工厂账号数量"
      >
      </el-table-column>
      <el-table-column
        prop="employeeAccountQuantity"
        header-align="center"
        align="center"
        label="员工账号数量"
      >
      </el-table-column>
      <el-table-column
        prop="level"
        header-align="center"
        align="center"
        label="套餐级别"
      >
      </el-table-column>
      <el-table-column
        prop="createTime"
        header-align="center"
        align="center"
        label="创建时间"
        width="150"
      >
      </el-table-column>
      <el-table-column
        fixed="right"
        header-align="center"
        align="center"
        width="150"
        label="操作"
      >
        <template slot-scope="scope">
          <el-button
            type="text"
            size="small"
            @click="addOrUpdateHandle(scope.row.id)"
            >编辑</el-button
          >
          <el-button
            type="text"
            size="small"
            @click="deleteHandle(scope.row.id)"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      size="small"
      @size-change="sizeChangeHandle"
      @current-change="currentChangeHandle"
      :current-page="dataForm.pageIndex"
      :page-sizes="[10, 20, 50, 100]"
      :page-size="dataForm.pageSize"
      :total="totalPage"
      layout="total, sizes, prev, pager, next, jumper"
    >
    </el-pagination>
    <!-- 弹窗, 新增 / 修改 -->
    <add-or-update
      v-if="addOrUpdateVisible"
      ref="addOrUpdate"
      @refreshDataList="getDataList"
    ></add-or-update>
  </div>
</template>

<script>
import {
  queryComboApi,
  deleteComboApi,
} from "@/api/modules/combo";
import AddOrUpdate from "./add-or-update";
export default {
  data() {
    return {
      dataForm: {
        comboName: "",
        pageNo: 1,
        pageSize: 10,
      },
      dataList: [],
      totalPage: 0,
      dataListLoading: false,
      dataListSelections: [],
      addOrUpdateVisible: false,
    };
  },
  components: {
    AddOrUpdate,
  },
  mounted() {
    this.getDataList();
  },
  methods: {
    //重新查询
    restPage() {
      this.dataForm.pageIndex = 1;
      this.getDataList();
    },
    reset(dataForm) {
      this.$refs.dataForm.resetFields();
      this.restPage();
    },
    // 获取数据列表
    getDataList() {
      this.dataListLoading = true;
      queryComboApi(this.dataForm).then(
        ({ records, totalRecords }) => {
          if (totalRecords > 0) {
            this.dataList = records;
            this.totalPage = totalRecords;
          } else {
            this.dataList = [];
            this.totalPage = 0;
          }
          this.dataListLoading = false;
        }
      );
    },
    // 每页数
    sizeChangeHandle(val) {
      this.dataForm.pageSize = val;
      this.dataForm.pageIndex = 1;
      this.getDataList();
    },
    // 当前页
    currentChangeHandle(val) {
      this.dataForm.pageIndex = val;
      this.getDataList();
    },
    // 多选
    selectionChangeHandle(val) {
      this.dataListSelections = val;
    },
    // 新增 / 修改
    addOrUpdateHandle(id) {
      this.addOrUpdateVisible = true;
      this.$nextTick(() => {
        this.$refs.addOrUpdate.init(id);
      });
    },
    // 删除
    deleteHandle(id) {
      this.$confirm(`是否删除该数据?`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        deleteComboApi(id).then(() => {
          this.$baseMessage("删除成功", "success");
          this.getDataList();
        });
      });
    },
  },
};
</script>
