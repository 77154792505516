<template>
  <el-dialog
    :title="!dataForm.id ? '交易详情' : '交易详情'"
    :close-on-click-modal="false"
    :visible.sync="visible">
    <el-form :model="dataForm" size="small" ref="dataForm" @keyup.enter.native="dataFormSubmit()" label-width="120px"
     v-loading="dataLoading" style="width:100%;"  element-loading-text=""  element-loading-spinner="el-icon-loading"
    >
      <el-form-item label="交易流水号" prop="serialNumber">
        <el-input v-model="dataForm.serialNumber" placeholder="交易流水号"  size="small"></el-input>
      </el-form-item>
      <!--<el-form-item label="流水类型（lease_battery：电池租赁订单，buy_battery：购买电池订单，scan_pay：扫码付款订单）" prop="serialType">
        <el-input v-model="dataForm.serialType" placeholder="流水类型（lease_battery：电池租赁订单，buy_battery：购买电池订单，scan_pay：扫码付款订单）"  size="small"></el-input>
      </el-form-item>-->
      <el-form-item label="订单编号" prop="sampleOrderNumber">
        <el-input v-model="dataForm.sampleOrderNumber" placeholder="交易流水号"  size="small"></el-input>
      </el-form-item>
      <el-form-item label="订单金额" prop="payAmount">
        <el-input v-model="dataForm.payAmount" placeholder="订单金额"  size="small"></el-input>
      </el-form-item>
      <!--<el-form-item label="分成金额" prop="divideAmount">
        <el-input v-model="dataForm.divideAmount" placeholder="分成金额"  size="small"></el-input>
      </el-form-item>-->
      <el-form-item label="商户名" prop="companyName">
        <el-input v-model="dataForm.companyName" placeholder="支付类型"  size="small"></el-input>
      </el-form-item>
      <el-form-item label="订单支付类型" prop="payType">
        <el-input v-model="dataForm.payType" placeholder="支付类型"  size="small"></el-input>
      </el-form-item>
      <el-form-item label="交易类型" prop="payStatus">
        <el-input v-model="dataForm.payStatus" placeholder="交易类型"  size="small"></el-input>
      </el-form-item>
      <!--<el-form-item label="支付三方流水号" prop="payThirdNumber">
        <el-input v-model="dataForm.payThirdNumber" placeholder="支付三方流水号"  size="small"></el-input>
      </el-form-item>-->
      <el-form-item label="交易时间" prop="payTime">
        <el-input v-model="dataForm.payTime" placeholder="交易时间"  size="small"></el-input>
      </el-form-item>
      <!--<el-form-item label="退款三方流水号" prop="refundThirdNumber">
        <el-input v-model="dataForm.refundThirdNumber" placeholder="退款三方流水号"  size="small"></el-input>
      </el-form-item>
      <el-form-item label="退款时间" prop="refundTime">
        <el-input v-model="dataForm.refundTime" placeholder="退款时间"  size="small"></el-input>
      </el-form-item>
      <el-form-item label="用户ID" prop="userId">
        <el-input v-model="dataForm.userId" placeholder="用户ID"  size="small"></el-input>
      </el-form-item>
      <el-form-item label="门店ID" prop="storeId">
        <el-input v-model="dataForm.storeId" placeholder="门店ID"  size="small"></el-input>
      </el-form-item>
      <el-form-item label="区域ID" prop="areaId">
        <el-input v-model="dataForm.areaId" placeholder="区域ID"  size="small"></el-input>
      </el-form-item>
      <el-form-item label="创建人" prop="createBy">
        <el-input v-model="dataForm.createBy" placeholder="创建人"  size="small"></el-input>
      </el-form-item>
      <el-form-item label="创建时间" prop="createTime">
        <el-input v-model="dataForm.createTime" placeholder="创建时间"  size="small"></el-input>
      </el-form-item>
      <el-form-item label="更新人" prop="updateBy">
        <el-input v-model="dataForm.updateBy" placeholder="更新人"  size="small"></el-input>
      </el-form-item>
      <el-form-item label="更新时间" prop="updateTime">
        <el-input v-model="dataForm.updateTime" placeholder="更新时间"  size="small"></el-input>
      </el-form-item>
      <el-form-item label="版本号" prop="version">
        <el-input v-model="dataForm.version" placeholder="版本号"  size="small"></el-input>
      </el-form-item>-->
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="visible = false" size="small">取消</el-button>
      <!--<el-button type="primary" @click="dataFormSubmit()" size="small"  :disabled="dataLoading">确定</el-button>-->
    </span>
  </el-dialog>
</template>

<script>
  import {info,add,update} from '@/api/modules/saorderflow'
  export default {
    data () {
      return {
        visible: false,
        dataLoading: false,
        dataForm: {
          orderFlowId: 0,
          serialNumber: '',
          sampleOrderNumber:'',
          companyName:'',
          payAmount: '',
          serialType: '',
          divideAmount: '',
          payType: '',
          payStatus: '',
          payThirdNumber: '',
          payTime: '',
          refundThirdNumber: '',
          refundTime: '',
          userId: '',
          storeId: '',
          areaId: '',
          createBy: '',
          createTime: '',
          updateBy: '',
          updateTime: '',
          version: ''
        },
        dataRule: {
          serialNumber: [
            { required: true, message: '流水号不能为空', trigger: 'blur' }
          ],
          serialType: [
            { required: true, message: '流水类型（lease_battery：电池租赁订单，buy_battery：购买电池订单，scan_pay：扫码付款订单）不能为空', trigger: 'blur' }
          ],
          payAmount: [
            { required: true, message: '付款金额不能为空', trigger: 'blur' }
          ],
          divideAmount: [
            { required: true, message: '分成金额不能为空', trigger: 'blur' }
          ],
          payType: [
            { required: true, message: '支付类型（weixin：微信支付，alipay：支付宝支付）不能为空', trigger: 'blur' }
          ],
          payStatus: [
            { required: true, message: '支付状态（unpaid：未支付，paid：已支付，refund：已退款）不能为空', trigger: 'blur' }
          ],
          payThirdNumber: [
            { required: true, message: '支付三方流水号不能为空', trigger: 'blur' }
          ],
          payTime: [
            { required: true, message: '支付时间不能为空', trigger: 'blur' }
          ],
          refundThirdNumber: [
            { required: true, message: '退款三方流水号不能为空', trigger: 'blur' }
          ],
          refundTime: [
            { required: true, message: '退款时间不能为空', trigger: 'blur' }
          ],
          userId: [
            { required: true, message: '用户ID不能为空', trigger: 'blur' }
          ],
          storeId: [
            { required: true, message: '门店ID不能为空', trigger: 'blur' }
          ],
          areaId: [
            { required: true, message: '区域ID不能为空', trigger: 'blur' }
          ],
          createBy: [
            { required: true, message: '创建人不能为空', trigger: 'blur' }
          ],
          createTime: [
            { required: true, message: '创建时间不能为空', trigger: 'blur' }
          ],
          updateBy: [
            { required: true, message: '更新人不能为空', trigger: 'blur' }
          ],
          updateTime: [
            { required: true, message: '更新时间不能为空', trigger: 'blur' }
          ],
          version: [
            { required: true, message: '版本号不能为空', trigger: 'blur' }
          ]
        }
      }
    },
    methods: {
      init (id) {
        this.dataForm.orderFlowId = id || 0
        this.visible = true
        this.$nextTick(() => {
          this.$refs['dataForm'].resetFields()
          if (this.dataForm.orderFlowId) {
            info({orderFlowId:this.dataForm.orderFlowId}).then(({data}) => {
              if (data && data.code === 0) {
                this.dataForm.serialNumber = data.sampleOrderFlowEntity.serialNumber
                this.dataForm.sampleOrderNumber =data.sampleOrderFlowEntity.sampleOrderNumber
                this.dataForm.companyName =data.sampleOrderFlowEntity.companyName
                this.dataForm.serialType = data.sampleOrderFlowEntity.serialType
                this.dataForm.payAmount = data.sampleOrderFlowEntity.payAmount
                this.dataForm.divideAmount = data.sampleOrderFlowEntity.divideAmount
                this.dataForm.payType = data.sampleOrderFlowEntity.payType
                this.dataForm.payStatus = data.sampleOrderFlowEntity.payStatus
                this.dataForm.payTime = data.sampleOrderFlowEntity.payTime
              }
            })
          }
        })
      },
      // 表单提交
      dataFormSubmit () {
        this.$refs['dataForm'].validate((valid) => {
          if (valid) {
          	this.dataLoading = true;
            var params = {
              'orderFlowId': this.dataForm.orderFlowId || undefined,
              'serialNumber': this.dataForm.serialNumber,
              'serialType': this.dataForm.serialType,
              'payAmount': this.dataForm.payAmount,
              'divideAmount': this.dataForm.divideAmount,
              'payType': this.dataForm.payType,
              'payStatus': this.dataForm.payStatus,
              'payThirdNumber': this.dataForm.payThirdNumber,
              'payTime': this.dataForm.payTime,
              'refundThirdNumber': this.dataForm.refundThirdNumber,
              'refundTime': this.dataForm.refundTime,
              'userId': this.dataForm.userId,
              'storeId': this.dataForm.storeId,
              'areaId': this.dataForm.areaId,
              'createBy': this.dataForm.createBy,
              'createTime': this.dataForm.createTime,
              'updateBy': this.dataForm.updateBy,
              'updateTime': this.dataForm.updateTime,
              'version': this.dataForm.version
            }
            var tick = !this.dataForm.orderFlowId ? add(params) : update(params)
            tick.then(({data}) => {
              if (data && data.code === 0) {
                this.$message({
                  message: '操作成功',
                  type: 'success',
                  duration: 1500,
                  onClose: () => {
                    this.visible = false;
	              	this.dataLoading = false;
                    this.$emit('refreshDataList')
                  }
                })
              } else {
                this.$message.error(data.msg)
                this.dataLoading = false;
              }
            })
          }
        })
      }
    }
  }
</script>
