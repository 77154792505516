import request from '@/utils/request'

// 获取列表
export function list (params) {
  return request({
    url: '/article/article/list',
    method: 'get',
    params: params
  })
}

// 获取信息
export function info (params) {
  return request({
    url: '/article/article/info',
    method: 'get',
    params: params
  })
}

// 添加
export function add (params) {
  return request({
    url: '/article/article/save',
    method: 'post',
    data: params
  })
}

// 修改
export function update (params) {
  return request({
    url: '/article/article/update',
    method: 'post',
    data: params
  })
}

// 删除
export function del (params) {
  return request({
    url: '/article/article/delete',
    method: 'post',
    data: params
  })
}


// 禁用启用
export function stopState (params) {
    return request({
        url: '/article/article/stopState',
        method: 'post',
        data: params
    })
}
export function fetchArticleCategory(params) {
    return request({
        url: '/article/article/getArticleCategory',
        method: 'get',
        params: params
    })
}
// 编辑颜色平台同步文章
export function updateBrandArticle (params) {
  return request({
    url: '/article/article/updateBrandArticle',
    method: 'post',
    data: params
  })
}