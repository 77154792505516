<template>
  <el-dialog
    :title="!dataForm.id ? '新增' : '修改'"
    :close-on-click-modal="false"
    :visible.sync="visible"
    width="600px"
  >
    <el-form
      :model="dataForm"
      :rules="dataRule"
      size="small"
      ref="dataForm"
      @keyup.enter.native="dataFormSubmit()"
      label-width="150px"
      v-loading="dataLoading"
      style="width: 100%"
      element-loading-text=""
      element-loading-spinner="el-icon-loading"
    >
      <el-form-item label="关联属性" prop="productAttributeId">
        <span slot="label">
          <span
            class="span-box"
            title="设置当前属性值的所属属性"
            style="cursor: pointer"
          >
            <span>关联属性</span>
            <i class="el-icon-question" style="margin-left: 5px"></i>
          </span>
        </span>
        <el-select
          v-model="dataForm.productAttributeId"
          size="small"
          @change="selectAttrValueList()"
          placeholder="请选择属性"
          style="width: 80%"
          :disabled="dataForm.id ? true : false"
        >
          <el-option
            v-for="item in productAttrList"
            :key="item.id"
            :label="item.attributeName"
            :value="item.id"
            size="small"
          ></el-option>
        </el-select>
      </el-form-item>

      <el-form-item v-if="isGlossProperties" label="光泽值">
        <el-input-number v-model="dataForm.valueMin" :max="999999" :precision="2" label="最小值" size="small"></el-input-number>
        至
        <el-input-number v-model="dataForm.valueMax" :max="999999" :precision="2" label="最小值" size="small"></el-input-number>
      </el-form-item>


      <div
        v-if="
          relationAttributeValueList && relationAttributeValueList.length > 0
        "
      >
        <el-form-item label="关联通用属性">
          <div style="width: 80%">{{ this.dataForm.attributeName }}</div>
        </el-form-item>
        <el-form-item label="关联通用属性值" prop="relationAttributeValueId">
          <el-radio-group v-model="dataForm.relationAttributeValueId">
            <el-radio
              v-for="item in relationAttributeValueList"
              :key="item.id"
              :label="item.id"
              :value="item.id"
              size="small"
              >{{ item.valueName }}</el-radio
            >
          </el-radio-group>
        </el-form-item>
      </div>
      <el-form-item label="上级属性值" prop="parentId">
        <span slot="label">
          <span
            class="span-box"
            title="设置当前属性值的所属属性值，若选择“一级属性值”，则表示当前属性无上级属性值"
            style="cursor: pointer"
          >
            <span>上级属性值</span>
            <i class="el-icon-question" style="margin-left: 5px"></i>
          </span>
        </span>
        <el-select
          v-model="dataForm.parentId"
          size="small"
          placeholder="请选择属性"
          style="width: 80%"
        >
          <el-option label="一级属性值" value="0"></el-option>
          <el-option
            v-for="item in dataList"
            :key="item.id"
            :label="item.valueName"
            :value="item.id"
            size="small"
          ></el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="属性值编号" prop="valueNo">
        <span slot="label">
          <span
            class="span-box"
            title="设置当前属性值的编号，此编号品牌内惟一"
            style="cursor: pointer"
          >
            <span>属性值编号</span>
            <i class="el-icon-question" style="margin-left: 5px"></i>
          </span>
        </span>
        <el-input
          v-model="dataForm.valueNo"
          placeholder="属性值编号"
          size="small"
          style="width: 80%"
          maxlength="64"
        ></el-input>
      </el-form-item>

      <el-form-item label="属性值名称" prop="valueName">
        <span slot="label">
          <span
            class="span-box"
            title="设置当前属性值的名称，此名称品牌内惟一"
            style="cursor: pointer"
          >
            <span>属性值名称</span>
            <i class="el-icon-question" style="margin-left: 5px"></i>
          </span>
        </span>
        <el-input
          v-model="dataForm.valueName"
          placeholder="属性值名称"
          size="small"
          style="width: 80%"
          maxlength="64"
        ></el-input>
      </el-form-item>
      <el-form-item label="属性值别名" prop="valueAlias">
        <span slot="label">
          <span
            class="span-box"
            title="设置属性值的别名，用于扩展属性值名称，一般情况下与名称保持一致即可"
            style="cursor: pointer"
          >
            <span>属性值别名</span>
            <i class="el-icon-question" style="margin-left: 5px"></i>
          </span>
        </span>
        <el-input
          v-model="dataForm.valueAlias"
          placeholder="属性值名称"
          size="small"
          style="width: 80%"
          maxlength="64"
        ></el-input>
      </el-form-item>

      <el-form-item label="是否显示" prop="isShow">
        <span slot="label">
          <span
            class="span-box"
            title="若设置“显示”，当前属性值显示在【小程序】筛选条件中；否则“隐藏”，则不显示"
            style="cursor: pointer"
          >
            <span>是否显示</span>
            <i class="el-icon-question" style="margin-left: 5px"></i>
          </span>
        </span>
        <el-switch v-model="dataForm.isShow"> </el-switch>
      </el-form-item>

      <el-form-item label="排序" prop="sortNumber">
        <el-input-number
          v-model="dataForm.sortNumber"
          :min="1"
          :max="999999"
          placeholder="排序"
          size="small"
          style="width: 80%"
        ></el-input-number>
      </el-form-item>
      <el-form-item label="属性值说明" prop="remarks">
        <div style="margin: 5px 0"></div>
        <el-input
          type="textarea"
          :rows="4"
          placeholder="产品说明"
          v-model="dataForm.remarks"
          maxlength="150"
          show-word-limit
          style="width: 80%"
        >
        </el-input>
      </el-form-item>
    </el-form>

    <span slot="footer" class="dialog-footer">
      <el-button @click="visible = false" size="small">取消</el-button>
      <el-button
        type="primary"
        @click="dataFormSubmit()"
        size="small"
        :disabled="dataLoading"
        >确定</el-button
      >
    </span>
  </el-dialog>
</template>

<script>
import {
  queryProductAttributeValueApi,
  getProductAttributeValueApi,
  addOrUpdateProductAttributeValueApi,
  getGeneralProductAttributeValueApi,
} from "@/api/modules/productAttributeValue";
import { queryProductAttributeApi } from "@/api/modules/productAttribute";
export default {
  data() {
    return {
      visible: false,
      dataLoading: false,
      productAttrList: [],
      dataList: [],
      relationAttributeValueList: [],
      relationAttr: {
        relationAttrName: "",
        relationAttrId: "",
      },
      role: "",
      isGlossProperties: false,
      dataForm: {
        id: undefined,
        parentId: "0",
        productAttributeId: "",
        valueNo: "",
        valueName: "",
        valueAlias: "",
        isShow: true,
        sortNumber: 1,
        remarks: "",
        attributeName: "",
        relationAttributeId: "0",
        relationAttributeValueId: "0",
      },
      dataRule: {
        valueNo: [
          { required: true, message: "属性值编号不能为空", trigger: "blur" },
        ],
        valueName: [
          { required: true, message: "属性值名称不能为空", trigger: "blur" },
        ],
        valueAlias: [
          { required: true, message: "属性值别名不能为空", trigger: "blur" },
        ],
        isShow: [
          { required: true, message: "是否显示不能为空", trigger: "blur" },
        ],
        productAttributeId: [
          { required: true, message: "关联属性不能为空", trigger: "change" },
        ],
        relationAttributeValueId: [
          {
            required: true,
            message: "关联通用属性值不能为空",
            trigger: "change",
          },
        ],
        parentId: [
          { required: true, message: "上级属性值不能为空", trigger: "change" },
        ],
        sortNumber: [
          { required: true, message: "排序不能为空", trigger: "blur" },
        ],
      },
    };
  },
  methods: {
    // 获取数据列表
    async selectAttrValueList() {
      await this.getProductAttributeValueList();
      await this.getRelationAttributeValueList();
      if(this.dataForm.productAttributeId) {
        const item = this.productAttrList.filter(item => item.id == this.dataForm.productAttributeId)[0]
        if(item.isGlossProperties) {
          // 光泽
          this.isGlossProperties = true;
        }
      }

    },
    // 获取关联属性值列表
    async getRelationAttributeValueList() {
      const response = await getGeneralProductAttributeValueApi(
        this.dataForm.productAttributeId
      );

      if (response && response.length > 0) {
        this.relationAttributeValueList = response;
        this.dataForm.relationAttributeId = response[0].productAttributeId;
        this.dataForm.attributeName = response[0].attributeName;
      } else {
        this.relationAttributeValueList = [];
      }
    },
    // 获取属性值列表
    async getProductAttributeValueList() {
      const { records } = await queryProductAttributeValueApi({
        productAttributeId: this.dataForm.productAttributeId,
      });
      if (records && records.length > 0) {
        this.dataList = records;
      } else {
        this.dataList = [];
      }
      this.dataListLoading = false;
    },
    //产品属性列表
    async getProductAttrInfo() {
      const { records } = await queryProductAttributeApi();
      if (records && records.length > 0) {
        this.productAttrList = records;
      } else {
        this.productAttrList = [];
      }
    },
    async init(id, isCopy) {
      this.dataForm.id = id || undefined;
      this.visible = true;
      this.relationAttributeValueList = [];
      await this.getProductAttrInfo();
      this.$nextTick(() => {
        this.$refs["dataForm"].resetFields();
        if (id) {
          getProductAttributeValueApi(id).then(async (response) => {
            this.dataForm = response;
            await this.selectAttrValueList();
            if (isCopy) {
              this.dataForm.id = undefined;
            }
          });
        }
      });
    },
    // 表单提交
    dataFormSubmit() {
      this.$refs["dataForm"].validate((valid) => {
        if (valid) {
          this.dataLoading = true;
          addOrUpdateProductAttributeValueApi(this.dataForm)
            .then(() => {
              this.$baseMessage("操作成功", "success");
              this.visible = false;
              this.$emit("refreshDataList");
            })
            .finally(() => {
              this.dataLoading = false;
            });
        }
      });
    },
  },
};
</script>
