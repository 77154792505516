import request from '@/utils/request'

const url = '/m/userLogin';

// 获取列表
export function queryUserLoginApi (data) {
  return request({
    url: `${url}/query`,
    method: 'post',
    data
  })
}