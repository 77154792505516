<template>
  <div class="app-container">
    <el-form
      :inline="true"
      size="small"
      :model="dataForm"
      ref="dataForm"
      @keyup.enter.native="restPage()"
    >
      <el-form-item prop="deviceNo">
        <el-input
          v-model="dataForm.deviceNo"
          placeholder="设备编号"
          size="small"
          clearable
          maxlength="30"
        ></el-input>
      </el-form-item>
      <el-form-item prop="deviceName">
        <el-input
          v-model="dataForm.deviceName"
          placeholder="设备名称"
          size="small"
          clearable
          maxlength="30"
        ></el-input>
      </el-form-item>
      <el-form-item prop="state">
        <el-select v-model="dataForm.state" placeholder="设备状态">
          <el-option label="全部" value=""></el-option>
          <el-option
            v-for="item in States"
            :key="item.key"
            :label="item.key"
            :value="item.value"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button @click="restPage()" size="small">查询</el-button>
        <el-button @click="reset('dataForm')" size="small">清空</el-button>
        <el-button type="primary" @click="addOrUpdateHandle()" size="small"
          >新增设备</el-button
        >
      </el-form-item>
    </el-form>
    <el-table
      :data="dataList"
      border
      size="small"
      v-loading="dataListLoading"
      @selection-change="selectionChangeHandle"
      style="width: 100%"
    >
      <el-table-column
        type="selection"
        fixed="left"
        header-align="center"
        align="center"
        width="50"
      >
      </el-table-column>
      <el-table-column
        prop="deviceNo"
        header-align="center"
        align="center"
        label="设备编号"
      >
      </el-table-column>
      <el-table-column
        prop="deviceName"
        header-align="center"
        align="center"
        label="设备名称"
      >
      </el-table-column>
      <el-table-column
        prop="picUrl"
        header-align="center"
        align="center"
        label="设备图片"
      >
        <template slot-scope="scope">
          <img :src="scope.row.picUrl" min-width="70" height="70" />
        </template>
      </el-table-column>
      <el-table-column
        prop="deviceModelName"
        header-align="center"
        align="center"
        label="设备型号"
      >
      </el-table-column>
      <el-table-column
        prop="deviceType"
        header-align="center"
        align="center"
        label="设备类型"
      >
        <template slot-scope="scope">
          <span v-if="scope.row.deviceType === 1">运营设备</span>
          <span v-if="scope.row.deviceType === 2">企业设备</span>
          <span v-if="scope.row.deviceType === 3">会员设备</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="isWifi"
        header-align="center"
        align="center"
        label="支持wifi"
      >
        <template slot-scope="scope">
          <span v-if="scope.row.isWifi">支持</span>
          <span v-if="!scope.row.isWifi">不支持</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="limitDate"
        header-align="center"
        align="center"
        label="设备有效期"
      >
        <template slot-scope="scope">
          <span v-if="!scope.row.isTimeLimit">永久</span>
          <span v-else>{{ scope.row.endDate }}</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="state"
        header-align="center"
        align="center"
        label="设备状态"
      >
        <template slot-scope="scope">
          <span v-if="scope.row.state == '0'">停用</span>
          <span v-if="scope.row.state == '1'">正常</span>
          <span v-if="scope.row.state == '2'">同步中</span>
          <span v-if="scope.row.state == '3'">同步完成</span>
        </template>
      </el-table-column>
      <el-table-column
        fixed="right"
        header-align="center"
        align="center"
        width="150"
        label="操作"
      >
        <template slot-scope="scope">
          <el-button
            v-if="scope.row.deviceType == 1"
            type="text"
            size="small"
            style="color: green"
            @click="deviceUserAddHandle(scope.row)"
            >设备授权</el-button
          >
          <el-button
            type="text"
            size="small"
            @click="addOrUpdateHandle(scope.row.id)"
            >编辑</el-button
          >
          <el-button
            v-if="scope.row.deviceType == 1"
            type="text"
            size="small"
            style="color: green"
            @click="deviceUserListHandle(scope.row)"
            >授权列表</el-button
          >
          <el-button
            type="text"
            size="small"
            style="color: red"
            @click="deleteHandle(scope.row.id)"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      size="small"
      @size-change="sizeChangeHandle"
      @current-change="currentChangeHandle"
      :current-page="dataForm.pageNo"
      :page-sizes="[10, 20, 50, 100]"
      :page-size="dataForm.pageSize"
      :total="totalPage"
      layout="total, sizes, prev, pager, next, jumper"
    >
    </el-pagination>
    <!-- 弹窗, 新增 / 修改 -->
    <add-or-update
      v-if="addOrUpdateVisible"
      ref="addOrUpdate"
      @refreshDataList="getDataList"
    ></add-or-update>
    <!--新增授权-->
    <device-user-add
      v-if="addOrUpdateVisible"
      ref="deviceUserAdd"
      @refreshDataList="getDataList"
    ></device-user-add>
    <!--授权列表-->
    <device-user-list
      v-if="addOrUpdateVisible"
      ref="deviceUserList"
      @refreshDataList="getDataList"
    ></device-user-list>
  </div>
</template>

<script>
import { deleteDeviceApi, queryDeviceApi } from '@/api/modules/device'
import AddOrUpdate from './add-or-update'
import DeviceUserAdd from './device-user-add'
import DeviceUserList from './device-user-index'
export default {
  data() {
    return {
      dataForm: {
        deviceNo: '',
        deviceName: '',
        daterange: [],
        state: '',
        pageNo: 1,
        pageSize: 10
      },
      States: [
        {
          value: '0',
          key: '停用'
        },
        {
          value: '1',
          key: '正常'
        },
        {
          value: '2',
          key: '同步中'
        },
        {
          value: '3',
          key: '同步完成'
        },
        {
          value: '4',
          key: '过期'
        },
        {
          value: '5',
          key: '冻结'
        }
      ],
      dataList: [],
      totalPage: 0,
      dataListLoading: false,
      dataListSelections: [],
      addOrUpdateVisible: false
    }
  },
  components: {
    AddOrUpdate,
    DeviceUserAdd,
    DeviceUserList
  },
  created() {
    this.getDataList()
  },
  methods: {
    //重新查询
    restPage() {
      this.dataForm.pageNo = 1
      this.getDataList()
    },
    reset(dataForm) {
      this.$refs.dataForm.resetFields()
      this.restPage()
    },
    // 获取数据列表
    getDataList() {
      this.dataListLoading = true
      let params = {
        deviceNameLike: this.dataForm.deviceName,
        deviceNoLike: this.dataForm.deviceNo,
        pageSize: this.dataForm.pageSize,
        pageNo: this.dataForm.pageNo
      }
      queryDeviceApi(params).then(({ records, totalRecords }) => {
        if (totalRecords > 0) {
          this.dataList = records
          this.totalPage = totalRecords
        } else {
          this.dataList = []
          this.totalPage = 0
        }
        this.dataListLoading = false
      })
    },
    // 每页数
    sizeChangeHandle(val) {
      this.dataForm.pageSize = val
      this.dataForm.pageNo = 1
      this.getDataList()
    },
    // 当前页
    currentChangeHandle(val) {
      this.dataForm.pageNo = val
      this.getDataList()
    },
    // 多选
    selectionChangeHandle(val) {
      this.dataListSelections = val
    },
    // 新增 / 修改
    addOrUpdateHandle(id) {
      this.addOrUpdateVisible = true
      this.$nextTick(() => {
        this.$refs.addOrUpdate.init(id)
      })
    },
    //设备授权
    deviceUserAddHandle(row) {
      this.addOrUpdateVisible = true
      this.$nextTick(() => {
        this.$refs.deviceUserAdd.init(row)
      })
    },
    //授权列表
    deviceUserListHandle(row) {
      this.addOrUpdateVisible = true
      this.$nextTick(() => {
        this.$refs.deviceUserList.init(row)
      })
    },
    // 删除
    deleteHandle(id) {
      this.$confirm(`是否确认删除设备?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        await deleteDeviceApi(id)
        this.$baseMessage('删除成功', 'success')
        this.getDataList()
      })
    }
  }
}
</script>
