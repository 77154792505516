import request from '@/utils/request'

// 获取列表
export function queryFactoryApi (params) {
  return request({
    url: '/m/factory/query',
    method: 'post',
    data: params
  })
}

// 获取信息
export function getFactoryApi (id) {
  return request({
    url: `/m/factory/get/${id}`,
    method: 'get'
  })
}

// 添加
export function addOrUpdateFactoryApi (params) {
  return request({
    url: '/m/factory/addOrUpdate',
    method: 'put',
    data: params
  })
}

// 删除
export function deleteFactoryApi (id) {
  return request({
    url: `/m/factory/delete/${id}`,
    method: 'delete'
  })
}
//品牌下拉列表
export function brandList (params) {
  return request({
    url: '/factory/factory/brandList',
    method: 'post',
    data: params
  })
}

//品牌下拉列表
export function updateFactoryStateApi (data) {
  return request({
    url: '/m/factory/updateState',
    method: 'put',
    data
  })
}
