<template>
  <el-dialog
    :title="!dataForm.id ? '新增' : '修改'"
    :close-on-click-modal="false"
    :visible.sync="visible"
    width="660px"
  >
    <el-form
      :model="dataForm"
      :rules="dataRule"
      size="small"
      ref="dataForm"
      @keyup.enter.native="dataFormSubmit()"
      label-width="120px"
      v-loading="dataLoading"
      style="width: 100%"
      element-loading-text=""
      element-loading-spinner="el-icon-loading"
    >
      <el-row>
        <el-col :span="12">
          <el-form-item label="套餐名称" prop="comboName">
            <el-input
              v-model="dataForm.comboName"
              placeholder="套餐名称"
              size="small"
              maxlength="50"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="角色" prop="roleList">
            <el-select
              v-model="dataForm.roleId"
              size="small"
              placeholder="请选择角色"
            >
              <el-option
                v-for="item in roleList"
                :key="item.id"
                :label="item.roleName"
                :value="item.id"
                size="small"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="套餐级别" prop="level">
            <el-input-number
              v-model="dataForm.level"
              :min="0"
              :max="99999999"
              placeholder="套餐级别"
              style="width: 180px"
            ></el-input-number>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="生产产品数量" prop="productQuantity">
            <el-input-number
              v-model="dataForm.productQuantity"
              :min="0"
              :max="99999999"
              placeholder="生产产品数量"
              style="width: 180px"
            ></el-input-number>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="实验产品数量" prop="labProductQuantity">
            <el-input-number
              v-model="dataForm.labProductQuantity"
              :min="0"
              :max="99999999"
              placeholder="实验产品数量"
              style="width: 180px"
            ></el-input-number>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="色差仪数量" prop="colorimeterQuantity">
            <el-input-number
              v-model="dataForm.colorimeterQuantity"
              :min="0"
              :max="99999999"
              placeholder="色差仪数量"
              style="width: 180px"
            ></el-input-number>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="称料一体机数量" prop="labScaleQuantity">
            <el-input-number
              v-model="dataForm.labScaleQuantity"
              :min="0"
              :max="99999999"
              placeholder="称料一体机数量"
              style="width: 180px"
            ></el-input-number>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="品牌账号数量" prop="brandAccountQuantity">
            <el-input-number
              v-model="dataForm.brandAccountQuantity"
              :min="0"
              :max="99999999"
              placeholder="品牌账号数量"
              style="width: 180px"
            ></el-input-number>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="工厂账号数量" prop="factoryAccountQuantity">
            <el-input-number
              v-model="dataForm.factoryAccountQuantity"
              :min="0"
              :max="99999999"
              placeholder="工厂账号数量"
              style="width: 180px"
            ></el-input-number>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="员工账号数量" prop="employeeAccountQuantity">
            <el-input-number
              v-model="dataForm.employeeAccountQuantity"
              :min="0"
              :max="99999999"
              placeholder="员工账号数量"
              style="width: 180px"
            ></el-input-number>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="visible = false" size="small">取消</el-button>
      <el-button
        type="primary"
        @click="dataFormSubmit()"
        size="small"
        :disabled="dataLoading"
        >确定</el-button
      >
    </span>
  </el-dialog>
</template>

<script>
import { getComboApi, addOrUpdateComboApi } from "@/api/modules/combo";
import { queryRoleApi } from "@/api/modules/role";
export default {
  data() {
    return {
      visible: false,
      dataLoading: false,
      // 角色列表
      roleList: [],
      dataForm: {
        id: undefined,
        comboName: "",
        productQuantity: 0,
        labProductQuantity: 0,
        colorimeterQuantity: 0,
        labScaleQuantity: 0,
        brandAccountQuantity: 1,
        factoryAccountQuantity: 1,
        employeeAccountQuantity: 1,
        roleId: "",
        level: 1,
      },
      dataRule: {
        comboName: [
          { required: true, message: "套餐名称不能为空", trigger: "blur" },
        ],
        roleId: [
          { required: true, message: "请选择角色", trigger: "change" },
        ],
        level: [
          { required: true, message: "套餐级别不能为空", trigger: "blur" },
        ],
        productQuantity: [
          { required: true, message: "生产产品数量不能为空", trigger: "blur" },
        ],
        labProductQuantity: [
          { required: true, message: "实验产品数量不能为空", trigger: "blur" },
        ],
        colorimeterQuantity: [
          { required: true, message: "色差仪数量不能为空", trigger: "blur" },
        ],
        labScaleQuantity: [
          { required: true, message: "称料一体机数量不能为空", trigger: "blur" },
        ],
        brandAccountQuantity: [
          { required: true, message: "品牌账号数量不能为空", trigger: "blur" },
        ],
        factoryAccountQuantity: [
          { required: true, message: "工厂账号数量不能为空", trigger: "blur" },
        ],
        employeeAccountQuantity: [
          { required: true, message: "员工账号数量不能为空", trigger: "blur" },
        ],
      },
    };
  },
  methods: {
    async getRoleList() {
      const { records } = await queryRoleApi({brandId: 0, pageSize: 100000});
      if (records && records.length > 0) {
        this.roleList = records;
      } else {
        this.roleList = [];
      }
    },
    async init(id) {
      await this.getRoleList();
      this.dataForm.id = id || undefined;
      this.visible = true;
      this.$nextTick(() => {
        this.$refs["dataForm"].resetFields();
        if (id) {
          getComboApi(id).then((response) => {
            this.dataForm = response;
          });
        }
      });
    },
    // 表单提交
    dataFormSubmit() {
      this.$refs["dataForm"].validate((valid) => {
        if (valid) {
          this.dataLoading = true;
          addOrUpdateComboApi(this.dataForm)
            .then(() => {
              this.visible = false;
              this.$emit("refreshDataList");
              this.$baseMessage("操作成功", "success");
            })
            .finally(() => {
              this.dataLoading = false;
            });
        }
      });
    },
  },
};
</script>
