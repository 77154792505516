import request from '@/utils/request'

const url = '/m/announcement';

// 获取列表
export function queryAnnouncementApi (data) {
  return request({
    url: `${url}/query`,
    method: 'post',
    data
  })
}

// 获取信息
export function getAnnouncementApi(id) {
  return request({
    url: `${url}/get/${id}`,
    method: 'get'
  })
}


// 获取生效的公告
export function getEnabledAnnouncementApi () {
  return request({
    url: `${url}/getEnabledAnnouncement`,
    method: 'get'
  })
}

// 获取当天激活的公告
export function getActiveAnnouncementApi () {
  return request({
    url: `${url}/getActiveAnnouncement`,
    method: 'get'
  })
}

// 添加点击次数
export function addClick (id) {
  return request({
    url: `${url}/addClick/${id}`,
    method: 'put'
  })
}

// 完成
export function disableAnnouncementApi (id) {
  return request({
    url: `${url}/disableAnnouncement/${id}`,
    method: 'get'
  })
}

// 添加
export function addAnnouncementApi (params) {
  return request({
    url: `${url}/add`,
    method: 'put',
    data: params
  })
}

// 修改
export function updateAnnouncementApi (params) {
  return request({
    url: `${url}/update`,
    method: 'put',
    data: params
  })
}

// 删除
export function deleteAnnouncementApi(data) {
  return request({
    url: `${url}/delete`,
    method: 'delete',
    data
  })
}
