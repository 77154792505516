<template>
  <el-dialog :title="!dataForm.id ? '新增' : '修改'" :close-on-click-modal="false" :visible.sync="visible" width="600px">
    <el-form :model="dataForm" :rules="dataRule" ref="dataForm" @keyup.enter.native="dataFormSubmit()"
      label-width="130px" size="small">
      <el-form-item label="类型" prop="resourceType">
        <el-radio-group v-model="dataForm.resourceType">
          <el-radio v-for="(type, index) in dataForm.typeList" :label="index" :key="index">{{ type }}</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item :label="dataForm.typeList[dataForm.resourceType] + '名称'" prop="resourceName">
        <el-input v-model="dataForm.resourceName"
          :placeholder="dataForm.typeList[dataForm.resourceType] + '名称'"></el-input>
      </el-form-item>
      <el-form-item label="上级菜单" prop="parentName">
        <el-popover ref="menuListPopover" placement="bottom-start" trigger="click">
          <el-tree :data="menuList" :props="menuListTreeProps" node-key="id" ref="menuListTree"
            @current-change="menuListTreeCurrentChangeHandle" :default-expand-all="false" :highlight-current="true"
            :expand-on-click-node="false">
          </el-tree>
        </el-popover>
        <el-input v-model="dataForm.parentName" v-popover:menuListPopover :readonly="true" placeholder="点击选择上级菜单"
          class="menu-list__input"></el-input>
      </el-form-item>
      <el-form-item v-if="dataForm.resourceType !== 3" label="菜单路由" prop="url">
        <el-input v-model="dataForm.url" placeholder="菜单路由"></el-input>
      </el-form-item>
      <el-form-item label="授权标识" prop="resourceCode">
        <el-input v-model="dataForm.resourceCode" placeholder="授权标识"></el-input>
      </el-form-item>
      <el-form-item label="排序号" prop="sortNumber">
        <el-input-number v-model="dataForm.sortNumber" controls-position="right" :min="1" label="排序号"></el-input-number>
      </el-form-item>
      <el-form-item label="状态" size="mini" prop="isEnable">
        <el-radio-group v-model="dataForm.isEnable">
          <el-radio-button :label="true">正常</el-radio-button>
          <el-radio-button :label="false">禁用</el-radio-button>
        </el-radio-group>
      </el-form-item>
      <el-form-item v-if="dataForm.resourceType !== 3" label="菜单图标" prop="icon">
        <el-row>
          <el-col :span="24">
            <el-popover ref="iconListPopover" placement="bottom-start" trigger="click"
              popper-class="mod-menu__icon-popover">
              <div class="mod-menu__icon-list">
                <el-button v-for="(item, index) in iconList" :key="index" @click="iconActiveHandle(item)"
                  :class="{ 'is-active': item === dataForm.icon }">
                  <svg-icon :icon-class="item"></svg-icon>
                </el-button>
              </div>
            </el-popover>
            <el-input v-model="dataForm.icon" v-popover:iconListPopover :readonly="true" placeholder="菜单图标名称"
              class="icon-list__input"></el-input>
          </el-col>
        </el-row>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="visible = false">取消</el-button>
      <el-button type="primary" @click="dataFormSubmit()">确定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { treeDataTranslate } from "@/utils";
import Icon from "@/icons";
import { menuList, menuDataFormSubmit, menuInfo } from "@/api/sys";
export default {
  data() {
    var validateUrl = (rule, value, callback) => {
      if (this.dataForm.resourceType === 1 && !/\S/.test(value)) {
        callback(new Error("菜单URL不能为空"));
      } else {
        callback();
      }
    };
    return {
      visible: false,
      dataForm: {
        id: 0,
        resourceType: 0,
        typeList: ["目录", "菜单", "接口"],
        resourceName: "",
        parentId: 0,
        parentName: "",
        url: "",
        resourceCode: "",
        sortNumber: 1,
        icon: "",
        iconList: [],
        isEnable: true,
      },
      dataRule: {
        resourceName: [
          { required: true, message: "名称不能为空", trigger: "blur" },
        ],
        parentName: [
          { required: true, message: "上级菜单不能为空", trigger: "change" },
        ],
        url: [{ validator: validateUrl, trigger: "blur" }],
      },
      menuList: [],
      menuListTreeProps: {
        label: "resourceName",
        children: "children",
      },
    };
  },
  created() {
    this.iconList = Icon.getNameList();
  },
  methods: {
    async init(id) {
      this.dataForm.id = id || undefined;
      const { records } = await menuList({
        resourceTypeList: [0, 1],
        pageSize: 100000,
      });
      records.push({ id: 0, parentId: -1, resourceName: "一级菜单" });
      this.menuList = treeDataTranslate(records);
      this.visible = true;
      await this.$nextTick(() => {
        this.$refs["dataForm"].resetFields();
      });
      if (!this.dataForm.id) {
        // 新增
        this.menuListTreeSetCurrentNode();
      } else {
        // 修改
        const data = await menuInfo(this.dataForm.id);
        this.dataForm.id = data.id;
        this.dataForm.parentId = data.parentId;
        this.dataForm.resourceType = data.resourceType;
        this.dataForm.resourceName = data.resourceName;
        this.dataForm.url = data.url;
        this.dataForm.resourceCode = data.resourceCode;
        this.dataForm.sortNumber = data.sortNumber;
        this.dataForm.icon = data.icon;
        this.menuListTreeSetCurrentNode();
      }
    },
    // 菜单树选中
    menuListTreeCurrentChangeHandle(data, node) {
      this.dataForm.parentId = data.id;
      this.dataForm.parentName = data.resourceName;
    },
    // 菜单树设置当前选中节点
    menuListTreeSetCurrentNode() {
      this.$refs.menuListTree.setCurrentKey(this.dataForm.parentId);
      this.dataForm.parentName = (this.$refs.menuListTree.getCurrentNode() ||
        {})["resourceName"];
    },
    // 图标选中
    iconActiveHandle(iconName) {
      this.dataForm.icon = iconName;
    },
    // 表单提交
    dataFormSubmit() {
      this.$refs["dataForm"].validate(async (valid) => {
        if (valid) {
          await menuDataFormSubmit(this.dataForm);
          this.$baseMessage("操作成功", "success");
          this.$emit("refreshDataList");
          this.visible = false;
        }
      });
    },
  },
};
</script>

<style lang="scss">
.mod-menu {

  .menu-list__input,
  .icon-list__input {
    >.el-input__inner {
      cursor: pointer;
    }
  }

  &__icon-popover {
    max-width: 370px;
  }

  &__icon-list {
    max-height: 240px;
    padding: 0;
    margin: -8px 0 0 -8px;

    >.el-button {
      padding: 8px;
      margin: 8px 0 0 8px;

      >span {
        display: inline-block;
        vertical-align: middle;
        width: 18px;
        height: 18px;
        font-size: 18px;
      }
    }
  }

  .icon-list__tips {
    font-size: 18px;
    text-align: center;
    color: #e6a23c;
    cursor: pointer;
  }
}
</style>
