<template>
  <div class="app-container">
    <el-form
      :inline="true"
      size="small"
      :model="dataForm"
      ref="dataForm"
      @keyup.enter.native="restPage()"
    >
      <el-form-item prop="title">
        <el-input
          v-model="dataForm.title"
          placeholder="标题"
          size="small"
          clearable
          maxlength="30"
        ></el-input>
      </el-form-item>
      <el-form-item prop="content">
        <el-input
          v-model="dataForm.content"
          placeholder="内容"
          size="small"
          clearable
          maxlength="250"
        ></el-input>
      </el-form-item>
      <el-form-item label="开始时间范围" prop="dateRange">
        <div class="block">
          <el-date-picker
            v-model="dataForm.dateRange"
            value-format="yyyy-MM-dd"
            type="daterange"
            range-separator="-"
            start-placeholder="开始时间下限"
            end-placeholder="开始日期上线"
          >
          </el-date-picker>
        </div>
      </el-form-item>
      <el-form-item>
        <el-button @click="restPage()" size="small">查询</el-button>
        <el-button @click="reset('dataForm')" size="small">清空</el-button>
        <el-button type="primary" @click="addOrUpdateHandle()" size="small"
          >新增</el-button
        >
        <el-button
          type="danger"
          @click="deleteHandle()"
          :disabled="dataListSelections.length <= 0"
          size="small"
          >批量删除</el-button
        >
      </el-form-item>
    </el-form>
    <el-table
      :data="dataList"
      border
      size="small"
      v-loading="dataListLoading"
      @selection-change="selectionChangeHandle"
      style="width: 100%"
    >
      <el-table-column
        type="selection"
        fixed="left"
        header-align="center"
        align="center"
        width="50"
      >
      </el-table-column>
      <el-table-column
        prop="title"
        header-align="center"
        align="center"
        label="标题"
      >
      </el-table-column>
      <el-table-column
        prop="content"
        header-align="center"
        align="center"
        label="内容"
      >
      </el-table-column>
      <el-table-column
        prop="startTime"
        header-align="center"
        align="center"
        label="开始时间"
      >
      </el-table-column>
      <el-table-column
        prop="endTime"
        header-align="center"
        align="center"
        label="结束时间"
      >
      </el-table-column>
      <el-table-column
        prop="isEnabled"
        header-align="center"
        align="center"
        label="状态"
      >
        <template slot-scope="scope">
          <span v-if="scope.row.isEnabled == '0'">失效</span>
          <span v-if="scope.row.isEnabled == '1'">生效</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="clickNum"
        header-align="center"
        align="center"
        label="点击数次"
      >
      </el-table-column>
      <el-table-column
        prop="createTime"
        header-align="center"
        align="center"
        label="创建时间"
      >
      </el-table-column>
      <el-table-column
        fixed="right"
        header-align="center"
        align="center"
        width="150"
        label="操作"
      >
        <template slot-scope="scope">
          <el-button
            type="text"
            size="small"
            @click="addOrUpdateHandle(scope.row.id, true)"
            >查看</el-button
          >
          <el-button
            type="text"
            size="small"
            @click="addOrUpdateHandle(scope.row.id, false)"
            >编辑</el-button
          >
          <el-button
            type="text"
            size="small"
            style="color: green"
            v-if="scope.row.isEnabled == '1'"
            @click="disableHandle(scope.row.id)"
            >完成</el-button
          >
          <el-button
            type="text"
            size="small"
            style="color: red"
            @click="deleteHandle(scope.row.id)"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      size="small"
      @size-change="sizeChangeHandle"
      @current-change="currentChangeHandle"
      :current-page="pageIndex"
      :page-sizes="[10, 20, 50, 100]"
      :page-size="pageSize"
      :total="totalPage"
      layout="total, sizes, prev, pager, next, jumper"
    >
    </el-pagination>
    <!-- 弹窗, 新增 / 修改 -->
    <add-or-update
      v-if="addOrUpdateVisible"
      ref="addOrUpdate"
      @refreshDataList="getDataList"
    ></add-or-update>
  </div>
</template>

<script>
import {
  queryAnnouncementApi,
  deleteAnnouncementApi,
  disableAnnouncementApi
} from '@/api/modules/announcement'
import AddOrUpdate from './add-or-update'
export default {
  data() {
    return {
      dataForm: {
        dateRange: [],
        title: '',
        content: ''
      },
      dataList: [],
      pageIndex: 1,
      pageSize: 10,
      totalPage: 0,
      dataListLoading: false,
      dataListSelections: [],
      addOrUpdateVisible: false
    }
  },
  components: {
    AddOrUpdate
  },
  created() {
    this.getDataList()
  },
  methods: {
    //重新查询
    restPage() {
      this.pageIndex = 1
      this.getDataList()
    },
    reset(dataForm) {
      this.$refs.dataForm.resetFields()
      this.restPage()
    },
    // 获取数据列表
    getDataList() {
      this.dataListLoading = true
      var startTimeMin = null
      var startTimeMax = null
      if (this.dataForm.dateRange && this.dataForm.dateRange.length > 0) {
        startTimeMin = this.dataForm.dateRange[0]
        startTimeMax = this.dataForm.dateRange[1]
      }
      var params = {
        page: this.pageIndex,
        limit: this.pageSize,
        title: this.dataForm.title,
        content: this.dataForm.content,
        startTimeMin: startTimeMin ? startTimeMin + ' 00:00:00' : '',
        startTimeMax: startTimeMax ? startTimeMax + ' 23:59:59' : ''
      }
      queryAnnouncementApi(params).then(({ records, totalRecords }) => {
        if (totalRecords > 0) {
          this.dataList = records
          this.totalPage = totalRecords
        } else {
          this.dataList = []
          this.totalPage = 0
        }
        this.dataListLoading = false
      })
    },
    // 每页数
    sizeChangeHandle(val) {
      this.pageSize = val
      this.pageIndex = 1
      this.getDataList()
    },
    // 当前页
    currentChangeHandle(val) {
      this.pageIndex = val
      this.getDataList()
    },
    // 多选
    selectionChangeHandle(val) {
      this.dataListSelections = val
    },
    // 新增 / 修改
    addOrUpdateHandle(id, disabled) {
      this.addOrUpdateVisible = true
      this.$nextTick(() => {
        this.$refs.addOrUpdate.init(id, disabled)
      })
    },
    disableHandle(id) {
      disableAnnouncementApi(id).then(() => {
        this.$baseMessage('操作成功', 'success')
        this.getDataList()
        this.$nextTick(() => {
          this.restPage()
        })
        this.dataListLoading = false
      })
    },
    // 删除
    deleteHandle(id) {
      var ids = id
        ? [id]
        : this.dataListSelections.map((item) => {
            return item.id
          })

      this.$confirm(`是否确认删除公告?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(async () => {
          await deleteAnnouncementApi(ids)
          this.$baseMessage('操作成功', 'success')
          this.getDataList()
        })
        .catch(() => {})
    }
  }
}
</script>
