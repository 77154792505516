<template>
  <el-dialog
    :title="!dataForm.id ? '新增' : '修改'"
    :close-on-click-modal="false"
    :visible.sync="visible">
    <el-form :model="dataForm" :rules="dataRule"  size="small" ref="dataForm" @keyup.enter.native="dataFormSubmit()" label-width="80px"
     v-loading="dataLoading" style="width:100%;"  element-loading-text=""  element-loading-spinner="el-icon-loading"
    >
      <el-form-item label="意见反馈内容" prop="suggestions">
        <el-input v-model="dataForm.suggestions" placeholder="意见反馈内容"  size="small"></el-input>
      </el-form-item>
      <el-form-item label="会员表id" prop="sysMemberId">
        <el-input v-model="dataForm.sysMemberId" placeholder="会员表id"  size="small"></el-input>
      </el-form-item>
      <el-form-item label="是否删除（0：否，1：是）" prop="isDelete">
        <el-input v-model="dataForm.isDelete" placeholder="是否删除（0：否，1：是）"  size="small"></el-input>
      </el-form-item>
      <el-form-item label="创建人" prop="createBy">
        <el-input v-model="dataForm.createBy" placeholder="创建人"  size="small"></el-input>
      </el-form-item>
      <el-form-item label="创建时间" prop="createDate">
        <el-input v-model="dataForm.createDate" placeholder="创建时间"  size="small"></el-input>
      </el-form-item>
      <el-form-item label="更新人" prop="updateBy">
        <el-input v-model="dataForm.updateBy" placeholder="更新人"  size="small"></el-input>
      </el-form-item>
      <el-form-item label="更新时间" prop="updateDate">
        <el-input v-model="dataForm.updateDate" placeholder="更新时间"  size="small"></el-input>
      </el-form-item>
      <el-form-item label="版本号" prop="version">
        <el-input v-model="dataForm.version" placeholder="版本号"  size="small"></el-input>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="visible = false" size="small">取消</el-button>
      <el-button type="primary" @click="dataFormSubmit()" size="small"  :disabled="dataLoading">确定</el-button>
    </span>
  </el-dialog>
</template>

<script>
  import {info,add,update} from '@/api/suggestions'
  export default {
    data () {
      return {
        visible: false,
        dataLoading: false,
        dataForm: {
          memberSuggestionsId: 0,
          suggestions: '',
          sysMemberId: '',
          isDelete: '',
          createBy: '',
          createDate: '',
          updateBy: '',
          updateDate: '',
          version: ''
        },
        dataRule: {
          suggestions: [
            { required: true, message: '意见反馈内容不能为空', trigger: 'blur' }
          ],
          sysMemberId: [
            { required: true, message: '会员表id不能为空', trigger: 'blur' }
          ],
          isDelete: [
            { required: true, message: '是否删除（0：否，1：是）不能为空', trigger: 'blur' }
          ],
          createBy: [
            { required: true, message: '创建人不能为空', trigger: 'blur' }
          ],
          createDate: [
            { required: true, message: '创建时间不能为空', trigger: 'blur' }
          ],
          updateBy: [
            { required: true, message: '更新人不能为空', trigger: 'blur' }
          ],
          updateDate: [
            { required: true, message: '更新时间不能为空', trigger: 'blur' }
          ],
          version: [
            { required: true, message: '版本号不能为空', trigger: 'blur' }
          ]
        }
      }
    },
    methods: {
      init (id) {
        this.dataForm.memberSuggestionsId = id || 0
        this.visible = true
        this.$nextTick(() => {
          this.$refs['dataForm'].resetFields()
          if (this.dataForm.memberSuggestionsId) {
            info({memberSuggestionsId:this.dataForm.memberSuggestionsId}).then(({data}) => {
              if (data && data.code === 0) {
                this.dataForm.suggestions = data.sysmembersuggestions.suggestions
                this.dataForm.sysMemberId = data.sysmembersuggestions.sysMemberId
                this.dataForm.isDelete = data.sysmembersuggestions.isDelete
                this.dataForm.createBy = data.sysmembersuggestions.createBy
                this.dataForm.createDate = data.sysmembersuggestions.createDate
                this.dataForm.updateBy = data.sysmembersuggestions.updateBy
                this.dataForm.updateDate = data.sysmembersuggestions.updateDate
                this.dataForm.version = data.sysmembersuggestions.version
              }
            })
          }
        })
      },
      // 表单提交
      dataFormSubmit () {
        this.$refs['dataForm'].validate((valid) => {
          if (valid) {
          	this.dataLoading = true;
            var params = {
              'memberSuggestionsId': this.dataForm.memberSuggestionsId || undefined,
              'suggestions': this.dataForm.suggestions,
              'sysMemberId': this.dataForm.sysMemberId,
              'isDelete': this.dataForm.isDelete,
              'createBy': this.dataForm.createBy,
              'createDate': this.dataForm.createDate,
              'updateBy': this.dataForm.updateBy,
              'updateDate': this.dataForm.updateDate,
              'version': this.dataForm.version
            }
            var tick = !this.dataForm.memberSuggestionsId ? add(params) : update(params)
            tick.then(({data}) => {
              if (data && data.code === 0) {
                this.$message({
                  message: '操作成功',
                  type: 'success',
                  duration: 1500,
                  onClose: () => {
                    this.visible = false;
	              	this.dataLoading = false;
                    this.$emit('refreshDataList')
                  }
                })
              } else {
                this.$message.error(data.msg)
                this.dataLoading = false;
              }
            })
          }
        })
      }
    }
  }
</script>
