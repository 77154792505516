import request from "@/utils/request";

const url = "/m/productAttribute/";

/**
 * 分页获取产品属性列表信息
 * @returns
 */
export function queryProductAttributeApi(data) {
  if (!data) {
    data = { pageSize: 10000 };
  }
  return request({
    url: `${url}query`,
    method: "post",
    data,
  });
}

/**
 * 创建/编辑产品属性
 * @returns
 */
export function addOrUpdateProductAttributeApi(data) {
  return request({
    url: `${url}${!data.id ? "add" : "update"}`,
    method: "put",
    data,
  });
}

/**
 * 根据ID获取产品属性信息
 * @returns
 */
export function getProductAttributeApi(id) {
  return request({
    url: `${url}get/${id}`,
    method: "get",
  });
}

/**
 * 删除产品属性
 * @returns
 */
export function deleteProductAttributeApi(id) {
  return request({
    url: `${url}delete/${id}`,
    method: "delete",
  });
}

/**
 * 修改产品属性状态
 */
export function updateProductAttributeStatusApi(id) {
  return request({
    url: `${url}updateStatus/${id}`,
    method: "put",
  });
}


/**
 * 获取通用属性列表
 */
 export function getGeneralProductAttributeApi() {
  return request({
    url: `${url}getGeneralList`,
    method: "get",
  });
}
