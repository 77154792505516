<template>
  <el-dialog
    :title="!dataForm.id ? '新增' : '修改'"
    :close-on-click-modal="false"
    :visible.sync="visible"
    width="650px"
  >
    <el-form
      :model="dataForm"
      :rules="dataRule"
      ref="dataForm"
      @keyup.enter.native="dataFormSubmit()"
      label-width="80px"
      size="small"
      ><el-row>
        <el-col :span="12">
          <el-form-item label="用户名" prop="userName">
            <el-input
              v-model="dataForm.userName"
              placeholder="登录帐号"
              maxlength="30"
              :disabled="dataForm.id"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="昵称" prop="nickName">
            <el-input
              v-model="dataForm.nickName"
              placeholder="昵称"
              maxlength="30"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12" v-if="!dataForm.id">
          <el-form-item
            label="密码"
            prop="userPwd"
          >
            <el-input
              v-model="dataForm.userPwd"
              type="password"
              placeholder="密码"
              maxlength="20"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12" v-if="!dataForm.id">
          <el-form-item
            label="确认密码"
            prop="comfirmPassword"
          >
            <el-input
              v-model="dataForm.comfirmPassword"
              type="password"
              placeholder="确认密码"
              maxlength="20"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="邮箱" prop="email">
            <el-input v-model="dataForm.email" placeholder="邮箱"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="手机号" prop="mobile">
            <el-input v-model="dataForm.mobile" placeholder="手机号"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="状态" size="mini" prop="isEnable">
            <el-radio-group v-model="dataForm.isEnable">
              <el-radio-button :label="true">正常</el-radio-button>
              <el-radio-button :label="false">禁用</el-radio-button>
            </el-radio-group>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="管理员" prop="isAdmin">
            <el-radio-group v-model="dataForm.isAdmin">
              <el-radio-button :label="true">是</el-radio-button>
              <el-radio-button :label="false">否</el-radio-button>
            </el-radio-group>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item
            v-if="!dataForm.isAdmin"
            label="角色"
            size="mini"
            prop="roleId"
          >
            <el-select
              v-model="dataForm.roleIdList"
              multiple
              placeholder="请选择"
            >
              <el-option
                v-for="item in roleList"
                :key="item.id"
                :label="item.roleName"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="visible = false">取消</el-button>
      <el-button type="primary" :loading="loading" @click="dataFormSubmit()"
        >确定</el-button
      >
    </span>
  </el-dialog>
</template>

<script>
import { isEmail, isMobile } from "@/utils/validate";
import { queryRoleListApi, userDataFormSubmit, getUserByIdApi } from "@/api/sys";
import { cryptoAes, deepClone } from "@/utils/index";
export default {
  data() {
    var validateUserName = (rule, value, callback) => {
      if (value.length < 6 || value.length > 30) {
        callback(new Error("用户账号为6-30位"));
      } else {
        callback();
      }
    };
    var validatePassword = (rule, value, callback) => {
      if (!this.dataForm.id && !/\S/.test(value)) {
        callback(new Error("密码不能为空"));
      } else if (value.length < 6) {
        callback(new Error("密码为6-20位"));
      } else {
        callback();
      }
    };
    var validateComfirmPassword = (rule, value, callback) => {
      if (!this.dataForm.id && !/\S/.test(value)) {
        callback(new Error("确认密码不能为空"));
      } else if (this.dataForm.userPwd !== value) {
        callback(new Error("确认密码与密码输入不一致"));
      } else {
        callback();
      }
    };
    var validateEmail = (rule, value, callback) => {
      if (!isEmail(value)) {
        callback(new Error("邮箱格式错误"));
      } else {
        callback();
      }
    };
    var validateMobile = (rule, value, callback) => {
      if (!isMobile(value)) {
        callback(new Error("手机号格式错误"));
      } else {
        callback();
      }
    };
    return {
      loading: false,
      visible: false,
      roleList: [],
      dataForm: {
        id: undefined,
        userName: "",
        nickName: "",
        userPwd: "",
        comfirmPassword: "",
        email: "",
        mobile: "",
        isAdmin: false,
        roleIdList: [],
        isEnable: true,
      },
      dataRule: {
        userName: [
          { required: true, message: "用户名不能为空", trigger: "blur" },
          { validator: validateUserName, trigger: "blur" }
        ],
        nickName: [
          { required: true, message: "昵称不能为空", trigger: "blur" },
        ],
        userPwd: [
          { required: true, message: "密码不能为空", trigger: "blur" },
          { validator: validatePassword, trigger: "blur" }
        ],
        comfirmPassword: [
          { required: true, message: "请确认密码", trigger: "blur" },
          { validator: validateComfirmPassword, trigger: "blur" },
        ],
        roleIdList: [
          { required: true, message: "请选择角色", trigger: "change" },
        ],
      },
    };
  },
  methods: {
    async init(id) {
      this.dataForm.id = id || undefined;
      const { records } = await queryRoleListApi({ pageSize: 10000 });
      if (records && records.length > 0) {
        this.roleList = records;
      } else {
        this.roleList = [];
      }
      this.visible = true;
      this.$nextTick(() => {
        this.$refs["dataForm"].resetFields();
        if (id) {
          getUserByIdApi(id).then(response => {
            this.dataForm.userName = response.userName;
            this.dataForm.nickName = response.nickName;
            this.dataForm.email = response.email;
            this.dataForm.mobile = response.mobile;
            this.dataForm.isEnable = response.isEnable;
            this.dataForm.isAdmin = response.isAdmin;
            this.dataForm.roleIdList = response.roleIdList;
          })
        }
      });
    },
    // 表单提交
    dataFormSubmit() {
      this.$refs["dataForm"].validate(async (valid) => {
        if (valid) {
          this.loading = true;
          let data = deepClone(this.dataForm)
          data.userPwd = cryptoAes(data.userPwd);
          userDataFormSubmit(data)
            .then(() => {
              this.$baseMessage("操作成功", "success");
              this.visible = false
              this.$emit("refreshDataList");
            })
            .finally(() => {
              this.loading = false;
            });
        }
      });
    },
  },
};
</script>


