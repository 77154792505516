<template>
    <div class="app-container">
        <el-form :inline="true" size="small" :model="dataForm" ref="dataForm" @keyup.enter.native="restPage()">
            <el-form-item prop="mediaType" >
                <el-select v-model="dataForm.mediaType" placeholder="广告类型">
                    <el-option label="全部" value=""></el-option>
                    <el-option label="小程序" value="0"></el-option>
                    <el-option label="门户网站" value="1"></el-option>
                </el-select>
            </el-form-item>
            <!--<el-form-item prop="daterange">-->
                <!--<el-date-picker type="daterange" v-model="dataForm.daterange" range-separator="~" format="yyyy-MM-dd"-->
                                <!--value-format="yyyy-MM-dd HH:mm:ss"-->
                                <!--:editable="false" start-placeholder="开始时间" end-placeholder="结束时间"></el-date-picker>-->
            <!--</el-form-item>-->
            <el-form-item>
                <el-button @click="restPage()" size="small">查询</el-button>
                <el-button @click="reset('dataForm')" size="small">清空</el-button>
                <el-button v-if="isAuth('advert:advert:save')" type="primary" @click="addOrUpdateHandle()" size="small">
                    新增
                </el-button>
                <el-button v-if="isAuth('advert:advert:delete')" type="danger" @click="deleteHandle()"
                           :disabled="dataListSelections.length <= 0" size="small">批量删除
                </el-button>
            </el-form-item>
        </el-form>
        <el-table
                :data="dataList"
                border
                size="small"
                v-loading="dataListLoading"
                @selection-change="selectionChangeHandle"
                style="width: 100%;">
            <el-table-column
                    type="selection"
                    fixed="left"
                    header-align="center"
                    align="center"
                    width="50">
            </el-table-column>
            <el-table-column
                    type="index"
                    header-align="center"
                    align="center"
                    label="序号">
            </el-table-column>
            <el-table-column
                    prop="imageUrl"
                    header-align="center"
                    align="center"
                    label="广告图片">
                <template slot-scope="scope">
                    <img :src="scope.row.imageUrl" min-width="70" height="70"/>
                </template>
            </el-table-column>
            <!--<el-table-column-->
                    <!--prop="advertPositionId"-->
                    <!--header-align="center"-->
                    <!--align="center"-->
                    <!--label="广告位id">-->
            <!--</el-table-column>-->
            <el-table-column
                    prop="mediaType"
                    header-align="center"
                    align="center"
                    label="广告类型">
                <template slot-scope="scope">
                    <span v-if="scope.row.mediaType=='0'">小程序</span>
                    <span v-if="scope.row.mediaType=='1'">门户网站</span>
                </template>
            </el-table-column>
            <el-table-column
                    prop="positionTypeName"
                    header-align="center"
                    align="center"
                    label="广告位置">
            </el-table-column>
            <el-table-column
                    prop="name"
                    header-align="center"
                    align="center"
                    label="广告名称">
            </el-table-column>
            <el-table-column
                    prop="link"
                    header-align="center"
                    align="center"
                    label="链接">
            </el-table-column>
            <el-table-column
                    prop="sort"
                    header-align="center"
                    align="center"
                    label="排序">
            </el-table-column>

            <el-table-column
                    fixed="right"
                    header-align="center"
                    align="center"
                    width="150"
                    label="操作">
                <template slot-scope="scope">
                    <el-button type="text" size="small" @click="addOrUpdateHandle(scope.row.id)">修改</el-button>
                    <el-button type="text" size="small" @click="deleteHandle(scope.row.id)">删除</el-button>
                </template>
            </el-table-column>
        </el-table>
        <el-pagination
                size="small"
                @size-change="sizeChangeHandle"
                @current-change="currentChangeHandle"
                :current-page="pageIndex"
                :page-sizes="[10, 20, 50, 100]"
                :page-size="pageSize"
                :total="totalPage"
                layout="total, sizes, prev, pager, next, jumper">
        </el-pagination>
        <!-- 弹窗, 新增 / 修改 -->
        <add-or-update v-if="addOrUpdateVisible" ref="addOrUpdate" @refreshDataList="getDataList"></add-or-update>
    </div>
</template>

<script>
    import {list, del} from '@/api/modules/advert'
    import AddOrUpdate from './add-or-update'

    export default {
        data() {
            return {
                dataForm: {
                    mediaType: null,
                    daterange: []
                },
                dataList: [],
                pageIndex: 1,
                pageSize: 10,
                totalPage: 0,
                dataListLoading: false,
                dataListSelections: [],
                addOrUpdateVisible: false
            }
        },
        components: {
            AddOrUpdate
        },
        created() {
            this.getDataList()
        },
        methods: {
            //重新查询
            restPage() {
                this.pageIndex = 1;
                this.getDataList();
            },
            reset(dataForm) {
                this.$refs.dataForm.resetFields();
                this.restPage();
            },
            // 获取数据列表
            getDataList() {
                this.dataListLoading = true
                var params = {
                    page: this.pageIndex,
                    limit: this.pageSize,
                    mediaType: this.dataForm.mediaType
                }
                list(params).then(({data}) => {
                    if (data && data.code === 0) {
                        this.dataList = data.page.list
                        this.totalPage = data.page.totalCount
                    } else {
                        this.dataList = []
                        this.totalPage = 0
                    }
                    this.dataListLoading = false
                })
            },
            // 每页数
            sizeChangeHandle(val) {
                this.pageSize = val
                this.pageIndex = 1
                this.getDataList()
            },
            // 当前页
            currentChangeHandle(val) {
                this.pageIndex = val
                this.getDataList()
            },
            // 多选
            selectionChangeHandle(val) {
                this.dataListSelections = val
            },
            // 新增 / 修改
            addOrUpdateHandle(id) {
                this.addOrUpdateVisible = true
                this.$nextTick(() => {
                    this.$refs.addOrUpdate.init(id)
                })
            },
            // 删除
            deleteHandle(id) {
                var ids = id ? [id] : this.dataListSelections.map(item => {
                    return item.id
                })
                this.$confirm(`确定删除操作?`, '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    del(ids).then(({data}) => {
                        if (data && data.code === 0) {
                            this.$message({
                                message: '操作成功',
                                type: 'success',
                                duration: 1500,
                                onClose: () => {
                                    this.getDataList()
                                }
                            })
                        } else {
                            this.$message.error(data.msg)
                        }
                    })
                })
            }
        }
    }
</script>
