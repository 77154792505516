<template>
  <el-dialog
    :title="!dataForm.id ? '新增' : '修改'"
    :close-on-click-modal="false"
    :visible.sync="visible">
    <el-form :model="dataForm" :rules="dataRule"  size="small" ref="dataForm" @keyup.enter.native="dataFormSubmit()" label-width="80px"
     v-loading="dataLoading" style="width:100%;"  element-loading-text=""  element-loading-spinner="el-icon-loading"
    >
      <el-form-item label="名称" prop="name">
        <el-input v-model="dataForm.name" placeholder="名称"  size="small"></el-input>
      </el-form-item>
      <el-form-item label="排序" prop="rank">
        <!--<el-input v-model="dataForm.rank" placeholder="排序"  size="small"></el-input>-->
        <el-input-number v-model="dataForm.rank" controls-position="right" :min="0"
                         label="排序号"></el-input-number>
      </el-form-item>
      <el-form-item label="状态" prop="state">
        <el-radio v-model="dataForm.state" label="0">启用</el-radio>
        <el-radio v-model="dataForm.state" label="1">禁用</el-radio>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="visible = false" size="small">取消</el-button>
      <el-button type="primary" @click="dataFormSubmit()" size="small"  :disabled="dataLoading">确定</el-button>
    </span>
  </el-dialog>
</template>

<script>
  import {info,add,update} from '@/api/modules/articlecategory'
  export default {
    data () {
      return {
        visible: false,
        dataLoading: false,
        dataForm: {
          id: 0,
          name: '',
          rank: '',
          state: '',
          delFlag: '',
          createBy: '',
          createDate: '',
          updateBy: '',
          updateDate: '',
          remarks: ''
        },
        dataRule: {
          name: [
            { required: true, message: '名称不能为空', trigger: 'blur' }
          ],
          rank: [
            { required: true, message: '排序不能为空', trigger: 'blur' }
          ],
          state: [
            { required: true, message: '状态不能为空', trigger: 'blur' }
          ]
        }
      }
    },
    methods: {
      init (id) {
        this.dataForm.id = id || 0
        this.visible = true
        this.$nextTick(() => {
          this.$refs['dataForm'].resetFields()
          if (this.dataForm.id) {
            info({id:this.dataForm.id}).then(({data}) => {
              if (data && data.code === 0) {
                this.dataForm.name = data.articleCategory.name
                this.dataForm.rank = data.articleCategory.rank
                this.dataForm.state = data.articleCategory.state
                this.dataForm.delFlag = data.articleCategory.delFlag
                this.dataForm.createBy = data.articleCategory.createBy
                this.dataForm.createDate = data.articleCategory.createDate
                this.dataForm.updateBy = data.articleCategory.updateBy
                this.dataForm.updateDate = data.articleCategory.updateDate
                this.dataForm.remarks = data.articleCategory.remarks
              }
            })
          }
        })
      },
      // 表单提交
      dataFormSubmit () {
        this.$refs['dataForm'].validate((valid) => {
          if (valid) {
            this.dataLoading = true;
            var params = {
              'id': this.dataForm.id || undefined,
              'name': this.dataForm.name,
              'rank': this.dataForm.rank,
              'state': this.dataForm.state,
              'delFlag': this.dataForm.delFlag,
              'createBy': this.dataForm.createBy,
              'createDate': this.dataForm.createDate,
              'updateBy': this.dataForm.updateBy,
              'updateDate': this.dataForm.updateDate,
              'remarks': this.dataForm.remarks
            }
            var tick = !this.dataForm.id ? add(params) : update(params)
            tick.then(({data}) => {
              if (data && data.code === 0) {
                this.$message({
                  message: '操作成功',
                  type: 'success',
                  duration: 1500,
                  onClose: () => {
                    this.visible = false;
                    this.dataLoading = false;
                    this.$emit('refreshDataList')
                  }
                })
              } else {
                this.$message.error(data.msg)
                this.dataLoading = false;
              }
            })
          }
        })
      }
    }
  }
</script>
