<template>
  <el-dialog
    title="上传文件"
    :close-on-click-modal="false"
    @close="closeHandle"
    :visible.sync="visible"
  >
    <el-form
      :model="dataForm"
      :rules="dataRule"
      size="small"
      ref="dataForm"
      label-width="25%"
      v-loading="dataLoading"
      style="width: 100%"
    >
      <el-form-item label="色号品牌" prop="colorBookBrandId">
        <el-select
          v-model="dataForm.colorBookBrandId"
          size="small"
          placeholder="请选择品牌"
          @change="getColorBookCategory()"
        >
          <el-option
            v-for="item in brandList"
            :key="item.id"
            :label="item.brandName"
            :value="item.id"
            size="small"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="色号分类" prop="colorBookCategoryId">
        <el-select
          v-model="dataForm.colorBookCategoryId"
          size="small"
          placeholder="请选择色号分类"
        >
          <el-option
            v-for="item in colorBookCategoryList"
            :key="item.id"
            :label="item.categoryName"
            :value="item.id"
            size="small"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="上传文件">
        <el-upload
          drag
          action="13"
          :http-request="uploadExcel"
          :before-upload="beforeUploadHandle"
          multiple
          :file-list="fileList"
          :show-file-list="false"
        >
          <i class="el-icon-upload"></i>
          <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
          <div class="el-upload__tip" slot="tip">
            只支持Excel文件（.xls，.xlsx）
          </div>
        </el-upload>
      </el-form-item>
    </el-form>
    <p v-for="(item, index) in errorList" :key="index" style="color: #F56C6C">{{ item }}</p>
  </el-dialog>
</template>

<script>
import { importExcel as excelImport } from "@/api/modules/colorbook";
import { queryColorBookCategoryApi } from "@/api/modules/colorbookcategory";
import { queryColorBookBrandApi } from "@/api/modules/colorBookBrand";
export default {
  data() {
    return {
      visible: false,
      dataLoading: false,
      successNum: 0,
      colorBookCategoryList: [],
      brandList: [],
      errorList: [],
      dataForm: {
        colorBookBrandId: "",
        colorBookCategoryId: "",
      },
      dataRule: {
        colorBookBrandId: [
          { required: true, message: "请选择色号品牌", trigger: "change" },
        ],
        uploadExcelFile: [
          { required: true, message: "请选择色号品牌", trigger: "change" },
        ],
      },
      fileList: [],
    };
  },
  methods: {
    async init(id) {
      await this.getBrandList();
      this.visible = true;
      this.getColorBookCategory();
    },
    // 上传之前
    beforeUploadHandle(file) {
      const isExcel =
        file.name.toString().indexOf(".xls") > 0 ||
        file.name.toString().indexOf(".xlsx") > 0;
      const isLt10M = file.size / 1024 / 1024 < 10;
      if (!isExcel) {
        this.$message.error("请上传文件格式不正确!");
      }
      if (!isLt10M) {
        this.$message.error("上传文件大小不能超过 10MB!");
      }
      const flag = isExcel && isLt10M;
      if(flag) {
        this.errorList = [];
      }
      return flag;
    },
    async getBrandList() {
      const { records, totalRecords } = await queryColorBookBrandApi();
      if (totalRecords > 0) {
        this.brandList = records;
      } else {
        this.brandList = [];
      }
      this.getColorBookCategory();
    },
    //分类下拉
    async getColorBookCategory() {
      this.dataForm.colorBookCategoryId = "";
      const params = {
        colorBookBrandId: this.dataForm.colorBookBrandId,
        pageSize: 100000,
      };
      const { records, totalRecords } = await queryColorBookCategoryApi(params);
      if (totalRecords > 0) {
        this.colorBookCategoryList = records;
      } else {
        this.colorBookCategoryList = [];
      }
    },
    // 弹窗关闭时
    closeHandle() {
      this.fileList = [];
      this.$emit("refreshDataList");
    },
    //upload Excel
    uploadExcel(item) {
      if (
        this.dataForm.colorBookBrandId == "" ||
        this.dataForm.colorBookBrandId == null ||
        this.dataForm.colorBookBrandId == undefined
      ) {
        this.$message.error("请选择色号品牌!");
        return;
      }
      let importData = new FormData();
      importData.append("file", item.file);
      importData.append("brandId", this.dataForm.colorBookBrandId);
      importData.append("categoryId", (this.dataForm.colorBookCategoryId) ? this.dataForm.colorBookCategoryId : 0);
      excelImport(importData).then((response) => {
        if (response && response.length > 0) {
          this.errorList = response;
          this.$baseMessage("部分数据导入成功", "warning", 4000);
        } else {
          this.$baseMessage("数据导入成功", "success");
          this.visible = false;
          this.$emit("refreshDataList");
        }
      });
    },
  },
};
</script>
