<template>

    <!--<div style="display: flex" class="d2">
      <div @click="addRecipe(1)" :class="{'d1':subIndex==1}">样板价格设置</div>
      <div style="margin-left: 50px" :class="{'d1':subIndex==2}" @click="addRecipeHandle(2)">样粉价格设置</div>
    </div>-->
  <el-tabs v-model="activeName" type="card" @tab-click="handleClick">
      <el-tab-pane label="样板价格设置" name="tal_0"></el-tab-pane>
      <el-tab-pane label="样粉价格设置" name="tal_1"></el-tab-pane>
      <!--跳转页面-->
      <powderIndex v-if="powderIndexVisible" ref="powderIndex"></powderIndex>
      <sampleIndex v-if="sampleIndexVisible" ref="powderIndex"></sampleIndex>
  </el-tabs>


</template>

<script>
  import PowderIndex from './powderIndex'
  import SampleIndex from './sampleIndex'
  export default {
    data () {
      return {
          activeName:'tal_0',
        dataForm: {
          key: '',
          daterange:[]
        },
        dataList: [],
        pageIndex: 1,
        pageSize: 10,
        totalPage: 0,
        dataListLoading: false,
        dataListSelections: [],
          powderIndexVisible:false,
          sampleIndexVisible:true,
        addOrUpdateVisible: false,
          subIndex:""
      }
    },
    components: {
        PowderIndex,
        SampleIndex
    },
    mounted(){
        this.getDataList()
    },
    activated () {
      this.getDataList()
    },
    methods: {
        handleClick(tab, event) {
            // console.log(tab, event);
            // console.log(tab.index, event);
            this.activeName = "tal_" + tab.index;
            console.log(this.activeName);
            if(tab.index == 0){
                //this.addPerformanceHandle(tab.activeName);
                this.sampleIndexVisible = true
                this.powderIndexVisible = false
            } else if(tab.index == 1) {
                //this.addColorSeriesHandle(tab.activeName);
                this.powderIndexVisible = true
                this.sampleIndexVisible = false
            }
        },
        //样粉页面跳转
        addRecipeHandle(index) {
            console.log(index)
            this.subIndex = index;
            this.powderIndexVisible = true
            this.sampleIndexVisible = false
        },
        //样板页面跳转
        addRecipe(index) {
            this.subIndex = index;
            this.sampleIndexVisible = true
            this.powderIndexVisible = false
        },
      //重新查询
      restPage() {
        this.pageIndex = 1;
        this.getDataList();
      },
      reset(dataForm) {
        this.$refs.dataForm.resetFields();
        this.restPage();
      },
      // 获取数据列表
      getDataList () {
        this.dataListLoading = true
        var params = {
          page: this.pageIndex,
          limit: this.pageSize,
          key: this.dataForm.key
        }

      },
      // 每页数
      sizeChangeHandle (val) {
        this.pageSize = val
        this.pageIndex = 1
        this.getDataList()
      },
      // 当前页
      currentChangeHandle (val) {
        this.pageIndex = val
        this.getDataList()
      },
      // 多选
      selectionChangeHandle (val) {
        this.dataListSelections = val
      },
      // 新增 / 修改
      addOrUpdateHandle (id) {
        this.addOrUpdateVisible = true
        this.$nextTick(() => {
          this.$refs.addOrUpdate.init(id)
        })
      },
      // 删除
      deleteHandle (id) {
        var ids = id ? [id] : this.dataListSelections.map(item => {
          return item.id
        })
        this.$confirm(`确定对[id=${ids.join(',')}]进行[${id ? '删除' : '批量删除'}]操作?`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
        })
      }
    }
  }
</script>
<style>
  .d1{
    background-color: #4AB7BD;
  }
  .d2{
    border-bottom:1px solid;
    border-color: #99a9bf;
  }
</style>
