<template>
  <el-dialog
    :title="!dataForm.id ? '分派工厂' : '分派工厂'"
    :close-on-click-modal="false"
    :visible.sync="visible"
  width="500px">
    <el-form :model="dataForm"  :rules="dataRule"  size="small" ref="dataForm" @keyup.enter.native="dataFormSubmit()" label-width="25%"
     v-loading="dataLoading" style="width:100%;"  element-loading-text=""  element-loading-spinner="el-icon-loading"
    >
      <el-form-item label="分派工厂" prop="factoryId">
        <el-select v-model="dataForm.factoryId" size="small"  style="width: 64%">
          <el-option
                  v-for="item in factoryList"
                  :key="item.id"
                  :label="item.factoryName"
                  :value="item.id"
                  size="small"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="工厂订单号" prop="sapNo">
        <el-input v-model="dataForm.sapNo" placeholder="工厂订单号" size="small" style="width: 64%"></el-input>
      </el-form-item>
      <el-form-item prop="deliverDate" label="交付日期">
        <div class="block">
          <el-date-picker
                  v-model="dataForm.deliverDate"
                  type="date"
                  value-format="yyyy-MM-dd"
                  placeholder="选择日期">
          </el-date-picker>
        </div>
      </el-form-item>

    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="visible = false" size="small">取消</el-button>
      <el-button type="primary" @click="dataFormSubmit()" size="small"  :disabled="dataLoading">确定</el-button>
    </span>
  </el-dialog>
</template>

<script>
  import {info,update} from '@/api/modules/sampleorder';
  import {getFactory} from '@/api/modules/product'
  import {getToken} from '@/utils/auth';
  import Tinymce from '@/components/Tinymce'

  export default {
    components: {
      Tinymce
    },
    data () {
      return {
        action: `${process.env.VUE_APP_BASE_API}/sys/oss/upload?token=${getToken()}`,
        visible: false,
        dataLoading: false,
        factoryList:[],
        dataForm: {
          id: 0,
          deliverDate:'',
          factoryId: '',
          sapNo: '',
        },
        dataRule: {
          factoryId: [
            { required: true, message: '分派工厂不能为空', trigger: 'blur' }
          ],
          sapNo: [
            { required: true, message: '工厂订单号不能为空', trigger: 'blur' }
          ],
          deliverDate: [
            { required: true, message: '交付时间不能为空', trigger: 'blur' }
          ]
        }
      }
    },
    methods: {
      init (id,brandId) {
        this.getFactoryList(brandId),
        this.dataForm.id = id || 0
        this.visible = true
        this.$nextTick(() => {
          this.$refs['dataForm'].resetFields()
          if (this.dataForm.id) {
            info({id:this.dataForm.id}).then(({data}) => {
              if (data && data.code === 0) {
                this.dataForm.factoryList = data.list
              }
            })
          }
        })
      },
      //获取工厂列表
      getFactoryList(brandId) {
        let params = {brandId:brandId}
        getFactory(params).then(({data}) => {
          if (data && data.code === 0) {
            this.factoryList = data.list
          } else {
            this.factoryList = []
          }
        })
      },
      // 表单提交
      dataFormSubmit () {
        this.$refs['dataForm'].validate((valid) => {
          if (valid) {
          this.dataLoading = true;
            var params = {
              'id': this.dataForm.id || undefined,
              'factoryId': this.dataForm.factoryId,
              'deliverDate':this.dataForm.deliverDate,
              'sapNo':this.dataForm.sapNo,
            };
            console.log(params);
            console.log(this.dataForm.deliverDate)
            var tick = !this.dataForm.id ? update(params): update(params)
              console.log(tick);
            console.log(params)
            tick.then(({data}) => {
              if (data && data.code === 0) {
                this.$message({
                  message: '操作成功',
                  type: 'success',
                  duration: 1500,
                  onClose: () => {
                    this.visible = false;
                    this.dataLoading = false;
                    this.$emit('refreshDataList')
                  }
                })
              } else {
                this.$message.error(data.msg)
                this.dataLoading = false;
              }
            })
          }
        })
      }
    }
  }
</script>