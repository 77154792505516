import request from '@/utils/request'

const url = '/m/memberLabel';

// 获取列表
export function getMemberLabelList (params) {
  return request({
    url: '/member/memberLabel/getMemberLabelList',
    method: 'get',
    params: params
  })
}

// 获取列表
export function queryMemberLabelApi (data) {
  if(!data) {
    data = { pageSize: 100000 }
  }
  return request({
    url: `${url}/query`,
    method: 'post',
    data
  })
}

// 获取信息
export function getMemberLabelApi (id) {
  return request({
    url: `${url}/get/${id}`,
    method: 'get',
  })
}

// 添加
export function addOrUpdateMemberLabelApi (params) {
  return request({
    url: `${url}/addOrUpdate`,
    method: 'put',
    data: params
  })
}

// 删除
export function deleteMemberLabelApi (id) {
  return request({
    url: `${url}/delete/${id}`,
    method: 'delete',
  })
}
