import request from '@/utils/request'

// 获取列表
export function queryBrandApi (data) {
  return request({
    url: '/m/brand/query',
    method: 'post',
    data
  })
}

// 获取信息
export function getBrandApi (id) {
  return request({
    url: `/m/brand/get/${id}`,
    method: 'get'
  })
}

// 添加
export function addOrUpdateApi (params) {
  return request({
    url: '/m/brand/addOrUpdate',
    method: 'put',
    data: params
  })
}


// 删除
export function deleteBrandApi (id) {
  return request({
    url: `/m/brand/delete/${id}`,
    method: 'delete'
  })
}

// 获取信息
export function selectRenewListInfo (data) {
  return request({
    url: '/m/renewInfo/query',
    method: 'post',
    data
  })
}

// 创建品牌续费
export function renewInfoAddApi (data) {
  return request({
    url: '/m/renewInfo/add',
    method: 'put',
    data
  })
}