<template>
  <div class="app-container">
    <el-form
      :inline="true"
      size="small"
      :model="dataForm"
      ref="dataForm"
      @keyup.enter.native="restPage()"
    >
      <el-form-item prop="categoryNo">
        <el-input
          v-model="dataForm.categoryNo"
          placeholder="编码"
          size="small"
          clearable
        ></el-input>
      </el-form-item>
      <el-form-item prop="categoryName">
        <el-input
          v-model="dataForm.categoryName"
          placeholder="分类名称"
          size="small"
          clearable
        ></el-input>
      </el-form-item>
      <el-form-item label="" prop="colorBookBrandId">
        <el-select
          v-model="dataForm.colorBookBrandId"
          size="small"
          placeholder="请选择色号品牌"
          clearable
        >
          <el-option
            v-for="item in brandList"
            :key="item.id"
            :label="item.brandName"
            :value="item.id"
            size="small"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button @click="restPage()" size="small">查询</el-button>
        <el-button @click="reset('dataForm')" size="small">清空</el-button>
        <el-button type="primary" @click="addOrUpdateHandle()" size="small"
          >新增</el-button
        >
      </el-form-item>
    </el-form>
    <el-table
      :data="dataList"
      border
      size="small"
      v-loading="dataListLoading"
      @selection-change="selectionChangeHandle"
      style="width: 100%"
    >
      <el-table-column
        prop="id"
        header-align="center"
        align="center"
        label="id"
        width="150"
      >
      </el-table-column>
      <el-table-column
        prop="categoryNo"
        header-align="center"
        align="center"
        label="编码"
      >
      </el-table-column>
      <el-table-column
        prop="categoryName"
        header-align="center"
        align="center"
        label="名称"
      >
      </el-table-column>
      <el-table-column
        prop="brandName"
        header-align="center"
        align="center"
        label="品牌名称"
      >
      </el-table-column>
      <el-table-column
        fixed="right"
        header-align="center"
        align="center"
        width="150"
        label="操作"
      >
        <template slot-scope="scope">
          <el-button
            type="text"
            size="small"
            @click="addOrUpdateHandle(scope.row.id)"
            >编辑</el-button
          >
          <el-button
            type="text"
            size="small"
            @click="deleteHandle(scope.row.id)"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      size="small"
      @size-change="sizeChangeHandle"
      @current-change="currentChangeHandle"
      :current-page="dataForm.pageIndex"
      :page-sizes="[10, 20, 50, 100]"
      :page-size="dataForm.pageSize"
      :total="totalPage"
      layout="total, sizes, prev, pager, next, jumper"
    >
    </el-pagination>
    <!-- 弹窗, 新增 / 修改 -->
    <add-or-update
      v-if="addOrUpdateVisible"
      ref="addOrUpdate"
      @refreshDataList="getDataList"
    ></add-or-update>
  </div>
</template>

<script>
import {
  queryColorBookCategoryApi,
  deleteColorBookCategoryApi,
} from "@/api/modules/colorbookcategory";
import { queryColorBookBrandApi } from "@/api/modules/colorBookBrand";
import AddOrUpdate from "./add-or-update";
export default {
  data() {
    return {
      dataForm: {
        categoryNo: "",
        categoryName: "",
        colorBookBrandId: "",
        pageNo: 1,
        pageSize: 10,
      },
      dataList: [],
      totalPage: 0,
      dataListLoading: false,
      dataListSelections: [],
      addOrUpdateVisible: false,
    };
  },
  components: {
    AddOrUpdate,
  },
  mounted() {
    this.getBrandList();
  },
  methods: {
    //重新查询
    restPage() {
      this.dataForm.pageIndex = 1;
      this.getDataList();
    },
    reset(dataForm) {
      this.$refs.dataForm.resetFields();
      this.restPage();
    },
    getBrandList() {
      queryColorBookBrandApi().then(({ records, totalRecords }) => {
        if (totalRecords > 0) {
          this.brandList = records;
        } else {
          this.brandList = [];
        }
        this.getDataList();
      });
    },
    // 获取数据列表
    getDataList() {
      this.dataListLoading = true;
      queryColorBookCategoryApi(this.dataForm).then(
        ({ records, totalRecords }) => {
          if (totalRecords > 0) {
            this.dataList = records;
            this.totalPage = totalRecords;
          } else {
            this.dataList = [];
            this.totalPage = 0;
          }
          this.dataListLoading = false;
        }
      );
    },
    // 每页数
    sizeChangeHandle(val) {
      this.dataForm.pageSize = val;
      this.dataForm.pageIndex = 1;
      this.getDataList();
    },
    // 当前页
    currentChangeHandle(val) {
      this.dataForm.pageIndex = val;
      this.getDataList();
    },
    // 多选
    selectionChangeHandle(val) {
      this.dataListSelections = val;
    },
    // 新增 / 修改
    addOrUpdateHandle(id) {
      this.addOrUpdateVisible = true;
      this.$nextTick(() => {
        this.$refs.addOrUpdate.init(id);
      });
    },
    // 删除
    deleteHandle(id) {
      this.$confirm(`是否删除该数据?`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        deleteColorBookCategoryApi(id).then(() => {
          this.$baseMessage("删除成功", "success");
          this.getDataList();
        });
      });
    },
  },
};
</script>
