<template>
  <el-dialog
          :title="!dataForm.id ? '用户评价' : '用户评价'"
          :close-on-click-modal="false"
          :visible.sync="visible"
  width="600px">
    <el-form :model="dataForm"   size="small" ref="dataForm"  label-width="80px"
             v-loading="dataLoading" style="width:100%;"  element-loading-text=""  element-loading-spinner="el-icon-loading"
    >
      <!--<el-form-item prop="state">
        <template>
          <el-radio v-model="dataForm.state" label="4" @change="consent()">同意</el-radio>
          <el-radio v-model="dataForm.state" label="1" @change="callOff()">取消</el-radio>
        </template>
      </el-form-item>-->
      <div>
        <p>产品编号：{{dataForm.productNo}}</p>
        <p>评价：{{dataForm.evaluateContent}}</p>
        <!--<p>收款单位名称：{{dataForm.reason}}</p>
        <p>收款单位账号：{{dataForm.createDate}}</p>
        <p>收款单位开户银行：{{dataForm.reason}}</p>-->
        <div style="display: flex">
          <p>图片：</p>
          <div v-for="(item,index) in picList" style="margin-left: 5px">
            <el-image
                    style="width: 100px; height: 100px"
                    :src="item"
                    :preview-src-list="picList"
            ></el-image>
            <!--<img :src="item" min-width="100" height="100" width="100"/>-->
          </div>
        </div>
      </div>
    </el-form>
    <!--<span slot="footer" class="dialog-footer">
      <el-button @click="visible = false" size="small" >取消</el-button>
      <el-button type="primary" @click="queRenShouKuan" size="small"  :disabled="dataLoading" >确认收款</el-button>
    </span>-->
  </el-dialog>
</template>

<script>
    import {evaLuateInfo} from '@/api/modules/sampleorder'
    export default {
        data () {
            return {
                showBzu:false,
                visible: false,
                dataLoading: false,
                picList:[],
                dataForm: {
                    id: 0,
                  productNo: '',
                  evaluateContent:'',
                  evaluate_pic:'',
                }
            }
        },
        methods: {
            init (id) {
                this.dataForm.id = id || 0
              this.picList = [],
                this.visible = true
                this.$nextTick(() => {
                    this.$refs['dataForm'].resetFields()
                    if (this.dataForm.id) {
                      evaLuateInfo({id:this.dataForm.id}).then(({data}) => {
                            if (data && data.code === 0) {
                                this.dataForm.productNo = data.sampleOrderEvaluate.productNo
                                this.dataForm.evaluateContent = data.sampleOrderEvaluate.evaluateContent
                                /*this.dataForm.evaluate_pic = data.sampleOrderEvaluate.evaluate_pic*/
                              if (data.sampleOrderEvaluate.evaluate_pic !==null) {
                                this.picList = data.sampleOrderEvaluate.evaluate_pic.split(",")
                                console.log(this.picList+"========")
                              }
                            }
                        })
                    }
                })
            },
            /*// 表单提交
            dataFormSubmit () {
                this.$refs['dataForm'].validate((valid) => {
                    if (valid) {
                        this.dataLoading = true;
                        var params = {
                            'aftersaleId': this.dataForm.id || undefined,
                            'customerReply':this.dataForm.customerReply,
                        }
                        var tick = !this.dataForm.id ? ruturnTake(params) : ruturnTake(params)
                        tick.then(({data}) => {
                            if (data && data.code === 0) {
                                this.$message({
                                    message: '操作成功',
                                    type: 'success',
                                    duration: 1500,
                                    onClose: () => {
                                        this.visible = false;
                                        //    this.showBzu = false;
                                        this.dataLoading = false;
                                        this.$emit('refreshDataList')
                                    }
                                })
                            } else {
                                this.$message.error(data.msg)
                                this.dataLoading = false;
                            }
                        })
                    }
                })
            }*/
        }
    }
</script>
