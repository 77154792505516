<template>
    <el-dialog
            :title="!dataForm.id ? '新增' : '修改'"
            :close-on-click-modal="false"
            :visible.sync="visible">
        <el-form :model="dataForm" :rules="dataRule" size="small" ref="dataForm" @keyup.enter.native="dataFormSubmit()"
                 label-width="80px"
                 v-loading="dataLoading" style="width:100%;" element-loading-text=""
                 element-loading-spinner="el-icon-loading"
        >
            <el-form-item label="广告类型" prop="mediaType">
                <el-select v-model="dataForm.mediaType" placeholder="广告类型" @change="getPositionList">
                    <el-option label="小程序" value="0"></el-option>
                    <el-option label="门户网站" value="1"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="广告图片" prop="imageUrl">
                <el-upload
                        class="avatar-uploader"
                        :action="action"
                        :show-file-list="false"
                        :on-success="handleAvatarSuccess"
                        :before-upload="beforeAvatarUpload">
                    <img v-if="dataForm.imageUrl" :src="dataForm.imageUrl" class="avatar">
                    <i v-else class="el-icon-plus avatar-uploader-icon" title="点击上传"></i>
                </el-upload>
            </el-form-item>

            <el-form-item label="广告位置" prop="advertPositionId">
                <el-select v-model="dataForm.advertPositionId" placeholder="所属工厂">
                    <el-option label="全部" value=""></el-option>
                    <el-option
                            v-for="item in positionList"
                            :key="item.id"
                            :label="item.name"
                            :value="item.id">
                    </el-option>
                </el-select>
                <!--<el-select v-model="dataForm.positionType" placeholder="广告位置">-->
                    <!--<el-option label="首页广告" value="0"></el-option>-->
                    <!--<el-option label="商城广告" value="1"></el-option>-->
                    <!--<el-option label="活动广告" value="2"></el-option>-->
                <!--</el-select>-->
            </el-form-item>

            <el-form-item label="广告名称" prop="name">
                <el-input v-model="dataForm.name" placeholder="名称" size="small"></el-input>
            </el-form-item>
            <el-form-item label="链接地址" prop="link">
                <el-input v-model="dataForm.link" placeholder="链接" size="small"></el-input>
            </el-form-item>
            <el-form-item label="排序号" prop="sort">
                <el-input-number v-model="dataForm.sort" controls-position="right" :min="0"
                                 label="排序号"></el-input-number>
            </el-form-item>

            <el-form-item label="广告内容" prop="content">
                <el-input v-model="dataForm.content" placeholder="广告内容" size="small"></el-input>
            </el-form-item>

        </el-form>
        <span slot="footer" class="dialog-footer">
      <el-button @click="visible = false" size="small">取消</el-button>
      <el-button type="primary" @click="dataFormSubmit()" size="small" :disabled="dataLoading">确定</el-button>
    </span>
    </el-dialog>
</template>

<script>
    import {info, add, update} from '@/api/modules/advert'
    import {getPositionList} from '@/api/modules/advertposition'
    import {getToken} from '@/utils/auth';
    import Tinymce from '@/components/Tinymce'
    export default {
        data() {
            return {
                action: `${process.env.VUE_APP_BASE_API}/sys/oss/upload?token=${getToken()}`,
                visible: false,
                dataLoading: false,
                positionList:[],
                dataForm: {
                    id: 0,
                    advertPositionId: '',
                    mediaType: '',
                    name: '',
                    link: '',
                    imageUrl: '',
                    content: '',
                    endTime: '',
                    enabled: '',
                    sort: '',
                    positionType:''
                },
                dataRule: {
                    mediaType: [
                        {required: true, message: '广告类型不能为空', trigger: 'blur'}
                    ],
                    name: [
                        {required: true, message: '广告名称不能为空', trigger: 'blur'}
                    ],
                    link: [
                        {required: true, message: '广告链接不能为空', trigger: 'blur'}
                    ],
                    imageUrl: [
                        {required: true, message: '图片url不能为空', trigger: 'blur'}
                    ],
                    positionType:[
                        {required: true, message: '广告位置不能为空', trigger: 'blur'}
                    ]

                }
            }
        },

        methods: {
            init(id) {
                this.dataForm.mediaType='';

                this.dataForm.id = id || 0
                this.visible = true
                this.$nextTick(() => {
                    this.$refs['dataForm'].resetFields()
                    if (this.dataForm.id) {
                        info({id: this.dataForm.id}).then(({data}) => {
                            if (data && data.code === 0) {
                                this.dataForm.advertPositionId = data.advert.advertPositionId
                                this.dataForm.mediaType = data.advert.mediaType.toString()
                                this.dataForm.name = data.advert.name
                                this.dataForm.link = data.advert.link
                                this.dataForm.imageUrl = data.advert.imageUrl
                                this.dataForm.content = data.advert.content
                                this.dataForm.endTime = data.advert.endTime
                                this.dataForm.enabled = data.advert.enabled
                                this.dataForm.sort=data.advert.sort
                                this.dataForm.positionType=data.advert.positionType
                            }
                        })
                    }
                })
                this.getPositionList();
            },
            //获取广告位置
            getPositionList(){
                var param={
                    'application':this.dataForm.mediaType,
                };
                getPositionList(param).then(({ data })=>{
                    if(data && data.code === 0 ){
                        this.positionList = data.positionList;
                    }
                })
            },
            //图片上传
            handleAvatarSuccess(res, file, type) {
                if (res && res.code === 0) {
                    this.dataForm.imageUrl = res.data.fileUrl;
                } else {
                    this.$message.error(res.msg);
                }
            },
            beforeAvatarUpload(file) {
                const isJPG =
                    file.type === "image/jpeg" ||
                    file.type === "image/bmp" ||
                    file.type === "image/png";
                const isLt2M = file.size / 1024 / 1024 < 8;
                if (!isJPG) {
                    this.$message.error("上传图片格式不支持!");
                }
                if (!isLt2M) {
                    this.$message.error("上传图片大小不能超过 8MB!");
                }
                return isJPG && isLt2M;
            },
            // 表单提交
            dataFormSubmit() {
                this.$refs['dataForm'].validate((valid) => {
                    if (valid) {
                        this.dataLoading = true;
                        var params = {
                            'id': this.dataForm.id || undefined,
                            'advertPositionId': this.dataForm.advertPositionId,
                            'mediaType': this.dataForm.mediaType,
                            'name': this.dataForm.name,
                            'link': this.dataForm.link,
                            'imageUrl': this.dataForm.imageUrl,
                            'content': this.dataForm.content,
                            'endTime': this.dataForm.endTime,
                            'enabled': this.dataForm.enabled,
                            'sort':this.dataForm.sort,
                            'positionType':this.dataForm.positionType
                        }
                        var tick = !this.dataForm.id ? add(params) : update(params)
                        tick.then(({data}) => {
                            if (data && data.code === 0) {
                                this.$message({
                                    message: '操作成功',
                                    type: 'success',
                                    duration: 1500,
                                    onClose: () => {
                                        this.visible = false;
                                        this.dataLoading = false;
                                        this.$emit('refreshDataList')
                                    }
                                })
                            } else {
                                this.$message.error(data.msg)
                                this.dataLoading = false;
                            }
                        })
                    }
                })
            }
        }
    }
</script>
<style>
    .avatar-uploader .el-upload {
        border: 1px dashed #d9d9d9;
        border-radius: 6px;
        cursor: pointer;
        position: relative;
        overflow: hidden;
    }

    .avatar-uploader .el-upload:hover {
        border-color: #409EFF;
    }

    .avatar-uploader-icon {
        font-size: 28px;
        color: #8c939d;
        width: 178px;
        height: 178px;
        line-height: 178px;
        text-align: center;
    }

    .avatar {
        width: 178px;
        height: 178px;
        display: block;
    }
</style>