<template>
  <el-dialog
    :title="!dataForm.logisticsId ? '新增' : '修改'"
    :close-on-click-modal="false"
    :visible.sync="visible"
  width="600px">
    <el-form :model="dataForm" :rules="dataRule"  size="small" ref="dataForm" @keyup.enter.native="dataFormSubmit()" label-width="20%"
     v-loading="dataLoading" style="width:100%;"  element-loading-text=""  element-loading-spinner="el-icon-loading"
    >
      <el-form-item label="物流编码" prop="logisticsCode">
        <el-input v-model="dataForm.logisticsCode" placeholder="物流编码"  size="small"></el-input>
      </el-form-item>
      <el-form-item label="物流名称" prop="logisticsName">
        <el-input v-model="dataForm.logisticsName" placeholder="物流名称"  size="small"></el-input>
      </el-form-item>
      <el-form-item label="物流公司电话" prop="logisticsPhone">
        <el-input v-model="dataForm.logisticsPhone" placeholder="物流公司电话"  size="small"></el-input>
      </el-form-item>
      <!--<el-form-item label="创建人" prop="createBy">
        <el-input v-model="dataForm.createBy" placeholder="创建人"  size="small"></el-input>
      </el-form-item>
      <el-form-item label="创建时间" prop="createTime">
        <el-input v-model="dataForm.createTime" placeholder="创建时间"  size="small"></el-input>
      </el-form-item>
      <el-form-item label="更新人" prop="updateBy">
        <el-input v-model="dataForm.updateBy" placeholder="更新人"  size="small"></el-input>
      </el-form-item>
      <el-form-item label="更新时间" prop="updateTime">
        <el-input v-model="dataForm.updateTime" placeholder="更新时间"  size="small"></el-input>
      </el-form-item>
      <el-form-item label="版本号" prop="version">
        <el-input v-model="dataForm.version" placeholder="版本号"  size="small"></el-input>
      </el-form-item>-->
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="visible = false" size="small">取消</el-button>
      <el-button type="primary" @click="dataFormSubmit()" size="small"  :disabled="dataLoading">确定</el-button>
    </span>
  </el-dialog>
</template>

<script>
  import {info,add,update} from '@/api/modules/salogistics'
  export default {
    data () {
      return {
        visible: false,
        dataLoading: false,
        dataForm: {
          logisticsId: 0,
          logisticsCode: '',
          logisticsName: '',
          logisticsPhone: '',
          createBy: '',
          createTime: '',
          updateBy: '',
          updateTime: '',
          version: ''
        },
        dataRule: {
          logisticsCode: [
            { required: true, message: '物流编码不能为空', trigger: 'blur' }
          ],
          logisticsName: [
            { required: true, message: '物流名称不能为空', trigger: 'blur' }
          ],
          logisticsPhone: [
            { required: true, message: '物流公司电话不能为空', trigger: 'blur' }
          ]/*,
          createBy: [
            { required: true, message: '创建人不能为空', trigger: 'blur' }
          ],
          createTime: [
            { required: true, message: '创建时间不能为空', trigger: 'blur' }
          ],
          updateBy: [
            { required: true, message: '更新人不能为空', trigger: 'blur' }
          ],
          updateTime: [
            { required: true, message: '更新时间不能为空', trigger: 'blur' }
          ],
          version: [
            { required: true, message: '版本号不能为空', trigger: 'blur' }
          ]*/
        }
      }
    },
    methods: {
      init (id) {
        this.dataForm.logisticsId = id || 0
        this.visible = true
        this.$nextTick(() => {
          this.$refs['dataForm'].resetFields()
          if (this.dataForm.logisticsId) {
            info({logisticsId:this.dataForm.logisticsId}).then(({data}) => {
              if (data && data.code === 0) {
                this.dataForm.logisticsCode = data.saLogistics.logisticsCode
                this.dataForm.logisticsName = data.saLogistics.logisticsName
                this.dataForm.logisticsPhone = data.saLogistics.logisticsPhone
                this.dataForm.createBy = data.saLogistics.createBy
                this.dataForm.createTime = data.saLogistics.createTime
                this.dataForm.updateBy = data.saLogistics.updateBy
                this.dataForm.updateTime = data.saLogistics.updateTime
                this.dataForm.version = data.saLogistics.version
              }
            })
          }
        })
      },
      // 表单提交
      dataFormSubmit () {
        this.$refs['dataForm'].validate((valid) => {
          if (valid) {
          	this.dataLoading = true;
            var params = {
              'logisticsId': this.dataForm.logisticsId || undefined,
              'logisticsCode': this.dataForm.logisticsCode,
              'logisticsName': this.dataForm.logisticsName,
              'logisticsPhone': this.dataForm.logisticsPhone,
              'createBy': this.dataForm.createBy,
              'createTime': this.dataForm.createTime,
              'updateBy': this.dataForm.updateBy,
              'updateTime': this.dataForm.updateTime,
              'version': this.dataForm.version
            }
            var tick = !this.dataForm.logisticsId ? add(params) : update(params)
            tick.then(({data}) => {
              if (data && data.code === 0) {
                this.$message({
                  message: '操作成功',
                  type: 'success',
                  duration: 1500,
                  onClose: () => {
                    this.visible = false;
	              	this.dataLoading = false;
                    this.$emit('refreshDataList')
                  }
                })
              } else {
                this.$message.error(data.msg)
                this.dataLoading = false;
              }
            })
          }
        })
      }
    }
  }
</script>
