<template>
  <el-dialog
    :title="!dataForm.id ? '新增' : '修改'"
    :close-on-click-modal="false"
    :visible.sync="visible">
    <el-form :model="dataForm" :rules="dataRule"  size="small" ref="dataForm" @keyup.enter.native="dataFormSubmit()" label-width="80px"
     v-loading="dataLoading" style="width:100%;"  element-loading-text=""  element-loading-spinner="el-icon-loading"
    >
      <el-form-item label="会员Id" prop="userId">
        <el-input v-model="dataForm.userId" placeholder="会员Id"  size="small"></el-input>
      </el-form-item>
      <el-form-item label="商品Id" prop="mallGoodsMainId">
        <el-input v-model="dataForm.mallGoodsMainId" placeholder="商品Id"  size="small"></el-input>
      </el-form-item>
      <el-form-item label="商品SKU的ID" prop="mallGoodsSkuId">
        <el-input v-model="dataForm.mallGoodsSkuId" placeholder="商品SKU的ID"  size="small"></el-input>
      </el-form-item>
      <el-form-item label="商品序列号" prop="goodsSn">
        <el-input v-model="dataForm.goodsSn" placeholder="商品序列号"  size="small"></el-input>
      </el-form-item>
      <el-form-item label="产品Id" prop="productId">
        <el-input v-model="dataForm.productId" placeholder="产品Id"  size="small"></el-input>
      </el-form-item>
      <el-form-item label="产品名称" prop="goodsTitle">
        <el-input v-model="dataForm.goodsTitle" placeholder="产品名称"  size="small"></el-input>
      </el-form-item>
      <el-form-item label="市场价" prop="originalPrice">
        <el-input v-model="dataForm.originalPrice" placeholder="市场价"  size="small"></el-input>
      </el-form-item>
      <el-form-item label="零售价格" prop="payPrice">
        <el-input v-model="dataForm.payPrice" placeholder="零售价格"  size="small"></el-input>
      </el-form-item>
      <el-form-item label="数量" prop="number">
        <el-input v-model="dataForm.number" placeholder="数量"  size="small"></el-input>
      </el-form-item>
      <el-form-item label="" prop="checked">
        <el-input v-model="dataForm.checked" placeholder=""  size="small"></el-input>
      </el-form-item>
      <el-form-item label="商品sku图片" prop="picUrl">
        <el-input v-model="dataForm.picUrl" placeholder="商品sku图片"  size="small"></el-input>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="visible = false" size="small">取消</el-button>
      <el-button type="primary" @click="dataFormSubmit()" size="small"  :disabled="dataLoading">确定</el-button>
    </span>
  </el-dialog>
</template>

<script>
  import {info,add,update} from '@/api/modules/membershopcart'
  export default {
    data () {
      return {
        visible: false,
        dataLoading: false,
        dataForm: {
          id: 0,
          userId: '',
          mallGoodsMainId: '',
          mallGoodsSkuId: '',
          goodsSn: '',
          productId: '',
          goodsTitle: '',
          originalPrice: '',
          payPrice: '',
          number: '',
          checked: '',
          picUrl: ''
        },
        dataRule: {
          userId: [
            { required: true, message: '会员Id不能为空', trigger: 'blur' }
          ],
          mallGoodsMainId: [
            { required: true, message: '商品Id不能为空', trigger: 'blur' }
          ],
          mallGoodsSkuId: [
            { required: true, message: '商品SKU的ID不能为空', trigger: 'blur' }
          ],
          goodsSn: [
            { required: true, message: '商品序列号不能为空', trigger: 'blur' }
          ],
          productId: [
            { required: true, message: '产品Id不能为空', trigger: 'blur' }
          ],
          goodsTitle: [
            { required: true, message: '产品名称不能为空', trigger: 'blur' }
          ],
          originalPrice: [
            { required: true, message: '市场价不能为空', trigger: 'blur' }
          ],
          payPrice: [
            { required: true, message: '零售价格不能为空', trigger: 'blur' }
          ],
          number: [
            { required: true, message: '数量不能为空', trigger: 'blur' }
          ],
          checked: [
            { required: true, message: '不能为空', trigger: 'blur' }
          ],
          picUrl: [
            { required: true, message: '商品sku图片不能为空', trigger: 'blur' }
          ]
        }
      }
    },
    methods: {
      init (id) {
        this.dataForm.id = id || 0
        this.visible = true
        this.$nextTick(() => {
          this.$refs['dataForm'].resetFields()
          if (this.dataForm.id) {
            info({id:this.dataForm.id}).then(({data}) => {
              if (data && data.code === 0) {
                this.dataForm.userId = data.userId
                this.dataForm.mallGoodsMainId = data.mallGoodsMainId
                this.dataForm.mallGoodsSkuId = data.mallGoodsSkuId
                this.dataForm.goodsSn = data.goodsSn
                this.dataForm.productId = data.productId
                this.dataForm.goodsTitle = data.goodsTitle
                this.dataForm.originalPrice = data.originalPrice
                this.dataForm.payPrice = data.payPrice
                this.dataForm.number = data.number
                this.dataForm.checked = data.checked
                this.dataForm.picUrl = data.picUrl
              }
            })
          }
        })
      },
      // 表单提交
      dataFormSubmit () {
        this.$refs['dataForm'].validate((valid) => {
          if (valid) {
            this.dataLoading = true;
            var params = {
              'id': this.dataForm.id || undefined,
              'userId': this.dataForm.userId,
              'mallGoodsMainId': this.dataForm.mallGoodsMainId,
              'mallGoodsSkuId': this.dataForm.mallGoodsSkuId,
              'goodsSn': this.dataForm.goodsSn,
              'productId': this.dataForm.productId,
              'goodsTitle': this.dataForm.goodsTitle,
              'originalPrice': this.dataForm.originalPrice,
              'payPrice': this.dataForm.payPrice,
              'number': this.dataForm.number,
              'checked': this.dataForm.checked,
              'picUrl': this.dataForm.picUrl
            }
            var tick = !this.dataForm.id ? add(params) : update(params)
            tick.then(({data}) => {
              if (data && data.code === 0) {
                this.$message({
                  message: '操作成功',
                  type: 'success',
                  duration: 1500,
                  onClose: () => {
                    this.visible = false;
                    this.dataLoading = false;
                    this.$emit('refreshDataList')
                  }
                })
              } else {
                this.$message.error(data.msg)
                this.dataLoading = false;
              }
            })
          }
        })
      }
    }
  }
</script>
