<template>
  <div class="app-container">
    <el-form
      :inline="true"
      size="small"
      :model="dataForm"
      ref="dataForm"
      @keyup.enter.native="restPage()"
    >
      <el-form-item prop="userName" label="联系人">
        <el-input
          v-model="dataForm.userName"
          placeholder="请输入联系人"
          size="small"
          clearable
        ></el-input>
      </el-form-item>
      <el-form-item prop="company" label="公司名称">
        <el-input
          v-model="dataForm.company"
          placeholder="请输入"
          size="small"
          clearable
        ></el-input>
      </el-form-item>
      <el-form-item prop="mobile" label="手机号">
        <el-input
          v-model="dataForm.mobile"
          placeholder="请输入"
          size="small"
          clearable
        ></el-input>
      </el-form-item>
      <!-- <el-form-item label="标签">
        <el-select
          v-model="dataForm.memberLabelId"
          placeholder="标签"
          clearable
        >
          <el-option label="全部" value=""></el-option>
          <el-option
            v-for="item in memberLabelList"
            :key="item.id"
            :label="item.labelName"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item> -->
      <!-- <el-form-item label="是否冻结" prop="isFreeze">
        <el-select v-model="dataForm.isFreeze" placeholder="请选择" clearable style="width: 100px">
          <el-option label="正常" :value="false">正常</el-option>
          <el-option label="冻结" :value="true">冻结</el-option>
        </el-select>
      </el-form-item> -->
      <el-form-item label="备注">
        <el-input
          v-model="dataForm.remarks"
          placeholder="备注"
          size="small"
          clearable
        ></el-input>
      </el-form-item>
      <el-form-item label="创建时间">
        <el-date-picker
          v-model="dataForm.createDates"
          value-format="yyyy-MM-dd"
          type="daterange"
          range-separator="-"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item>
        <el-button @click="restPage()" size="small">查询</el-button>
        <el-button @click="reset('dataForm')" size="small">清空</el-button>
      </el-form-item>
    </el-form>
    <el-table
      :data="dataList"
      border
      size="small"
      v-loading="dataListLoading"
      style="width: 100%"
    >
      <el-table-column
        prop="id"
        header-align="center"
        align="center"
        label="id"
        width="150"
      >
      </el-table-column>
      <el-table-column
        prop="userName"
        header-align="center"
        align="center"
        label="联系人"
      >
      </el-table-column>
      <el-table-column
        prop="company"
        header-align="center"
        align="center"
        label="公司名称"
      >
      </el-table-column>
      <el-table-column
        prop="mobile"
        header-align="center"
        align="center"
        label="手机号"
      >
      </el-table-column>
      <!-- <el-table-column
        prop="isFreeze"
        header-align="center"
        align="center"
        label="是否冻结"
      >
        <template slot-scope="scope">
          <el-tag v-if="!scope.row.isFreeze" type="success">正常</el-tag>
          <el-tag v-else type="danger">冻结</el-tag>
        </template>
      </el-table-column> -->
      <el-table-column
        prop="remarks"
        header-align="center"
        align="center"
        label="备注"
      >
      </el-table-column>
      <el-table-column
        prop="createTime"
        header-align="center"
        align="center"
        label="创建时间"
      >
      </el-table-column>
      <el-table-column
        fixed="right"
        header-align="center"
        align="center"
        width="150"
        label="操作"
      >
        <template slot-scope="scope">
          <el-button
            type="text"
            size="small"
            @click="addOrUpdateHandle(scope.row.id)"
            >修改</el-button
          >
          <!-- <el-button
            type="text"
            size="small"
            @click="stopMemberStatus(scope.row.id, scope.row.isFreeze)"
          >
            {{ scope.row.isFreeze == "0" ? "禁用" : "启用" }}</el-button
          > -->
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      size="small"
      @size-change="sizeChangeHandle"
      @current-change="currentChangeHandle"
      :current-page="dataForm.pageNo"
      :page-sizes="[10, 20, 50, 100]"
      :page-size="dataForm.pageSize"
      :total="totalPage"
      layout="total, sizes, prev, pager, next, jumper"
    >
    </el-pagination>
    <!-- 弹窗, 新增 / 修改 -->
    <add-or-update
      v-if="addOrUpdateVisible"
      ref="addOrUpdate"
      @refreshDataList="getDataList"
    ></add-or-update>
  </div>
</template>

<script>
import { queryMemberApi, updateMemberFreezeApi } from "@/api/modules/member";
import AddOrUpdate from "./add-or-update";
import { queryMemberLabelApi } from "@/api/modules/memberlabel";

export default {
  data() {
    return {
      dataForm: {
        pageNo: 1,
        pageSize: 10,
        userName: "",
        mobile: "",
        memberLabelId: "",
        isFreeze: "",
        company: "",
        remarks: "",
        createDates: [],
      },
      dataList: [],
      totalPage: 0,
      dataListLoading: false,
      addOrUpdateVisible: false,
      memberLabelList: [],
    };
  },
  components: {
    AddOrUpdate,
  },
  // activated () {
  //   this.getDataList()
  // },
  mounted() {
    this.getMemberLabel();
    this.getDataList();
  },
  methods: {
    //重新查询
    restPage() {
      this.pageIndex = 1;
      this.getDataList();
    },
    reset(dataForm) {
      this.$refs["dataForm"].resetFields();
      (this.dataForm.memberLabelId = ""),
        (this.dataForm.isFreeze = ""),
        (this.dataForm.company = ""),
        (this.dataForm.remarks = ""),
        (this.dataForm.createDates = []),
        this.restPage();
    },
    getMemberLabel() {
      queryMemberLabelApi().then(({ records, totalRecords }) => {
        if (totalRecords > 0) {
          this.memberLabelList = records;
        } else {
          this.memberLabelList = [];
        }
      });
    },
    // 获取数据列表
    getDataList() {
      this.dataListLoading = true;
      var arrOrderTime = this.dataForm.createDates;
      var startDate = null;
      var endDate = null;
      if (arrOrderTime && arrOrderTime.length > 0) {
        startDate = arrOrderTime[0];
        endDate = arrOrderTime[1];
      }

      this.dataForm.createTimeFrom = startDate ? startDate + " 00:00:00" : "";
      this.dataForm.createTimeTo = endDate ? endDate + " 23:59:59" : "";
      queryMemberApi(this.dataForm).then(({ records, totalRecords }) => {
        if (totalRecords > 0) {
          this.dataList = records;
          this.totalPage = totalRecords;
        } else {
          this.dataList = [];
          this.totalPage = 0;
        }
        this.dataListLoading = false;
      });
    },
    // 每页数
    sizeChangeHandle(val) {
      this.dataForm.pageSize = val;
      this.dataForm.pageNo = 1;
      this.getDataList();
    },
    // 当前页
    currentChangeHandle(val) {
      this.dataForm.pageNo = val;
      this.getDataList();
    },
    // 新增 / 修改
    addOrUpdateHandle(id) {
      this.addOrUpdateVisible = true;
      this.$nextTick(() => {
        this.$refs.addOrUpdate.init(id);
      });
    },
    //状态禁用启用
    stopMemberStatus(id, isFreeze) {
      this.$confirm(
        `确定进行[${!isFreeze ? "禁用" : "启用"}]操作?`,
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      ).then(() => {
        updateMemberFreezeApi(id).then(() => {
          this.$baseMessage("操作成功", "success");
          this.getDataList();
        });
      });
    },
  },
};
</script>