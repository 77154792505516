<template>
  <el-dialog
    :title="!dataForm.id ? '新增' : '修改'"
    :close-on-click-modal="false"
    :visible.sync="visible"
    width="500px"
  >
    <el-form
      :model="dataForm"
      :rules="dataRule"
      size="small"
      ref="dataForm"
      @keyup.enter.native="dataFormSubmit()"
      label-width="140px"
      v-loading="dataLoading"
      style="width: 100%"
      element-loading-text=""
      element-loading-spinner="el-icon-loading"
    >
      <el-form-item label="品牌名称" prop="brandName">
        <el-input
          v-model="dataForm.brandName"
          placeholder="品牌名称"
          size="small"
        ></el-input>
      </el-form-item>
      <el-form-item label="LAB值是否需要转换" prop="isConvert">
        <el-switch v-model="dataForm.isConvert"> </el-switch>
      </el-form-item>
      <el-form-item label="备注" prop="remarks">
        <el-input
          v-model="dataForm.remarks"
          type="textarea"
          size="small"
          placeholder="备注"
          maxlength="100"
          show-word-limit
        ></el-input>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="visible = false" size="small">取消</el-button>
      <el-button
        type="primary"
        @click="dataFormSubmit()"
        size="small"
        :disabled="dataLoading"
        >确定</el-button
      >
    </span>
  </el-dialog>
</template>

<script>
import {
  getColorBookBrandApi,
  addOrUpdateColorBookBrandApi
} from '@/api/modules/colorBookBrand'
export default {
  data() {
    return {
      visible: false,
      dataLoading: false,
      dataForm: {
        id: undefined,
        brandName: '',
        isConvert: false,
        remarks: ''
      },
      dataRule: {
        brandName: [
          { required: true, message: '品牌名称不能为空', trigger: 'blur' }
        ]
      }
    }
  },
  methods: {
    init(id) {
      this.dataForm.id = id || undefined
      this.visible = true
      this.$nextTick(() => {
        this.$refs['dataForm'].resetFields()
        if (id) {
          getColorBookBrandApi(id).then((response) => {
            this.dataForm = response
          })
        }
      })
    },
    // 表单提交
    dataFormSubmit() {
      this.$refs['dataForm'].validate((valid) => {
        if (valid) {
          this.dataLoading = true
          addOrUpdateColorBookBrandApi(this.dataForm)
            .then(() => {
              this.visible = false
              this.$emit('refreshDataList')
              this.$baseMessage('操作成功', 'success')
            })
            .finally(() => {
              this.dataLoading = false
            })
        }
      })
    }
  }
}
</script>
