<template>
  <sides-lip ref="SidesLip">
    <div slot="soltForm">
      <el-row class="order_state">当前订单状态：{{dataForm.statusDesc}}</el-row>
      <!-- 基本信息 -->
      <el-row>
        <el-container>
          <el-header class="header">基本信息</el-header>
          <el-main>
            <table width="100%" class="table">
              <tr class="title">
                <td class="td_right">订单编号</td>
                <td class="td_right">支付流水</td>
                <td class="td_right">买家账号</td>
                <td class="td_right">支付方式</td>
                <td class="td_bottom">下单时间</td>
              </tr>
              <tr>
                <td class="no_border_bottom">{{dataForm.orderNumber}}</td>
                <td class="no_border_bottom">{{dataForm.serialNumber}}</td>
                <td class="no_border_bottom">{{dataForm.username}}</td>
                <td class="td_bottom">{{dataForm.payType}}</td>
                <td class="no_border_bottom">{{dataForm.strOrderTime}}</td>
              </tr>
              <tr class="title">
                <td class="td_right">物流公司</td>
                <td class="td_right">物流单号</td>
                <td class="td_right">支付时间</td>
                <td class="td_bottom" colspan="2">买家留言</td>
              </tr>
              <tr>
                <td class="no_border_bottom">{{dataForm.logisticsName}}</td>
                <td class="no_border_bottom">{{dataForm.logisticsNumber}}</td>
                <td class="no_border_bottom">{{dataForm.strPayTime}}</td>
                <td colspan="2" class="no_border_bottom">{{dataForm.orderRemark}}</td>
              </tr>
            </table>
          </el-main>
        </el-container>
      </el-row>
      <!-- 收货人信息 -->
      <el-row>
        <el-container>
          <el-header class="header">收货人信息</el-header>
          <el-main>
            <table width="100%" class="table">
              <tr class="title">
                <td class="td_right">收货人</td>
                <td class="td_right">手机号码</td>
                <td class="td_bottom">收货地址</td>
              </tr>
              <tr>
                <td class="no_border_bottom">{{dataForm.personalName}}</td>
                <td class="no_border_bottom">{{dataForm.personalMobile}}</td>
                <td class="td_bottom">{{dataForm.deliveryAddress}}</td>
              </tr>
            </table>
          </el-main>
        </el-container>
      </el-row>
      <!-- 商品明细 -->
      <el-row>
        <el-container>
          <el-header class="header">商品信息</el-header>
          <el-main>
            <el-table :data="skuTableData" style="width: 100%">
              <el-table-column label="商品图片">
                <template slot-scope="scope">
                  <img :src="scope.row.goodsSkuPic" width="60px" height="60px" />
                </template>
              </el-table-column>
              <!-- <el-table-column prop="goodsTitle" label="商品标题"></el-table-column> -->
              <el-table-column prop="goodsSkuName" label="商品标题"></el-table-column>
              <el-table-column prop="categoryName" label="分类"></el-table-column>
              <el-table-column prop="attrValues" label="属性"></el-table-column>
              <el-table-column prop="originalPrice" label="原价(￥)"></el-table-column>
              <el-table-column prop="buyNum" label="数量"></el-table-column>
              <el-table-column prop="skuPayPrice" label="小计(￥)"></el-table-column>
            </el-table>
          </el-main>
        </el-container>
      </el-row>
      <!-- 物流信息 -->
      <el-row style="margin-bottom:80px;">
        <el-container>
          <el-header class="header">物流信息</el-header>
          <el-main class="mainBottom">
            <el-row>发货方式：{{dataForm.payType}}</el-row>
            <el-row>物流公司：{{dataForm.logisticsName}}</el-row>
            <el-row>运单号码：{{dataForm.logisticsNumber}}</el-row>
            <span v-if="dataForm.orderStatus != 'unpaid' && dataForm.orderStatus != 'refund'">
              <el-row>物流跟踪：</el-row>
              <el-row
                class="row"
                style="margin-left:20px;"
                v-for="item in dataForm.data"
                :key="item"
              >{{item.ftime}} {{item.context}}</el-row>
            </span>
          </el-main>
        </el-container>
      </el-row>
    </div>
    <div slot="soltFooter">
      <el-button @click="sideClose">关闭</el-button>
      <!-- <el-button type="primary" :loading="loading" @click="dataFormSubmit()">确定</el-button> -->
    </div>
  </sides-lip>
</template>
<script>
import { findOrderInfo } from "@/api/sys";
import SidesLip from "@/components/SidesLip";
export default {
  data() {
    return {
      loading: false,
      visible: false,
      dataForm: {
        orderNumber: "",
        categoryName: "",
        personalName: "",
        personalMobile: "",
        address: "",
        orderRemark: "",
        orderTime: "",
        orderFlowId: "",
        payTime: "",
        statusDesc: "",
        logisticsCompany: "",
        logisticsNumber: "",
        serialNumber: "",
        payType: "",
        data: []
      },
      skuTableData: []
    };
  },
  components: {
    SidesLip
  },
  methods: {
    async init(id) {
      this.dataForm.id = id || 0;
      this.$refs.SidesLip.init("订单详情");
      await this.$nextTick(() => {
        this.$refs["dataForm"].resetFields();
      });
      if (this.dataForm.id) {
        const { data } = await findOrderInfo(
          `/mall/ordermallbuy/info/${this.dataForm.id}`
        );
        if (data && data.code === 0) {
          this.dataForm = data.orderMallBuy;

          var payType = data.orderMallBuy.payType;
          if (payType != null && payType != "" && payType != undefined) {
            if (payType === "weixin") {
              this.dataForm.payType = "微信";
            } else if (payType === "alipay") {
              this.dataForm.payType = "支付宝";
            }
          }
          var status = data.orderMallBuy.orderStatus;
          if (status == "unpaid") {
            this.dataForm.statusDesc = "未支付";
          } else if (status == "paid") {
            this.dataForm.statusDesc = "已支付";
          } else if (status == "refund") {
            this.dataForm.statusDesc = "已取消";
          } else if (status == "delivered") {
            this.dataForm.statusDesc = "已发货";
          } else if (status == "trade_finished") {
            this.dataForm.statusDesc = "交易完成";
          }
          var dataList = data.orderMallBuy.data;
          console.log(dataList);
          debugger;
          if (null != dataList && dataList != undefined && dataList != "") {
            this.dataForm.data = dataList;
          } else {
            var arr = [];
            var obj = new Object();
            obj.context = "暂无物流信息";
            obj.ftime = "";
            arr.push(obj);
            this.dataForm.data = arr;
          }
          this.skuTableData = data.orderGoodsList;
        }
      }
    },
    sideClose() {
      this.$refs.SidesLip.sideClose();
    }
  }
};
</script>
<style>
.header {
  /* background-color:#5588AA; */
  font-size: 18px;
  font-weight: bold;
  border-bottom: 1px solid #ccc;
  line-height: 58px;
  padding: 0;
}
.table {
  border: 1px solid #dddddd;
}
.td_right {
  border-right: 1px solid #dddddd;
  border-bottom: 1px solid #dddddd;
  height: 38px;
  text-align: center;
}
.td_bottom {
  border-bottom: 1px solid #dddddd;
  height: 38px;
  text-align: center;
}
.title {
  background-color: #dddddd;
}

.no_border_bottom {
  border-right: 1px solid #dddddd;
  border-bottom: 0px;
  height: 38px;
  text-align: center;
}
.order_state {
  color: red;
  font-size: 28px;
  margin-bottom: 10px;
}
.row {
  height: 58px;
}
</style>
