<template>
  <div class="app-container">
    <el-form
      :inline="true"
      :model="dataForm"
      ref="dataForm"
      @keyup.enter.native="restPage()"
      size="small"
    >
      <el-form-item prop="userNameLike">
        <el-input
          v-model="dataForm.userNameLike"
          placeholder="用户名"
          clearable
          maxlength="30"
        ></el-input>
      </el-form-item>
      <el-form-item>
        <el-button @click="restPage()">查询</el-button>
        <el-button @click="reset('dataForm')">清空</el-button>
        <el-button
          v-if="isAuth('sys:user:save')"
          type="primary"
          @click="addOrUpdateHandle()"
          >新增</el-button
        >
        <el-button
          v-if="isAuth('sys:user:delete')"
          type="danger"
          @click="deleteHandle()"
          :disabled="dataListSelections.length <= 0"
          >批量删除</el-button
        >
      </el-form-item>
    </el-form>
    <el-table
      :data="dataList"
      border
      v-loading="dataListLoading"
      tooltip-effect="dark"
      @selection-change="selectionChangeHandle"
      size="small"
      style="width: 100%"
    >
      <el-table-column
        type="selection"
        header-align="center"
        align="center"
        width="50"
      >
      </el-table-column>
      <el-table-column
        prop="id"
        header-align="center"
        align="center"
        label="id"
        width="150"
      >
      </el-table-column>
      <el-table-column
        prop="userName"
        header-align="center"
        align="center"
        label="用户名"
      >
      </el-table-column>
      <el-table-column
        prop="nickName"
        header-align="center"
        align="center"
        label="昵称"
      >
      </el-table-column>
      <el-table-column
        prop="email"
        header-align="center"
        align="center"
        label="邮箱"
      >
      </el-table-column>
      <el-table-column
        prop="mobile"
        header-align="center"
        align="center"
        label="手机号"
      >
      </el-table-column>
      <el-table-column
        prop="isAdmin"
        header-align="center"
        align="center"
        label="是否管理员"
        width="100"
      >
        <template slot-scope="scope">
          {{ scope.row.isAdmin ? "是" : "否" }}
        </template>
      </el-table-column>
      <el-table-column
        prop="isEnable"
        header-align="center"
        align="center"
        label="状态"
        width="100"
      >
        <template slot-scope="scope">
          <el-tag v-if="scope.row.isEnable" type="success">正常</el-tag>
          <el-tag v-else type="danger">禁用</el-tag>
        </template>
      </el-table-column>
      <el-table-column
        prop="createTime"
        header-align="center"
        align="center"
        width="150"
        label="创建时间"
      >
      </el-table-column>
      <el-table-column
        fixed="right"
        header-align="center"
        align="center"
        width="150"
        label="操作"
      >
        <template slot-scope="scope">
          <el-button
            v-if="isAuth('sys:user:update')"
            type="text"
            size="small"
            @click="addOrUpdateHandle(scope.row.id)"
            >修改</el-button
          >
          <el-button
            v-if="isAuth('sys:user:delete')"
            type="text"
            size="small"
            @click="deleteHandle(scope.row.id)"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      @size-change="sizeChangeHandle"
      @current-change="currentChangeHandle"
      :current-page="dataForm.pageNo"
      :page-sizes="[10, 20, 50, 100]"
      :page-size="dataForm.pageSize"
      :total="totalPage"
      layout="total, sizes, prev, pager, next, jumper"
    >
    </el-pagination>
    <!-- 弹窗, 新增 / 修改 -->
    <add-or-update
      v-if="addOrUpdateVisible"
      ref="addOrUpdate"
      @refreshDataList="restPage"
    ></add-or-update>
  </div>
</template>

<script>
import { userList, userDeleteApi } from "@/api/sys";
import AddOrUpdate from "./components/user-add-or-update";

export default {
  data() {
    return {
      dataForm: {
        pageNo: 1,
        pageSize: 10,
        userNameLike: "",
      },
      dataList: [],
      totalPage: 0,
      dataListLoading: false,
      dataListSelections: [],
      addOrUpdateVisible: true,
    };
  },
  components: {
    AddOrUpdate,
  },
  // activated() {
  //   this.getDataList();
  // },
  methods: {
    // 查询清空 修改  by葛海军
    reset(dataForm) {
      this.$refs[dataForm].resetFields();
      this.restPage();
    },
    // 跳到第一页进行查询
    restPage() {
      this.pageIndex = 1;
      this.getDataList();
    },

    // 获取数据列表
    getDataList() {
      this.dataListLoading = true;
      userList(this.dataForm).then(({ records, totalRecords }) => {
        if (totalRecords > 0) {
          this.dataList = records;
          this.totalPage = totalRecords;
        } else {
          this.dataList = [];
          this.totalPage = 0;
        }
        this.dataListLoading = false;
      });
    },
    // 每页数
    sizeChangeHandle(val) {
      this.dataForm.pageSize = val;
      this.dataForm.pageNo = 1;
      this.getDataList();
    },
    // 当前页
    currentChangeHandle(val) {
      this.dataForm.pageNo = val;
      this.getDataList();
    },
    // 多选
    selectionChangeHandle(val) {
      this.dataListSelections = val;
    },
    // 新增 / 修改
    addOrUpdateHandle(id) {
      this.addOrUpdateVisible = true;
      this.$nextTick(() => {
        this.$refs.addOrUpdate.init(id);
      });
    },
    // 删除
    deleteHandle(id) {
      var userIds = id
        ? [id]
        : this.dataListSelections.map((item) => {
            return item.id;
          });
      this.$confirm(`确定进行${id ? "删除" : "批量删除"}操作?`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          userDeleteApi(userIds).then(() => {
            this.$baseMessage("删除成功", "success");
            this.restPage();
          });
        })
        .catch(() => {});
    },
  },
  mounted() {
    this.getDataList();
  },
};
</script>
