<template>
  <el-dialog
    :title="!dataForm.id ? '新增' : '修改'"
    :close-on-click-modal="false"
    :visible.sync="visible">
    <el-form :model="dataForm" :rules="dataRule"  size="small" ref="dataForm" @keyup.enter.native="dataFormSubmit()" label-width="80px"
     v-loading="dataLoading" style="width:100%;"  element-loading-text=""  element-loading-spinner="el-icon-loading"
    >
      <el-form-item label="位置名称" prop="name">
        <el-input v-model="dataForm.name" placeholder="名称"  size="small"></el-input>
      </el-form-item>
      <el-form-item label="广告类型" prop="application">
        <el-select v-model="dataForm.application" placeholder="广告类型">
          <el-option label="小程序" value="0"></el-option>
          <el-option label="门户网站" value="1"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="位置" prop="type">
        <el-select v-model="dataForm.type" placeholder="广告类型">
          <el-option label="品牌选择页" value="1"></el-option>
        </el-select>
      </el-form-item>

    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="visible = false" size="small">取消</el-button>
      <el-button type="primary" @click="dataFormSubmit()" size="small"  :disabled="dataLoading">确定</el-button>
    </span>
  </el-dialog>
</template>

<script>
  import {info,add,update} from '@/api/modules/advertposition'
  export default {
    data () {
      return {
        visible: false,
        dataLoading: false,
        dataForm: {
          id: 0,
          name: '',
          width: '',
          height: '',
          type: '',
          application: '',
          desc: ''
        },
        dataRule: {
          name: [
            { required: true, message: '广告位名称不能为空', trigger: 'blur' }
          ],
          type: [
            { required: true, message: '位置不能为空', trigger: 'blur' }
          ],
          application: [
            { required: true, message: '广告类型不能为空', trigger: 'blur' }
          ],
        }
      }
    },
    methods: {
      init (id) {
        this.dataForm.id = id || 0
        this.visible = true
        this.$nextTick(() => {
          this.$refs['dataForm'].resetFields()
          if (this.dataForm.id) {
            info({id:this.dataForm.id}).then(({data}) => {
              if (data && data.code === 0) {
                this.dataForm.name = data.advertPosition.name
                this.dataForm.width = data.advertPosition.width
                this.dataForm.height = data.advertPosition.height
                this.dataForm.type = data.advertPosition.type
                this.dataForm.application = data.advertPosition.application
                this.dataForm.desc = data.advertPosition.desc
              }
            })
          }
        })
      },
      // 表单提交
      dataFormSubmit () {
        this.$refs['dataForm'].validate((valid) => {
          if (valid) {
          	this.dataLoading = true;
            var params = {
              'id': this.dataForm.id || undefined,
              'name': this.dataForm.name,
              'width': this.dataForm.width,
              'height': this.dataForm.height,
              'type': this.dataForm.type,
              'application': this.dataForm.application,
              'desc': this.dataForm.desc
            }
            var tick = !this.dataForm.id ? add(params) : update(params)
            tick.then(({data}) => {
              if (data && data.code === 0) {
                this.$message({
                  message: '操作成功',
                  type: 'success',
                  duration: 1500,
                  onClose: () => {
                    this.visible = false;
	              	this.dataLoading = false;
                    this.$emit('refreshDataList')
                  }
                })
              } else {
                this.$message.error(data.msg)
                this.dataLoading = false;
              }
            })
          }
        })
      }
    }
  }
</script>
