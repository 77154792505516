import request from '@/utils/request'

const url = '/m/colorBook';

// 获取列表
export function queryColorBookApi (data) {
  if(!data) {
    data = {pageSize: 100000}
  }
  return request({
    url: `${url}/query`,
    method: 'post',
    data
  })
}

// 获取信息
export function getColorBookApi (id) {
  return request({
    url: `${url}/get/${id}`,
    method: 'get',
  })
}

// 添加
export function addOrUpdateColorBookApi (data) {
  return request({
    url: `${url}/addOrUpdate`,
    method: 'put',
    data
  })
}

// 删除
export function deleteColorBookApi (id) {
  return request({
    url: `${url}/delete/${id}`,
    method: 'delete'
  })
}

// 获取类别下拉列表
export function colorBookDropDown (params) {
    return request({
        url: '/m/colorBook//colorBookDropDown',
        method: 'get',
        params: params
    })
}
// 导入
export function importExcel(params) {
    return request({
        url: '/m/colorBook/importExcel',
        method: 'post',
        data: params
    })
}
// 获取分类下拉列表
export function getColorBookCategory (params) {
    return request({
        url: '/m/colorBook/getColorBookCategory',
        method: 'get',
        params: params
    })
}
// 获取根据色号类型匹配色号分类数据
export function getCategoryList (params) {
    return request({
        url: '/m/colorBook/getCategoryList',
        method: 'get',
        params: params
    })
}
