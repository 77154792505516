import request from '@/utils/request'

// 获取列表
export function list (params) {
  return request({
    url: '/sample/sampleorder/list',
    method: 'get',
    params: params
  })
}

// 获取信息
export function info (params) {
  return request({
    url: '/sample/sampleorder/info',
    method: 'get',
    params: params
  })
}

// 添加
export function add (params) {
  return request({
    url: '/sample/sampleorder/save',
    method: 'post',
    data: params
  })
}

// 修改
export function update (params) {
  return request({
    url: '/sample/sampleorder/update',
    method: 'post',
    data: params
  })
}

// 删除
export function del (params) {
  return request({
    url: '/sample/sampleorder/delete',
    method: 'post',
    data: params
  })
}


//保存物流单号
export function sampleLogisticsUpdate (params) {
  return request({
    url: '/sample/samplelogistics/update',
    method: 'post',
    data: params
  })
}

//查看物流信息
export function selectLoginic (params) {
  return request({
    url: '/sample/sampleorder/selectLoginic',
    method: 'post',
    data: params
  })
}

// 确认收款
export function payment (id) {
  return request({
    url: '/sample/sampleorder/payment',
    method: 'get',
    params: id
  })
}
//付款证明
export function paymentProof (id) {
  return request({
    url: '/sample/sampleorder/paymentProof',
    method: 'get',
    params: id
  })
}
//用户评价
export function evaLuateInfo (id) {
  return request({
    url: '/sample/sampleorder/evaLuateInfo',
    method: 'get',
    params: id
  })
}
//用户评价
export function priceAdjustment (params) {
  return request({
    url: '/sample/sampleorder/priceAdjustment',
    method: 'post',
    data: params
  })
}