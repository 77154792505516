<template>
  <div class="searchanalysis-container">
    <el-row :gutter="0">
      <el-col
        :span="24"
        style="
          color: #394158;
          border-bottom: 1px solid #d8d8d8;
          display: flex;
          align-items: center;
          height: 50px;
        "
      >
        <img :src="img01" style="
            width: 17px;
            height: 17px;
            margin-right: 10px;
            margin-left: 10px;
          "
          alt="404"
        />
        <h4>搜索分析</h4>
      </el-col>
    </el-row>
    <el-row :gutter="0">
      <el-col
        :span="24"
        style="padding: 20px; margin-left: 30px; font-size: 15px"
      >
        <div style="margin: 20px 0px">
          时 间：
          <el-button plain size="small" @click="timeValue  = [getBeforeDate(0),getBeforeDate(0)]" :class="timeValue && timeValue[0] === getBeforeDate(0) && timeValue[1] === getBeforeDate(0) ? 'selectin' : ''">今天</el-button>
          <el-button plain size="small" @click="timeValue  = [getBeforeDate(1),getBeforeDate(1)]" :class="timeValue && timeValue[0] === getBeforeDate(1) && timeValue[1] === getBeforeDate(1) ? 'selectin' : ''">昨天</el-button>
          <el-button plain size="small" @click="timeValue  = [getBeforeDate(7),getBeforeDate(0)]" :class="timeValue && timeValue[0] === getBeforeDate(7) && timeValue[1] === getBeforeDate(0) ? 'selectin' : ''">最近七天</el-button>
          <el-button plain size="small" @click="timeValue  = [getBeforeDate(30),getBeforeDate(0)]" :class="timeValue && timeValue[0] === getBeforeDate(30) && timeValue[1] === getBeforeDate(0) ? 'selectin' : ''">最近30天</el-button>
          <el-date-picker
            size="small"
            style="margin-left: 10px"
            v-model="timeValue"
            value-format="yyyy-MM-dd"
            type="daterange"
            range-separator="-"
            start-placeholder="开始日期"
            end-placeholder="结束日期">
          </el-date-picker>
        </div>
        <div style="margin: 20px 0px">
          品 牌：
          <el-button plain size="small" v-for="(item,index) in brandList" :key="index" :class="brandId === item.id ? 'selectin' : ''" @click="brandId = item.id">{{item.brandName}}</el-button>
        </div>
        <div style="margin: 20px 0px">
          标 签：
          <el-select
            v-model="memberLabelId"
            placeholder="标签"
            clearable
            size="small"
            style="margin-right: 15px"
          >
            <el-option label="全部" value=""></el-option>
            <el-option
              v-for="item in memberLabelList"
              :key="item.productLabelId"
              :label="item.memberLabelName"
              :value="item.productLabelId"
            >
            </el-option>
          </el-select>
          手机号：
          <el-input
            v-model="mobile"
            placeholder="手机号"
            size="small"
            clearable
            style="width: 200px; margin-right: 15px"
          ></el-input>
          备注：<el-input
            v-model="remarks"
            placeholder="备注"
            size="small"
            clearable
            style="width: 200px; margin-right: 15px"
          ></el-input>
          <el-button @click="startAnalysis()" type="primary" size="small"
            >开始分析</el-button
          >
        </div>
      </el-col>
    </el-row>
    <el-row :gutter="10">
      <el-col :span="20">
        <div
          style="
            color: #394158;
            display: flex;
            align-items: center;
            height: 50px;
          "
        >
          <img
            :src="img03"
            style="
              width: 17px;
              height: 17px;
              margin-right: 10px;
              margin-left: 10px;
            "
            alt="404"
          />
          <h4>搜索概览</h4>
        </div>
        <div
          style="
            width: 100%;
            height: 90px;
            background: #f6f7ff;
            border-radius: 5px;
          "
          class="flex"
        >
          <div>
            <div class="oneColor">搜索次数</div>
            <div class="twoColor">{{searchOverview && (searchOverview.searchCount || searchOverview.searchCount === 0) ? searchOverview.searchCount : '-'}}</div>
          </div>
          <div>
            <div class="oneColor">搜索人数</div>
            <div class="twoColor">{{searchOverview && (searchOverview.peopleCount || searchOverview.peopleCount === 0) ? searchOverview.peopleCount : '-'}}</div>
          </div>
          <div>
            <div class="oneColor">搜索匹配率</div>
            <div class="twoColor">{{searchOverview && (searchOverview.matchingProbability || searchOverview.matchingProbability === 0) ? searchOverview.matchingProbability + '%' : '-'}}</div>
          </div>
          <div>
            <div class="oneColor">搜索匹配次数</div>
            <div class="twoColor">{{searchOverview && (searchOverview.matchingCount || searchOverview.matchingCount === 0) ? searchOverview.matchingCount : '-'}}</div>
          </div>
          <div>
            <div class="oneColor">不匹配次数</div>
            <div class="twoColor">{{searchOverview && (searchOverview.noMatchingCount || searchOverview.noMatchingCount === 0) ? searchOverview.noMatchingCount : '-'}}</div>
          </div>
          <div>
            <div class="oneColor">人均搜索次数</div>
            <div class="twoColor">{{searchOverview && (searchOverview.averageOfPeopleCount || searchOverview.averageOfPeopleCount === 0) ? searchOverview.averageOfPeopleCount : '-'}}</div>
          </div>
          <div>
            <div class="oneColor">看产品详情</div>
            <div class="twoColor">{{searchOverview && (searchOverview.searchProductInfo || searchOverview.searchProductInfo === 0) ? searchOverview.searchProductInfo : '-'}}</div>
          </div>
        </div>
      </el-col>
    </el-row>
    <el-row :gutter="0" style="margin-top:30px;"> 
      <el-col :span="24">
        <div
          style="
            color: #394158;
            display: flex;
            align-items: center;
            height: 50px;
          "
        >
          <img
            :src="img02"
            style="
              width: 20px;
              height: 15px;
              margin-right: 10px;
              margin-left: 10px;
            "
            alt="404"
          />
          <h4>会员概览</h4>
        </div>
        <div
          style="
            width: 100%;
            height: 90px;
            background: #f6f7ff;
            border-radius: 5px;
          "
          class="flex"
        >
          <div>
            <div class="oneColor">当前会员数</div>
            <div class="twoColor">{{memberOverview && (memberOverview.memberCount || memberOverview.memberCount === 0) ? memberOverview.memberCount : '-'}}</div>
          </div>
          <div>
            <div class="oneColor">新会员数</div>
            <div class="twoColor">{{memberOverview && (memberOverview.newMemberCount || memberOverview.newMemberCount === 0) ? memberOverview.newMemberCount : '-'}}</div>
          </div>
          <div>
            <div class="oneColor">产品收藏数</div>
            <div class="twoColor">{{memberOverview && (memberOverview.collectCount || memberOverview.collectCount === 0) ? memberOverview.collectCount : '-'}}</div>
          </div>
          <div>
            <div class="oneColor">会员设备数量</div>
            <div class="twoColor">{{memberOverview && (memberOverview.memberDeviceCount || memberOverview.memberDeviceCount === 0) ? memberOverview.memberDeviceCount : '-'}}</div>
          </div>
          <div>
            <div class="oneColor">已授权设备数量</div>
            <div class="twoColor">{{memberOverview && (memberOverview.memberAuthDeviceCount || memberOverview.memberAuthDeviceCount === 0) ? memberOverview.memberAuthDeviceCount : '-'}}</div>
          </div>
          <div>
            <div class="oneColor">已授权会员数</div>
            <div class="twoColor">{{memberOverview && (memberOverview.memberAuthCount || memberOverview.memberAuthCount === 0) ? memberOverview.memberAuthCount : '-'}}</div>
          </div>
          <div>
            <div class="oneColor">新增设备数</div>
            <div class="twoColor">{{memberOverview && (memberOverview.memberNewDeviceCount || memberOverview.memberNewDeviceCount === 0) ? memberOverview.memberNewDeviceCount : '-'}}</div>
          </div>
          <div>
            <div class="oneColor">新增授权设备数</div>
            <div class="twoColor">{{memberOverview && (memberOverview.memberNewAuthDeviceCount || memberOverview.memberNewAuthDeviceCount === 0) ? memberOverview.memberNewAuthDeviceCount : '-'}}</div>
          </div>
          <div>
            <div class="oneColor">新增授权会员数</div>
            <div class="twoColor">{{memberOverview && (memberOverview.memberNewAuthCount || memberOverview.memberNewAuthCount === 0) ? memberOverview.memberNewAuthCount : '-'}}</div>
          </div>
        </div>
      </el-col>
    </el-row>
    <el-row :gutter="0" style="margin-top: 20px">
      <el-col :span="24" style="padding:0 50px;">
        <div style="width: 100%; text-align: center; color: #485465">
          <h4>搜索趋势</h4>
        </div>
        <div id="discountNoDate" style="width: 100%;height: 400px;text-align:center;padding-top: 180px;"><span>暂无数据</span></div>
        <div id="discount" style="width: 100%; height: 400px"></div>
      </el-col>
    </el-row>
    <el-row
      :gutter="0"
      style="padding-top: 0px; border-top: 1px solid #cccccc"
    >
      <el-col :span="12" style="border-right: 1px solid #cccccc">
        <div style="width: 100%; color: #485465;padding-top: 10px;">
          <h4>搜索方式分布</h4>
        </div>
        <div id="piechart01NoDate" style="width: 100%;height: 400px;text-align:center;padding-top: 180px;"><span>暂无数据</span></div>
        <div id="piechart01" style="width: 100%; height: 400px"></div>
      </el-col>
      <el-col :span="12">
        <div style="color: #485465; margin-left: 20px;padding-top: 10px;">
          <h4>搜索属性分布</h4>
        </div>
        <div id="piechart02NoDate" style="width: 100%;height: 400px;text-align:center;padding-top: 180px;"><span>暂无数据</span></div>
        <div id="piechart02" style="width: 100%; height: 400px"></div>
      </el-col>
    </el-row>
    <el-row
      :gutter="0"
      style="border-top: 1px solid #cccccc"
    >
      <el-col :span="12" style="border-right: 1px solid #cccccc">
        <div style="color: #485465; margin-left: 20px;padding-top: 10px;">
          <h4>搜索属性值分布
          <el-select size="small" style="float:right;margin-right:20px;" v-model="productAttrId" @change="searchAttrValueOverview">
            <el-option label="全部" value=""></el-option>
            <el-option
              v-for="item in productAttrList"
              :key="item.productAttrId"
              :label="item.attrName"
              :value="item.productAttrId"
            ></el-option>
          </el-select></h4>
        </div>
        <div id="piechart03NoDate" style="width: 100%;height: 400px;text-align:center;padding-top: 180px;"><span>暂无数据</span></div>
        <div id="piechart03" style="width: 100%; height: 500px"></div>
      </el-col>
      <el-col :span="12">
        <div style="color: #485465; margin-left: 20px;padding-top: 10px;">
          <h4>光泽搜索排行</h4>
        </div>
        <div id="piechart04NoDate" style="width: 100%;height: 400px;text-align:center;padding-top: 180px;"><span>暂无数据</span></div>
        <div id="piechart04" style="width: 100%; height: 500px"></div>
      </el-col>
    </el-row>
  </div>
</template>
<script>
import img01 from "@/assets/img/searchanalysis_s.png";
import img02 from "@/assets/img/searchanalysis_p.png";
import img03 from "@/assets/img/searchanalysis_b.png";
import { getMemberLabelList } from "@/api/modules/memberlabel";
import * as echarts from "echarts";
import {getBrand} from '@/api/modules/product'
import {searchAttrValueOverview, searchAttrOverview, searchTypeOverview, searchMemberOverview, searchTrend, searchOverview, selectExistAttr, searchGlossValueOverview} from "@/api/modules/searchanalysis";
export default {
  data() {
    return {
      img01,
      img02,
      img03,
      timeValue: [],
      memberLabelId: "",
      brand: "",
      mobile: "",
      remarks: "",
      memberLabelList: [],
      brandList:[],
      brandId:'',
      productAttrId:'',
      productAttrList:[],
      searchOverview:{},
      memberOverview:{},
    };
  },
  created() {
    this.timeValue = [this.getBeforeDate(0),this.getBeforeDate(0)]
    this.getBrandList();
    console.log(echarts.version);
  },
  mounted() {
  },

  methods: {
    //开始分析
    startAnalysis(){
      if (this.brandId && this.timeValue && this.timeValue.length > 0) {
        this.productAttrId = '',
        this.searchTrend()
        this.searchTypeOverview();
        this.searchAttrValueOverview();
        this.getOverview();
        this.getproductAttrList()
      }else{
        if (this.brandId) {
          this.$message.warning("请选择一个时间段！")
        }else{
          this.$message.warning("请选择品牌！")
        }
      }
    },
    //时间
    getBeforeDate(n) {
      var d = new Date();
      var year = d.getFullYear();
      var mon = d.getMonth() + 1;
      var day = d.getDate();
      if(day <= n) {
          if(mon > 1) {
              mon = mon - 1;
          } else {
              year = year - 1;
              mon = 12;
          }
      }
      d.setDate(d.getDate() - n);
      year = d.getFullYear();
      mon = d.getMonth() + 1;
      day = d.getDate();
      var s = ''
      s = year + "-" + (mon < 10 ? ('0' + mon) : mon) + "-" + (day < 10 ? ('0' + day) : day);
      return s;
    },
    //获取品牌列表
    getBrandList(){
      var params = {
      }
      getBrand(params).then(({data}) => {
        if (data && data.code === 0) {
            this.brandList = data.list
            this.brandId = data.list[0].id
            this.getproductAttrList()
            this.getOverview();
            this.getMemberLabel();
            setTimeout(() => {
              this.searchTrend();
              this.searchTypeOverview();
              this.searchAttrValueOverview();
            }, 0);
        } else {
            this.brandList = []
        }
      })
    },
    getproductAttrList(){
      this.productAttrId = ''
      var data = []
      var startDate = null;
      var endDate = null;
      if (this.timeValue && this.timeValue.length > 0) {
        startDate = this.timeValue[0];
        endDate = this.timeValue[1];
      }
      let params = {
        'memberLabelId':this.memberLabelId,
        'brandId':this.brandId,
        'mobile':this.mobile,
        'remarks':this.remarks,
        'productAttrId':this.productAttrId,
        'startDate': startDate ? startDate + ' 00:00:00' : '',
        'endDate': endDate ? endDate + ' 23:59:59' : ''
      };
      selectExistAttr(params).then(({data}) => {
        if (data && data.code === 0) {
            this.productAttrList = data.existAttrList
        } else {
            this.productAttrList = []
        }
      })
    },
    getMemberLabel() {
      //获取标签列表
      let params = {};
      getMemberLabelList(params).then(({ data }) => {
        if (data && data.code === 0) {
          this.memberLabelList = data.data;
        } else {
          this.memberLabelList = [];
        }
      });
    },

    //搜索属性值分布
    searchAttrValueOverview(){
      var data = []
      var startDate = null;
      var endDate = null;
      if (this.timeValue && this.timeValue.length > 0) {
        startDate = this.timeValue[0];
        endDate = this.timeValue[1];
      }
      let params = {
        'memberLabelId':this.memberLabelId,
        'brandId':this.brandId,
        'mobile':this.mobile,
        'remarks':this.remarks,
        'productAttrId':this.productAttrId,
        'startDate': startDate ? startDate + ' 00:00:00' : '',
        'endDate': endDate ? endDate + ' 23:59:59' : ''
      };
      searchAttrValueOverview(params).then(({ data }) => {
        if (data && data.code === 0) {
          this.initPieChart03(data.searchAttrValueOverview)
        }
      });
    },

    //概览
    getOverview(){
      var startDate = null;
      var endDate = null;
      if (this.timeValue && this.timeValue.length > 0) {
        startDate = this.timeValue[0];
        endDate = this.timeValue[1];
      }
      let params = {
        'memberLabelId':this.memberLabelId,
        'brandId':this.brandId,
        'mobile':this.mobile,
        'remarks':this.remarks,
        'startDate': startDate ? startDate + ' 00:00:00' : '',
        'endDate': endDate ? endDate + ' 23:59:59' : ''
      };
      //搜索概览
      searchOverview(params).then(({ data }) => {
        if (data && data.code === 0) {
          this.searchOverview = data.searchOverview
        }else{
          this.searchOverview = {}
        }
      });
      //用户概览
      searchMemberOverview(params).then(({ data }) => {
        if (data && data.code === 0) {
          this.memberOverview = data.memberOverview
        }else{
          this.memberOverview = {}
        }
      });
    },

  //搜索来源分布
    searchTypeOverview(){
      var startDate = null;
      var endDate = null;
      if (this.timeValue && this.timeValue.length > 0) {
        startDate = this.timeValue[0];
        endDate = this.timeValue[1];
      }
      let params = {
        'memberLabelId':this.memberLabelId,
        'brandId':this.brandId,
        'mobile':this.mobile,
        'remarks':this.remarks,
        'startDate': startDate ? startDate + ' 00:00:00' : '',
        'endDate': endDate ? endDate + ' 23:59:59' : ''
      };
      //搜索概览
      searchTypeOverview(params).then(({ data }) => {
        if (data && data.code === 0) {
          this.initPieChart01(data.searchTypeOverview ? data.searchTypeOverview : [])
        }
      });
      //搜索属性分布
      searchAttrOverview(params).then(({ data }) => {
        if (data && data.code === 0) {
          this.initPieChart02(data.searchAttrOverview)
        }
      });
      //光泽搜索分布
      searchGlossValueOverview(params).then(({ data }) => {
        if (data && data.code === 0) {
          this.initPieChart04(data.glossValueList)
        }
      });
    },

    //搜索趋势
    searchTrend(){
      var startDate = null;
      var endDate = null;
      if (this.timeValue && this.timeValue.length > 0) {
        startDate = this.timeValue[0];
        endDate = this.timeValue[1];
      }
      let params = {
        'memberLabelId':this.memberLabelId,
        'brandId':this.brandId,
        'mobile':this.mobile,
        'remarks':this.remarks,
        'startDate': startDate ? startDate + ' 00:00:00' : '',
        'endDate': endDate ? endDate + ' 23:59:59' : '',
        'sameDay': endDate && startDate && endDate == startDate ? '1' :null
      };
      //搜索概览
      searchTrend(params).then(({ data }) => {
        if (data && data.code === 0) {
          var data1 = []
          var data2 = []
          if (endDate && startDate && endDate == startDate) {
            data1 = ['00','01','02','03','04','05','06','07','08','09','10','11','12','13','14','15','16','17','18','19','20','21','22','23']
            data2 = data1.map((item) =>{
              var isshow = 0
              data.memberSearchTrend.map((ite,inde) =>{
                if (ite.searchDate == item) {
                  isshow = ite.searchCount
                }
              })
              return isshow
            })
          }else{
            data1 = data.memberSearchTrend.map((item) =>{
              return item.searchDate
            })
            data2 = data.memberSearchTrend.map((item) =>{
              return item.searchCount
            })
          }
          this.initChartDiscount(data1,data2)
        }
      });
    },

    //折线图
    initChartDiscount(data1,data2) {
      if (!data2) {
        document.getElementById("discountNoDate").style.display="";
        document.getElementById("discount").style.display="none";
      }else{
        document.getElementById("discountNoDate").style.display="none";
        document.getElementById("discount").style.display="";
      }
      // console.log(data1,data2)
      var chartDom = document.getElementById("discount");
      var chart = echarts.init(chartDom);
      var option = {};
      option = {
        color: ["#7073FF"],
        tooltip: {
          trigger: "axis",
          axisPointer: {
            // 坐标轴指示器，坐标轴触发有效
            type: "line", // 默认为直线，可选为：'line' | 'shadow'
          },
          formatter: data1[0] === '00' ? "{b}:00 <br/>搜索总次数: {c}" : "{b} <br/>搜索总次数: {c}",
        },
        grid: {
          left: "30px",
          right: "30px",
          bottom: "30px",
          top: "30px",
          containLabel: true,
        },
        xAxis: {
          type: "category",
          data: data1,
        },
        yAxis: {
          type: "value",
        },
        series: [
          {
            data: data2,
            type: "line",
          },
        ],
      };
      option && chart.setOption(option);
      chart.resize();
    },
    //饼图引导图01
    initPieChart01(Data) {
      console.log('之前',Data)
      if (Data.length === 0) {
        document.getElementById("piechart01NoDate").style.display="";
        document.getElementById("piechart01").style.display="none";
      }else{
        document.getElementById("piechart01NoDate").style.display="none";
        document.getElementById("piechart01").style.display="";
      }
      var chartDom = document.getElementById("piechart01");
      var chart = echarts.init(chartDom);
      var option = {};
      var data = [
        Data.map((item) =>{
          var obj = {
            name:item.searchTypeName,
            value:item.searchTypeCount
          }
          return obj
        })
      ];
      option = {
        color: ["#5470C6", "#3BA272", "#FAC858", "#FC8452"],
        tooltip: {
          trigger: "item",
          formatter: "{b}: {c} ({d}%)",
        },
        series: data.map(function (data, idx) {
          var top = idx * 33.3;
          return {
            type: "pie",
            radius: [70, 120],
            top: top + "%",
            height: "33.33%",
            left: "center",
            width: 400,
            itemStyle: {
              borderColor: "#fff",
              borderWidth: 0,
            },
            label: {
              alignTo: "edge",
              formatter: "{name|{b}({d}%)}",
              minMargin: 5,
              edgeDistance: 10,
              lineHeight: 15,
              rich: {
                time: {
                  fontSize: 10,
                  color: "#999",
                },
              },
            },
            labelLine: {
              length: 15,
              length2: 0,
              maxSurfaceAngle: 80,
            },
            labelLayout: function (params) {
              var isLeft = params.labelRect.x < chart.getWidth() / 2;
              var points = params.labelLinePoints;
              // Update the end point.
              points[2][0] = isLeft
                ? params.labelRect.x
                : params.labelRect.x + params.labelRect.width;
              return {
                labelLinePoints: points,
              };
            },
            data: data,
          };
        }),
      };
      option && chart.setOption(option,true);
      chart.resize();
    },
    initPieChart02(Data) {
      if (Data.length === 0) {
        document.getElementById("piechart02NoDate").style.display="";
        document.getElementById("piechart02").style.display="none";
      }else{
        document.getElementById("piechart02NoDate").style.display="none";
        document.getElementById("piechart02").style.display="";
      }
      var chartDom = document.getElementById("piechart02");
      var chart = echarts.init(chartDom);
      var option = {};
      var data = [
        Data.map((item,index) =>{
          var obj = {
            name:item.attrName,
            value:item.searchAttrCount
          }
          return obj
        })
      ];
      option = {
        color: ["#5470C6", "#3BA272", "#FAC858", "#FC8452", "#53A8E2"],
        tooltip: {
          trigger: "item",
          formatter: "{b}: {c} ({d}%)",
        },
        series: data.map(function (data, idx) {
          var top = idx * 33.3;
          return {
            type: "pie",
            radius: [60, 120],
            top: top + "%",
            height: "33.33%",
            left: "center",
            width: 400,
            itemStyle: {
              borderColor: "#fff",
              borderWidth: 3,
            },
            label: {
              alignTo: "edge",
              formatter: "{name|{b}({d}%)}",
              minMargin: 5,
              edgeDistance: 10,
              lineHeight: 15,
              rich: {
                time: {
                  fontSize: 10,
                  color: "#999",
                },
              },
            },
            labelLine: {
              length: 15,
              length2: 0,
              maxSurfaceAngle: 80,
            },
            labelLayout: function (params) {
              var isLeft = params.labelRect.x < chart.getWidth() / 2;
              var points = params.labelLinePoints;
              // Update the end point.
              points[2][0] = isLeft
                ? params.labelRect.x
                : params.labelRect.x + params.labelRect.width;
              return {
                labelLinePoints: points,
              };
            },
            data: data,
          };
        }),
      };
      option && chart.setOption(option);
      chart.resize();
    },
    initPieChart03(Data) {
      if (Data.length === 0) {
        document.getElementById("piechart03NoDate").style.display="";
        document.getElementById("piechart03").style.display="none";
      }else{
        document.getElementById("piechart03NoDate").style.display="none";
        document.getElementById("piechart03").style.display="";
      }
      var chartDom = document.getElementById("piechart03");
      var chart = echarts.init(chartDom);
      var option = {};
      var data = Data.map((item,index) =>{
        var obj = {
          name:item.attrValueName + "_" + index,
          value:item.searchAttrCount,
          index:index,
          searchAttrProbability:item.searchAttrProbability
        }
        return obj
      })
      option = {
        color: ["#5470C6", "#3BA272", "#FAC858", "#FC8452", "#53A8E2"],
        tooltip: {
          trigger: "item",
          formatter: "{a} <br/>{b}: {c} ({d}%)",
        },
        grid: {
          x: 10,
          y: 10,
          containLabel: true,
        },
        legend: {
          type: 'scroll',
          right: 10,
          top: 27,
          bottom: 20,
          selected: 10,
          orient: "vertical",
          icon: "circle",
          data: data, 
          x: "60%",
          y: "10%",
          width: "100",
          padding: [10, 10, 0, 0],
          itemWidth: 10, // 图形宽度。
          itemHeight: 10, // 图形高度。
          itemGap: 8, // 间隔
          formatter: function (name) {
            // console.log(data)
            // console.log(name);
            let html = '';
            for (var i = 0, l = data.length; i < l; i++) {
              if (data[i].name === name) {
                html +=
                  name.substring(0, name.indexOf("_")) +
                  "  |  " +
                  "{ts|" +
                  data[i].searchAttrProbability +
                  "      " +
                  data[i].value +
                  "}";
                // html += name + "  |  " + "{ts|" + data[i].count + "}";
              }
            }
            return html;
          },
          textStyle: {
            color: "#000",
            rich: {
              ts: {
                color: "#999",
              },
            },
          },
        },

        series: [
          {
            tooltip: {
              show: true,
              trigger: "item",
              showContent: true,
              // formatter: "{b} | {d}% {c}",
              formatter: function (name) {
              return name.data.name.substring(0, name.data.name.indexOf("_")) + " | " + name.data.searchAttrProbability + "  " + name.data.value;
            },
            },
            type: "pie",
            center: ["30%", "50%"],
            radius: ["40%", "60%"],
            avoidLabelOverlap: false,
            label: {
              show: true,
              position: "center",
              formatter: function () {
                return (
                  "{tt|" +
                  "属性值搜索次数\r\n\n " +
                  "}" +
                  "{ta|" + 
                  data
                    .map((item) => item.value)
                    .reduce((x, y) => {
                      return x + y;
                    }) 
                    +
                  "}"
                );
              },
              textStyle: {
                color: "#707070",
                rich: {
                  tt: {
                    color: "#242424",
                    fontWeight: 400,
                    fontSize: 16,
                  },
                  ta: {
                    color: "#242424",
                    fontWeight: 400,
                    fontSize: 34,
                  },
                },
              },
            },
            emphasis: {
              label: {
                show: false,
                fontSize: "30",
                fontWeight: "bold",
                position: "center",
              },
            },
            labelLine: {
              show: false,
            },
            data: data,
            itemStyle: {
              borderWidth: 3,
              borderColor: "#fff",
            },
          },
        ],
      };
      option && chart.setOption(option);
      chart.resize();
    },
    initPieChart04(Data) {
      if (JSON.stringify(Data) == '{}' || JSON.stringify(Data) == '[]') {
        document.getElementById("piechart04NoDate").style.display="";
        document.getElementById("piechart04").style.display="none";
      }else{
        document.getElementById("piechart04NoDate").style.display="none";
        document.getElementById("piechart04").style.display="";
      }
      var chartDom = document.getElementById("piechart04");
      var chart = echarts.init(chartDom);
      var option = {};
      var color = ["#b3c25a", "#67b580", "#93cc7c", "#feb041", "#ff8a5d", "#5470C6", "#3BA272", "#FAC858", "#FC8452", "#53A8E2"]
      var data1 = Object.keys(Data).map(key => {
        return key
      })
      var data2 = Object.keys(Data).map((key,index) => {
        var obj = {
          value: Data[key],
          itemStyle: {
            color: color[index] ? color[index] : color[index - 10]
          }
        }
        return obj
      })
      option = {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
              type: 'shadow'
          }
        },
        grid: {
          top: 30,
          left: 30,
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: {
          type: 'value',
          boundaryGap: [0, 0.5],
          axisLine:{
            lineStyle:{
              width:0,//这里是为了突出显示加上的，可以去掉
            }
          }
        },
        yAxis: {
          type: 'category',
          data: data1.reverse()
        },
        series: [
          {
            type: 'bar',
            data: data2.reverse(),
            barWidth:30
          }
        ]
      };
      option && chart.setOption(option);
      chart.resize();
    },
  },
};
</script>

<style lang="scss" scoped>
.searchanalysis-container {
  width: calc(100% - 20px);
  height: auto;
  margin: 10px;
  box-shadow: 0px 2px 6px rgba(82, 82, 82, 0.16);
  padding: 10px;
  .flex {
    display: flex;
    align-items: center;
    justify-content: space-around;
  }
  .oneColor{
    color: #404040;
    text-align: center;
    margin-bottom: 10px;
  }
  .twoColor{
    color: #000000;
    font-size: 18px;
    text-align: center;
  }
  .selectin{
    color: #409EFF;
    border-color: #409EFF;
  }
}
</style>