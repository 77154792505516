<template>
  <el-dialog
    :title="!dataForm.productAttrId ? '新增' : '修改'"
    :close-on-click-modal="false"
    class="productattr"
    :visible.sync="visible"
    width="600px"
  >
    <el-form
      :model="dataForm"
      :rules="dataRule"
      size="small"
      ref="dataForm"
      @keyup.enter.native="dataFormSubmit()"
      label-width="150px"
      v-loading="dataLoading"
      style="width: 100%"
      element-loading-text=""
      element-loading-spinner="el-icon-loading"
    >
      <el-form-item label="属性名称" prop="attributeName">
        <el-input
          v-model="dataForm.attributeName"
          placeholder="属性名称"
          size="small"
          style="width: 80%"
        ></el-input>
      </el-form-item>
      <el-form-item label="当前排序" prop="sortNumber">
        <el-input-number
          v-model="dataForm.sortNumber"
          :min="1"
          :max="999999"
          placeholder="当前排序"
          size="small"
          style="width: 80%"
        ></el-input-number>
      </el-form-item>
      <el-form-item label="级联级别" prop="cascadeLevel">
        <span slot="label">
          <span
            class="span-box"
            title="一级：当前属性的属性值只有其本身一个层级
  二级：当前属性的属性值存在两个层级"
            style="cursor: pointer"
          >
            <span>级联级别</span>
            <i class="el-icon-question" style="margin-left: 5px"></i>
          </span>
        </span>
        <el-select
          v-model="dataForm.cascadeLevel"
          size="small"
          placeholder="级联级别"
          style="width: 80%"
        >
          <el-option label="一级" :value="1" size="small"></el-option>
          <el-option label="二级" :value="2" size="small"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="操作类型" prop="operationType">
        <el-select
          v-model="dataForm.operationType"
          size="small"
          placeholder="选择操作类型"
          style="width: 80%"
        >
          <el-option label="单值选择框" :value="1" size="small"></el-option>
          <el-option label="多值选择框" :value="2" size="small"></el-option>
          <el-option label="多级选择框" :value="3" size="small"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="关联标准属性" prop="relationAttributeId">
        <el-select
          v-model="dataForm.relationAttributeId"
          size="small"
          placeholder="选择关联标准属性"
          style="width: 80%"
          clearable
        >
          <el-option
            v-for="item in relationAttributeList"
            :key="item.id"
            :label="item.attributeName"
            :value="item.id"
            size="small"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-row>
        <el-col :span="12">
          <el-form-item prop="isListShow">
            <span slot="label">
              <span
                class="span-box"
                :title="massage.isListShow"
                style="cursor: pointer"
              >
                <span>列表展示</span>
                <i class="el-icon-question" style="margin-left: 5px"></i>
              </span>
            </span>
            <template>
              <el-switch v-model="dataForm.isListShow"> </el-switch>
            </template>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="是否展示" prop="isShow">
            <span slot="label">
              <span
                class="span-box"
                :title="massage.isShow"
                style="cursor: pointer"
              >
                <span>是否展示</span>
                <i class="el-icon-question" style="margin-left: 5px"></i>
              </span>
            </span>
            <el-switch v-model="dataForm.isShow"> </el-switch>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="" prop="isSearch">
            <span slot="label">
              <span
                class="span-box"
                :title="massage.isSearch"
                style="cursor: pointer"
              >
                <span>支持搜索</span>
                <i class="el-icon-question" style="margin-left: 5px"></i>
              </span>
            </span>
            <template>
              <el-switch v-model="dataForm.isSearch"> </el-switch>
            </template>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="支持小程序搜索" prop="isAppletSearch">
            <span slot="label">
              <span
                class="span-box"
                :title="massage.isAppletSearch"
                style="cursor: pointer"
              >
                <span>支持小程序搜索</span>
                <i class="el-icon-question" style="margin-left: 5px"></i>
              </span>
            </span>
            <template>
              <el-switch v-model="dataForm.isAppletSearch"> </el-switch>
            </template>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="是否光泽" prop="isGlossProperties">
            <template>
              <el-switch
                v-model="dataForm.isGlossProperties"
                :disabled="dataForm.relationAttributeId == '' ? false : true"
              >
              </el-switch>
            </template>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="是否计价" prop="isValuation">
            <span slot="label">
              <span
                class="span-box"
                :title="massage.isValuation"
                style="cursor: pointer"
              >
                <span>是否计价</span>
                <i class="el-icon-question" style="margin-left: 5px"></i>
              </span>
            </span>
            <el-switch v-model="dataForm.isValuation"> </el-switch>
          </el-form-item>
        </el-col>
      </el-row>
      <el-form-item label="状态" prop="isEnable">
        <template>
          <el-radio v-model="dataForm.isEnable" :label="true">启用</el-radio>
          <el-radio v-model="dataForm.isEnable" :label="false">禁用</el-radio>
        </template>
      </el-form-item>
      <el-form-item label="属性说明" prop="remarks">
        <div style="margin: 5px 0"></div>
        <el-input
          type="textarea"
          :rows="4"
          placeholder=""
          v-model="dataForm.remarks"
          maxlength="150"
          show-word-limit
          style="width: 80%"
        >
        </el-input>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="visible = false" size="small">取消</el-button>
      <el-button
        type="primary"
        @click="dataFormSubmit()"
        size="small"
        :disabled="dataLoading"
        >确定</el-button
      >
    </span>
  </el-dialog>
</template>

<script>
import {
  getProductAttributeApi,
  addOrUpdateProductAttributeApi,
  getGeneralProductAttributeApi,
} from '@/api/modules/productAttribute'
import { deepClone } from "@/utils/index";
export default {
  data() {
    return {
      visible: false,
      dataLoading: false,
      dataForm: {
        id: undefined,
        attributeName: "",
        sortNumber: "",
        cascadeLevel: "",
        isValuation: true,
        isShow: true,
        isEnable: true,
        isGlossProperties: true,
        isListShow: true,
        isSearch: true,
        isAppletSearch: true,
        operationType: "",
        relationAttributeId: "",
        remarks: "",
      },
      massage: {
        isListShow:
          "若设置“是”，当前属性显示在“产品管理”的产品列表，否则不显示",
        isSearch:
          "若设置“是”，当前属性作为筛选条件显示在“搜索产品”和“产品管理”功能中，否则不显示",
        isAppletSearch:
          "若设置“是”，当前属性作为筛选条件显示在【小程序】，否则不显示",
        cascadeLevel: "",
        isValuation:
          "若设置“是”，当前属性参与【小程序】打样订单产品预估价的计算，否则不参与计算",
        isShow: "若设置“是”，当前属性显示在【小程序】产品详情，否则不显示",
      },
      relationAttributeList: [],
      dataRule: {
        attributeName: [
          { required: true, message: "属性名称不能为空", trigger: "blur" },
        ],
        sortNumber: [
          { required: true, message: "当前排序不能为空", trigger: "blur" },
        ],
        isListShow: [{ required: true, message: "不能为空", trigger: "blur" }],
        isSearch: [{ required: true, message: "不能为空", trigger: "blur" }],
        isAppletSearch: [
          { required: true, message: "不能为空", trigger: "blur" },
        ],
        cascadeLevel: [
          { required: true, message: "级联级别不能为空", trigger: "blur" },
        ],
        isValuation: [
          { required: true, message: "是否计价不能为空", trigger: "blur" },
        ],
        isShow: [
          { required: true, message: "是否展示不能为空", trigger: "blur" },
        ],
        operationType: [
          { required: true, message: "操作类型不能为空", trigger: "blur" },
        ],
        isEnable: [{ required: true, message: "不能为空", trigger: "blur" }],
        isGlossProperties: [
          { required: true, message: "不能为空", trigger: "blur" },
        ],
      },
    };
  },
  methods: {
    async init(id) {
      this.dataForm.id = id || 0;
      this.visible = true;
      await this.getProductAttrInfo();
      this.$nextTick(() => {
        this.$refs["dataForm"].resetFields();
        if (id) {
          getProductAttributeApi(id).then((response) => {
            if (response.relationAttributeId == 0) {
              response.relationAttributeId = "";
            }
            this.dataForm = response;
          });
        }
      });
    },
    async getProductAttrInfo() {
      const response = await getGeneralProductAttributeApi();
      if (response && response.length > 0) {
        this.relationAttributeList = response;
      } else {
        this.relationAttributeList = [];
      }
    },
    // 表单提交
    dataFormSubmit() {
      this.$refs["dataForm"].validate((valid) => {
        if (valid) {
          this.dataLoading = true;
          let params = deepClone(this.dataForm);
          params.relationAttributeId = !this.dataForm.relationAttributeId
            ? 0
            : this.dataForm.relationAttributeId;
          addOrUpdateProductAttributeApi(params)
            .then(() => {
              this.$baseMessage("操作成功", "success");
              this.visible = false;
              this.$emit("refreshDataList");
            })
            .finally(() => {
              this.dataLoading = false;
            });
        }
      });
    },
  },
};
</script>
<style lang="scss">
.productattr {
  .el-form-item__label {
    width: 150px !important;
  }
}
</style>
