<template>
  <el-dialog
          :title="!dataForm.id ? '付款证明' : '付款证明'"
          :close-on-click-modal="false"
          :visible.sync="visible"
  width="600px">
    <el-form :model="dataForm"   size="small" ref="dataForm"  label-width="80px"
             v-loading="dataLoading" style="width:100%;"  element-loading-text=""  element-loading-spinner="el-icon-loading"
    >
      <!--<el-form-item prop="state">
        <template>
          <el-radio v-model="dataForm.state" label="4" @change="consent()">同意</el-radio>
          <el-radio v-model="dataForm.state" label="1" @change="callOff()">取消</el-radio>
        </template>
      </el-form-item>-->
      <div>
        <p>订单编号：{{dataForm.sampleOrderNo}}</p>
        <p>订单金额：{{dataForm.orderPrice}}</p>
        <!--<p>收款单位名称：{{dataForm.reason}}</p>
        <p>收款单位账号：{{dataForm.createDate}}</p>
        <p>收款单位开户银行：{{dataForm.reason}}</p>-->
        <p>付款单位名称：{{dataForm.companyName ? dataForm.companyName : "暂无"}}</p>
        <p>付款单位账号：{{dataForm.companyNumber ?dataForm.companyNumber :"暂无" }}</p>
        <p>付款单位开户银行：{{dataForm.depositBank ? dataForm.depositBank :"暂无"}}</p>
        <div style="display: flex">
          <p style="margin-top:  0">支付回单:</p>
          <img :src="dataForm.payVoucher" min-width="250px" height="200px" v-if="dataForm.payVoucher"/>
          <P v-else style="margin-top:  0">暂无</P>
        </div>
      </div>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="visible = false" size="small" >取消</el-button>
      <el-button type="primary" @click="queRenShouKuan" size="small"  :disabled="dataLoading" >确认收款</el-button>
    </span>
  </el-dialog>
</template>

<script>
    import {payment,paymentProof} from '@/api/modules/sampleorder'
    export default {
        data () {
            return {
                showBzu:false,
                visible: false,
                dataLoading: false,
                dataForm: {
                    id: 0,
                  companyName: '',
                  companyNumber:'',
                  depositBank:'',
                  orderFlowId:'',
                  orderPrice:'',
                  payVoucher: '',
                  sampleOrderNo:''
                }
            }
        },
        methods: {
            init (id) {
                this.dataForm.id = id || 0
                console.log(this.dataForm.state)
                this.visible = true
                this.$nextTick(() => {
                    this.$refs['dataForm'].resetFields()
                    if (this.dataForm.id) {
                      paymentProof({id:this.dataForm.id}).then(({data}) => {
                            if (data && data.code === 0) {
                                this.dataForm.companyName = data.paymentProofVo.companyName
                                this.dataForm.companyNumber = data.paymentProofVo.companyNumber
                                this.dataForm.depositBank = data.paymentProofVo.depositBank
                                this.dataForm.orderFlowId = data.paymentProofVo.orderFlowId
                                this.dataForm.orderPrice = data.paymentProofVo.orderPrice
                                this.dataForm.payVoucher = data.paymentProofVo.payVoucher
                                this.dataForm.sampleOrderNo = data.paymentProofVo.sampleOrderNo
                            }
                        })
                    }
                })
            },
          //确认收款
          queRenShouKuan(){
            this.$confirm(`是否确认收款?`, '提示', {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              type: 'warning'
            }).then(() => {
              payment({'id':this.dataForm.id}).then(({data}) => {
                console.log(this.dataForm.id)
                if (data && data.code === 0) {
                  this.$message({
                    message: '操作成功',
                    type: 'success',
                    duration: 1500,
                    onClose: () => {
                      this.visible=false
                      // this.$emit('refreshList')
                       this.$emit('refreshDataList')
                    },
                  })
                } else {
                  this.$message.error(data.msg)
                }
              })
            })
          },
            /*// 表单提交
            dataFormSubmit () {
                this.$refs['dataForm'].validate((valid) => {
                    if (valid) {
                        this.dataLoading = true;
                        var params = {
                            'aftersaleId': this.dataForm.id || undefined,
                            'customerReply':this.dataForm.customerReply,
                        }
                        var tick = !this.dataForm.id ? ruturnTake(params) : ruturnTake(params)
                        tick.then(({data}) => {
                            if (data && data.code === 0) {
                                this.$message({
                                    message: '操作成功',
                                    type: 'success',
                                    duration: 1500,
                                    onClose: () => {
                                        this.visible = false;
                                        //    this.showBzu = false;
                                        this.dataLoading = false;
                                        this.$emit('refreshDataList')
                                    }
                                })
                            } else {
                                this.$message.error(data.msg)
                                this.dataLoading = false;
                            }
                        })
                    }
                })
            }*/
        }
    }
</script>
