<template>
  <!--<el-dialog
    :title="!dataForm.id ? '新增' : '修改'"
    :close-on-click-modal="false"
    :visible.sync="visible"
      >-->
  <sides-lip ref="SidesLip" @close="close">
    <div slot="soltForm">
      <el-form
        :model="dataForm"
        :rules="dataRule"
        size="small"
        ref="dataForm"
        @keyup.enter.native="dataFormSubmit()"
        label-width="120px"
        v-loading="dataLoading"
        style="margin: auto"
        element-loading-text=""
        element-loading-spinner="el-icon-loading"
      >
        <el-form-item label="设备名称" prop="deviceName">
          <el-input
            v-model="dataForm.deviceName"
            placeholder="设备名称"
            size="small"
            maxlength="30"
          ></el-input>
        </el-form-item>
        <el-form-item label="设备编号" prop="deviceNo">
          <el-input
            v-model="dataForm.deviceNo"
            placeholder="设备编号"
            size="small"
            maxlength="30"
            @blur="getModelListInfo()"
          ></el-input>
        </el-form-item>
        <el-form-item label="设备图片" prop="picUrl">
          <el-upload
            class="avatar-uploader"
            :action="action"
            :headers="headers"
            :show-file-list="false"
            :on-success="handleAvatarSuccess"
            :before-upload="beforeAvatarUpload"
          >
            <img v-if="dataForm.picUrl" :src="dataForm.picUrl" class="avatar" />
            <i
              v-else
              class="el-icon-plus avatar-uploader-icon"
              title="点击上传"
            ></i>
          </el-upload>
          <!-- <el-input v-model="dataForm.picUrl" placeholder="设备图片"  size="small"></el-input> -->
        </el-form-item>
        <el-form-item label="设备品牌" prop="deviceBrandId">
          <el-select
            v-model="dataForm.deviceBrandId"
            placeholder="设备品牌"
            @change="getDeviceModelList()"
          >
            <el-option
              v-for="item in devicobrandList"
              :key="item.id"
              :label="item.deviceBrandName"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="设备型号" prop="deviceModelId">
          <el-select
            v-model="dataForm.deviceModelId"
            placeholder="设备型号"
            @change="getModelListInfo()"
          >
            <el-option
              v-for="item in deviceModelList"
              :key="item.id"
              :label="item.deviceModelName"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="是否积分球" v-if="dataForm.deviceModelId">
          <template>
            <div>
              {{
                deviceModelList.length > 0 &&
                deviceModelList.find((e) => {
                  if (e.id == dataForm.deviceModelId) {
                    return e
                  }
                }).deviceMeasureType == 1
                  ? '是'
                  : '否'
              }}
            </div>
          </template>
        </el-form-item>
        <el-form-item label="设备类型" prop="deviceType">
          <template>
            <el-radio
              v-model="dataForm.deviceType"
              :label="1"
              :disabled="!dataForm.id ? false : true"
              >运营设备</el-radio
            >
            <el-radio
              v-model="dataForm.deviceType"
              :label="2"
              :disabled="!dataForm.id ? false : true"
              >企业设备</el-radio
            >
            <el-radio
              v-model="dataForm.deviceType"
              :label="3"
              :disabled="!dataForm.id ? false : true"
              >会员设备</el-radio
            >
          </template>
        </el-form-item>
        <el-form-item
          label="所属企业"
          prop="factoryId"
          v-if="dataForm.deviceType == 2"
        >
          <el-select
            v-model="dataForm.brandId"
            placeholder="选择品牌"
            @change="getDataFactory()"
          >
            <el-option
              v-for="brand in brandList"
              :key="brand.brandId"
              :label="brand.brandName"
              :value="brand.brandId"
            >
            </el-option>
          </el-select>
          <el-select v-model="dataForm.factoryId" placeholder="选择工厂">
            <el-option
              v-for="factory in factoryList"
              :key="factory.id"
              :label="factory.factoryName"
              :value="factory.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="读取LAB方式" prop="readMode">
          <template>
            <el-radio v-model="dataForm.readMode" :label="0">自动读取</el-radio>
            <el-radio v-model="dataForm.readMode" :label="1">手动读取</el-radio>
          </template>
        </el-form-item>
        <el-form-item label="模型转换" prop="isConvert">
          <template>
            <el-radio-group
              @change="getModelListInfo()"
              v-model="dataForm.isConvert"
            >
              <el-radio :label="true">需要</el-radio>
              <el-radio :label="false">不需要</el-radio>
            </el-radio-group>
          </template>
        </el-form-item>
        <el-form-item label="选择模型" prop="modelNo" v-if="dataForm.isConvert">
          <el-select
            v-model="dataForm.modelNo"
            placeholder="请选择设备编号对应的模型"
          >
            <el-option
              v-for="model in modelList"
              :key="model.id"
              :label="model.modelNo"
              :value="model.modelNo"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="支持wifi" prop="isWifi">
          <template>
            <el-radio v-model="dataForm.isWifi" :label="true">支持</el-radio>
            <el-radio v-model="dataForm.isWifi" :label="false">不支持</el-radio>
          </template>
        </el-form-item>
        <el-form-item label="有效期" prop="isTimeLimit">
          <template>
            <el-radio v-model="dataForm.isTimeLimit" :label="false"
              >永久</el-radio
            >
            <el-radio v-model="dataForm.isTimeLimit" :label="true"
              >设置</el-radio
            >
          </template>
        </el-form-item>
        <el-form-item
          label="有效时间"
          prop="orderTime"
          v-if="dataForm.isTimeLimit"
        >
          <div class="block">
            <el-date-picker
              v-model="dataForm.orderTime"
              value-format="yyyy-MM-dd"
              type="daterange"
              range-separator="至"
              start-placeholder="有效时间开始"
              end-placeholder="有效时间结束"
            ></el-date-picker>
          </div>
        </el-form-item>
        <el-form-item label="设备状态" prop="state">
          <template>
            <el-radio v-model="dataForm.state" :label="1">正常</el-radio>
            <el-radio v-model="dataForm.state" :label="0">停用</el-radio>
            <el-radio v-model="dataForm.state" :label="4">过期</el-radio>
            <el-radio v-model="dataForm.state" :label="5">冻结</el-radio>
          </template>
        </el-form-item>
        <el-form-item label="设备信息" prop="remarks">
          <el-input
            type="textarea"
            :rows="5"
            placeholder="请输入设备信息"
            maxlength="150"
            show-word-limit
            v-model="dataForm.remarks"
          >
          </el-input>
        </el-form-item>
      </el-form>
    </div>
    <div slot="soltFooter">
      <span slot="footer" class="dialog-footer">
        <el-button @click="sideClose()" size="small">取消</el-button>
        <el-button
          type="primary"
          @click="dataFormSubmit()"
          size="small"
          :disabled="dataLoading"
          >确定</el-button
        >
      </span>
    </div>
  </sides-lip>
  <!--</el-dialog>-->
</template>

<script>
import {
  getDeviceApi,
  addOrUpdateDeviceApi,
  getByDeviceBrandIdApi,
  getModelListInfoApi,
  getDeviceBrandListInfoApi
} from '@/api/modules/device'
import { queryBrandApi } from '@/api/modules/brand'
import { queryFactoryApi } from '@/api/modules/factory'
import { getToken } from '@/utils/auth'
import Tinymce from '@/components/Tinymce'
import SidesLip from '@/components/SidesLip'
import { deepClone } from '@/utils/index'

export default {
  components: {
    SidesLip,
    Tinymce
  },
  data() {
    return {
      action: `/api/m/file/upload`,
      headers: { Authorization: getToken() },
      visible: false,
      dataLoading: false,
      deviceModelList: [],
      factoryList: [],
      brandList: [],
      dataFactoryBrand: [],
      modelList: [],
      devicobrandList: [],
      memberList: '',
      dataForm: {
        id: 0,
        deviceNo: '',
        deviceName: '',
        picUrl: '',
        deviceModelId: '',
        deviceBrandId: '',
        factoryId: '',
        brandId: '',
        memberId: '',
        startDate: '',
        endDate: '',
        deviceType: 1,
        isTimeLimit: false,
        isWifi: true,
        readMode: 1,
        modelNo: '',
        isConvert: false,
        deviceMeasureType: '',
        state: 1,
        orderTime: [],
        remarks: ''
      },
      dataRule: {
        deviceNo: [
          { required: true, message: '设备编号不能为空', trigger: 'blur' }
        ],
        deviceName: [
          { required: true, message: '设备名称不能为空', trigger: 'blur' }
        ],
        deviceBrandId: [
          { required: true, message: '设备品牌不能为空', trigger: 'blur' }
        ],
        deviceModelId: [
          { required: true, message: '设备型号不能为空', trigger: 'blur' }
        ],
        factoryId: [
          { required: true, message: '企业工厂不能为空', trigger: 'blur' }
        ],
        deviceType: [
          { required: true, message: '设备类型不能为空', trigger: 'blur' }
        ],
        readMode: [
          { required: true, message: '读取LAB方式不能为空', trigger: 'blur' }
        ],
        isConvert: [{ required: true, message: '不能为空', trigger: 'blur' }],
        isWifi: [{ required: true, message: '不能为空', trigger: 'blur' }],
        isTimeLimit: [{ required: true, message: '不能为空', trigger: 'blur' }],
        state: [{ required: true, message: '不能为空', trigger: 'blur' }],
        modelNo: [{ required: true, message: '不能为空', trigger: 'blur' }],
        orderTime: [{ required: true, message: '不能为空', trigger: 'blur' }]
      }
    }
  },
  methods: {
    init(id) {
      this.$refs.SidesLip.init(id ? '修改' : '新增', '700px', 'block')
      this.modelList = []
      this.dataForm.id = id || undefined
      this.getDataBrand()
      this.getbrandListInfo(), (this.dataForm.id = id || undefined)
      this.visible = true
      this.deviceModelList = []
      this.$nextTick(() => {
        this.$refs['dataForm'].resetFields()
        if (id) {
          getDeviceApi(id).then(async (response) => {
            this.dataForm = response
            var orderTime = []
            orderTime[0] = response.startDate
            orderTime[1] = response.endDate
            this.dataForm.orderTime = response.startDate ? orderTime : []
            await this.getDeviceModelList(response.deviceModelId)
            this.getDataFactory()
          })
        }
      })
    },
    //获取下拉品牌列表
    getbrandListInfo() {
      getDeviceBrandListInfoApi().then((response) => {
        if (response) {
          this.devicobrandList = response
        } else {
          this.devicobrandList = []
        }
      })
    },
    //获取下拉型号列表
    async getDeviceModelList(deviceModelId) {
      this.dataForm.modelNo = ''
      this.modelList = []
      if (this.dataForm.deviceBrandId) {
        const response = await getByDeviceBrandIdApi(
          this.dataForm.deviceBrandId
        )
        if (response) {
          this.deviceModelList = response
        } else {
          this.deviceModelList = []
        }
      } else {
        this.deviceModelList = []
      }
      this.dataForm.deviceModelId = deviceModelId ? deviceModelId : ''
    },
    close() {
      this.devicobrandList = []
      this.dataForm = {
        id: 0,
        deviceNo: '',
        deviceName: '',
        picUrl: '',
        deviceModelId: '',
        deviceBrandId: '',
        factoryId: '',
        brandId: '',
        memberId: '',
        startDate: '',
        endDate: '',
        deviceType: 1,
        isTimeLimit: false,
        isWifi: true,
        readMode: 1,
        modelNo: '',
        isConvert: false,
        state: 1,
        orderTime: [],
        // userMobile:'',
        remarks: ''
      }
      this.deviceModelList = []
      this.modelList = []
    },
    //根据设备型号与编号，获取设备模型
    getModelListInfo() {
      if (this.dataForm.deviceModelId) {
        this.dataForm.deviceMeasureType = this.deviceModelList.find((e) => {
          if (e.id == this.dataForm.deviceModelId) {
            return e
          }
        }).deviceMeasureType
      }
      if (this.dataForm.deviceModelId && this.dataForm.deviceNo) {
        this.dataForm.modelNo = ''
        let deviceModel_ = this.deviceModelList.find((item) => {
          if (item.id == this.dataForm.deviceModelId) {
            return item
          }
        })
        var params = {
          deviceModel: deviceModel_.deviceModelName,
          deviceNo: this.dataForm.deviceNo
        }
        getModelListInfoApi(params).then(({ data }) => {
          if (this.dataForm.isConvert) {
            this.modelList = data
          } else {
            this.modelList = []
          }
        })
      }
    },
    //获取当前登录人的工厂信息
    getDataFactory() {
      if (this.dataForm.brandId) {
        var params = {
          brandId: this.dataForm.brandId,
          pageSize: 100000
        }
        queryFactoryApi(params).then(({ records, totalRecords }) => {
          this.dataForm.factoryId = ''
          if (totalRecords > 0) {
            this.factoryList = records
          } else {
            this.factoryList = []
          }
        })
      }
    },
    //获取品牌信息
    getDataBrand() {
      queryBrandApi({ pageSize: 100000 }).then(({ records, totalRecords }) => {
        if (totalRecords > 0) {
          this.brandList = records
        } else {
          this.brandList = []
        }
      })
    },

    //图片上传
    handleAvatarSuccess(res, file, type) {
      if (res.data) {
        this.dataForm.picUrl = res.data
      }
    },
    beforeAvatarUpload(file) {
      const isJPG =
        file.type === 'image/jpeg' ||
        file.type === 'image/bmp' ||
        file.type === 'image/png'
      const isLt2M = file.size / 1024 / 1024 < 8
      if (!isJPG) {
        this.$message.error('上传图片格式不支持!')
      }
      if (!isLt2M) {
        this.$message.error('上传图片大小不能超过 8MB!')
      }
      return isJPG && isLt2M
    },
    // 表单提交
    dataFormSubmit() {
      this.$refs['dataForm'].validate(async (valid) => {
        if (valid) {
          this.dataLoading = true
          var arrOrderTime = this.dataForm.orderTime
          var startDate = null
          var endDate = null
          var factoryId = ''
          var brandId = ''
          if (arrOrderTime.length > 0 && this.dataForm.isTimeLimit == 1) {
            startDate = arrOrderTime[0]
            endDate = arrOrderTime[1]
          }
          if (this.dataForm.deviceType == 2) {
            factoryId = this.dataForm.factoryId
            brandId = this.dataForm.brandId
            if (factoryId == '') {
              this.$message.error('请输入企业工厂信息')
              this.dataLoading = false
              return
            }
          }
          if (this.dataForm.isConvert && !this.dataForm.modelNo) {
            this.$message.warning('请选择模型！')
            this.dataLoading = false
          } else {
            let params = deepClone(this.dataForm)
            params.factoryId = factoryId
            params.brandId = brandId
            params.startDate = startDate
            params.endDate = endDate
            params.modelNo = this.dataForm.isConvert
              ? this.dataForm.modelNo
              : ''
            params.deviceModelName = this.deviceModelList.find((item) => {
              if (item.id == this.dataForm.deviceModelId) {
                return item
              }
            }).deviceModelName
            await addOrUpdateDeviceApi(params)
              .then(() => {
                this.$baseMessage('操作成功', 'success')
                this.$emit('refreshDataList')
                this.visible = false
                this.$refs.SidesLip.sideClose()
                this.modelList = []
              })
              .finally(() => {
                this.dataLoading = false
              })
          }
        }
      })
    },
    createFilter(queryString) {
      return (restaurant) => {
        return (
          restaurant.loginName
            .toLowerCase()
            .indexOf(queryString.toLowerCase()) === 0
        )
      }
    },
    sideClose() {
      this.$refs.SidesLip.sideClose()
    }
  }
}
</script>
<style scoped>
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
</style>
