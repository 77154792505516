<template>
  <el-dialog
    :title="!dataForm.id ? '新增' : '修改'"
    :close-on-click-modal="false"
    :visible.sync="visible">
    <el-form :model="dataForm" :rules="dataRule"  size="small" ref="dataForm" @keyup.enter.native="dataFormSubmit()" label-width="80px"
     v-loading="dataLoading" style="width:100%;"  element-loading-text=""  element-loading-spinner="el-icon-loading"
    >
      <el-form-item label="会员" prop="memberId">
        <el-select v-model="dataForm.memberId" placeholder="选择会员" popper-class = "optionsContent" filterable>
          <el-option v-for="item in memberList"
            :key="item.id" :label="item.loginName" :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="设备编号" prop="deviceId">
        <el-select v-model="dataForm.deviceId" placeholder="选择设备" popper-class = "optionsContent" filterable>
            <el-option v-for="item in deviceList" :key="item.id"
                 :label="item.deviceNo" :value="item.id">
            </el-option> 
        </el-select>
      </el-form-item>
      <el-form-item label="状态" size="mini" prop="state">
          <el-radio-group v-model="dataForm.state">
            <el-radio :label="0">未授权</el-radio>
            <el-radio :label="1">已授权</el-radio>
          </el-radio-group>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="visible = false" size="small">取消</el-button>
      <el-button type="primary" @click="dataFormSubmit()" size="small"  :disabled="dataLoading">确定</el-button>
    </span>
  </el-dialog>
</template>

<script>
  import {info,add,update,memberList,deviceList} from '@/api/modules/memberdeviceauth'
  export default {
    data () {
      return {
        visible: false,
        dataLoading: false,
        memberList:[],
        deviceList:[],
        dataForm: {
          id: 0,
          memberId: '',
          deviceId: '',
          state: ''
        },
        dataRule: {
          
        }
      }
    },
    methods: {
      init (id) {
        this.getMemberList();
        this.getDeviceList();
        this.dataForm.id = id || 0
        this.visible = true
        this.$nextTick(() => {
          this.$refs['dataForm'].resetFields()
          if (this.dataForm.id) {
            info({id:this.dataForm.id}).then(({data}) => {
              if (data && data.code === 0) {
                this.dataForm.memberId = data.memberDeviceAuth.userId
                this.dataForm.deviceId = data.memberDeviceAuth.deviceId
                this.dataForm.state = data.memberDeviceAuth.state
              }
            })
          }
        })
      },
      //获取用户下拉列表
      getMemberList(id){
        memberList().then(({ data })=>{
          if(data && data.code === 0 ){
            this.memberList = data.memberList;
          }
        })
      },
      //获取设备下拉列表
      getDeviceList(id){
        deviceList().then(({ data })=>{
          if(data && data.code === 0 ){
            this.deviceList = data.deviceList;
          }
        })
      },
      // 表单提交
      dataFormSubmit () {
        this.$refs['dataForm'].validate((valid) => {
          if (valid) {
          	this.dataLoading = true;
            var params = {
              'id': this.dataForm.id || undefined,
              'userId': this.dataForm.memberId,
              'deviceId': this.dataForm.deviceId,
              'state': this.dataForm.state
            }
            var tick = !this.dataForm.id ? add(params) : update(params)
            tick.then(({data}) => {
              if (data && data.code === 0) {
                this.$message({
                  message: '操作成功',
                  type: 'success',
                  duration: 1500,
                  onClose: () => {
                    this.visible = false;
	              	this.dataLoading = false;
                    this.$emit('refreshDataList')
                  }
                })
              } else {
                this.$message.error(data.msg)
                this.dataLoading = false;
              }
            })
          }
        })
      }
    }
  }
</script>
