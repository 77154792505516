<template>
  <el-dialog
          :title="!dataForm.id ? '退货退款详情' : '退货退款详情'"
          :close-on-click-modal="false"
          :visible.sync="visible">
    <el-form :model="dataForm" :rules="dataRule"  size="small" ref="dataForm" @keyup.enter.native="dataFormSubmit()" label-width="80px"
             v-loading="dataLoading" style="width:100%;"  element-loading-text=""  element-loading-spinner="el-icon-loading"
    >
      <div>
        <p>申请原因：{{dataForm.reason}}</p>
        <div style="display: flex">
        <p>凭证图片：</p>
        <div v-for="(item,index) in picList" style="margin-left: 5px">
        <img :src="item" min-width="100" height="100"/>
        </div>
        </div>
        <p>申请说明：{{dataForm.description}}</p>
        <p>申请时间：{{dataForm.createDate}}</p>
        <p>订单金额：{{dataForm.orderPrice}}元</p>
        <p>客服回复：</p>
          <el-input v-model="dataForm.customerReply" type="textarea" size="small" placeholder="" maxlength="50" show-word-limit></el-input>
        <div style="display: flex">
        <p>退货物流单号：{{dataForm.logisticsOrderno}}</p>
        <p style="margin-left: 50%">物流公司：{{dataForm.logisticsName}}</p>
        </div>
        <div style="display: flex">
        <p v-if="dataForm.state=='0'">退货状态：申请售后</p>
        <p v-if="dataForm.state=='1'">退货状态：已取消</p>
        <p v-if="dataForm.state=='2'">退货状态：待退货</p>
        <p v-if="dataForm.state=='3'">退货状态：待退款</p>
        <p v-if="dataForm.state=='4'">退货状态：已完成</p>
        <p v-if="dataForm.state=='5'">退货状态：重新打样</p>
          <p v-if="dataForm.state=='4'" style="margin-left: 50%">退款金额：{{dataForm.returnMoney}}</p>
        </div>
      </div>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="visible = false" size="small">取消</el-button>
      <el-button type="primary" @click="dataFormSubmit()" size="small"  :disabled="dataLoading">确认退款</el-button>
    </span>
  </el-dialog>
</template>

<script>
    import {checkOrder,getCheckState,afterRefundOrReturns,ruturnTake} from '@/api/modules/sampleaftersale'
    export default {
        data () {
            return {
                showBzu:false,
                visible: false,
                dataLoading: false,
                picList:[],
                dataForm: {
                    id: 0,
                    description: '',//申请说明
                    reason: '',//售后原因
                    returnPic: '',//售后凭证
                    createDate: '',//申请时间
                    customerReply: '',//客服处理
                    orderPrice: '',//打样订单金额
                    logisticsOrderno: '',//物流单号
                    logisticsName: '',//物流名称
                    state: '',//售后状态,
                    returnMoney:''//退款金额
                },
                dataRule: {
                    description: [
                        { required: true, message: '备注不能为空', trigger: 'blur' }
                    ],
                    state: [
                        { required: true, message: '审核状态', trigger: 'blur' }
                    ],
                }
            }
        },
        methods: {
            //取消显示备注信息填写
            callOff(){
                this.showBzu = true;
            },
            consent(){
                this.showBzu = false;
                this.dataForm.description = ""
            },
            init (id) {
                this.picList = []
                this.dataForm.id = id || 0
                //= ""
                console.log(this.dataForm.state)

                this.visible = true
                this.$nextTick(() => {
                    this.$refs['dataForm'].resetFields()
                    if (this.dataForm.id) {
                        afterRefundOrReturns({id:this.dataForm.id}).then(({data}) => {
                            if (data && data.code === 0) {
                                this.dataForm.state = data.afterRefundOrReturns.state
                                this.dataForm.description = data.afterRefundOrReturns.description
                                this.dataForm.reason = data.afterRefundOrReturns.reason
                                this.dataForm.createDate = data.afterRefundOrReturns.createDate
                                this.dataForm.customerReply = data.afterRefundOrReturns.customerReply
                                this.dataForm.orderPrice = data.afterRefundOrReturns.orderPrice
                                this.dataForm.logisticsOrderno = data.afterRefundOrReturns.logisticsOrderno
                                this.dataForm.logisticsName = data.afterRefundOrReturns.logisticsName
                                this.dataForm.returnMoney = data.afterRefundOrReturns.returnMoney
                                /* this.dataForm.returnPic = data.afterRefundOrReturns.returnPic*/
                                if (data.afterRefundOrReturns.returnPic !==null) {
                                    this.picList=data.afterRefundOrReturns.returnPic.split(",")
                                    console.log(this.picList+"========")
                                }
                            }
                        })
                    }
                })
            },
            // 表单提交
            dataFormSubmit () {
                this.$refs['dataForm'].validate((valid) => {
                    if (valid) {
                        this.dataLoading = true;
                        var params = {
                            'aftersaleId': this.dataForm.id || undefined,
                            'customerReply':this.dataForm.customerReply,
                        }
                        var tick = !this.dataForm.id ? ruturnTake(params) : ruturnTake(params)
                        tick.then(({data}) => {
                            if (data && data.code === 0) {
                                this.$message({
                                    message: '操作成功',
                                    type: 'success',
                                    duration: 1500,
                                    onClose: () => {
                                        this.visible = false;
                                        //    this.showBzu = false;
                                        this.dataLoading = false;
                                        this.$emit('refreshDataList')
                                    }
                                })
                            } else {
                                this.$message.error(data.msg)
                                this.dataLoading = false;
                            }
                        })
                    }
                })
            }
        }
    }
</script>
