<template>
  <div class="app-container">
    <el-form :inline="true" :model="dataForm" @keyup.enter.native="getDataList()">
      <el-form-item   prop="goodsTitle">
        <el-input v-model="dataForm.goodsTitle" placeholder="请输入商品标题" clearable></el-input>
      </el-form-item>
      <el-form-item   prop="mallGoodsCategoryId">
        <el-select v-model="mallGoodsCategoryId" placeholder="请选择分类">
          <el-option v-for="item in categoryList" :key="item.categoryName" :label="item.categoryName" :value="item.mallGoodsCategoryId">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item   prop="isShelf">
           <el-select v-model="isShelf" placeholder="请选择是否上架">
            <el-option v-for="item in shelfList" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
      </el-form-item>
      <el-form-item>
        <el-button @click="restPage()">查询</el-button>
        <el-button @click="reset('dataForm')" >清空</el-button>
        <el-button v-if="isAuth('mall:mallgoodsmain:save')" type="primary" @click="addGoodsMainInfo()">新增</el-button>
        <el-button v-if="isAuth('mall:mallgoodsmain:delete')" type="danger" @click="deleteHandle()" :disabled="dataListSelections.length <= 0">批量删除</el-button>
      </el-form-item>
    </el-form>
    <el-table
      :data="dataList"
      border
      v-loading="dataListLoading"
      @selection-change="selectionChangeHandle"
      style="width: 100%;">
      <el-table-column
        type="selection"
        header-align="center"
        align="center"
        width="50">
      </el-table-column>
      <el-table-column
        prop="goodsTitle"
        header-align="center"
        align="center"
        label="标题">
      </el-table-column>
      <el-table-column
        prop="categoryName"
        header-align="center"
        align="center"
        width="180"
        label="分类名称">
      </el-table-column>
      <el-table-column
        prop="shelfName"
        header-align="center"
        align="center"
        width="180"
        label="状态">
      </el-table-column>
      <el-table-column
        prop="updateTime"
        header-align="center"
        align="center"
        width="180"
        label="创建时间">
      </el-table-column>
      <el-table-column
        fixed="right"
        header-align="center"
        align="center"
        width="180"
        label="操作">
        <template slot-scope="scope">
          <el-button type="text" size="small" @click="findDetail(scope.row.mallGoodsMainId)">商品详情</el-button>
          <el-button v-if="isAuth('mall:mallgoodsmain:update') && scope.row.isShelf == 1" type="text" size="small" @click="offShelfHandle(scope.row.mallGoodsMainId)">下架</el-button>
          <el-button v-if="isAuth('mall:mallgoodsmain:update') && (scope.row.isShelf == 0 || scope.row.isShelf ==undefined)" type="text" size="small" @click="onShelfHandle(scope.row.mallGoodsMainId)">上架</el-button>
          <el-button v-if="isAuth('mall:mallgoodsmain:update') && (scope.row.isShelf == 0 || scope.row.isShelf ==undefined)" type="text" size="small" @click="addGoodsMainInfo(scope.row.mallGoodsMainId)">修改</el-button>
          <el-button v-if="isAuth('mall:mallgoodsmain:delete') && (scope.row.isShelf == 0 || scope.row.isShelf ==undefined)" type="text" size="small" @click="deleteHandle(scope.row.mallGoodsMainId)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      @size-change="sizeChangeHandle"
      @current-change="currentChangeHandle"
      :current-page="pageIndex"
      :page-sizes="[10, 20, 50, 100]"
      :page-size="pageSize"
      :total="totalPage"
      layout="total, sizes, prev, pager, next, jumper">
    </el-pagination>
  </div>
</template>

<script>
import { mallGoodsMainList, delMallGoodsMainValue, onShelfByGoodsMain, offShelfByGoodsMain} from "@/api/sys";
export default {
  data() {
    return {
      dataForm: {
        goodsTitle: ""
      },
      dataList: [],
      shelfList: [{
        value: 1,
        label: "上架中"
      },
      {
        value: 0,
        label: "下架中"
      }
      ],
      isShelf:"",
      categoryList:[],
      mallGoodsCategoryId: "",
      pageIndex: 1,
      pageSize: 10,
      totalPage: 0,
      dataListLoading: false,
      dataListSelections: []
    };
  },
  components: {

  },
  methods: {
    // 查询 清空 修改  by葛海军
    reset(dataForm) {
      this.dataForm.goodsTitle = "";
      this.isShelf = "";
      this.mallGoodsCategoryId = "";
      this.restPage();
    },
    // 跳到第一页进行查询
    restPage() {
      this.pageIndex = 1;
      this.getDataList();
    },
    // 获取数据列表
    async getDataList() {
      this.dataListLoading = true;
      let params = {
        page: this.pageIndex,
        limit: this.pageSize,
        goodsTitle: this.dataForm.goodsTitle,
        isShelf: this.isShelf,
        mallGoodsCategoryId: this.mallGoodsCategoryId

      };
      const { data } = await mallGoodsMainList(params);
      if (data && data.code === 0) {
        this.dataList = data.result.page.list;
        this.totalPage = data.result.page.totalCount;
        this.categoryList = data.result.categoryList;
      } else {
        this.dataList = [];
        this.totalPage = 0;
         this.categoryList = [];
      }
      this.dataListLoading = false;
    },
    // 每页数
    sizeChangeHandle(val) {
      this.pageSize = val;
      this.pageIndex = 1;
      this.getDataList();
    },
    // 当前页
    currentChangeHandle(val) {
      this.pageIndex = val;
      this.getDataList();
    },
    // 多选
    selectionChangeHandle(val) {
      this.dataListSelections = val;
    },
    // 新增 / 修改
    addOrUpdateHandle(id) {
      this.$nextTick(() => {
        this.$refs.addOrUpdate.init(id);
      });
    },
    addGoodsMainInfo(id){
      this.$router.push({ path: "/mall/goods/main/add", query:{"mallGoodsMainId":id} });
    },
    findDetail(id){
      this.$router.push({ path: "/mall/goods/main/detail", query:{"mallGoodsMainId":id} });
    },
    // 删除
    deleteHandle(id) {
      var ids = id
        ? [id]
        : this.dataListSelections.map(item => {
            return item.mallGoodsMainId;
          });
      this.$confirm(`确定进行[${id ? "删除" : "批量删除"}]操作?`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(async () => {
          // let params = {
          //   roleIds: ids
          // };
          const { data } = await delMallGoodsMainValue(ids);
          if (data && data.code === 0) {
            this.$message({
              message: "操作成功",
              type: "success",
              duration: 1500,
              onClose: () => {
                this.getDataList();
              }
            });
          } else {
            this.$message.error(data.msg);
          }
        })
        .catch(() => {});
    },     
    onShelfHandle(id) {
      var ids = id? [id]: this.dataListSelections.map(item => {
            return item.mallGoodsMainId;
          });
          this.$confirm(`确定进行[${id ? "上架" : "批量上架"}]操作?`, "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning"
      })
        .then(async () => {
          // let params = {
          //   roleIds: ids
          // };
          const { data } = await onShelfByGoodsMain(ids);
          if (data && data.code === 0) {
            this.$message({
              message: "操作成功",
              type: "success",
              duration: 1500,
              onClose: () => {
                this.getDataList();
              }
            });
          } else {
            this.$message.error(data.msg);
          }
        })
        .catch(() => {});
    },
    offShelfHandle(id) {
      var ids = id
        ? [id]
        : this.dataListSelections.map(item => {
            return item.mallGoodsMainId;
          });
      this.$confirm(`确定进行[${id ? "下架" : "批量下架"}]操作?`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(async () => {
          // let params = {
          //   roleIds: ids
          // };
          const { data } = await offShelfByGoodsMain(ids);
          if (data && data.code === 0) {
            this.$message({
              message: "操作成功",
              type: "success",
              duration: 1500,
              onClose: () => {
                this.getDataList();
              }
            });
          } else {
            this.$message.error(data.msg);
          }
        })
        .catch(() => {});
    }
  },
  mounted() {
    this.getDataList();
  }
};
</script>
