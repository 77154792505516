<template>
  <el-dialog
    :title="'已授权列表'"
    :close-on-click-modal="false"
    :visible.sync="visible"
  >
    <el-form
      :model="dataForm"
      size="small"
      ref="dataForm"
      v-loading="dataLoading"
      style="width: 100%"
      element-loading-text=""
      element-loading-spinner="el-icon-loading"
    >
      <div style="border-bottom: 1px solid #cfcfcf; margin-bottom: 20px">
        <span class="one1">设备编号:{{ dataForm.deviceNo }}</span>
        <span class="one1"
          >所在地:{{ dataForm.factoryName
          }}<el-divider direction="vertical"></el-divider
          >{{ dataForm.laboratory }}</span
        >
        <span class="one1">负责人:{{ dataForm.leadMan }}</span>
      </div>
      <el-table
        :data="dataForm.dataList"
        border
        size="small"
        v-loading="dataListLoading"
        style="width: 100%"
      >
        <el-table-column
          prop="nickName"
          header-align="center"
          align="center"
          label="用户"
        >
        </el-table-column>
        <el-table-column
          prop="state"
          header-align="center"
          align="center"
          label="授权"
          width="100"
        >
          <template slot-scope="scope">
            <span v-if="scope.row.state == '0'">已启用</span>
            <span v-if="scope.row.state == '1'">已停用</span>
          </template>
        </el-table-column>
        <el-table-column header-align="center" align="center" label="授权期限">
          <template slot-scope="scope">
            <span>{{ scope.row.startDate }} 至 {{ scope.row.endDate }}</span>
          </template>
        </el-table-column>
        <el-table-column
          fixed="right"
          header-align="center"
          align="center"
          width="120"
          label="操作"
        >
          <template slot-scope="scope">
            <el-button
              type="text"
              size="small"
              style="color: red"
              @click="deleteHandle(scope.row.id)"
              >撤销授权</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        size="small"
        @size-change="sizeChangeHandle"
        @current-change="currentChangeHandle"
        :current-page="dataForm.pageNo"
        :page-sizes="[10, 5]"
        :page-size="dataForm.pageSize"
        :total="dataForm.totalCount"
        layout="total, sizes, prev, pager, next, jumper"
      >
      </el-pagination>
    </el-form>
    <span slot="footer" class="dialog-footer"> </span>
  </el-dialog>
</template>

<script>
import {
  querySysUserDeviceApi,
  deleteSysUserDeviceApi,
} from "@/api/modules/deviceuser";
export default {
  data() {
    return {
      visible: false,
      dataLoading: false,
      dataForm: {
        deviceId: "",
        deviceNo: "",
        factoryName: "",
        solTime: "",
        laboratory: "",
        leadMan: "",
        dataList: [],
        pageNo: 1,
        pageSize: 10,
        totalPage: 0,
        totalCount: 0,
      },
      dataListLoading: false,
    };
  },
  methods: {
    //重新查询
    restPage() {
      this.dataForm.pageNo = 1;
      this.getDataList();
    },
    init(row) {
      this.visible = true;
      this.$nextTick(() => {
        this.$refs["dataForm"].resetFields();
        this.dataForm.deviceId = row.id;
        this.dataForm.deviceNo = row.deviceNo;
        this.dataForm.leadMan = row.leadMan;
        this.dataForm.factoryName = row.factoryName;
        this.getDataList();
      });
    },
    //获取数据列表
    getDataList() {
      querySysUserDeviceApi(this.dataForm).then(({ records, totalRecords }) => {
        if (totalRecords > 0) {
          this.dataForm.dataList = records;
          this.dataForm.totalCount = totalRecords;
        } else {
          this.dataForm.dataList = [];
          this.dataForm.totalCount = 0;
        }
        this.dataListLoading = false;
      });
    },
    // 每页数
    sizeChangeHandle(val) {
      this.dataForm.pageSize = val;
      this.dataForm.pageNo = 1;
      this.getDataList();
    },
    // 当前页
    currentChangeHandle(val) {
      this.dataForm.pageNo = val;
      this.getDataList();
    },
    //撤销授权
    deleteHandle(id) {
      this.$confirm(`是否确认撤销授权操作?`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        deleteSysUserDeviceApi(id).then(() => {
          this.$baseMessage("撤销授权成功", "success");
          this.getDataList();
        });
      });
    },
  },
};
</script>
<style scoped>
.one1 {
  display: inline-block;
  margin-right: 35px;
  padding: 0 0 10px 0;
}
</style>