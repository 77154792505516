<template>
  <div id="myArea">
    <el-form
      :model="dataForm"
      :rules="dataRule"
      size="small"
      ref="dataForm"
      label-width="100px"
      v-loading="dataLoading"
      style="width:100%;"
      element-loading-text
      element-loading-spinner="el-icon-loading"
    >
      <el-form-item class="inline-input" label="公司地址">
        <el-input
          id="address"
          v-model="address"
          placeholder
          size="small"
          @focus="serchFocus()"
          @blur="serchBlur()"
        ></el-input>
        <div ref="tipBox" class="tip-box" id="searchTip" v-show="show_tipBox"></div>
        <el-input v-model="lng" placeholder size="small" :disabled="true"></el-input>
        <el-input v-model="lat" placeholder size="small" :disabled="true"></el-input>
      </el-form-item>
    </el-form>
    <div class="map" style="height:400px;margin-left:80px">
      <el-amap
        vid="amap"
        :plugin="plugin"
        class="amap-demo"
        :center="center"
        :amap-manager="amapManager"
        :events="events"
      >
        <el-amap-marker v-for="(marker,index) in markers" :key="index" :events="marker.events" :position="marker.position" />
        <el-amap-info-window
          v-if="window"
          :position="window.position"
          :visible="window.visible"
          :content="window.content"
          :offset="window.offset"
        ></el-amap-info-window>
      </el-amap>
    </div>
  </div>
</template>
<script>
import { AMapManager, lazyAMapApiLoaderInstance } from "vue-amap";
let amapManager = new AMapManager();
export default {
  props: ["msgVal"],
  data() {
    const self = this;
    return {
      show_tipBox: false,
      show_area: true,
      dataLoading: false,
      dataForm: {},
      prov: "省份",
      city: "城市",
      district: "区域",
      provArr: [],
      cityArr: [],
      districtArr: [],
      lng:"",
      lat:"",
      areaMsg: {
        provId: "",
        cityId: "",
        districtId: "",
      },
      dataRule: {},
      amapManager,
      center: [121.59996, 31.197646],
      lng: null,
      lat: null,
      result: [],
      address: "",
      poiPicker: null,
      markers: [],
      windows: [],
      window: "",
      plugin: [
        {
          enableHighAccuracy: true, //是否使用高精度定位，默认:true
          timeout: 10000, //超过10秒后停止定位，默认：无穷大
          maximumAge: 0, //定位结果缓存0毫秒，默认：0
          convert: true, //自动偏移坐标，偏移后的坐标为高德坐标，默认：true
          showButton: true, //显示定位按钮，默认：true
          buttonPosition: "RB", //定位按钮停靠位置，默认：'LB'，左下角
          showMarker: true, //定位成功后在定位到的位置显示点标记，默认：true
          showCircle: false, //定位成功后用圆圈表示定位精度范围，默认：true
          circleOptions: {
            // 定位精度圈的样式
            strokeColor: "#0093FF",
            noSelect: true,
            strokeOpacity: 0.5,
            strokeWeight: 1,
            fillColor: "#02B0FF",
            fillOpacity: 0.25
          },
          panToLocation: true, //定位成功后将定位到的位置作为地图中心点，默认：true
          zoomToAccuracy: true, //定位成功后调整地图视野范围使定位位置及精度范围视野内可见，默认：f
          extensions: "all",
          pName: "Geolocation",
          events: {
            init(o) {
              if (self.lng && self.lat) {
                console.log("存在经纬度");
                self.center = [self.lng, self.lat];
                self.addMarker(self.lng, self.lat, self.address);
              } else {
                // o 是高德地图定位插件实例
                o.getCurrentPosition((status, result) => {
                  console.log(result);
                  if (result && result.position) {
                    // self.lng = result.position.lng;
                    // self.lat = result.position.lat;
                    // self.address = result.formattedAddress;
                    // self.center = [self.lng, self.lat];
                    // self.$nextTick();
                  } else {
                    console.log(result); //定位失败
                  }
                  self.initSearch();
                });
              }
              lazyAMapApiLoaderInstance.load().then(() => {
                self.initSearch();
              });
            }
          }
        }
      ],
      events: {
        click(e) {
          self.markers = [];
          let { lng, lat } = e.lnglat;
          self.lng = lng;
          self.lat = lat;
          // 这里通过高德 SDK 完成。
          var geocoder = new AMap.Geocoder({
            radius: 3000,
            extensions: "all"
          });
          geocoder.getAddress([lng, lat], function(status, result) {
            if (status === "complete" && result.info === "OK") {
              if (result && result.regeocode) {
                console.log(result);
                self.prov = result.regeocode.addressComponent.province;
                self.city =
                  result.regeocode.addressComponent.city == ""
                    ? "市辖区"
                    : result.regeocode.addressComponent.city;
                self.district = result.regeocode.addressComponent.district;
                // self.pro_city_dir(
                //   self.areaMsg,
                //   self.prov,
                //   self.city,
                //   self.district
                // );
                self.address = result.regeocode.formattedAddress;
                self.addMarker(self.lng, self.lat, self.address);
                self.addWindow(self.lng, self.lat, self.address);
                self.$nextTick();
              }
            }
          });
        }
      }
    };
  },
  mounted() {
    console.log("======详情数据=====");
    console.log(this.msgVal);
    this.lng = this.msgVal.lng;
    this.lat = this.msgVal.lat;
    this.prov = this.msgVal.provinceName;
    this.city = this.msgVal.cityName;
    this.district = this.msgVal.regionName;
    this.address = this.msgVal.address;
    this.areaMsg.provId = this.msgVal.provinceId;
    this.areaMsg.cityId = this.msgVal.cityId;
    this.areaMsg.districtId = this.msgVal.districtId;
    if (this.msgVal.provinceId) {
      this.updateCity(this.msgVal.provinceId);
    }
    if (this.msgVal.cityId) {
      this.updateDistrict(this.msgVal.cityId);
    }
  },
  methods: {
    serchFocus() {
      this.show_tipBox = true;
    },
    serchBlur() {
      // setTimeout(() => {
      //   this.show_tipBox = false;
      // }, 500);
    },

    addMarker(longitude, latitude, address) {
      this.markers.push({
        icon: "//a.amap.m/jsapi_demos/static/demo-center/icons/poi-marker-default.pncog",
        position: [longitude, latitude],
        events: {
          click: () => {
            if (this.window.visible) {
              this.window.visible = false;
              this.$nextTick(() => {
                this.window.visible = true; //点击点坐标，出现信息窗体
              });
            } else {
              this.addWindow(longitude, latitude, address);
            }
          }
        },
        visible: true,
        draggable: false // 是否可拖拽
      });
      console.log(this.markers);
    },
    addWindow(lng, lat, address) {
      this.window = {
        position: [lng, lat],
        content: "<div id='content'>" + address + "</div>", //内容
        offset: [5, -15], //窗体偏移
        visible: true //初始是否显示
      };
    },
    initSearch() {
      let _this = this;
      let map = this.amapManager.getMap();
      AMapUI.loadUI(["misc/PoiPicker"], function(PoiPicker) {
        var poiPicker = new PoiPicker({
          city: "", //地点搜索配置
          input: "address", //输入框id
          placeSearchOptions: {
            map: map,
            pageSize: 10
          },
          suggestContainer: "searchTip", //输入提示显示DOM
          searchResultsContainer: "searchTip" //搜索结果显示DOM
        });
        _this.poiPicker = poiPicker;
        //监听poi选中信息
        poiPicker.on("poiPicked", function(poiResult) {
          let source = poiResult.source;
          let poi = poiResult.item;
          console.log(poiResult);
          if (_this.window.visible) {
            _this.window.visible = false;
          }
          if (source !== "search") {
            poiPicker.searchByKeyword(poi.name);
          } else {
            poiPicker.clearSearchResults();
            _this.center = [
              poi.location.lng,
              poi.location.lat
            ];
            _this.lng = poi.location.lng;
            _this.lat = poi.location.lat;
            _this.address = poi.name;
            _this.prov = poi.pname;
            _this.city =
              poi.cityname == poi.pname
                ? "市辖区"
                : poi.cityname;
            _this.district = poi.adname;
            // _this.pro_city_dir(_this.areaMsg, _this.prov, _this.city, _this.district);
            _this.addMarker(
              poi.location.lng,
              poi.location.lat,
              poi.name
            );
            _this.addWindow(
              poi.location.lng,
              poi.location.lat,
              poi.name
            );
          }
        });
      });
    },
    searchByHand(searchKey) {
      if (searchKey && searchKey !== "") {
        this.markers = [];
        lazyAMapApiLoaderInstance.load().then(() => {
          this.poiPicker.searchByKeyword(searchKey);
          let map = this.amapManager.getMap();
          var center = map.getCenter(); //获取当前地图中心位置
          this.lat = center.lat;
          this.lng = center.lng;
        });
      }
    }
  }
};
</script>
<style lang="scss">
.areaOption {
  .el-form-item__content {
    display: flex;
  }
  .el-select {
    flex: 1;
    margin-right: 5px;
  }
  .el-select:last-child {
    margin: 0px;
  }
}
</style>
<style lang="scss" scope>
.el-popper .popper__arrow {
  display: none;
}
.inline-input {
  .el-form-item__content {
    display: flex;
    justify-content: space-between;
  }
  .el-input {
    width: 100px;
    margin-right: 5px;
  }
  .el-input:first-child {
    flex: 1 1;
  }
  .el-input:last-child {
    margin: 0px;
  }
}
.tip-box {
  width: 100%;
  max-height: 260px;
  position: absolute;
  top: 32px;
  overflow-y: auto;
  background-color: #fff;
  z-index: 99;
}
.map {
  margin-bottom: 20px;
}
</style>
