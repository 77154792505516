import request from '@/utils/request'

const url = '/m/member';

// 获取列表
export function queryMemberApi (data) {
  if(!data) {
    data = {pageSize: 100000}
  }
  return request({
    url: `${url}/query`,
    method: 'post',
    data
  })
}

// 获取信息
export function getMemberApi (id) {
  return request({
    url: `${url}/get/${id}`,
    method: 'get'
  })
}

// 修改
export function updateMemberApi (data) {
  return request({
    url: `${url}/update`,
    method: 'put',
    data
  })
}

// 删除
export function del (params) {
  return request({
    url: '/member/member/delete',
    method: 'post',
    data: params
  })
}

// 禁用启用
export function updateMemberFreezeApi (id) {
  return request({
    url: `${url}/updateFreeze/${id}`,
    method: 'put'
  })
}
