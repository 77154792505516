import request from '@/utils/request'

// 获取列表
export function list (params) {
  return request({
    url: '/member/memberinvoice/list',
    method: 'get',
    params: params
  })
}

// 获取信息
export function info (params) {
  return request({
    url: '/member/memberinvoice/info',
    method: 'get',
    params: params
  })
}

// 添加
export function add (params) {
  return request({
    url: '/member/memberinvoice/save',
    method: 'post',
    data: params
  })
}

// 修改
export function update (params) {
  return request({
    url: '/member/memberinvoice/update',
    method: 'post',
    data: params
  })
}

// 删除
export function del (params) {
  return request({
    url: '/member/memberinvoice/delete',
    method: 'post',
    data: params
  })
}

// 开发票
export function makeInvoice (params) {
    return request({
        url: '/member/memberinvoice/makeInvoice',
        method: 'post',
        data: params
    })
}

// 邮寄发票
export function mailInvoice (params) {
    return request({
        url: '/member/memberinvoice/mailInvoice',
        method: 'post',
        data: params
    })
}

