import request from '@/utils/request'

// 搜索属性值分布
export function searchAttrValueOverview (params) {
    return request({
        url: '/member/membersearchhistory/searchAttrValueOverview',
        method: 'get',
        params: params
    })
}

// 搜索属性分布
export function searchAttrOverview (params) {
    return request({
        url: '/member/membersearchhistory/searchAttrOverview',
        method: 'get',
        params: params
    })
}

// 搜索来源分布
export function searchTypeOverview (params) {
    return request({
        url: '/member/membersearchhistory/searchTypeOverview',
        method: 'get',
        params: params
    })
}

// 用户概览
export function searchMemberOverview (params) {
    return request({
        url: '/member/membersearchhistory/searchMemberOverview',
        method: 'get',
        params: params
    })
}

// 搜索趋势
export function searchTrend (params) {
    return request({
        url: '/member/membersearchhistory/searchTrend',
        method: 'get',
        params: params
    })
}

// 搜索概览
export function searchOverview (params) {
    return request({
        url: '/member/membersearchhistory/searchOverview',
        method: 'get',
        params: params
    })
}

// 筛选条件
export function selectExistAttr (params) {
    return request({
        url: '/member/membersearchhistory/selectExistAttr',
        method: 'get',
        params: params
    })
}

//光泽搜索排行
export function searchGlossValueOverview (params) {
    return request({
        url: '/member/membersearchhistory/searchGlossValueOverview',
        method: 'get',
        params: params
    })
}
