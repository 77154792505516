<template>
  <el-dialog
    :title="!dataForm.id ? '新增' : '修改'"
    :close-on-click-modal="false"
    :visible.sync="visible">
    <el-form :model="dataForm" :rules="dataRule"  size="small" ref="dataForm" @keyup.enter.native="dataFormSubmit()" label-width="80px"
     v-loading="dataLoading" style="width:100%;"  element-loading-text=""  element-loading-spinner="el-icon-loading"
    >
      <el-form-item label="会员id" prop="memberId">
        <el-input v-model="dataForm.memberId" placeholder="会员id"  size="small"></el-input>
      </el-form-item>
      <el-form-item label="产品id" prop="productId">
        <el-input v-model="dataForm.productId" placeholder="产品id"  size="small"></el-input>
      </el-form-item>
      <el-form-item label="L值" prop="l">
        <el-input v-model="dataForm.l" placeholder="L值"  size="small"></el-input>
      </el-form-item>
      <el-form-item label="A值" prop="a">
        <el-input v-model="dataForm.a" placeholder="A值"  size="small"></el-input>
      </el-form-item>
      <el-form-item label="B值" prop="b">
        <el-input v-model="dataForm.b" placeholder="B值"  size="small"></el-input>
      </el-form-item>
      <el-form-item label="收藏时间" prop="collectDate">
        <el-input v-model="dataForm.collectDate" placeholder="收藏时间"  size="small"></el-input>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="visible = false" size="small">取消</el-button>
      <el-button type="primary" @click="dataFormSubmit()" size="small"  :disabled="dataLoading">确定</el-button>
    </span>
  </el-dialog>
</template>

<script>
  import {info,add,update} from '@/api/modules/membercollectcolor'
  export default {
    data () {
      return {
        visible: false,
        dataLoading: false,
        dataForm: {
          id: 0,
          memberId: '',
          productId: '',
          l: '',
          a: '',
          b: '',
          collectDate: ''
        },
        dataRule: {
          memberId: [
            { required: true, message: '会员id不能为空', trigger: 'blur' }
          ],
          productId: [
            { required: true, message: '产品id不能为空', trigger: 'blur' }
          ],
          l: [
            { required: true, message: 'L值不能为空', trigger: 'blur' }
          ],
          a: [
            { required: true, message: 'A值不能为空', trigger: 'blur' }
          ],
          b: [
            { required: true, message: 'B值不能为空', trigger: 'blur' }
          ],
          collectDate: [
            { required: true, message: '收藏时间不能为空', trigger: 'blur' }
          ]
        }
      }
    },
    methods: {
      init (id) {
        this.dataForm.id = id || 0
        this.visible = true
        this.$nextTick(() => {
          this.$refs['dataForm'].resetFields()
          if (this.dataForm.id) {
            info({id:this.dataForm.id}).then(({data}) => {
              if (data && data.code === 0) {
                this.dataForm.memberId = data.membercollectcolor.memberId
                this.dataForm.productId = data.membercollectcolor.productId
                this.dataForm.l = data.membercollectcolor.l
                this.dataForm.a = data.membercollectcolor.a
                this.dataForm.b = data.membercollectcolor.b
                this.dataForm.collectDate = data.membercollectcolor.collectDate
              }
            })
          }
        })
      },
      // 表单提交
      dataFormSubmit () {
        this.$refs['dataForm'].validate((valid) => {
          if (valid) {
          	this.dataLoading = true;
            var params = {
              'id': this.dataForm.id || undefined,
              'memberId': this.dataForm.memberId,
              'productId': this.dataForm.productId,
              'l': this.dataForm.l,
              'a': this.dataForm.a,
              'b': this.dataForm.b,
              'collectDate': this.dataForm.collectDate
            }
            var tick = !this.dataForm.id ? add(params) : update(params)
            tick.then(({data}) => {
              if (data && data.code === 0) {
                this.$message({
                  message: '操作成功',
                  type: 'success',
                  duration: 1500,
                  onClose: () => {
                    this.visible = false;
	              	this.dataLoading = false;
                    this.$emit('refreshDataList')
                  }
                })
              } else {
                this.$message.error(data.msg)
                this.dataLoading = false;
              }
            })
          }
        })
      }
    }
  }
</script>
