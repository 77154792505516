<template>
  <!--<el-dialog
    :title="!dataForm.id ? '详情' : '详情'"
    :close-on-click-modal="false"
    :visible.sync="visible">-->
  <sides-lip ref="SidesLip">
    <div slot="soltForm">
    <el-form :model="dataForm" :rules="dataRule"  size="small" ref="dataForm" @keyup.enter.native="dataFormSubmit()" label-width="80px"
     v-loading="dataLoading" style="width:100%;"  element-loading-text=""  element-loading-spinner="el-icon-loading"
    >
      <el-form-item label="订单号" prop="sampleOrderNo">
        <el-input v-model="dataForm.sampleOrderNo" placeholder="打样订单编号"  size="small"></el-input>
      </el-form-item>
      <el-form-item label="产品编号" prop="productNo">
        <el-input v-model="dataForm.productNo" placeholder="产品编号"  size="small"></el-input>
      </el-form-item>
      <el-form-item label="产品名称" prop="productName">
        <el-input v-model="dataForm.productName" placeholder="产品名称"  size="small"></el-input>
      </el-form-item>
      <el-form-item label="退款金额" prop="returnMoney">
        <el-input v-model="dataForm.returnMoney" placeholder="退款金额"  size="small"></el-input>
      </el-form-item>
      <el-form-item label="售后原因" prop="reason">
        <el-input v-model="dataForm.reason" placeholder="售后原因"  size="small"></el-input>
      </el-form-item>
      <el-form-item label="售后凭证" prop="returnPic">
        <img v-if="dataForm.returnPic" :src="dataForm.returnPic" class="avatar">
      </el-form-item>
      <el-form-item label="备注" prop="description">
        <el-input v-model="dataForm.description" placeholder="备注"  size="small"></el-input>
      </el-form-item>
      <el-form-item label="售后状态" prop="state">
        <el-input v-model="dataForm.state" placeholder="售后状态"  size="small"></el-input>
      </el-form-item>
      <el-form-item label="客服处理" prop="customerReply">
        <el-input v-model="dataForm.customerReply" placeholder="客服处理"  size="small"></el-input>
      </el-form-item>
      <el-form-item label="下单人" prop="contactsName">
        <el-input v-model="dataForm.contactsName" placeholder="下单人"  size="small"></el-input>
      </el-form-item>
      <el-form-item label="下单时间" prop="createDate">
        <el-input v-model="dataForm.createDate" placeholder="下单时间"  size="small"></el-input>
      </el-form-item>
      <el-form-item label="联系方式" prop="phone">
        <el-input v-model="dataForm.phone" placeholder="联系方式"  size="small"></el-input>
      </el-form-item>
      <el-form-item label="收货地址" prop="contactsAddress">
        <el-input v-model="dataForm.contactsAddress" placeholder="收货地址"  size="small"></el-input>
      </el-form-item>
    </el-form>
    </div>
    <div slot="soltFooter">
      <el-button @click="sideClose" size="small">关闭</el-button>
    </div>
  </sides-lip>

</template>

<script>
  import {info,add,update} from '@/api/modules/sampleaftersale'
  import SidesLip from "@/components/SidesLip";
  export default {
    data () {
      return {
        visible: false,
        dataLoading: false,
        dataForm: {
          id: 0,
          sampleOrderId: '',
          sampleOrderNo: '',
          returnMoney: '',
          reason: '',
          returnPic: '',
          description: '',
          state: '',
          customerReply: '',
          createDate: '',
            phone:'',
            contactsAddress:'',
            contactsName:'',
            productNo:'',
            productName:'',
        },
      }
    },
      components: {
          SidesLip
      },
    methods: {
      init (id) {
        this.dataForm.id = id || 0
          this.$refs.SidesLip.init("订单详情","650px");
        this.visible = true
        this.$nextTick(() => {
          this.$refs['dataForm'].resetFields()
          if (this.dataForm.id) {
            info({id:this.dataForm.id}).then(({data}) => {
              if (data && data.code === 0) {
                this.dataForm.contactsName = data.sampleAftersale.contactsName
                this.dataForm.sampleOrderNo = data.sampleAftersale.sampleOrderNo
                this.dataForm.returnMoney = data.sampleAftersale.returnMoney
                this.dataForm.reason = data.sampleAftersale.reason
                this.dataForm.returnPic = data.sampleAftersale.returnPic
                this.dataForm.description = data.sampleAftersale.description
                this.dataForm.state = data.sampleAftersale.state
                this.dataForm.customerReply = data.sampleAftersale.customerReply
                this.dataForm.phone = data.sampleAftersale.phone
                this.dataForm.createDate = data.sampleAftersale.createDate
                this.dataForm.contactsAddress = data.sampleAftersale.contactsAddress
                this.dataForm.productNo = data.sampleAftersale.productNo
                this.dataForm.productName = data.sampleAftersale.productName
              }
            })
          }
        })
      },
        sideClose() {
            this.$refs.SidesLip.sideClose();
        }
      /*// 表单提交
      dataFormSubmit () {
        this.$refs['dataForm'].validate((valid) => {
          if (valid) {
            this.dataLoading = true;
            var params = {
              'id': this.dataForm.id || undefined,
              'sampleOrderId': this.dataForm.sampleOrderId,
              'sampleOrderNo': this.dataForm.sampleOrderNo,
              'returnMoney': this.dataForm.returnMoney,
              'reason': this.dataForm.reason,
              'returnPic': this.dataForm.returnPic,
              'description': this.dataForm.description,
              'state': this.dataForm.state,
              'customerReply': this.dataForm.customerReply,
              'delFlag': this.dataForm.delFlag,
              'createBy': this.dataForm.createBy,
              'createDate': this.dataForm.createDate,
              'updateBy': this.dataForm.updateBy,
              'updateDate': this.dataForm.updateDate,
              'remarks': this.dataForm.remarks
            }
            var tick = !this.dataForm.id ? add(params) : update(params)
            tick.then(({data}) => {
              if (data && data.code === 0) {
                this.$message({
                  message: '操作成功',
                  type: 'success',
                  duration: 1500,
                  onClose: () => {
                    this.visible = false;
                    this.dataLoading = false;
                    this.$emit('refreshDataList')
                  }
                })
              } else {
                this.$message.error(data.msg)
                this.dataLoading = false;
              }
            })
          }
        })
      }*/
    }
  }
</script>
<style>
  .avatar {
    width: 178px;
    height: 178px;
    display: block;
  }
</style>
