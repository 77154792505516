<template>
  <el-dialog
          :title="!dataForm.id ? '退货退款审核' : '退货退款审核'"
          :close-on-click-modal="false"
          :visible.sync="visible">
    <el-form :model="dataForm" :rules="dataRule"  size="small" ref="dataForm" @keyup.enter.native="dataFormSubmit()" label-width="80px"
             v-loading="dataLoading" style="width:100%;"  element-loading-text=""  element-loading-spinner="el-icon-loading"
    >
      <div>
        <p>申请原因：{{dataForm.reason}}</p>
        <div style="display: flex">
          <p>凭证图片：</p>
          <div v-for="(item,index) in picList" style="margin-left: 5px">
            <img :src="item" min-width="100" height="100"/>
          </div>
        </div>
        <p>申请说明：{{dataForm.description}}</p>
        <p>申请时间：{{dataForm.createDate}}</p>
        <p>订单金额：{{dataForm.orderPrice}}元</p>
        <p v-if="dataForm.state=='0'">退货状态：申请售后</p>
        <p v-if="dataForm.state=='1'">退货状态：已取消</p>
        <p v-if="dataForm.state=='2'">退货状态：待退货</p>
        <p v-if="dataForm.state=='3'">退货状态：待退款</p>
        <p v-if="dataForm.state=='4'">退货状态：已完成</p>
        <p v-if="dataForm.state=='5'">退货状态：重新打样</p>
        <p>客服回复：</p>
          <el-input v-model="dataForm.customerReply" type="textarea" size="small" placeholder="" maxlength="50" show-word-limit></el-input>
      </div>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="consent()" size="small">拒绝退货</el-button>
      <el-button type="primary" @click="callOff()" size="small"  :disabled="dataLoading">同意退货</el-button>
    </span>
  </el-dialog>
</template>

<script>
    import {checkOrder,getCheckState,refundOrReturns} from '@/api/modules/sampleaftersale'
    export default {
        data () {
            return {
                showBzu:false,
                visible: false,
                dataLoading: false,
                picList:[],
                dataForm: {
                    id: 0,
                    description: '',//申请说明
                    reason:'',//申请原因
                    returnPic:'',//售后凭证
                    createDate:'',//申请时间
                    orderPrice:'',//订单金额
                    customerReply:'',//客服备注
                    state: '',//售后状态
                    isNo:''//是否同意申请
                },
                dataRule: {
                    description: [
                        { required: true, message: '备注不能为空', trigger: 'blur' }
                    ],
                    state: [
                        { required: true, message: '审核状态', trigger: 'blur' }
                    ],
                }
            }
        },
        methods: {
            //同意退款退货
            callOff(){
                this.dataForm.isNo = '0'
                let params = {
                    'isNo': this.dataForm.isNo,
                    'aftersaleId':this.dataForm.id,
                    'customerReply':this.dataForm.customerReply,
                }
                checkOrder(params).then(({data})=>{
                    if (data && data.code === 0) {
                        this.$message({
                            message: '操作成功',
                            type: 'success',
                            duration: 1500,
                            onClose: () => {
                                this.visible = false;
                                //    this.showBzu = false;
                                this.dataLoading = false;
                                this.$emit('refreshDataList')
                            }
                        })
                    }else {
                        this.$message.error(data.msg)
                        this.dataLoading = false;
                    }
                })
            },
            //拒绝退款退货
            consent(){
                this.dataForm.isNo = '1'
                let params = {
                    'isNo': this.dataForm.isNo,
                    'aftersaleId':this.dataForm.id,
                    'customerReply':this.dataForm.customerReply,
                }
                checkOrder(params).then(({data})=>{
                    if (data && data.code === 0) {
                        this.$message({
                            message: '操作成功',
                            type: 'success',
                            duration: 1500,
                            onClose: () => {
                                this.visible = false;
                                //    this.showBzu = false;
                                this.dataLoading = false;
                                this.$emit('refreshDataList')
                            }
                        })
                    }else {
                        this.$message.error(data.msg)
                        this.dataLoading = false;
                    }
                })
            },
            init (id) {
                this.picList = []
                this.dataForm.id = id || 0
                //= ""
                console.log(this.dataForm.state)

                this.visible = true
                this.$nextTick(() => {
                    this.$refs['dataForm'].resetFields()
                    if (this.dataForm.id) {
                        refundOrReturns({id:this.dataForm.id}).then(({data}) => {
                            if (data && data.code === 0) {
                                this.dataForm.state = data.refundOrReturns.state
                                this.dataForm.reason = data.refundOrReturns.reason
                                this.dataForm.createDate = data.refundOrReturns.createDate
                                this.dataForm.orderPrice = data.refundOrReturns.orderPrice
                                this.dataForm.description = data.refundOrReturns.description
                                this.dataForm.customerReply = data.refundOrReturns.customerReply
                                console.log(data.refundOrReturns.returnPic)
                                if (data.refundOrReturns.returnPic !==null) {
                                    this.picList=data.refundOrReturns.returnPic.split(",")
                                   /* console.log(this.picList+"========")*/
                                }
                            }
                        })
                    }
                })
            },
            // 表单提交
            dataFormSubmit () {
                this.$refs['dataForm'].validate((valid) => {
                    if (valid) {
                        this.dataLoading = true;
                        var params = {
                            'aftersaleId': this.dataForm.id || undefined,
                            'description': this.dataForm.description,
                            'state': this.dataForm.state,
                        }
                        var tick = !this.dataForm.id ? checkOrder(params) : checkOrder(params)
                        tick.then(({data}) => {
                            if (data && data.code === 0) {
                                this.$message({
                                    message: '操作成功',
                                    type: 'success',
                                    duration: 1500,
                                    onClose: () => {
                                        this.visible = false;
                                        //    this.showBzu = false;
                                        this.dataLoading = false;
                                        this.$emit('refreshDataList')
                                    }
                                })
                            } else {
                                this.$message.error(data.msg)
                                this.dataLoading = false;
                            }
                        })
                    }
                })
            }
        }
    }
</script>
