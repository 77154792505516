<template>
  <!--<el-dialog
    :title="!dataForm.id ? '新增' : '修改'"
    :close-on-click-modal="false"
    :visible.sync="visible">-->
  <sides-lip ref="SidesLip">
    <div slot="soltForm">
      <el-form :model="dataForm" size="small" ref="dataForm" @keyup.enter.native="dataFormSubmit()" label-width="0px"
        style="width:100%;"  element-loading-text=""  element-loading-spinner="el-icon-loading">
        <div style="display: flex">
          <div style="width: 50%">
            <p>订单编号：{{dataForm.sampleOrderNo}}</p>
            <p>订单品牌：{{dataForm.brandName}}</p>
            <p>订单类型：{{dataForm.sampleType}}</p>
            <p>订单价格：{{dataForm.orderPrice}}元</p>
            <p>收货人：{{dataForm.userNameF}}</p>
            <p>收货人联系电话：{{dataForm.phoneF}}</p>
            <p>收货地址：{{dataForm.addressF}}</p>
            <p>样板数量：{{dataForm.modelSum}}</p>           
            <p>备注：{{dataForm.description}}</p>
          </div>
          <div style="width: 50%">
            <p><br/></p>
            <p>下单时间：{{dataForm.createDate}}</p>
            <p>订单状态：{{dataForm.statusName}}</p>
            <p>支付类型：{{dataForm.payType}}</p>
            <p>下单客户：{{dataForm.createByName}}</p>
            <!--<p>样板收货人：{{dataForm.userNameB}}</p>
            <p>样板收货联系电话：{{dataForm.phoneB}}</p>
            <p>样板收货地址：{{dataForm.addressB}}</p>-->
            <p>是否有样件：{{dataForm.hasSample}}</p>
            <p>样件物流：{{dataForm.logisticsOrderNo}}</p>
            <p>样粉数量：{{dataForm.materialSum}}公斤</p>
          </div>
        </div>
        <div style="border: solid 1px silver;padding: 15px 15px;margin-top: 30px;">
          <span style="font-size: 18px;font-weight: bold">订单商品信息</span>
<!--          <div style="display: flex">-->
<!--            <div style="width: 50%;">-->
<!--              <div style="width: 60%">-->
                <p >LAB值：L:{{dataForm.lAve}} A:{{dataForm.aAve}} B:{{dataForm.bAve}}</p>
                <div style="display: flex" >
                  <div style="width: 100%">
                    <template v-for="(item,index) in dataForm.attrMap">
                      <p v-if="index%2 == 0" class="ziti">{{item.attrName}} : {{item.name}}</p >
                    </template>
                  </div>
                  <div style="width: 100%">
                    <template v-for="(item,index) in dataForm.attrMap">
                      <p v-if="index%2==1" class="ziti">{{item.attrName}} : {{item.name}}</p >
                    </template>
<!--                  </div>-->
<!--                </div>-->
<!--              </div>-->
          </div>
        </div>
        </div>

        <div style="border: solid 1px silver;padding: 15px 15px;margin-top: 30px;">
          <span style="font-size: 18px;font-weight: bold">产品预估价</span>

          <el-table
                  :data="dataForm.predictruleEntitieList"
                  border
                  v-loading="dataListLoading"
                  @selection-change="selectionChangeHandle"
                  style="width: 100%;">
            <el-table-column
                    prop="name"
                    header-align="center"
                    align="center"
                    label="重量区间">
            </el-table-column>

            <el-table-column
                    prop="price"
                    header-align="center"
                    align="center"
                    label="预估价格">
            </el-table-column>
          </el-table>

        </div>

      </el-form>
    </div>
    <div slot="soltFooter">
      <span slot="footer" class="dialog-footer">
        <el-button @click="sideClose()" size="small">取消</el-button>
      </span>
    </div>
  </sides-lip>
  <!--</el-dialog>-->
</template>

<script>
  import {info,add,update} from '@/api/modules/sampleorder';
  import {getDict,} from '@/api/modules/product';
  import SidesLip from "@/components/SidesLip";
  import {getToken} from "@/utils/auth";
  export default {
    data () {
      return {
        action: `${process.env.VUE_APP_BASE_API}/sys/oss/upload?token=${getToken()}`,
        visible: false,
        dataLoading: false,
          dataListLoading:false,
        productGlossList:[],
        productSurfaceList:[],
        dataForm: {
          id: 0,
          sampleOrderNo: '',
          productSeriesName: '',
          sampleType: '',
          statusName: '',
          userNameB: '',
          userNameF: '',
          picUrl: '',
          ralColorNo: '',
          gloss: '',
          glossValue: '',
          surface: '',
          application: '',
          phoneF: '',
          lAve: '',
          aAve: '',
          bAve: '',
          phoneB: '',
          performanceName: '',
          orderPrice: '',
          modelSum: '',
          logisticsOrderNo: '',
          hasSample: '',
          materialSum: '',
          factoryId: '',
          brandId: '',
          description: '',
          glossName: '',
          createDate: '',
          createByName: '',
          colorSeriesName: '',
          cartegryName: '',
          brandName: '',
          applicationName: '',
          addressF: '',
          addressB: '',
          payType:"",
          predictruleEntitieList:[],
          attrMap:[]
        },
      }
    },
    components: {
      SidesLip,
    },
    methods: {
      init (id) {
        this.dataForm.id = id || 0
        this.$refs.SidesLip.init(this.dataForm.id ? "详情" : "详情","800px","block");
        this.visible = true
        this.$nextTick(() => {
          this.$refs['dataForm'].resetFields()
          if (this.dataForm.id) {
            info({id:this.dataForm.id}).then(({data}) => {
              if (data && data.code === 0) {
                this.dataForm.sampleOrderNo = data.sampleOrderInfoVo.sampleOrderNo
                this.dataForm.aAve = data.sampleOrderInfoVo.aAve
                this.dataForm.addressB = data.sampleOrderInfoVo.addressB
                this.dataForm.addressF = data.sampleOrderInfoVo.addressF
                this.dataForm.applicationName = data.sampleOrderInfoVo.applicationName
                this.dataForm.applicationNameS = data.sampleOrderInfoVo.applicationNameS
                this.dataForm.bAve = data.sampleOrderInfoVo.bAve
                this.dataForm.brandName = data.sampleOrderInfoVo.brandName
                this.dataForm.cartegryName = data.sampleOrderInfoVo.cartegryName
                this.dataForm.cartegryNameS = data.sampleOrderInfoVo.cartegryNameS
                this.dataForm.colorSeriesName = data.sampleOrderInfoVo.colorSeriesName
                this.dataForm.colorSeriesNameS = data.sampleOrderInfoVo.colorSeriesNameS
                this.dataForm.createByName = data.sampleOrderInfoVo.createByName
                this.dataForm.createDate = data.sampleOrderInfoVo.createDate
                this.dataForm.glossName = data.sampleOrderInfoVo.glossName
                this.dataForm.glossNameS = data.sampleOrderInfoVo.glossNameS
                this.dataForm.hasSample = data.sampleOrderInfoVo.hasSample
                this.dataForm.lAve = data.sampleOrderInfoVo.lAve
                this.dataForm.logisticsOrderNo = data.sampleOrderInfoVo.logisticsOrderNo
                this.dataForm.materialSum = data.sampleOrderInfoVo.materialSum
                this.dataForm.modelSum = data.sampleOrderInfoVo.modelSum
                this.dataForm.orderPrice = data.sampleOrderInfoVo.orderPrice
                this.dataForm.performanceName = data.sampleOrderInfoVo.performanceName
                this.dataForm.performanceNameS = data.sampleOrderInfoVo.performanceNameS
                this.dataForm.phoneB = data.sampleOrderInfoVo.phoneB
                this.dataForm.phoneF = data.sampleOrderInfoVo.phoneF
                this.dataForm.picUrl = data.sampleOrderInfoVo.picUrl
                this.dataForm.productSeriesName = data.sampleOrderInfoVo.productSeriesName
                this.dataForm.productSeriesNameS = data.sampleOrderInfoVo.productSeriesNameS
                this.dataForm.sampleType = data.sampleOrderInfoVo.sampleType
                this.dataForm.statusName = data.sampleOrderInfoVo.statusName
                this.dataForm.userNameB = data.sampleOrderInfoVo.userNameB
                this.dataForm.userNameF = data.sampleOrderInfoVo.userNameF
                this.dataForm.productId = data.sampleOrderInfoVo.productId
                this.dataForm.description = data.sampleOrderInfoVo.description
                this.dataForm.predictruleEntitieList = data.sampleOrderInfoVo.predictruleEntitieList
                this.dataForm.payType=data.sampleOrderInfoVo.payType
                this.dataForm.attrMap=data.attrMap
              }
            })
          }
        })
      },
      sideClose() {
        this.$refs.SidesLip.sideClose();
      },
      // 表单提交
      dataFormSubmit () {
        this.$refs['dataForm'].validate((valid) => {
          if (valid) {
          	this.dataLoading = true;
            var params = {
              'id': this.dataForm.id || undefined,
              'sampleOrderNo': this.dataForm.sampleOrderNo,
              'company': this.dataForm.company,
              'contactsName': this.dataForm.contactsName,
              'contactsPhone': this.dataForm.contactsPhone,
              'contactsAddress': this.dataForm.contactsAddress,
              'sysProductId': this.dataForm.sysProductId,
              'picUrl': this.dataForm.picUrl,
              'ralColorNo': this.dataForm.ralColorNo,
              'gloss': this.dataForm.gloss,
              'glossValue': this.dataForm.glossValue,
              'surface': this.dataForm.surface,
              'application': this.dataForm.application,
              'cartegry': this.dataForm.cartegry,
              'lAve': this.dataForm.lAve,
              'aAve': this.dataForm.aAve,
              'bAve': this.dataForm.bAve,
              'dosage': this.dataForm.dosage,
              'price': this.dataForm.price,
              'materialSum': this.dataForm.materialSum,
              'modelSum': this.dataForm.modelSum,
              'basicMaterial': this.dataForm.basicMaterial,
              'isBacksend': this.dataForm.isBacksend,
              'hasSample': this.dataForm.hasSample,
              'factoryId': this.dataForm.factoryId,
              'brandId': this.dataForm.brandId,
              'description': this.dataForm.description,
              'state': this.dataForm.state,
              'orderSource': this.dataForm.orderSource,
              'deliverDate': this.dataForm.deliverDate,
              'delFlag': this.dataForm.delFlag,
              'createBy': this.dataForm.createBy,
              'createDate': this.dataForm.createDate,
              'updateBy': this.dataForm.updateBy,
              'updateDate': this.dataForm.updateDate,
              'remarks': this.dataForm.remarks
            }
            var tick = !this.dataForm.id ? add(params) : update(params)
            tick.then(({data}) => {
              if (data && data.code === 0) {
                this.$message({
                  message: '操作成功',
                  type: 'success',
                  duration: 1500,
                  onClose: () => {
                    /*this.visible = false;*/
	              	this.dataLoading = false;
                    this.sideClose();
                    this.$emit('refreshDataList')
                  }
                })
              } else {
                this.$message.error(data.msg)
                this.dataLoading = false;
              }
            })
          }
        })
      },
      //上传产品图处理
      handleUploadSuccess(res, file, type) {
        this.dataForm.picUrl = res.data.fileUrl;
      },
      //上传前对图片大小及格式进行判断
      beforeUpload(file) {
        const isJPG = (file.type === "image/jpeg" || file.type === "image/bmp" || file.type === "image/png");
        const isLt2M = file.size / 1024 / 1024 < 8;

        if (!isJPG) {
          this.$message.error("上传图片格式不支持!");
        }
        if (!isLt2M) {
          this.$message.error("上传图片大小不能超过 8MB!");
        }
        return isJPG && isLt2M;
      },
    }
  }
</script>

<style lang="scss" scoped>
p{
  margin: 20px 0;
}
  .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409EFF;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
  }
  .avatar {
    width: 100%;
    height: 178px;
    display: block;
  }
</style>
