import request from '@/utils/request'

const url = '/m/resource';

// 获取列表
export function queryResourceApi (data) {
  if(!data) {
    data = {pageSize: 100000}
  }
  return request({
    url: `${url}/query`,
    method: 'post',
    data
  })
}

export function getResourceApi(id) {
  return request({
    url: `${url}/get/${id}`,
    method: 'get'
  })
}

export function addOrUpdateResourceApi(data) {
  return request({
    url: `${url}/addOrUpdate`,
    method: 'put',
    data
  })
}

export function deleteResourceApi(id) {
  return request({
    url: `${url}/delete/${id}`,
    method: 'delete'
  })
}