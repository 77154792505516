import request from '@/utils/request'

const url = '/m/requesMoniting';

// 获取列表
export function queryRequesMonitingApi(data) {
    return request({
        url: `${url}/queryRequesMoniting`,
        method: 'post',
        data
    })
}