<template>
  <div class="app-container">
    <el-form
      :inline="true"
      size="small"
      :model="dataForm"
      ref="dataForm"
      @keyup.enter.native="restPage()"
    >
      <el-form-item prop="brandNameLike">
        <el-input
          v-model="dataForm.brandNameLike"
          placeholder="品牌名称"
          size="small"
          clearable
          maxlength="10"
        ></el-input>
      </el-form-item>
      <el-form-item prop="brandEnameLike">
        <el-input
          v-model="dataForm.brandEnameLike"
          placeholder="品牌英文名"
          size="small"
          clearable
          maxlength="10"
        ></el-input>
      </el-form-item>
      <el-form-item>
        <el-button @click="restPage()" size="small">筛选</el-button>
        <el-button @click="reset('dataForm')" size="small">清空</el-button>
        <el-button type="primary" @click="addOrUpdateHandle()" size="small"
          >添加品牌</el-button
        >
      </el-form-item>
    </el-form>
    <el-table
      :data="dataList"
      border
      size="small"
      v-loading="dataListLoading"
      style="width: 100%"
    >
      <el-table-column
        prop="brandId"
        header-align="center"
        align="center"
        label="id"
        width="150"
      >
      </el-table-column>
      <el-table-column
        prop="brandName"
        header-align="center"
        align="center"
        label="品牌名称"
      >
      </el-table-column>
      <el-table-column
        prop="brandEname"
        header-align="center"
        align="center"
        label="品牌英文名"
      >
      </el-table-column>
      <el-table-column
        prop="brandLogo"
        header-align="center"
        align="center"
        label="品牌LOGO"
      >
        <!-- 图片的显示 -->
        <template slot-scope="scope">
          <img :src="scope.row.brandLogo" min-width="70" height="70" />
        </template>
      </el-table-column>
      <el-table-column
        prop="isShow"
        header-align="center"
        align="center"
        label="开通小程序"
      >
        <template slot-scope="scope">
          {{ scope.row.isShow ? '开通' : '不开通' }}
        </template>
      </el-table-column>
      <el-table-column
        prop="sortNumber"
        header-align="center"
        align="center"
        label="排序"
      >
      </el-table-column>
      <el-table-column
        prop="isShow"
        header-align="center"
        align="center"
        label="状态"
      >
        <template slot-scope="scope">
          <i v-if="scope.row.state == '0'">正常</i>
          <i v-else-if="scope.row.state == '1'">冻结</i>
          <i v-else-if="scope.row.state == '2'">已过期</i>
        </template>
      </el-table-column>
      <el-table-column
        prop="createTime"
        header-align="center"
        align="center"
        label="创建时间"
      >
      </el-table-column>
      <el-table-column
        fixed="right"
        header-align="center"
        align="center"
        width="150"
        label="操作"
      >
        <template slot-scope="scope">
          <el-button
            type="text"
            size="small"
            @click="addOrUpdateHandle(scope.row.brandId)"
            >编辑</el-button
          >
          <el-button
            type="text"
            size="small"
            @click="viewManagerHandle(scope.row.brandId)"
            >查看账号</el-button
          >
          <el-button
            type="text"
            size="small"
            @click="viewManagerHandlerenew(scope.row)"
            >续费</el-button
          >
          <el-button
            v-if="scope.row.appletQrCode"
            type="text"
            size="small"
            @click="downlwxQR(scope.row)"
            >下载二维码</el-button
          >
          <el-button
            type="text"
            size="small"
            style="color: red"
            @click="deleteHandle(scope.row.brandId)"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      size="small"
      @size-change="sizeChangeHandle"
      @current-change="currentChangeHandle"
      :current-page="dataForm.pageNo"
      :page-sizes="[10, 20, 50, 100]"
      :page-size="dataForm.pageSize"
      :total="totalPage"
      layout="total, sizes, prev, pager, next, jumper"
    >
    </el-pagination>
    <!-- 弹窗, 新增 / 修改 -->
    <add-or-update
      v-if="addOrUpdateVisible"
      ref="addOrUpdate"
      @refreshDataList="getDataList"
    ></add-or-update>
    <view-manager
      v-if="viewManagerVisible"
      ref="viewManager"
      @refreshDataList="getDataList"
    ></view-manager>
    <renew
      v-if="renewVisible"
      ref="renew"
      @refreshDataList="getDataList"
    ></renew>
    <div style="margin-top: 20px">
      <!--存放二维码-->
      <div
        class="qrcode"
        id="qrcode"
        ref="qrCodeUrl"
        style="margin-top: 20px"
      ></div>
    </div>
  </div>
</template>

<script>
import { queryBrandApi, deleteBrandApi } from '@/api/modules/brand'
import AddOrUpdate from './add-or-update'
import ViewManager from './view-manager'
import Renew from './renew'
export default {
  data() {
    return {
      dataForm: {
        brandNameLike: '',
        brandEnameLike: '',
        pageNo: 1,
        pageSize: 10
      },
      dataList: [],
      totalPage: 0,
      dataListLoading: false,
      addOrUpdateVisible: false,
      viewManagerVisible: false,
      renewVisible: false
    }
  },
  components: {
    AddOrUpdate,
    ViewManager,
    Renew
  },
  created() {
    this.getDataList()
  },
  methods: {
    //重新查询
    restPage() {
      this.dataForm.pageNo = 1
      this.getDataList()
    },
    reset(formName) {
      this.$refs[formName].resetFields()
      this.restPage()
    },
    // 根据条件获取数据列表
    getDataList() {
      this.dataListLoading = true
      queryBrandApi(this.dataForm).then(({ records, totalRecords }) => {
        if (totalRecords > 0) {
          this.dataList = records
          this.totalPage = totalRecords
        } else {
          this.dataList = []
          this.totalPage = 0
        }
        this.dataListLoading = false
      })
    },
    downlwxQR(row) {
      fetch(row.appletQrCode).then((res) =>
        res.blob().then((blob) => {
          var a = document.createElement('a')
          var url = window.URL.createObjectURL(blob)
          var filename = row.brandName || 'qrcode.jpg'
          a.href = url
          a.download = filename
          a.click()
          window.URL.revokeObjectURL(url)
        })
      )
    },
    download(href, name) {
      let eleLink = document.createElement('a')
      eleLink.download = name
      eleLink.href = href
      eleLink.click()
      eleLink.remove()
    },
    // 每页数
    sizeChangeHandle(val) {
      this.dataForm.pageSize = val
      this.dataForm.pageNo = 1
      this.getDataList()
    },
    // 当前页
    currentChangeHandle(val) {
      this.dataForm.pageNo = val
      this.getDataList()
    },
    // 新增 / 修改
    addOrUpdateHandle(id) {
      this.addOrUpdateVisible = true
      this.$nextTick(() => {
        this.$refs.addOrUpdate.init(id)
      })
    },
    viewManagerHandlerenew(row) {
      this.renewVisible = true
      this.$nextTick(() => {
        this.$refs.renew.init(row)
      })
    },
    viewManagerHandle(id) {
      this.viewManagerVisible = true
      this.$nextTick(() => {
        this.$refs.viewManager.init(id)
      })
    },
    // 删除
    deleteHandle(id) {
      this.$confirm(`是否确认删除品牌?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        await deleteBrandApi(id)
        this.$baseMessage('删除成功', 'success')
        this.getDataList()
      })
    }
  }
}
</script>
