<template>
  <div class="app-container">
    <el-form
      :inline="true"
      size="small"
      :model="dataForm"
      ref="dataForm"
      @keyup.enter.native="restPage()"
    >
      <el-form-item prop="labelNameLike" label="标签名">
        <el-input
          v-model="dataForm.labelNameLike"
          placeholder="请输入标签名"
          size="small"
          clearable
        ></el-input>
      </el-form-item>
      <el-form-item>
        <el-button @click="restPage()" size="small">查询</el-button>
        <el-button @click="reset('dataForm')" size="small">清空</el-button>
        <el-button
          v-if="isAuth('sys:user:save')"
          type="primary"
          @click="addOrUpdateHandle()"
          >新增</el-button
        >
      </el-form-item>
    </el-form>
    <el-table
      :data="dataList"
      border
      size="small"
      v-loading="dataListLoading"
      style="width: 100%"
    >
      <el-table-column
        prop="id"
        header-align="center"
        align="center"
        label="id"
      >
      </el-table-column>
      <el-table-column
        prop="labelName"
        header-align="center"
        align="center"
        label="标签名称"
      >
      </el-table-column>
      <el-table-column
        prop="createTime"
        header-align="center"
        align="center"
        label="创建时间"
      >
      </el-table-column>
      <el-table-column
        fixed="right"
        header-align="center"
        align="center"
        width="150"
        label="操作"
      >
        <template slot-scope="scope">
          <el-button
            type="text"
            size="small"
            @click="addOrUpdateHandle(scope.row.id)"
            >修改</el-button
          >
          <el-button
            type="text"
            size="small"
            @click="deleteHandle(scope.row.id)"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      size="small"
      @size-change="sizeChangeHandle"
      @current-change="currentChangeHandle"
      :current-page="dataForm.pageNo"
      :page-sizes="[10, 20, 50, 100]"
      :page-size="dataForm.pageSize"
      :total="totalPage"
      layout="total, sizes, prev, pager, next, jumper"
    >
    </el-pagination>
    <!-- 弹窗, 新增 / 修改 -->
    <add-or-update
      v-if="addOrUpdateVisible"
      ref="addOrUpdate"
      @refreshDataList="getDataList"
    ></add-or-update>
  </div>
</template>

<script>
import {
  queryMemberLabelApi,
  deleteMemberLabelApi,
} from "@/api/modules/memberlabel";
import AddOrUpdate from "./add-or-update";

export default {
  data() {
    return {
      dataForm: {
        labelNameLike: "",
        pageNo: 1,
        pageSize: 10,
      },
      dataList: [],
      totalPage: 0,
      dataListLoading: false,
      addOrUpdateVisible: false,
      memberLabelList: [],
    };
  },
  components: {
    AddOrUpdate,
  },
  mounted() {
    this.getDataList();
  },
  methods: {
    //重新查询
    restPage() {
      this.dataForm.pageNo = 1;
      this.getDataList();
    },
    reset() {
      this.$refs["dataForm"].resetFields();
      this.dataForm.labelNameLike = "";
      this.restPage();
    },
    // 获取数据列表
    getDataList() {
      this.dataListLoading = true;
      queryMemberLabelApi(this.dataForm)
        .then(({ records, totalRecords }) => {
          if (totalRecords > 0) {
            this.dataList = records;
            this.totalPage = totalRecords;
          } else {
            this.dataList = [];
            this.totalPage = 0;
          }
        })
        .finally(() => {
          this.dataListLoading = false;
        });
    },
    // 每页数
    sizeChangeHandle(val) {
      this.dataForm.pageSize = val;
      this.dataForm.pageNo = 1;
      this.getDataList();
    },
    // 当前页
    currentChangeHandle(val) {
      this.dataForm.pageNo = val;
      this.getDataList();
    },
    // 新增 / 修改
    addOrUpdateHandle(id) {
      this.addOrUpdateVisible = true;
      this.$nextTick(() => {
        this.$refs.addOrUpdate.init(id);
      });
    },
    // 删除
    deleteHandle(id) {
      this.$confirm(`确定删除标签吗?`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        deleteMemberLabelApi(id).then(() => {
          this.$baseMessage("删除成功", "success");
          this.getDataList();
        });
      });
    },
  },
};
</script>