<template>
  <el-dialog
    :title="!dataForm.userid ? '添加接线员' : '添加接线员'"
    :close-on-click-modal="false"
    :visible.sync="visible">
    <el-form :model="dataForm" :rules="dataRule"  size="small" ref="dataForm" @keyup.enter.native="dataFormSubmit()" label-width="120px"
     v-loading="dataLoading" style="width:100%;"  element-loading-text=""  element-loading-spinner="el-icon-loading"
    >
      <el-form-item label="选择部门" prop="departmentId">
          <el-select v-model="departmentId" clearable placeholder="请选择部门" style="width: 80%" @change="getSimpleList()">
            <el-option
                    v-for="item in departmentList"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id">
            </el-option>
          </el-select>
      </el-form-item>
      <el-form-item label="选择人员" prop="userid">
          <el-select v-model="dataForm.userid" clearable placeholder="请选择人员" style="width: 80%" >
            <el-option
                    v-for="item in userList"
                    :key="item.userid"
                    :label="item.name-item.userid"
                    :value="item.userid">
            </el-option>
          </el-select>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="visible = false" size="small">取消</el-button>
      <el-button type="primary" @click="dataFormSubmit()" size="small"  :disabled="dataLoading">确定</el-button>
    </span>
  </el-dialog>
</template>

<script>
  import {getDepartmentList,addKFServicer,getSimpleList} from '@/api/modules/kfaccount'
  export default {
    data () {
      return {
        visible: false,
        dataLoading: false,
        dataForm: {
          userid: '',
          kfid: ''
        },
        departmentList:[],
        departmentId:'',
        userList:[],
        dataRule: {
          userid: [
            { required: true, message: '人员不能为空', trigger: 'blur' }
          ]
        }
      }
    },
    methods: {
      init (id) {
        this.dataForm.kfid = id || 0
        this.getDepartmentList();
        this.departmentId='';
        this.visible = true
        this.$nextTick(() => {
          this.$refs['dataForm'].resetFields()
        })
      },
      //获取部门列表
      getDepartmentList(){
        getDepartmentList({kfid:this.dataForm.kfid}).then(res => {
          let { list } = res.data
          this.departmentList = list
        })
      },
      //获取部门人员列表
      getSimpleList(){
        getSimpleList({departmentId:this.departmentId}).then(res => {
          let { list } = res.data
          this.userList = list
        })
      },

      // 表单提交
      dataFormSubmit () {
        this.$refs['dataForm'].validate((valid) => {
          if (valid) {
          	this.dataLoading = true;
            var params = {
              'userid': this.dataForm.userid,
              'kfid': this.dataForm.kfid
            }
            addKFServicer(params).then(({data}) => {
              if (data && data.code === 0) {
                this.$message({
                  message: '操作成功',
                  type: 'success',
                  duration: 1500,
                  onClose: () => {
                    this.visible = false;
	              	  this.dataLoading = false;
                    let kfid = this.dataForm.kfid;
                    this.$emit('refreshDataList(kfid)');
                  }
                })
              } else {
                this.$message.error(data.msg)
                this.dataLoading = false;
              }
            })
          }
        })
      }
    }
  }
</script>
<style lang="scss">
  .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409EFF;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
  }
  .avatar {
    width: 178px;
    height: 178px;
    display: block;
  }
  .class_map_lib{
      font-weight:800;
  }
</style>
