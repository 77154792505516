import request from '@/utils/request'

// 获取列表
export function list (params) {
  return request({
    url: '/member/memberdeviceauth/list',
    method: 'get',
    params: params
  })
}

// 获取信息
export function info (params) {
  return request({
    url: '/member/memberdeviceauth/info',
    method: 'get',
    params: params
  })
}

// 添加
export function add (params) {
  return request({
    url: '/member/memberdeviceauth/save',
    method: 'post',
    data: params
  })
}

// 修改
export function update (params) {
  return request({
    url: '/member/memberdeviceauth/update',
    method: 'post',
    data: params
  })
}

// 删除
export function del (params) {
  return request({
    url: '/member/memberdeviceauth/delete',
    method: 'post',
    data: params
  })
}

// 添加
export function memberList () {
  return request({
    url: '/member/memberdeviceauth/getMemberList',
    method: 'get'
  })
}

// 添加
export function deviceList () {
  return request({
    url: '/member/memberdeviceauth/getDeviceList',
    method: 'get'
  })
}

//获取会员数据
export function getMember () {
  return request({
    url: '/member/memberdeviceauth/getMember',
    method: 'get'
  })
}

// 撤销授权
export function revocationAuth (params) {
  return request({
    url: '/member/memberdeviceauth/revocationAuth',
    method: 'get',
    params: params
  })
}