<template>
  <el-dialog
    :title="!dataForm.id ? '新增' : '修改'"
    :close-on-click-modal="false"
    :visible.sync="visible"
    width="400px"
  >
    <el-form
      :model="dataForm"
      :rules="dataRule"
      size="small"
      ref="dataForm"
      @keyup.enter.native="dataFormSubmit()"
      label-width="80px"
      v-loading="dataLoading"
      style="width: 100%"
      element-loading-text=""
      element-loading-spinner="el-icon-loading"
    >
      <el-form-item label="会员" prop="userId">
        <el-select v-model="dataForm.userId" placeholder="选择会员" filterable>
          <el-option
            v-for="item in userList"
            :key="item.id"
            :label="item.mobile"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="设备编号" prop="deviceId">
        <el-select
          v-model="dataForm.deviceId"
          placeholder="选择设备"
          filterable
        >
          <el-option
            v-for="item in deviceList"
            :key="item.id"
            :label="item.deviceNo"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="visible = false" size="small">取消</el-button>
      <el-button
        type="primary"
        @click="dataFormSubmit()"
        size="small"
        :disabled="dataLoading"
        >确定</el-button
      >
    </span>
  </el-dialog>
</template>

<script>
import { queryMemberApi } from '@/api/modules/member'
import { queryDeviceApi } from '@/api/modules/device'
import { addMemberDeviceApi } from '@/api/modules/memberdevice'
export default {
  data() {
    return {
      visible: false,
      dataLoading: false,
      userList: [],
      deviceList: [],
      dataForm: {
        userId: '',
        deviceId: '',
        state: 0
      },
      dataRule: {
        userId: [{ required: true, message: '请选择会员', trigger: 'change' }],
        deviceId: [{ required: true, message: '请选择设备', trigger: 'change' }]
      }
    }
  },
  methods: {
    init() {
      this.visible = true
      this.getMemberList()
      this.getDeviceList()
    },
    //获取用户下拉列表
    getMemberList() {
      queryMemberApi().then(({ records, totalRecords }) => {
        if (totalRecords > 0) {
          this.userList = records
        } else {
          this.userList = []
        }
      })
    },
    //获取设备下拉列表
    getDeviceList() {
      // 只获取会员设备
      queryDeviceApi({ deviceType: 3 }).then(({ records, totalRecords }) => {
        if (totalRecords > 0) {
          this.deviceList = records
        } else {
          this.deviceList = []
        }
      })
    },
    // 表单提交
    dataFormSubmit() {
      this.$refs['dataForm'].validate((valid) => {
        if (valid) {
          this.dataLoading = true
          addMemberDeviceApi(this.dataForm)
            .then(() => {
              this.$baseMessage('操作成功', 'success')
              this.visible = false
              this.$emit('refreshDataList')
            })
            .finally(() => {
              this.dataLoading = false
            })
        }
      })
    }
  }
}
</script>
